import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Descriptions,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Table,
  Tag,
  message,
} from "antd";
import { useDispatch } from "react-redux";
const ApproveMultiQuoteModal = ({
  isApproveMultiQuote,
  setIsApproveMultiQuote,
  record,
  fetchQuoteList,
}) => {
  const dispatch = useDispatch();
  const [quoteFormData, setQuoteFormData] = useState();
  const { Option } = Select;
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchApproveQuote();
  }, []);

  const fetchApproveQuote = () => {
    dispatch({
      type: "FETCH_APPROVE_QUOTE_ATTEMPT",
      payload: record?.quote_id,
      isSuccessFn: true,
      successFunction: (res) => {
        setQuoteFormData(res?.data);

        form.setFieldsValue({
          option_id:
            res?.data?.quote_options?.find((data) => data?.selected_at !== null)
              ?.id ?? null,
        });
      },
      failureFunction: (err) => {},
    });
  };

  const columns = [
    {
      title: "Quote Number",
      dataIndex: "quote_id",
      width: 120,
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      align: "center",
      width: 120,
    },
    {
      title: "Nature of Quote",
      dataIndex: "nature_of_quote",
      align: "center",
      width: 120,
    },
    {
      title: "Total",
      dataIndex: "total_price",
      align: "center",
      width: 120,
    },
    {
      title: "Quote Status",
      align: "center",
      width: 120,
      render: (data) => <Tag color={data?.color_code}>{data?.status}</Tag>,
    },
    {
      title: "Quote Date",
      dataIndex: "quote_date",
      align: "center",
      width: 120,
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      align: "center",
      width: 120,
    },
  ];

  const handleApproveQuote = (values) => {
    setIsLoading(true);
    dispatch({
      type: "APPROVE_MULTI_QUOTE_ATTEMPT",
      payload: {
        id: record?.quote_id,
        formData: {
          ...values,
          branch_id: localStorage.getItem("branchId"),
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsLoading(false);
        setIsApproveMultiQuote(false);
        message.success("Quote approve successful");
        fetchQuoteList();
      },
      failureFunction: (err) => {
        setIsLoading(false);
        message.error("Quote approve failed");
      },
    });
  };

  return (
    <Modal
      title="Approve Multi Quote"
      open={isApproveMultiQuote}
      onCancel={() => setIsApproveMultiQuote(false)}
      width={900}
      footer={false}
    >
      <Table
        dataSource={[
          {
            quote_id: record?.quote_number,
            customer_name: record?.customer_name,
            nature_of_quote: record?.nature_of_quote,
            total_price: quoteFormData?.quote?.total_price,
            status: record?.status,
            quote_date: record?.quote_date,
            expiry_date: record?.expiry_date,
            color_code: record?.color_code,
          },
        ]}
        columns={columns}
        bordered
        pagination={false}
      />

      <Card>
        <Form
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "0 1rem",
          }}
          layout="vertical"
          onFinish={handleApproveQuote}
          initialValues={{ send_email: true }}
          form={form}
        >
          <Form.Item
            rules={[{ required: true, message: "Quote Package is required" }]}
            name="option_id"
            style={{ gridColumn: "span 2", width: "100%" }}
          >
            <Radio.Group>
              {quoteFormData?.quote_options?.map((quote, index) => (
                <Radio value={quote?.id}>
                  <span> Option {index + 1}</span>
                  <QuoteOption quote={quote} index={index} />
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Advance payment" name="advance_payment">
            <Input type="number" placeholder="Advance payment" />
          </Form.Item>
          <Form.Item label="Payment Method" name="payment_method">
            <Select placeholder="Select payment method">
              {quoteFormData?.payment_methods?.map((data) => (
                <Option value={data}>{data}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Special Note" name="note">
            <Input placeholder="Special Note" />
          </Form.Item>
          <Form.Item label="Minimum deposit:(%)" name="minimum_deposit">
            <Input type="number" placeholder="Minimum deposit" />
          </Form.Item>
          <Form.Item
            style={{ gridColumn: "span 2" }}
            name={"send_email"}
            valuePropName="checked"
          >
            <Checkbox> Send email automatically</Checkbox>
          </Form.Item>
          {record?.status?.toLowerCase() !== "win" && (
            <Button
              style={{ width: "fit-content" }}
              htmlType="submit"
              type="primary"
              loading={isLoading}
            >
              Submit
            </Button>
          )}
        </Form>
      </Card>
    </Modal>
  );
};
export default ApproveMultiQuoteModal;

export const QuoteOption = ({ quote, index }) => {
  const columns = [
    {
      title: "Descriptions",
      dataIndex: "descriptions",
      width: 300,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      align: "center",
      width: 160,
    },
    {
      title: "Price: (AUD)",
      dataIndex: "price",
      align: "center",
      width: 160,
    },
    {
      title: "Total: (AUD)",
      dataIndex: "amount",
      align: "center",
      width: 160,
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        pagination={false}
        dataSource={quote?.packages}
        style={{ width: "100%" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <span className="grid-column-container">
          <strong>Regular Price :</strong>
          {quote?.gst_applicable === "1"
            ? Number(quote?.total_price) - Number(quote?.gst)
            : quote?.total}
          (AUD)
        </span>
        {Number(quote?.discount) !== 0 && (
          <span style={{ color: "red" }} className="grid-column-container">
            <strong>Discount :</strong>
            {quote?.is_flat === "1"
              ? Number(quote?.discount)
              : (
                  (Number(quote?.discount) * Number(quote?.price)) /
                  100
                ).toFixed(2)}
            (AUD)
          </span>
        )}
        <span className="grid-column-container">
          <strong>Sub Total :</strong>
          {quote?.price}(AUD)
        </span>
        <span className="grid-column-container">
          <strong>GST :</strong>
          {quote?.gst}(AUD)
        </span>
        <span className="grid-column-container">
          <strong>Total Price :</strong>
          {quote?.total_price}(AUD)
        </span>
      </div>
    </div>
  );
};
