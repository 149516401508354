import {
  Button,
  Pagination,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import AddProjectTesting from "./AddProjectTesting";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import UpdateTestStatus from "./UpdateTestStatus";

export default function TestingProject({ record }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isStatusUpdate, setIsStatusUpdate] = useState(false);
  const [testRecord, setTestRecord] = useState(null);

  const dispatch = useDispatch();
  const { projectTestList } = useSelector((store) => store?.ProjectReducer);

  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchProjectTestList();
  }, [page]);

  useEffect(() => {
    setTotalRecord(projectTestList?.total_tests);
  }, [projectTestList]);

  const fetchProjectTestList = () => {
    dispatch({
      type: "FETCH_PROJECT_TEST_LIST_REQUEST",
      payload: { id: record?.task_id, page: page },
      isSuccessFn: false,
      successFunction: (res) => {},
      failureFunction: (err) => {},
    });
  };

  const deleteRecordData = (record) => {
    dispatch({
      type: "DELETE_TEST_RECORD_ATTEMPT",
      payload: {
        id: record?.test_id,
        formData: { test_id: record?.test_id },
      },
      isSuccessFn: false,
      successFunction: (res) => {
        message.success(res?.data);
        fetchProjectTestList();
      },
      failureFunction: (err) => {
        message.error(err?.response?.data?.message);
      },
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage(current);
  };

  const columns = [
    {
      title: "Test description",
      dataIndex: "test_description",
      align: "center",
    },
    {
      title: "File",
      dataIndex: "testing_file",
      align: "center",
    },
    {
      title: "Added by",
      align: "center",
      render: (data) => `${data?.first_name} ${data?.last_name}`,
    },
    {
      title: "Added Date",
      dataIndex: "added_date",
      align: "center",
    },
    {
      title: "Actions",
      align: "center",
      fixed: "right",
      render: (dataObj) => (
        <Space size="middle">
          <Tooltip title="Update Test Status">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsStatusUpdate(true);
                  setTestRecord(dataObj);
                }}
              >
                <EditFilled style={{ color: "blue" }} />
              </div>
            </a>
          </Tooltip>
          <Popconfirm
            title={`Do you want to delete record?`}
            onConfirm={() => {
              deleteRecordData(dataObj);
            }}
            onCancel={() => {}}
            okText="Ok"
            cancelText="Cancel"
          >
            <a>
              <div
                className="bordered"
                onClick={() => {
                  // setIsDrawerOpen(true);
                  //   setRecord(dataObj);
                }}
              >
                <DeleteFilled style={{ color: "red" }} />
              </div>
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Button
        style={{ width: "fit-content" }}
        type="primary"
        onClick={() => setIsOpenModal(true)}
      >
        Add Test
      </Button>
      <Table
        bordered
        scroll={{ x: 1000 }}
        dataSource={projectTestList?.tests}
        columns={columns}
        pagination={false}
      />

      <Pagination
        style={{
          marginTop: "1rem",
          marginBottom: "1rem",
          alignSelf: "flex-end",
        }}
        // showSizeChanger
        current={page}
        // defaultPageSize={pageSize}
        onChange={onShowSizeChange}
        total={totalRecord}
      />

      {isOpenModal && (
        <AddProjectTesting
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          record={record}
          fetchProjectTestList={fetchProjectTestList}
        />
      )}

      {isStatusUpdate && (
        <UpdateTestStatus
          isStatusUpdate={isStatusUpdate}
          setIsStatusUpdate={setIsStatusUpdate}
          record={testRecord}
        />
      )}
    </div>
  );
}
