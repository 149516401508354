import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  TimePicker,
} from "antd";
import React, { useState } from "react";

export default function OrderAssignedContractor({
  isOrderAssigedContractor,
  setIsOrderAssignedContractor,
  record,
}) {
  const [allocateType, setAllocateType] = useState(null);
  return (
    <Modal
      open={isOrderAssigedContractor}
      onCancel={() => setIsOrderAssignedContractor(false)}
      footer={false}
      title="Job Allocation Center"
    >
      <Form
        layout="vertical"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "0 1rem",
        }}
      >
        <Form.Item label="Assigned To">
          <Select placeholder="Select">
            <Select.Option></Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Job Type">
          <Select placeholder="Select">
            <Select.Option></Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Start Date">
          <DatePicker format={"DD-MM-YYYY"} />
        </Form.Item>
        <Form.Item label="Start Time">
          <TimePicker format={"hh:mm A"} />
        </Form.Item>
        <Form.Item label="Project Deadline Date">
          <DatePicker format={"DD-MM-YYYY"} />
        </Form.Item>
        <Form.Item label="Project Deadline Time">
          <TimePicker format={"hh:mm A"} />
        </Form.Item>
        <Form.Item style={{ gridColumn: "span 2" }} label="Allocate Time by">
          <Radio.Group onChange={(e) => setAllocateType(e.target.value)}>
            <Radio value={"employer"}>Employer</Radio>
            <Radio value="employee">Employee</Radio>
          </Radio.Group>
        </Form.Item>
        {allocateType === "employer" && (
          <Form.Item style={{ gridColumn: "span 2" }} label="Payment Method">
            <Select placeholder="Select">
              <Select.Option></Select.Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item style={{ gridColumn: "span 2" }} label="Special Note">
          <Input.TextArea rows={4} placeholder="write..." />
        </Form.Item>
        <Form.Item label="Job Assigned by">
          <Input />
        </Form.Item>
        <Form.Item valuePropName="checked">
          <Checkbox> Send email to Installer.</Checkbox>
        </Form.Item>
        <Form.Item valuePropName="checked">
          <Checkbox> Send copy of email to me.</Checkbox>
        </Form.Item>
        <Form.Item valuePropName="checked">
          <Checkbox> Send pdf attachment</Checkbox>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
}
