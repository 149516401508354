import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Tag,
  message,
} from "antd";
import { useDispatch } from "react-redux";
const ApproveQuoteModal = ({
  isApproveModalOpen,
  setIsApproveModalOpen,
  record,
  fetchQuoteList,
}) => {
  const dispatch = useDispatch();
  const [quoteFormData, setQuoteFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { Option } = Select;
  useEffect(() => {
    fetchApproveQuote();
  }, []);

  const fetchApproveQuote = () => {
    dispatch({
      type: "FETCH_APPROVE_QUOTE_ATTEMPT",
      payload: record?.quote_id,
      isSuccessFn: true,
      successFunction: (res) => {
        setQuoteFormData(res?.data);
      },
      failureFunction: (err) => {},
    });
  };

  const columns = [
    {
      title: "Quote Number",
      dataIndex: "quote_id",
      align: "center",
      width: 120,
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      align: "center",
      width: 120,
    },
    {
      title: "Nature of Quote",
      dataIndex: "nature_of_quote",
      align: "center",
      width: 120,
    },
    {
      title: "Total",
      dataIndex: "total_price",
      align: "center",
      width: 120,
    },
    {
      title: "Quote Status",
      align: "center",
      width: 120,
      render: (data) => <Tag color={data?.color_code}>{data?.status}</Tag>,
    },
    {
      title: "Quote Date",
      dataIndex: "quote_date",
      align: "center",
      width: 120,
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      align: "center",
      width: 120,
    },
  ];

  const handleApproveQuote = (values) => {
    setIsLoading(true);

    dispatch({
      type: "APPROVE_QUOTE_ATTEMPT",
      payload: {
        id: record?.quote_id,
        formData: {
          ...values,
          branch_id: localStorage.getItem("branchId"),
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsApproveModalOpen(false);
        setIsLoading(false);
        message.success("Quote approve successful");
        fetchQuoteList();
      },
      failureFunction: (err) => {
        setIsLoading(false);
        message.error("Quote approve failed");
      },
    });
  };

  return (
    <Modal
      title="Approve Quote"
      open={isApproveModalOpen}
      onCancel={() => setIsApproveModalOpen(false)}
      width={900}
      footer={false}
    >
      <Table
        dataSource={[
          {
            quote_id: record?.quote_number,
            customer_name: record?.customer_name,
            nature_of_quote: record?.nature_of_quote,
            total_price: quoteFormData?.quote?.total_price,
            status: record?.status,
            quote_date: record?.quote_date,
            expiry_date: record?.expiry_date,
            color_code: record?.color_code,
          },
        ]}
        columns={columns}
        bordered
        pagination={false}
      />
      <Card>
        <Form
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "0 1rem",
          }}
          layout="vertical"
          onFinish={handleApproveQuote}
          initialValues={{ send_email: true }}
        >
          <Form.Item
            // rules={[{ required: true, message: "Advance payment is required" }]}
            label="Advance payment"
            name="advance_payment"
          >
            <Input type="number" placeholder="Advance payment" />
          </Form.Item>
          <Form.Item
            // rules={[{ required: true, message: "Payment method is required" }]}
            label="Payment Method"
            name="payment_method"
          >
            <Select placeholder="Select payment method">
              {quoteFormData?.payment_methods?.map((data) => (
                <Option value={data}>{data}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            // rules={[{ required: true, message: "Special note is required" }]}
            label="Special Note"
            name="note"
          >
            <Input placeholder="Special Note" />
          </Form.Item>
          <Form.Item
            // rules={[{ required: true, message: "Minimum deposit is required" }]}
            label="Minimum deposit:(%)"
            name="minimum_deposit"
          >
            <Input type="number" placeholder="Minimum deposit" />
          </Form.Item>
          <Form.Item
            style={{ gridColumn: "span 2" }}
            name={"send_email"}
            valuePropName="checked"
          >
            <Checkbox> Send email automatically</Checkbox>
          </Form.Item>
          {record?.status?.toLowerCase() !== "win" && (
            <Button
              style={{ width: "fit-content" }}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          )}
        </Form>
      </Card>
    </Modal>
  );
};
export default ApproveQuoteModal;
