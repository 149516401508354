import { Card, Table } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function OrderChecklist({ record }) {
  const dispatch = useDispatch();
  const { orderCheckList } = useSelector((store) => store?.OrderReducer);

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      align: "center",
    },
    {
      title: "Checklist",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Detail",
      dataIndex: "content",
      align: "center",
    },
  ];

  useEffect(() => {
    fetchOrderTask();
  }, []);

  const fetchOrderTask = () => {
    dispatch({
      type: "FETCH_ORDER_CHECKLIST_ATTEMPT",
      payload: record?.order_id,
      isSuccessFn: true,
      successFunction: (res) => {},
      failureFunction: () => {},
    });
  };

  return (
    <Card>
      <Table
        pagination={false}
        bordered
        columns={columns}
        dataSource={orderCheckList?.project_checklists}
      />
    </Card>
  );
}
