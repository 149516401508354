import {
  Button,
  Card,
  Divider,
  Drawer,
  Empty,
  Form,
  Input,
  Modal,
  Spin,
  Timeline,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function UpdateResourceStatus({
  isStatusUpdate,
  setIsStatusUpdate,
  record,
}) {
  const [uploadFile, setUploadFile] = useState(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { resourceLogList } = useSelector((store) => store?.ProjectReducer);

  useEffect(() => {
    fetchUpdateStatusLog();
  }, []);

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    const formData = new FormData();
    formData.append("files", newFile);
    formData.append("branch_id", localStorage.getItem("branchId"));    formData.append("path", "lms_project");

    setIsUploadLoading(true);
    dispatch({
      type: "UPLOAD_FILE_REQUEST",
      payload: formData,
      isSuccessFn: true,
      successFunction: (res) => {
        setUploadFile(res?.data?.source?.src);
        message.success("Upload file successful");
        setIsUploadLoading(false);
      },
      failureFunction: () => {
        setIsUploadLoading(false);
        message.error("Failed to upload file");
      },
    });
  };

  const handleUpdateStatus = (value) => {
    dispatch({
      type: "UPDATE_RESOURCE_STATUS_ATTEMPT",
      payload: {
        id: record?.id,
        formData: {
          ...value,
          file_name: uploadFile ?? "",
          branch_id: localStorage.getItem("branchId"),
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        form.resetFields();
        fetchUpdateStatusLog();
      },
      failureFunction: () => {},
    });
  };

  const fetchUpdateStatusLog = (value) => {
    dispatch({
      type: "FETCH_RESOURCE_STATUS_LOG_ATTEMPT",
      payload: record?.id,
      isSuccessFn: true,
      successFunction: (res) => {},
      failureFunction: () => {},
    });
  };

  return (
    <Drawer
      title="Update Resource Status"
      open={isStatusUpdate}
      onClose={() => setIsStatusUpdate(false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
        }}
      >
        <Form form={form} onFinish={handleUpdateStatus} layout="vertical">
          <Form.Item
            rules={[{ required: true, message: "Comment is required" }]}
            label="Comment"
            name={"details"}
          >
            <Input.TextArea placeholder="Write comment..." rows={2} />
          </Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <Form.Item name={"files"} label="Add New Attachment">
              <Input
                onChange={handleFileChange}
                placeholder="Add File"
                type="file"
              />
            </Form.Item>
            {isUploadLoading && (
              <span>
                <Spin />
              </span>
            )}
          </div>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>

        <Card
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "20rem",
            padding: "0.5rem !important",
          }}
        >
          <h3>Activity Log</h3>
          <Divider style={{ margin: "0.5rem 0" }} />

          <div
            style={{
              height: "100%",
              maxHeight: "15rem",
              overflowY: "scroll",
              padding: "0.5rem 0 0 0",
            }}
          >
            <Timeline>
              {resourceLogList?.results?.length > 0 ? (
                resourceLogList?.results?.map((data) => (
                  <Timeline.Item>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span>
                        {data?.first_name} {data?.last_name} ({data?.added_date}
                        )
                      </span>
                      <p>{data?.resource_title}</p>
                    </div>
                  </Timeline.Item>
                ))
              ) : (
                <Empty />
              )}
            </Timeline>
            {/* <Empty /> */}
          </div>
        </Card>
      </div>
    </Drawer>
  );
}
