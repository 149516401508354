import React, { useEffect, useState } from "react";
import SiderDemo from "../../Components/Layout/SiderDemo";
import {
  Alert,
  Badge,
  Button,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Radio,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import AddOrder from "./AddOrder";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import { EditOutlined } from "@ant-design/icons";
import { AiOutlineEye } from "react-icons/ai";
import UpdateOrder from "./UpdateOrder";
import { FaCircleDollarToSlot, FaCopy, FaUserSecret } from "react-icons/fa6";
import OrderPayment from "./OrderPayment";
import { MdOutlineEditNote } from "react-icons/md";
import OrderAdminNote from "./OrderAdminNote";
import OrderDetail from "./OrderDetail";
import CreateOrderCopy from "./CreateOrderCopy";
import AddInhouseOrder from "./AddInhouseOrder";
import { debounce } from "lodash";
import { VscPreview } from "react-icons/vsc";
import OrderAssignedContractor from "./OrderAssignedContractor";
import UpdatePrice from "./UpdatePrice";

export default function Orders() {
  const dispatch = useDispatch();
  const { globalBranchId } = useSelector((store) => store?.ProfileReducer);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditPriceModalVisible, setIsEditPriceModalVisible] = useState(false);
  const [isCopyOrderModalVisible, setIsCopyOrderModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const { orderList, isLoading } = useSelector((store) => store?.OrderReducer);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openAddInhouseDrawer, setOpenAddInhouseDrawer] = useState(false);
  const [isOrderAssigedContractor, setIsOrderAssignedContractor] =
    useState(false);
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [record, setRecord] = useState(null);
  const [quoteTypeRecord, setQuoteTypeRecord] = useState({
    quoteType: "yes",
    quoteNumber: null,
  });
  const [orderNature, setOrderNature] = useState("");

  const [isOrderPaymentOpen, setOrderPaymentOpen] = useState(false);
  const [isAddAdminNote, setIsAddAdminNote] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setIsloading] = useState(false);
  const [existingQuoteRecord, setExisitingQuoteRecord] = useState(null);
  const [cascadeType, setCascadeType] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    fetchOrderList();
  }, [page, orderNature, globalBranchId]);

  const fetchOrderList = (event = null) => {
    dispatch({
      type: "FETCH_ORDER_LIST_REQUEST",
      payload: {
        page: page,
        keyword: event ? event.target.value : "",
        status: orderNature !== "7" ? orderNature : "",
        invoice: orderNature === "7" ? orderNature : "",
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setTotalRecord(res?.data?.total_orders);
      },
    });
  };

  const columns = [
    {
      title: "Order Number",
      align: "center",
      width: 100,
      render: (dataObj) => (
        <Tooltip title="Order detail">
          <a>
            <div
              className="bordered"
              onClick={() => {
                setRecord(dataObj);
                setIsDrawerOpen(true);
              }}
            >
              {dataObj?.order_number}
            </div>
          </a>
        </Tooltip>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      align: "center",
      width: 150,
    },

    {
      title: "Nature of Order",
      dataIndex: "product",
      align: "center",
      width: 150,
    },
    {
      title: "Price",
      dataIndex: "price",
      align: "center",
      width: 150,
    },
    {
      title: "Due",
      dataIndex: "due_amount",
      align: "center",
      width: 150,
    },
    {
      title: "Commission",
      dataIndex: "commision",
      align: "center",
      width: 150,
    },
    {
      title: "Order Date",
      dataIndex: "added_date",
      align: "center",
      width: 150,
    },

    {
      title: "Order Status",
      align: "center",
      width: 150,

      render: (dataObj) => (
        <Tag
          style={{
            background: dataObj?.invoice_status_code ?? "black",
            color: "white",
          }}
        >
          {dataObj?.invoice_status}
        </Tag>
      ),
    },

    {
      title: "Make Archive",
      align: "center",
      width: 150,

      render: (dataObj) => (
        <Button size="small" type="primary">
          Archive
        </Button>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      align: "center",
      width: 150,
    },
    {
      title: "Estimated Deadline",
      dataIndex: "estimated_deadline",
      align: "center",
      width: 150,
    },
    {
      title: "Updated Price",

      align: "center",
      width: 150,
      render: (dataObj) => (
        <Button
          size="small"
          type="primary"
          onClick={() => {
            setRecord(dataObj);
            setIsEditPriceModalVisible(true);
          }}
        >
          Update Price
        </Button>
      ),
    },
    {
      title: "Order Status",
      align: "center",
      fixed: "right",
      width: 110,

      render: (dataObj) => (
        <Tag
          style={{
            background: dataObj?.order_status_code ?? "black",
            color: "white",
          }}
        >
          {dataObj?.order_status}
        </Tag>
      ),
    },

    {
      title: "Action",
      align: "center",
      width: 250,
      fixed: "right",
      render: (dataObj) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.8rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Update Order">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsEditModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>{" "}
          <Tooltip title="Duplicate Order">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsCopyOrderModalVisible(true);
                }}
              >
                <FaCopy />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Order payment">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setOrderPaymentOpen(true);
                }}
              >
                <FaCircleDollarToSlot />
              </div>
            </a>
          </Tooltip>
          {/* <Tooltip title="Order detail">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsDrawerOpen(true);
                }}
              >
                <VscPreview />
              </div>
            </a>
          </Tooltip> */}
          <Tooltip title="Assign to contractor">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsOrderAssignedContractor(true);
                }}
              >
                <FaUserSecret />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Order Seen">
            <Badge showZero count={dataObj?.orderseen} overflowCount={99}>
              <a>
                <div className="bordered">
                  <AiOutlineEye className="bordered" />{" "}
                </div>{" "}
              </a>
            </Badge>
          </Tooltip>
          <Tooltip title="Admin Note">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsAddAdminNote(true);
                }}
              >
                <MdOutlineEditNote style={{ fontSize: "1.2rem" }} />
              </div>
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];

  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };

  const handleSetQuoteTypeRecord = (values = null) => {
    setIsloading(true);
    dispatch({
      type: "FETCH_ORDER_PRESET_FORM_REQUEST",
      payload: {
        endPoint:
          values?.quoteType === "yes"
            ? `/add_order?quote_no=${
                values?.quoteNumber
              }&branch_id=${localStorage.getItem("branchId")}`
            : `/add_order?branch_id=${localStorage.getItem("branchId")}`,
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsloading(false);
        setIsModalOpen(false);
        setExisitingQuoteRecord(res?.data);
        (values?.quoteType === "yes" || values?.quoteType === "no") &&
          setIsAddModalVisible(true);
        values?.quoteType === "inhouse" && setOpenAddInhouseDrawer(true);
      },
      failureFunction: (err) => {
        setIsloading(false);
        message.warning(err?.response?.data?.message);
      },
    });
  };

  const handleDebounceChange = debounce(fetchOrderList, 1500);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleCascadeTask = () => {
    dispatch({
      type: "CASCADE_ATTEMPT",
      payload: {
        endPoint: "order_cascade_action",
        formData: {
          ids: selectedRowKeys,
          action: cascadeType,
          branch_id: localStorage.getItem("branchId"),
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data);
        setSelectedRowKeys([]);
        setCascadeType(null);
        fetchOrderList();
      },
      failureFunction: (res) => {
        message.error(res?.response?.data?.message);
      },
    });
  };

  return (
    <SiderDemo>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Segmented
          style={{
            marginBottom: "0.5rem",
            width: "fit-content",
          }}
          options={[
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>All [{orderList?.all_orders ?? 0}]</div>
                </div>
              ),
              value: "",
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>
                    Pending [{orderList?.order_status[0]?.number_order ?? 0}]
                  </div>
                </div>
              ),
              value: "15",
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>
                    Processing [{orderList?.order_status[1]?.number_order ?? 0}]
                  </div>
                </div>
              ),
              value: "16",
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>
                    Completed [{orderList?.order_status[2]?.number_order ?? 0}]
                  </div>
                </div>
              ),
              value: "17",
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>
                    Hold [{orderList?.order_status[3]?.number_order ?? 0}]
                  </div>
                </div>
              ),
              value: "18",
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>
                    Cancel [{orderList?.order_status[5]?.number_order ?? 0}]
                  </div>
                </div>
              ),
              value: "20",
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>
                    Assigned Installer [
                    {orderList?.order_status[4]?.number_order ?? 0}]
                  </div>
                </div>
              ),
              value: "19",
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>
                    Deposit Taken [
                    {orderList?.invoice_status[1]?.number_order ?? 0}]
                  </div>
                </div>
              ),
              value: "7",
            },
          ]}
          onChange={(value) => {
            setOrderNature(value);
          }}
          value={orderNature}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <button
            className="button"
            onClick={() => {
              // setIsAddModalVisible(true);
              setIsModalOpen(true);
            }}
          >
            <span>Add Order</span>
          </button>{" "}
          <Input.Search
            className="search"
            style={{ maxWidth: "18rem" }}
            placeholder="Search Order"
            allowClear
            size="large"
            onChange={handleDebounceChange}
          />
        </div>
        {selectedRowKeys?.length > 0 && (
          <Alert
            message={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                Do you want to
                <Select
                  value={cascadeType}
                  onChange={(value) => setCascadeType(value)}
                  placeholder="Select Action"
                  style={{ width: "8rem" }}
                >
                  <Select.Option value="delete">Delete</Select.Option>
                  <Select.Option value="publish">Publish</Select.Option>
                  <Select.Option value="unpublish">Unpublish</Select.Option>
                </Select>
                {cascadeType && (
                  <Popconfirm
                    title={`Do you want to ${cascadeType} record?`}
                    onConfirm={() => {
                      handleCascadeTask();
                    }}
                    onCancel={() => {}}
                    okText="Ok"
                    cancelText="Cancel"
                  >
                    <Button danger type="primary">
                      Ok
                    </Button>
                  </Popconfirm>
                )}
              </div>
            }
            type="warning"
          />
        )}
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <Table
            columns={columns}
            bordered
            scroll={{ x: 1000 }}
            dataSource={orderList?.orders?.map((data) => ({
              ...data,
              key: data?.order_id,
            }))}
            pagination={false}
            rowSelection={rowSelection}
          />
        )}
        <Pagination
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            alignSelf: "flex-end",
          }}
          // showSizeChanger
          current={page}
          // defaultPageSize={pageSize}
          onChange={onShowSizeChange}
          total={totalRecord}
        />
        <Modal
          title="Select Order Type"
          open={isModalOpen}
          onCancel={() => {
            form.resetFields();
            setQuoteTypeRecord((prev) => ({ ...prev, quoteType: "yes" }));
            setIsModalOpen(false);
          }}
          footer={false}
        >
          <Form
            form={form}
            layout="vertical"
            initialValues={{ quoteType: "yes" }}
            onFinish={handleSetQuoteTypeRecord}
          >
            <Form.Item
              rules={[{ required: true, message: "Select quote type" }]}
              label={"Is Existing Quote ?"}
              name={"quoteType"}
            >
              <Radio.Group
                onChange={(e) =>
                  setQuoteTypeRecord((prev) => ({
                    ...prev,
                    quoteType: e.target.value,
                  }))
                }
              >
                <Radio value={"yes"}>Yes</Radio>
                <Radio value={"no"}>No</Radio>
                <Radio value={"inhouse"}>Inhouse</Radio>
              </Radio.Group>
            </Form.Item>

            {quoteTypeRecord?.quoteType === "yes" && (
              <Form.Item
                rules={[{ required: true, message: "Enter quote number" }]}
                label={"Quote Number"}
                name={"quoteNumber"}
              >
                <Input placeholder="Quote Number" type="number" />
              </Form.Item>
            )}
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>

            <Button
              style={{ marginLeft: "0.3rem" }}
              danger
              type="primary"
              onClick={() => {
                form.resetFields();
                setQuoteTypeRecord((prev) => ({ ...prev, quoteType: "yes" }));
                setIsModalOpen(false);
              }}
              disabled={loading}
            >
              Cancel
            </Button>
          </Form>
        </Modal>
      </div>
      {isAddModalVisible && (
        <AddOrder
          existingQuoteRecord={existingQuoteRecord}
          quoteTypeRecord={quoteTypeRecord}
          isAddModalVisible={isAddModalVisible}
          setIsAddModalVisible={setIsAddModalVisible}
          fetchOrderList={fetchOrderList}
        />
      )}

      {openAddInhouseDrawer && (
        <AddInhouseOrder
          existingQuoteRecord={existingQuoteRecord}
          openAddInhouseDrawer={openAddInhouseDrawer}
          setOpenAddInhouseDrawer={setOpenAddInhouseDrawer}
        />
      )}
      {isEditModalVisible && (
        <UpdateOrder
          record={record}
          isEditModalVisible={isEditModalVisible}
          setIsEditModalVisible={setIsEditModalVisible}
          fetchOrderList={fetchOrderList}
        />
      )}

      {isEditPriceModalVisible && (
        <UpdatePrice
          record={record}
          isEditPriceModalVisible={isEditPriceModalVisible}
          setIsEditPriceModalVisible={setIsEditPriceModalVisible}
          fetchOrderList={fetchOrderList}
        />
      )}

      {isCopyOrderModalVisible && (
        <CreateOrderCopy
          record={record}
          isCopyOrderModalVisible={isCopyOrderModalVisible}
          setIsCopyOrderModalVisible={setIsCopyOrderModalVisible}
          fetchOrderList={fetchOrderList}
        />
      )}

      {isOrderPaymentOpen && (
        <OrderPayment
          fetchOrderList={fetchOrderList}
          isOrderPaymentOpen={isOrderPaymentOpen}
          setOrderPaymentOpen={setOrderPaymentOpen}
          record={record}
        />
      )}
      {isAddAdminNote && (
        <OrderAdminNote
          isAddAdminNote={isAddAdminNote}
          setIsAddAdminNote={setIsAddAdminNote}
          record={record}
        />
      )}
      {isDrawerOpen && (
        <OrderDetail
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          record={record}
        />
      )}

      {isOrderAssigedContractor && (
        <OrderAssignedContractor
          isOrderAssigedContractor={isOrderAssigedContractor}
          setIsOrderAssignedContractor={setIsOrderAssignedContractor}
          record={record}
        />
      )}
    </SiderDemo>
  );
}
