import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { CgAssign } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { formattedDate } from "../../HelperFunction";
import AddVisaCases from "./AddVisas";
import UpdateCaseStatus from "./UpdateCaseStatus";
import CaseStatusHistory from "./CaseStatusHistory";
import { AiOutlineEye, AiOutlineHistory } from "react-icons/ai";
import VisaCaseDetails from "./VisaCaseDetails";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import AssignAgents from "./AssignAgents";
import UpdateCase from "./UpdateCase";
import moment from "moment";
const { Search } = Input;

const columns = [
  {
    title: "Case No.",
    dataIndex: "caseNo",
  },
  {
    title: "Client",
    dataIndex: "name",
    sorter: (a, b) => {
      if (
        a?.name?.props?.children?.props?.children?.props?.children <
        b?.name?.props?.children?.props?.children?.props?.children
      ) {
        return -1;
      }
      if (
        a?.name?.props?.children?.props?.children?.props?.children >
        b?.name?.props?.children?.props?.children?.props?.children
      ) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Type",
    dataIndex: "visaCategory",
    align: "center",
  },
  {
    title: "Sales Rep",
    dataIndex: "salesRepName",
    align: "center",
  },
  {
    title: "Grand Total",
    dataIndex: "totalAmount",
    align: "center",
  },
  {
    title: "Case Assign To",
    dataIndex: "caseAssignedTo",
    align: "center",
  },
  {
    title: "Lead Type",
    dataIndex: "leadType",
    align: "center",
  },
  {
    title: "Campaign",
    dataIndex: "campaign",
    align: "center",
  },
  {
    title: "Added Date",
    dataIndex: "addedOn",
    align: "center",
  },
  {
    title: "Visa Outcome",
    dataIndex: "visaOutcome",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
    fixed: "right",
  },
];

const Visas = ({ isHeadBranch, branchRecord }) => {
  const store = useSelector((state) => {
    return {
      VisasState: state.VisasReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const [searchValue, setSearchValue] = useState("");
  const [isUpdateCase, setIsUpdateCase] = useState(false);

  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);
  const [isUpdateVisasModalVisible, setIsUpdateVisasModalVisible] =
    useState(false);
  // const [record, setRecord] = useState({});

  const [record, setRecord] = useState(null);
  const [VisasType, setVisasType] = useState("all-visas");
  const [isUpdateCaseStatusModalVisible, setIsUpdateCaseStatusModalVisible] =
    useState(false);
  const [isStatusHistoryModelVisible, setIsStatusHistoryModelVisible] =
    useState(false);
  const [isVisaCaseDetailsModalVisible, setIsVisaCaseDetailsModalVisible] =
    useState(false);
  const [isAssignAgentsModallVisible, setIsAssignAgentsModallVisible] =
    useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  const onSearch = (e) => {
    if (VisasType === "IN_PROGRESS") {
      dispatch({
        type: "SEARCH_IN_PROGRESS_CASES_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else if (VisasType === "NOT_ASSIGNED") {
      dispatch({
        type: "SEARCH_NOT_ASSIGNED_CASES_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else if (VisasType === "COMPLETED") {
      dispatch({
        type: "SEARCH_COMPLETED_CASES_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else if (VisasType === "all-visas") {
      dispatch({
        type: "SEARCH_CASES_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    }
  };
  const successFunction = (response) => {
    setCaseData((previousData) => {
      return {
        ...previousData,
        casePerfix: response?.data?.prefix?.casePrefix,
      };
    });
  };
  const [caseData, setCaseData] = useState({
    casePerfix: "",
  });
  useEffect(() => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
    if (VisasType === "IN_PROGRESS") {
      dispatch({
        type: "FETCH_IN_PROGRESS_FILTER_VISAS_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else if (VisasType === "NOT_ASSIGNED") {
      dispatch({
        type: "FETCH_NOT_ASSIGNED_FILTER_VISAS_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else if (VisasType === "COMPLETED") {
      dispatch({
        type: "FETCH_COMPLETED_FILTER_VISAS_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else if (VisasType === "all-visas") {
      dispatch({
        type: "FETCH_VISA_CASES_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else {
      fetchAllCases();
    }
  }, [VisasType, page, pageSize, store.branchState?.branchRecord]);

  const fetchAllCases = () => {
    dispatch({
      type: "FETCH_VISA_CASES_REQUEST",
      payload: {
        page,
        pageSize,
        branchId:
          store.branchState?.branchRecord.id ??
          localStorage.getItem("branchId"),
      },
    });
  };

  let data = [];
  if (VisasType === "all-visas") {
    data = store?.VisasState.visas?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        caseNo: `${caseData.casePerfix ?? ""}${dataObj.id}`,
        visaOutcome: dataObj.visaOutcome ? (
          <Tag
            color={
              dataObj.visaOutcomeColorCode
                ? dataObj.visaOutcomeColorCode
                : "White"
            }
          >
            {dataObj.visaOutcome}
          </Tag>
        ) : (
          "N/A"
        ),
        caseAssignedTo: dataObj?.caseAssignedTo ?? "N/A",
        caseStatus: dataObj.caseStatus?.caseStatus ?? "N/A",
        campaign: dataObj?.customer?.campaign?.name ?? "N/A",

        name: (
          <Tooltip title="Case Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  dispatch({
                    type: "ADD_DOCUMENT_CHECKLIST",
                    payload: {
                      requiredWorkflowCheckList: [
                        ...dataObj.requiredWorkflowCheckList,
                      ],
                    },
                  });
                  dispatch({
                    type: "ADD_COURSE_CHECKLIST",
                    payload: {
                      requiredCoursesCheckList: [
                        ...dataObj.requiredCoursesCheckList,
                      ],
                    },
                  });
                  setRecord(dataObj);
                  setIsVisaCaseDetailsModalVisible(true);
                }}
              >
                {dataObj.customer.firstName + " " + dataObj.customer.lastName}
              </div>
            </a>
          </Tooltip>
        ),
        visaCategory: dataObj.visaCategory ?? "N/A",
        salesRepName:
          dataObj.salesRepresentativeResponse?.salesRepName ?? "N/A",
        totalAmount: ` ${dataObj.totalAmount ?? 0} ${dataObj?.currency ?? ""}`,
        percentage: dataObj.institute?.percentage ?? "N/A",
        commission: dataObj.institute?.commission ?? "N/A",
        // addedOn: formattedDate(dataObj.addedOn),
        addedOn: dataObj?.addedOn
          ? moment(dataObj?.addedOn).format("MM/DD/YYYY hh-mm-ss")
          : null,
        leadType: dataObj.leadType ?? "N/A",
        status: dataObj.workFlowStatusDto?.statusName ? (
          <Tag
            color={
              dataObj.workFlowStatusDto?.colorCode
                ? dataObj.workFlowStatusDto?.colorCode
                : "green"
            }
          >
            {dataObj.workFlowStatusDto?.statusName}
          </Tag>
        ) : null,

        actions: (
          <Space size="middle">
            <Tooltip title="Update Case Status">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    // setIsUpdateCaseStatusModalVisible(true);
                    setIsUpdateCase(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
            {/* <Tooltip title="Delete Visas">
            <Popconfirm
              title="Are you sure to delete this Visas?"
              onConfirm={() => {
                dispatch({
                  type: "DELETE_VISAS_REQUEST",
                  payload: { id: dataObj.id },
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <div className="bordered">
                  <DeleteOutlined />
                </div>
              </a>{" "}
            </Popconfirm>
          </Tooltip> */}
            <Tooltip title="Case Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    dispatch({
                      type: "ADD_DOCUMENT_CHECKLIST",
                      payload: {
                        requiredWorkflowCheckList: [
                          ...dataObj.requiredWorkflowCheckList,
                        ],
                      },
                    });
                    dispatch({
                      type: "ADD_COURSE_CHECKLIST",
                      payload: {
                        requiredCoursesCheckList: [
                          ...dataObj.requiredCoursesCheckList,
                        ],
                      },
                    });
                    setRecord(dataObj);
                    setIsVisaCaseDetailsModalVisible(true);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {/* {isHeadBranch && (
              <Tooltip title="View status History">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStatusHistoryModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineHistory />
                  </div>
                </a>
              </Tooltip>
            )} */}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
              "ASSIGN_AGENTS"
            ) && (
              <Tooltip title="Assign Agents">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsAssignAgentsModallVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <CgAssign />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
  } else if (VisasType === "IN_PROGRESS") {
    data = store?.VisasState.filterVisas?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        caseNo: `${caseData.casePerfix ?? ""}${dataObj.id}`,
        caseAssignedTo: dataObj?.caseAssignedTo ?? "N/A",
        caseStatus: dataObj.caseStatus?.caseStatus ?? "N/A",
        campaign: dataObj?.customer?.campaign?.name ?? "N/A",

        visaOutcome: dataObj.visaOutcome ? (
          <Tag
            color={
              dataObj.visaOutcomeColorCode
                ? dataObj.visaOutcomeColorCode
                : "White"
            }
          >
            {dataObj.visaOutcome}
          </Tag>
        ) : (
          "N/A"
        ),
        name: (
          <Tooltip title="Case Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  dispatch({
                    type: "ADD_DOCUMENT_CHECKLIST",
                    payload: {
                      requiredWorkflowCheckList: [
                        ...dataObj.requiredWorkflowCheckList,
                      ],
                    },
                  });
                  dispatch({
                    type: "ADD_COURSE_CHECKLIST",
                    payload: {
                      requiredCoursesCheckList: [
                        ...dataObj.requiredCoursesCheckList,
                      ],
                    },
                  });
                  setRecord(dataObj);
                  setIsVisaCaseDetailsModalVisible(true);
                }}
              >
                {dataObj.customer.firstName + " " + dataObj.customer.lastName}
              </div>
            </a>
          </Tooltip>
        ),
        visaCategory: dataObj.visaCategory ?? "N/A",
        salesRepName:
          dataObj.salesRepresentativeResponse?.salesRepName ?? "N/A",
        totalAmount: dataObj.totalAmount ?? 0,
        percentage: dataObj.institute?.percentage ?? "N/A",
        commission: dataObj.institute?.commission ?? "N/A",
        addedOn: formattedDate(dataObj.addedOn),
        leadType: dataObj.leadType ?? "N/A",
        status: dataObj.workFlowStatusDto?.statusName ? (
          <Tag
            color={
              dataObj.workFlowStatusDto?.colorCode
                ? dataObj.workFlowStatusDto?.colorCode
                : "green"
            }
          >
            {dataObj.workFlowStatusDto?.statusName}
          </Tag>
        ) : null,

        statusUpdate: (
          <Space>
            <Tooltip title="Case Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsVisaCaseDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {/* {isHeadBranch && (
              <Tooltip title="View status History">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsStatusHistoryModelVisible(true);
                    }}
                  >
                    <AiOutlineHistory />
                  </div>
                </a>
              </Tooltip>
            )} */}
          </Space>
        ),

        actions: (
          <Space size="middle">
            {/* <Tooltip title="Update Case Status">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateCaseStatusModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip> */}
            {/* <Tooltip title="Delete Visas">
              <Popconfirm
                title="Are you sure to delete this Visas?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_VISAS_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip> */}

            <Tooltip title="Case Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsVisaCaseDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {/* {isHeadBranch && (
              <Tooltip title="View status History">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStatusHistoryModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineHistory />
                  </div>
                </a>
              </Tooltip>
            )} */}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
              "ASSIGN_AGENTS"
            ) && (
              <Tooltip title="Assign Agents">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsAssignAgentsModallVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <CgAssign />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
  } else if (VisasType === "NOT_ASSIGNED") {
    data = store?.VisasState.filterVisas?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        caseNo: `${caseData.casePerfix ?? ""}${dataObj.id}`,
        caseAssignedTo: dataObj?.caseAssignedTo ?? "N/A",
        visaOutcome: dataObj.visaOutcome ? (
          <Tag
            color={
              dataObj.visaOutcomeColorCode
                ? dataObj.visaOutcomeColorCode
                : "White"
            }
          >
            {dataObj.visaOutcome}
          </Tag>
        ) : (
          "N/A"
        ),
        caseStatus: dataObj.caseStatus?.caseStatus ?? "N/A",
        campaign: dataObj?.customer?.campaign?.name ?? "N/A",

        name: (
          <Tooltip title="Case Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  dispatch({
                    type: "ADD_DOCUMENT_CHECKLIST",
                    payload: {
                      requiredWorkflowCheckList: [
                        ...dataObj.requiredWorkflowCheckList,
                      ],
                    },
                  });
                  dispatch({
                    type: "ADD_COURSE_CHECKLIST",
                    payload: {
                      requiredCoursesCheckList: [
                        ...dataObj.requiredCoursesCheckList,
                      ],
                    },
                  });
                  setRecord(dataObj);
                  setIsVisaCaseDetailsModalVisible(true);
                }}
              >
                {dataObj.customer.firstName + " " + dataObj.customer.lastName}
              </div>
            </a>
          </Tooltip>
        ),
        visaCategory: dataObj.visaCategory ?? "N/A",
        salesRepName:
          dataObj.salesRepresentativeResponse?.salesRepName ?? "N/A",
        totalAmount: dataObj.totalAmount,
        percentage: dataObj.institute?.percentage ?? "N/A",
        commission: dataObj.institute?.commission ?? "N/A",
        addedOn: formattedDate(dataObj.addedOn),
        leadType: dataObj.leadType ?? "N/A",
        status: dataObj.workFlowStatusDto?.statusName ? (
          <Tag
            color={
              dataObj.workFlowStatusDto?.colorCode
                ? dataObj.workFlowStatusDto?.colorCode
                : "green"
            }
          >
            {dataObj.workFlowStatusDto?.statusName}
          </Tag>
        ) : null,

        actions: (
          <Space size="middle">
            {/* <Tooltip title="Update Case Status">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateCaseStatusModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip> */}
            {/* <Tooltip title="Delete Visas">
                <Popconfirm
                  title="Are you sure to delete this Visas?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_VISAS_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip> */}
            <Tooltip title="Case Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsVisaCaseDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {/* {isHeadBranch && (
              <Tooltip title="View status History">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStatusHistoryModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineHistory />
                  </div>
                </a>
              </Tooltip>
            )} */}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
              "ASSIGN_AGENTS"
            ) && (
              <Tooltip title="Assign Agents">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsAssignAgentsModallVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <CgAssign />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
  } else if (VisasType === "COMPLETED") {
    data = store?.VisasState.filterVisas?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        caseNo: `${caseData.casePerfix ?? ""}${dataObj.id}`,
        caseAssignedTo: dataObj?.caseAssignedTo ?? "N/A",
        visaOutcome: dataObj.visaOutcome ? (
          <Tag
            color={
              dataObj.visaOutcomeColorCode
                ? dataObj.visaOutcomeColorCode
                : "White"
            }
          >
            {dataObj.visaOutcome}
          </Tag>
        ) : (
          "N/A"
        ),
        caseStatus: dataObj.caseStatus?.caseStatus ?? "N/A",
        campaign: dataObj?.customer?.campaign?.name ?? "N/A",

        name: (
          <Tooltip title="Case Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  dispatch({
                    type: "ADD_DOCUMENT_CHECKLIST",
                    payload: {
                      requiredWorkflowCheckList: [
                        ...dataObj.requiredWorkflowCheckList,
                      ],
                    },
                  });
                  dispatch({
                    type: "ADD_COURSE_CHECKLIST",
                    payload: {
                      requiredCoursesCheckList: [
                        ...dataObj.requiredCoursesCheckList,
                      ],
                    },
                  });
                  setRecord(dataObj);
                  setIsVisaCaseDetailsModalVisible(true);
                }}
              >
                {dataObj.customer.firstName + " " + dataObj.customer.lastName}
              </div>
            </a>
          </Tooltip>
        ),
        visaCategory: dataObj.visaCategory ?? "N/A",
        salesRepName:
          dataObj.salesRepresentativeResponse?.salesRepName ?? "N/A",
        totalAmount: dataObj.totalAmount,
        percentage: dataObj.institute?.percentage ?? "N/A",
        commission: dataObj.institute?.commission ?? "N/A",
        addedOn: formattedDate(dataObj.addedOn),
        leadType: dataObj.leadType ?? "N/A",
        status: dataObj.workFlowStatusDto?.statusName ? (
          <Tag
            color={
              dataObj.workFlowStatusDto?.colorCode
                ? dataObj.workFlowStatusDto?.colorCode
                : "green"
            }
          >
            {dataObj.workFlowStatusDto?.statusName}
          </Tag>
        ) : null,

        actions: (
          <Space size="middle">
            {/* <Tooltip title="Update Case Status">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateCaseStatusModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip> */}
            {/* <Tooltip title="Delete Visas">
                <Popconfirm
                  title="Are you sure to delete this Visas?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_VISAS_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip> */}
            <Tooltip title="Case Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsVisaCaseDetailsModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {/* {isHeadBranch && (
              <Tooltip title="View status History">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStatusHistoryModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineHistory />
                  </div>
                </a>
              </Tooltip>
            )} */}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
              "ASSIGN_AGENTS"
            ) && (
              <Tooltip title="Assign Agents">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsAssignAgentsModallVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <CgAssign />
                  </div>
                </a>
              </Tooltip>
            )}
          </Space>
        ),
      };
    });
  }
  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="leadFlexRow">
          <div style={{ width: "30%" }}>
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
              "ADD"
            ) && (
              <button
                className="button"
                onClick={() => {
                  setIsAddVisasModalVisible(true);
                }}
                // disabled={!isHeadBranch}
              >
                <span>Add Cases</span>
              </button>
            )}
          </div>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>ALL</div>
                  </div>
                ),
                value: "all-visas",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>IN PROGRESS</div>
                  </div>
                ),
                value: "IN_PROGRESS",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>NOT ASSIGNED</div>
                  </div>
                ),
                value: "NOT_ASSIGNED",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>COMPLETED</div>
                  </div>
                ),
                value: "COMPLETED",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setVisasType(value);
            }}
          />
          <Search
            className="search"
            style={{
              width: "30%",
              borderRadius: "10px ! important",
            }}
            placeholder="Search for cases"
            allowClear
            size="large"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              onSearch(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "4rem",
          }}
        >
          {store?.VisasState.isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          )}

          {store?.VisasState.visas?.totalData ? (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={store?.VisasState.visas?.totalPage * 10}
            />
          ) : null}
        </div>
      </div>
      {isAddVisasModalVisible && (
        <AddVisaCases
          isAddVisaCasesModalVisible={isAddVisasModalVisible}
          setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
        />
      )}
      {/* <AddVisas
        isAddVisasModalVisible={isAddVisasModalVisible}
        setIsAddVisasModalVisible={setIsAddVisasModalVisible}
      />
      <UpdateVisas
        record={record}
        isUpdateVisasModalVisible={isUpdateVisasModalVisible}
        setIsUpdateVisasModalVisible={setIsUpdateVisasModalVisible}
      /> */}

      {isUpdateCaseStatusModalVisible && (
        <UpdateCaseStatus
          record={record}
          isUpdateCaseStatusModalVisible={isUpdateCaseStatusModalVisible}
          setIsUpdateCaseStatusModalVisible={setIsUpdateCaseStatusModalVisible}
        />
      )}
      {isStatusHistoryModelVisible && (
        <CaseStatusHistory
          record={record}
          isUpdateCaseStatusModalVisible={isStatusHistoryModelVisible}
          setIsUpdateCaseStatusModalVisible={setIsStatusHistoryModelVisible}
        />
      )}
      {isVisaCaseDetailsModalVisible && (
        <VisaCaseDetails
          caseData={caseData}
          visaRecord={record}
          record={record}
          isVisaCaseDetailsModalVisible={isVisaCaseDetailsModalVisible}
          setIsVisaCaseDetailsModalVisible={setIsVisaCaseDetailsModalVisible}
        />
      )}
      {isAssignAgentsModallVisible && (
        <AssignAgents
          record={record}
          isAssignAgentsModalVisible={isAssignAgentsModallVisible}
          setIsAssignAgentsModalVisible={setIsAssignAgentsModallVisible}
        />
      )}

      {isUpdateCase && (
        <UpdateCase
          fetchAllCases={fetchAllCases}
          record={record}
          isUpdateCase={isUpdateCase}
          setIsUpdateCase={setIsUpdateCase}
        />
      )}
    </SiderDemo>
  );
};
export default Visas;
