import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Tag,
  TimePicker,
} from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

const UpdateSupport = ({
  isUpdateSupportModalVisible,
  setIsUpdateSupportModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      timingDropdownState: state.AppointmentsReducer,
      leadState: state.LeadReducer,
    };
  });
  const { TextArea } = Input;
  const { Option } = Select;

  return (
    <Drawer
      title="Update Support"
      placement="right"
      open={isUpdateSupportModalVisible}
      onClose={() => {
        setIsUpdateSupportModalVisible(false);
      }}
      width={1163}
      footer={null}
    >
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Form
          layout="vertical"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
            maxWidth: "1024px",
            width: "100%",
          }}
        >
          <Form.Item name="customer" label="Customer">
            <Select placeholder="Select Customer">
              <Option>ABC</Option>
            </Select>{" "}
          </Form.Item>
          <Form.Item name="paidSupport" label="Paid Support">
            <Select placeholder="Select Paid Support">
              <Option>ABC</Option>
            </Select>{" "}
          </Form.Item>

          <Form.Item name="Title" label="Title">
            <Input placeholder="Enter Title" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            style={{ gridColumn: "span 2" }}
          >
            <TextArea rows={8} placeholder="Write here..." />
          </Form.Item>
          <Form.Item name="priority" label="Priority">
            <Radio.Group>
              <Radio.Button value="High">High</Radio.Button>
              <Radio.Button value="Low">Low</Radio.Button>
              <Radio.Button value="Medium">Medium</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="status" label="Support Status">
            <Select placeholder="Select Support Status">
              <Option>ABC</Option>
            </Select>{" "}
          </Form.Item>

          <Button
            style={{ width: "fit-content" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form>
      </div>
    </Drawer>
  );
};

export default UpdateSupport;
