import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Spin,
  TimePicker,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const AddLead = ({ isAddLeadVisible, setIsAddLeadVisible, fetchLeadsData }) => {
  const { TextArea } = Input;
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { leadFormData } = useSelector((store) => store?.LeadReducer);
  const [customerList, setCustomerList] = useState([]);
  const [uploadFile, setUploadFile] = useState(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  useEffect(() => {
    fetchAddLeadForm();
  }, []);

  const fetchAddLeadForm = (values) => {
    dispatch({
      type: "FETCH_ADD_LEAD_FORM_REQUEST",
      payload: {
        branch_id: localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setCustomerList(res?.data?.customers);
      },
    });
  };

  const handleAddLead = (values) => {
    dispatch({
      type: "ADD_LEAD_REQUEST",
      payload: {
        ...values,
        branch_id: localStorage.getItem("branchId"),
        email_send: values?.email_send?.length > 0 ? true : false,
        email_copy_send: values?.email_copy_send?.length > 0 ? true : false,
        campaigns: [values?.campaigns] ?? [],
        txt_files: uploadFile ? [uploadFile] : [],
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsAddLeadVisible(false);
        fetchLeadsData();
      },
    });
  };

  const handleSelectCustomer = (value, option) => {
    const selectedCustomer = leadFormData?.customers?.find(
      (data) => data?.customer_id === option?.key
    );

    form.setFieldsValue({
      name: selectedCustomer?.customer_name,
      email: selectedCustomer?.email,
      company: selectedCustomer?.company_name,
      phone: selectedCustomer?.mobile_number,
      // sales_rep: selectedCustomer?.sales_rep,
    });
  };

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    const formData = new FormData();
    formData.append("files", newFile);
    formData.append("branch_id", localStorage.getItem("branchId"));
    formData.append("path", "lms_project");

    setIsUploadLoading(true);
    dispatch({
      type: "UPLOAD_FILE_REQUEST",
      payload: formData,
      isSuccessFn: true,
      successFunction: (res) => {
        setUploadFile(res?.data?.source?.src);
        message.success("Upload file successful");
        setIsUploadLoading(false);
      },
      failureFunction: () => {
        setIsUploadLoading(false);
        message.error("Failed to upload file");
      },
    });
  };

  return (
    <Drawer
      title="Add Lead"
      placement="right"
      open={isAddLeadVisible}
      onClose={() => {
        setIsAddLeadVisible(false);
      }}
      width={1163}
      footer={null}
    >
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Form
          layout="vertical"
          style={{
            maxWidth: "1024px",
            width: "100%",
          }}
          className="grid-container"
          onFinish={handleAddLead}
          form={form}
        >
          <Form.Item
            className="grid-full-child"
            name="customer"
            label="Customer"
          >
            <Select
              onChange={handleSelectCustomer}
              onSearch={(value) => {
                const searchKey = value.trim();
                const test = leadFormData?.customers?.filter((employee) =>
                  employee.customer_name
                    .toLowerCase()
                    .includes(searchKey.toLowerCase())
                );
                setCustomerList(
                  searchKey === "" ? leadFormData?.customers : test
                );
              }}
              showSearch
              filterOption={false}
              placeholder="Select Customer"
            >
              {customerList?.map((data) => (
                <Select.Option
                  key={data?.customer_id}
                  value={data?.customer_id}
                >
                  {data?.customer_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="campaigns" label="Campaign">
            <Select placeholder="Select Campaign">
              {leadFormData?.campaigns?.map((data) => (
                <Option value={data?.id}>{data?.campaign_name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="sales_rep" label="Sales Rep">
            <Select placeholder="Select Sales Rep">
              {leadFormData?.sales_rep?.map((data) => (
                <Option value={data?.userid}>
                  {data?.first_name} {data?.last_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Lead name is required" }]}
          >
            <Input placeholder="Enter Name" />
          </Form.Item>
          <Form.Item name="company" label="Company">
            <Input placeholder="Enter Company" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Lead email is required" }]}
          >
            <Input type="email" placeholder="Enter Email" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone"
            rules={[
              { required: true, message: "Lead phone is required" },
              {
                pattern: /^\d{9,11}$/,
                message: "Phone number must be between 9 and 11 digits",
              },
            ]}
          >
            <InputNumber
              type="number"
              placeholder="Enter Phone Number"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="description"
            className="grid-full-child"
            label="Description"
          >
            <TextArea rows={8} placeholder="Write here..." />
          </Form.Item>
          <Form.Item
            name="priority"
            label="Priority"
            rules={[{ required: true, message: "Lead priority is required" }]}
          >
            <Radio.Group>
              <Radio.Button value="High">High</Radio.Button>
              <Radio.Button value="Low">Low</Radio.Button>
              <Radio.Button value="Medium">Medium</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            {" "}
            <Form.Item name="txt_files" label="Attachment">
              <Input type="file" onChange={handleFileChange} />
            </Form.Item>
            {isUploadLoading && (
              <span>
                <Spin /> Loading
              </span>
            )}
          </div>
          <Form.Item name="when_start" label="When Start">
            <Select placeholder="Select Start Time">
              {leadFormData?.starts?.map((data) => (
                <Option value={data?.start_id}>{data?.start_name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="lead_from" label="Lead From">
            <Select placeholder="Select Lead From">
              {leadFormData?.from?.map((data) => (
                <Option value={data?.threatre_id}>{data?.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="about_us" label="How do you know about us?">
            <Select placeholder="Select how you know">
              {leadFormData?.abouts?.map((data) => (
                <Option value={data?.threatre_id}>{data?.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Form.Item name="date" label="Remainder Date">
              <DatePicker />
            </Form.Item>
            <Form.Item name="time" label="Remainder Time">
              <TimePicker format={"hh:mm a"} />
            </Form.Item>
          </div>
          <Form.Item name="email_template" label="Select Email">
            <Select placeholder="Select Email">
              {leadFormData?.emails?.map((data) => (
                <Option value={data?.id}>{data?.email_subject}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="status_update" label="Status Update">
            <Input placeholder="Status Update" />
          </Form.Item>

          <Form.Item name="docs" label="Select Attach Document">
            <Select mode="tags" placeholder="Select attch document">
              {leadFormData?.docs?.map((data) => (
                <Option value={data?.content_id}>{data?.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <div style={{ display: "flex", gap: "1rem", paddingTop: "1.5rem" }}>
            <Form.Item name="email_send">
              <Checkbox.Group>
                <Checkbox value={true}>Send email to customer</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item name="email_copy_send">
              <Checkbox.Group>
                <Checkbox value={true}>Send copy to me</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <Button
            style={{ width: "fit-content" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form>
      </div>
    </Drawer>
  );
};

export default AddLead;
