import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, InputNumber, Select } from "antd";
import React, { useState } from "react";
import AddManager from "./AddManager";

const Customer = ({ current, setCurrent }) => {
  const { Option } = Select;
  const [isAddManagerModalVisible, setIsAddManagerModalVisible] =
    useState(false);

  const handleSubmitCustomerData = (values) => {
    setCurrent((prev) => prev + 1);
  };
  return (
    <Form
      layout="vertical"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "1rem",
        maxWidth: "1024px",
        width: "100%",
      }}
      onFinish={handleSubmitCustomerData}
    >
      <div
        style={{
          gridColumn: "span 2",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "0.3rem",
        }}
      >
        <Form.Item
          style={{ width: "100%" }}
          name="accountManager"
          label="Account Manager"
        >
          <Select placeholder="Select Account Manager">
            <Option>ABC</Option>
          </Select>{" "}
        </Form.Item>
        <PlusSquareOutlined
          onClick={() => setIsAddManagerModalVisible(true)}
          style={{
            cursor: "pointer",
            fontSize: "2rem",
            marginTop: "0.2rem",
          }}
        />
      </div>

      <Form.Item name="companyName" label="Company Name">
        <Input placeholder="Company Name" />
      </Form.Item>
      <Form.Item name="contactNumber" label="Contact Number">
        <InputNumber
          type="number"
          style={{ width: "100%" }}
          placeholder="Contact Number"
        />
      </Form.Item>
      <Form.Item name="customerName" label="Customer Name">
        <Input placeholder="Customer Name" />
      </Form.Item>
      <Form.Item name="mobileNumber" label="Mobile Number">
        <InputNumber
          type="number"
          style={{ width: "100%" }}
          placeholder="Mobile Number"
        />
      </Form.Item>
      <Form.Item name="email" label="Email">
        <Input type="email" placeholder="Email" />
      </Form.Item>
      <Form.Item name="country" label="Country">
        <Select placeholder="Select Country">
          <Option>ABC</Option>
        </Select>{" "}
      </Form.Item>
      <Form.Item name="abn" label="ABN">
        <InputNumber
          style={{ width: "100%" }}
          type="number"
          placeholder="abn"
        />
      </Form.Item>
      <Form.Item name="sendEmail">
        <Checkbox style={{ marginTop: "1.5rem" }}>
          hide in order confirmation and invoice
        </Checkbox>
      </Form.Item>

      <div
        style={{
          gridColumn: "span 2",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{ width: "fit-content" }}
          type="primary"
          htmlType="submit"
        >
          Next
        </Button>
      </div>
      {isAddManagerModalVisible && (
        <AddManager
          isAddManagerModalVisible={isAddManagerModalVisible}
          setIsAddManagerModalVisible={setIsAddManagerModalVisible}
        />
      )}
    </Form>
  );
};

export default Customer;
