import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Form,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import AddLead from "./AddLead";
import LeadDetails from "./LeadDetails";
import UpdateLead from "./UpdateLead";
import { CSVLink, CSVDownload } from "react-csv";
import Token from "../../Components/tokenComponent/Token";
import { debounce } from "lodash";

const { Search } = Input;

const Leads = ({ isHeadBranch, branchRecord }) => {
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });

  const [isAddLeadVisible, setIsAddLeadVisible] = useState(false);
  const [isLeadsDetailsModelVisible, setIsLeadsDetailsModelVisible] =
    useState(false);
  const [cascadeType, setCascadeType] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [isUpdateLeadModelVisible, setIsUpdateLeadModelVisible] =
    useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { globalBranchId } = useSelector((store) => store?.ProfileReducer);
  const { leadList, leadFormData } = useSelector((store) => store?.LeadReducer);
  const [leadStatus, setLeadStatus] = useState("active");
  const [record, setRecord] = useState({});
  const [loadingCsv, setLoadingCsv] = useState(false);
  const [loadingExportCsv, setLoadingExportCsv] = useState(false);

  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [campaignId, setCampaignId] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };

  useEffect(() => {
    setPage(leadList?.current_page);
    leadStatus === "active" && setTotalRecord(leadList?.active_leads);
    leadStatus === "pending" && setTotalRecord(leadList?.pending_leads);
  }, [leadList]);

  useEffect(() => {
    fetchLeadsData();
  }, [leadStatus, page, campaignId, globalBranchId]);

  const fetchLeadsData = (event = null) => {
    dispatch({
      type: "FETCH_LEADS_REQUEST",
      payload: {
        page: page,
        status: leadStatus,
        pageSize: pageSize,
        keyword: event ? event.target.value : "",
        campaign_id: campaignId,
      },
      successFunction: () => {},
      failureFunction: (err) => {
        err?.response?.data?.message === "Pin code expired." &&
          setIsTokenExpired(true);
      },
    });
  };

  useEffect(() => {
    fetchAddLeadForm();
  }, []);

  const fetchAddLeadForm = (values) => {
    dispatch({
      type: "FETCH_ADD_LEAD_FORM_REQUEST",
      payload: {
        branch_id: localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: (res) => {},
    });
  };

  const handleCascadeTask = () => {
    dispatch({
      type: "CASCADE_LEAD_ATTEMPT",
      payload: {
        ids: selectedRowKeys,
        action: cascadeType,
        branch_id: localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data);
        setSelectedRowKeys([]);
        setCascadeType(null);
        fetchLeadsData();
      },
      failureFunction: (res) => {
        message.error(res?.response?.data?.message);
      },
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const columns = [
    {
      title: "Lead ID",
      dataIndex: "lead_id",
      align: "center",
    },
    {
      title: "Full Name",
      dataIndex: "lead_name",
      align: "center",
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      align: "center",
    },

    {
      title: "Campaign",
      dataIndex: "campaign_name",
      align: "center",
    },

    {
      title: "Email",
      dataIndex: "email",
      align: "center",
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      align: "center",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      align: "center",
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (data) => (
        <Tag style={{ background: data?.color, color: "white" }}>
          {data?.name}
        </Tag>
      ),
    },

    {
      title: "Actions",
      align: "center",
      fixed: "right",
      render: (dataObj) => (
        <Space size="middle">
          <Tooltip title="Update Lead">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsUpdateLeadModelVisible(true);
                  setRecord(dataObj);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>

          <Tooltip title="Leads Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsLeadsDetailsModelVisible(true);
                  setRecord(dataObj);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleDebounceChange = debounce(fetchLeadsData, 1500);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUploadCSVfile = (e) => {
    setFile("");
    const formData = new FormData();
    formData.append("userfile", e.target.files[0]);
    formData.append("branch_id", localStorage.getItem("branchId") ?? null);
    setLoadingCsv(true);
    dispatch({
      type: "IMPORT_LEAD_REQUEST",
      payload: formData,
      isSuccessFn: true,
      successFunction: (res) => {
        setLoadingCsv(false);

        message.success("Lead import success");
      },
      failureFunction: () => {
        setLoadingCsv(false);
        message.error("Lead import failed");
      },
    });
  };

  const handleExportLead = () => {
    setLoadingExportCsv(true);

    dispatch({
      type: "EXPORT_LEAD_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: async (res) => {
        const csvString = await convertArrayToCSV(res?.data);
        downloadCSV(csvString);
        setLoadingExportCsv(false);
        message.success("Lead export success");
      },
      failureFunction: () => {
        setLoadingExportCsv(false);
        message.error("Lead export failed");
      },
    });
  };

  const downloadCSV = (csvString, filename = "lead.csv") => {
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const convertArrayToCSV = async (array) => {
    const csvRows = [];

    await array.forEach((row) => {
      csvRows.push(row.map((item) => (item === null ? "" : item)).join(","));
    });

    return csvRows.join("\n");
  };

  return (
    <SiderDemo>
      <div>
        <div className="leadFlexRow">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.2rem",
              alignItems: "center",
            }}
          >
            <button
              className="button"
              onClick={() => {
                setIsAddLeadVisible(true);
              }}
            >
              <span>Add Lead</span>
            </button>
            <Button
              style={{ borderRadius: "3px !important" }}
              size="large"
              type="primary"
              danger
              onClick={handleButtonClick}
              loading={loadingCsv}
            >
              Import
            </Button>
            <input
              value={file}
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleUploadCSVfile}
            />
            <Button
              style={{ borderRadius: "3px !important" }}
              size="large"
              type="outlined"
              danger
              onClick={handleExportLead}
              loading={loadingExportCsv}
            >
              Export
            </Button>
          </div>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Active [{leadList?.active_leads ?? 0}]</div>
                  </div>
                ),
                value: "active",
              },

              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Pending [{leadList?.pending_leads ?? 0}]</div>
                  </div>
                ),
                value: "pending",
              },
            ]}
            onChange={(value) => {
              setLeadStatus(value);
            }}
          />
          <div
            style={{
              display: "flex",
              width: "40%",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "0.3rem",
            }}
          >
            <Search
              className="search"
              style={{
                width: "100%",
                borderRadius: "10px ! important",
              }}
              placeholder="Search Lead"
              allowClear
              size="large"
              onChange={handleDebounceChange}
            />
            <Select
              size="large"
              placeholder="Select Campaign"
              onChange={(value) => setCampaignId(value)}
              style={{
                width: "100%",
                borderRadius: "10px ! important",
              }}
              value={campaignId}
            >
              <Select.Option value={""}>All Campaign</Select.Option>
              {leadFormData?.campaigns?.map((data) => (
                <Select.Option value={data?.id}>
                  {data?.campaign_name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {selectedRowKeys?.length > 0 && (
            <Alert
              message={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  Do you want to
                  <Select
                    value={cascadeType}
                    onChange={(value) => setCascadeType(value)}
                    placeholder="Select Action"
                    style={{ width: "8rem" }}
                  >
                    <Select.Option value="delete">Delete</Select.Option>
                    <Select.Option value="publish">Publish</Select.Option>
                    <Select.Option value="unpublish">Unpublish</Select.Option>
                  </Select>
                  {cascadeType && (
                    <Popconfirm
                      title={`Do you want to ${cascadeType} record?`}
                      onConfirm={() => {
                        handleCascadeTask();
                      }}
                      onCancel={() => {}}
                      okText="Ok"
                      cancelText="Cancel"
                    >
                      <Button danger type="primary">
                        Ok
                      </Button>
                    </Popconfirm>
                  )}
                </div>
              }
              type="warning"
            />
          )}

          {store?.leadState.isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <Table
                bordered
                columns={columns}
                dataSource={leadList?.leads?.map((data) => ({
                  ...data,
                  key: data?.lead_id,
                }))}
                pagination={false}
                rowSelection={rowSelection}
                scroll={{ x: 1000 }}
              />
            </>
          )}
          <Pagination
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
              alignSelf: "flex-end",
            }}
            current={page}
            onChange={onShowSizeChange}
            total={totalRecord}
          />
        </div>
      </div>
      {isAddLeadVisible && (
        <AddLead
          isAddLeadVisible={isAddLeadVisible}
          setIsAddLeadVisible={setIsAddLeadVisible}
          fetchLeadsData={fetchLeadsData}
        />
      )}
      {isUpdateLeadModelVisible && (
        <UpdateLead
          isUpdateLeadModelVisible={isUpdateLeadModelVisible}
          setIsUpdateLeadModelVisible={setIsUpdateLeadModelVisible}
          record={record}
          fetchLeadsData={fetchLeadsData}
        />
      )}
      {isLeadsDetailsModelVisible && (
        <LeadDetails
          Record={record}
          isLeadsDetailsModelVisible={isLeadsDetailsModelVisible}
          setIsLeadsDetailsModelVisible={setIsLeadsDetailsModelVisible}
        />
      )}

      {isTokenExpired && (
        <Token
          isTokenExpired={isTokenExpired}
          setIsTokenExpired={setIsTokenExpired}
        />
      )}
    </SiderDemo>
  );
};

export default Leads;
