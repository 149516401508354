import { Button, Input, Modal, Select, Form } from "antd";
import React from "react";

export default function DiscussionStatusUpdate({
  isStatusUpdateVisible,
  setIsStatusUpdateVisible,
}) {
  return (
    <Modal
      open={isStatusUpdateVisible}
      onCancel={() => setIsStatusUpdateVisible(false)}
      footer={false}
      title="Update discussion status"
    >
      <Form layout="vertical">
        <Form.Item
          rules={[
            {
              required: true,
              message: "Discussion comment is required",
            },
          ]}
          name="comment"
          label="Comment"
        >
          <Input.TextArea rows={4} placeholder="Comment" />
        </Form.Item>
        <Form.Item name="file" label="Attachment">
          <Input type="file" />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
}
