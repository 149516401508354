import { message } from "antd";

const INIT_STATE = {
  isLoading: false,

  quoteList: null,
  quoteById: null,
};

const QuotationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ADD_QUOTE_ATTEMPT":
    case "EDIT_QUOTE_ATTEMPT":
    case "EDIT_MULTI_QUOTE_ATTEMPT":
    case "ADD_MULTI_QUOTE_ATTEMPT": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_QUOTE_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Quote added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_QUOTE_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add quote.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "ADD_MULTI_QUOTE_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Quote added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_MULTI_QUOTE_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add quote.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "EDIT_QUOTE_SUCCESS":
    case "EDIT_MULTI_QUOTE_SUCCESS": {
      action?.payload?.message
        ? message.success(action?.payload?.message)
        : message.success("Success! Quote Edited.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "EDIT_QUOTE_FAILURE":
    case "EDIT_MULTI_QUOTE_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to edit quote.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_QUOTE_LIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_QUOTE_LIST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        quoteList: action?.payload,
      };
    }
    case "FETCH_QUOTE_LIST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to fetch quote.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_QUOTAtION_BY_ID_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_QUOTAtION_BY_ID_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        quoteById: action?.payload,
      };
    }
    case "FETCH_QUOTAtION_BY_ID_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to fetch quote.");

      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default QuotationReducer;
