import React, { useEffect, useRef, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import UpdateTask from "./UpdateTask";
import AddTask from "./AddTask";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import { AiOutlineEye } from "react-icons/ai";
import { LiaSignInAltSolid } from "react-icons/lia";

import ViewTask from "./ViewTask";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import JobBasket from "../../Components/jobBasket/JobBasket";
import PickupJob from "../../Components/pickupJob/PickupJob";
import DailyTask from "./DailyTask";
import Token from "../../Components/tokenComponent/Token";
import { debounce } from "lodash";

const Task = () => {
  const { taskListData, isLoading, dailyTaskList } = useSelector(
    (store) => store?.TaskReducer
  );
  const { globalBranchId } = useSelector((store) => store?.ProfileReducer);

  const [cascadeType, setCascadeType] = useState(null);
  const [empId, setEmpId] = useState("");

  const csvDownloadRef = useRef(null);
  const localizer = momentLocalizer(moment);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isAddJobBasketVisible, setIsAddJobBasketVisible] = useState(false);
  const [isPickupJobVisible, setIsPickupJobVisible] = useState(false);
  const [recordId, setRecordId] = useState(null);
  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [record, setRecord] = useState("");
  const [taskType, setTaskType] = useState("allTask");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [selectSlot, setSelectSlot] = useState(null);
  const [taskStatus, setTaskStatus] = useState("pending");
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);

  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };

  useEffect(() => {
    taskStatus === "pending" && setTotalRecord(taskListData?.pending_tasks);
    taskStatus === "current" && setTotalRecord(taskListData?.current_tasks);
    taskStatus === "archived" && setTotalRecord(taskListData?.archived_tasks);
    taskStatus === "exceeded" &&
      setTotalRecord(taskListData?.deadline_exceeded);
    taskStatus === "backlog" && setTotalRecord(taskListData?.backlogs);
  }, [taskListData]);

  useEffect(() => {
    fetchTaskList();
  }, [taskStatus, page, globalBranchId, empId]);

  useEffect(() => {
    fetchEmployeeTaskList();
  }, [globalBranchId]);

  useEffect(() => {
    fetchDailyTask();
  }, []);

  const fetchDailyTask = () => {
    dispatch({
      type: "FETCH_DAILY_TASK_LIST_REQUEST",
      payload: {
        date: moment().format("YYYY-MM-DD") ?? "",
        emp: "",
      },
      isSuccessFn: true,
      successFunction: (res) => {},
    });
  };

  const fetchTaskList = (event = null) => {
    dispatch({
      type: "FETCH_TASK_LIST_REQUEST",
      payload: {
        status: taskStatus,
        page: page,
        keyword: event ? event.target.value : "",
        empId: empId,
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setTaskList(
          res?.data?.tasks?.map((task) => ({
            ...task,
            title: task?.task_name,
            start:
              moment(task?.start_date, "DD-MM-YYYY").format(
                "YYYY-MM-DDTHH:mm:ss"
              ) ?? null,
            end:
              moment(task?.end_date, "DD-MM-YYYY").format(
                "YYYY-MM-DDTHH:mm:ss"
              ) ?? null,
          }))
        );
      },
      failureFunction: (err) => {
        err?.response?.data?.message === "Pin code expired." &&
          setIsTokenExpired(true);
      },
    });
  };

  const fetchEmployeeTaskList = () => {
    dispatch({
      type: "FETCH_EMPLOYEE_TASK_LIST_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: (res) => {},
    });
  };

  const handleCascadeTask = () => {
    dispatch({
      type: "CASCADE_TASK_ATTEMPT",
      payload: {
        ids: selectedRowKeys,
        action: cascadeType,
        branch_id: localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data);
        setSelectedRowKeys([]);
        setCascadeType(null);
        fetchTaskList();
      },
      failureFunction: (res) => {
        message.error(res?.response?.data?.message);
      },
    });
  };

  const handleSelectSlot = (slotInfo) => {
    setIsAddTaskModalVisible(true);
    setSelectSlot(slotInfo.start);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "task_id",
      align: "center",
      width: 100,
      key: "task_id",
    },
    {
      title: "Task Name",
      dataIndex: "tasktitle",
      sorter: (a, b) => {
        if (a.taskTitle < b.taskTitle) {
          return -1;
        }
        if (a.taskTitle > b.taskTitle) {
          return 1;
        }
        return 0;
      },
      align: "center",
      width: 300,
    },

    {
      title: "Project",
      dataIndex: "project_name",

      align: "center",
      width: 200,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      align: "center",
      width: 150,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      align: "center",
      width: 150,
    },
    {
      title: "Task Status",
      dataIndex: "status_name",
      align: "center",
    },
    {
      title: "Priority",
      align: "center",
      render: (dataObj) => (
        <Tag style={{ background: dataObj?.priority_code, color: "white" }}>
          {dataObj?.priority}
        </Tag>
      ),
    },
    {
      title: "Assign To",
      dataIndex: "assigned_employees",
      sorter: (a, b) => {
        if (a.assignedTo < b.assignedTo) {
          return -1;
        }
        if (a.assignedTo > b.assignedTo) {
          return 1;
        }
        return 0;
      },
      align: "center",
      render: (empList) =>
        empList?.map((emp) => (
          <Tag>
            {emp?.first_name} {emp?.last_name}
          </Tag>
        )),
    },
    {
      title: "Actions",
      align: "center",
      fixed: "right",
      render: (dataObj) => (
        <Space size="middle">
          {dataObj?.assigned_employees?.length === 0 &&
            dataObj?.status_name?.trim() === "Pending" && (
              <Tooltip title="Pickup Task">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsPickupJobVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <LiaSignInAltSolid size={18} />
                  </div>
                </a>
              </Tooltip>
            )}
          <Tooltip title="Update Task">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsEditModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Task Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsDrawerOpen(true);
                  setRecord(dataObj);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (
      dataArray &&
      csvDownloadRef &&
      csvDownloadRef.current &&
      csvDownloadRef.current.link
    ) {
      csvDownloadRef.current.link.click();
    }
  }, [dataArray]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleDebounceChange = debounce(fetchTaskList, 1500);

  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          {taskType === "allTask" && (
            <div style={{ width: "100%", gap: "0.5rem", display: "flex" }}>
              <Button
                type="primary"
                onClick={() => {
                  setIsAddTaskModalVisible(true);
                }}
              >
                Add Task
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setIsAddJobBasketVisible(true);
                }}
              >
                Add Job Basket
              </Button>
            </div>
          )}
          {taskType === "allTask" && (
            <div style={{ width: "100%" }}>
              <Select
                value={empId}
                style={{ width: "10rem" }}
                onChange={(value) => {
                  setEmpId(value);
                }}
                placeholder="Choose Employee"
              >
                <Select.Option value={""}>All Employee</Select.Option>
                {dailyTaskList?.users?.map((data) => (
                  <Select.Option value={data?.userid}>
                    {data?.first_name} {data?.last_name}
                  </Select.Option>
                ))}
              </Select>
              <Input
                style={{
                  borderRadius: "10px ! important",
                  width: "10rem",
                }}
                placeholder="Search Task"
                allowClear
                onChange={handleDebounceChange}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Segmented
              size="small"
              style={{
                width: "fit-content",
                alignSelf: "flex-end",
              }}
              options={[
                {
                  label: (
                    <div style={{ padding: 4 }}>
                      <div>All Task</div>
                    </div>
                  ),
                  value: "allTask",
                },
                {
                  label: (
                    <div style={{ padding: 4 }}>
                      <div>Task Calendar</div>
                    </div>
                  ),
                  value: "taskCalendar",
                },
                {
                  label: (
                    <div style={{ padding: 4 }}>
                      <div>Daily Task</div>
                    </div>
                  ),
                  value: "dailyTask",
                },
              ]}
              onChange={(value) => {
                setPage(1);
                setPageSize(10);
                setTaskType(value);
              }}
            />
          </div>
        </div>
        {taskType === "allTask" && (
          <Segmented
            size="small"
            style={{ alignSelf: "flex-end", marginBottom: "0.5rem" }}
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Pending [{taskListData?.pending_tasks ?? 0}]</div>
                  </div>
                ),
                value: "pending",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Current [{taskListData?.current_tasks ?? 0}]</div>
                  </div>
                ),
                value: "current",
              },

              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Completed [{taskListData?.completed_tasks ?? 0}]</div>
                  </div>
                ),
                value: "completed",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>BackLog [{taskListData?.backlogs ?? 0}]</div>
                  </div>
                ),
                value: "backlog",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Archived [{taskListData?.archived_tasks ?? 0}]</div>
                  </div>
                ),
                value: "archived",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Exceeded [{taskListData?.deadline_exceeded ?? 0}]</div>
                  </div>
                ),
                value: "exceeded",
              },
            ]}
            onChange={(value) => {
              setTaskStatus(value);
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "0.5rem",
          }}
        >
          <>
            {selectedRowKeys?.length > 0 && (
              <Alert
                message={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    Do you want to
                    <Select
                      value={cascadeType}
                      onChange={(value) => setCascadeType(value)}
                      placeholder="Select Action"
                      style={{ width: "8rem" }}
                    >
                      <Select.Option value="delete">Delete</Select.Option>
                      <Select.Option value="publish">Publish</Select.Option>
                      <Select.Option value="unpublish">Unpublish</Select.Option>
                    </Select>
                    {cascadeType && (
                      <Popconfirm
                        title={`Do you want to ${cascadeType} record?`}
                        onConfirm={() => {
                          handleCascadeTask();
                        }}
                        onCancel={() => {}}
                        okText="Ok"
                        cancelText="Cancel"
                      >
                        <Button danger type="primary">
                          Ok
                        </Button>
                      </Popconfirm>
                    )}
                  </div>
                }
                type="warning"
              />
            )}

            {taskType === "allTask" && (
              <>
                {isLoading ? (
                  <TableSkeleton />
                ) : (
                  <Table
                    bordered
                    size="small"
                    rowSelection={rowSelection}
                    columns={columns}
                    pagination={false}
                    dataSource={taskListData?.tasks?.map((data) => ({
                      ...data,
                      key: data?.task_id,
                    }))}
                  />
                )}
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  // showSizeChanger
                  current={page}
                  // defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={totalRecord}
                />
              </>
            )}
            {taskType === "dailyTask" && <DailyTask />}
            {taskType === "taskCalendar" && (
              <Calendar
                localizer={localizer}
                events={taskList}
                style={{ height: 600, cursor: "pointer" }}
                selectable={true}
                onSelectSlot={handleSelectSlot}
                onSelectEvent={(value) => {
                  setIsEditModalVisible(true);
                  setRecord(value);
                }}
                startAccessor={(event) => {
                  return new Date(event.start);
                }}
                endAccessor={(event) => {
                  return new Date(event.end);
                }}
                components={{
                  event: CustomEvent,
                }}
              />
            )}
          </>
        </div>
      </div>
      {isAddTaskModalVisible && (
        <AddTask
          fetchTaskList={fetchTaskList}
          isAddTaskModalVisible={isAddTaskModalVisible}
          setIsAddTaskModalVisible={setIsAddTaskModalVisible}
        />
      )}
      {isEditModalVisible && (
        <UpdateTask
          record={record}
          isEditModalVisible={isEditModalVisible}
          setIsEditModalVisible={setIsEditModalVisible}
          fetchTaskList={fetchTaskList}
        />
      )}
      {isDrawerOpen && (
        <ViewTask
          record={record}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          fetchTaskList={fetchTaskList}
        />
      )}
      {isAddJobBasketVisible && (
        <JobBasket
          isAddJobBasketVisible={isAddJobBasketVisible}
          setIsAddJobBasketVisible={setIsAddJobBasketVisible}
          fetchTaskList={fetchTaskList}
        />
      )}
      {isPickupJobVisible && (
        <PickupJob
          isPickupJobVisible={isPickupJobVisible}
          setIsPickupJobVisible={setIsPickupJobVisible}
          record={record}
          fetchTaskList={fetchTaskList}
        />
      )}
      {isTokenExpired && (
        <Token
          isTokenExpired={isTokenExpired}
          setIsTokenExpired={setIsTokenExpired}
        />
      )}
    </SiderDemo>
  );
};

export default Task;

export const CustomEvent = ({ event }) => {
  return (
    <div
      style={{
        padding: "0.2rem",
        borderRadius: "0.4rem",
        background: event?.code ?? "black",
      }}
    >
      <strong>{event?.title}</strong>
    </div>
  );
};
