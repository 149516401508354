import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Skeleton,
  Space,
  Tag,
  TimePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  MinusCircleOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import moment from "moment";

const UpdateMultiQuote = ({
  updateMultiQuote,
  setUpdateMultiQuote,
  fetchQuoteList,
  record,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const { Option } = Select;
  const [isAddCustomerModalVisible, setIsAddCustomerModalVisible] =
    useState(false);
  const [cType, setcType] = useState("exist");
  const [formList, setFormList] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [isGstApp, setIsGstApp] = useState(1);
  const [financeSelect, setFinanceSelect] = useState(
    record?.finance_option === "1" ? true : false
  );
  const [keys, setKeys] = useState([]);
  const [gstKeys, setGstKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customerDetail, setCustomerDetail] = useState(null);

  const [quoteRecord, setQuoteRecord] = useState(null);

  useEffect(() => {
    fetchQuoteEditRecord();

    function handleFetchApi() {
      fetchQuotePresetForm();
    }

    handleFetchApi();
  }, []);

  useEffect(() => {
    quoteRecord &&
      quoteRecord?.quote_options?.map((value, index) => {
        value?.gst_applicable === "1" && setGstKeys((prev) => [...prev, index]);
        setKeys((prev) => [...prev, index]);
      });
  }, [quoteRecord]);

  const fetchQuotePresetForm = () => {
    dispatch({
      type: "FETCH_QUOTE_PRESET_FORM_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: (res) => {
        setFormList(res?.data);
        setCustomerList(res?.data?.customers);
        setPackageList(res?.data?.package_types);
      },
      failureFunction: (err) => {},
    });
  };

  const fetchQuoteEditRecord = () => {
    dispatch({
      type: "FETCH_QUOTE_EDIT_RECORD_ATTEMPT",
      payload: record?.quote_id,
      isSuccessFn: true,
      successFunction: (res) => {
        const quoteOption = res?.data?.quote_options?.map((value) => ({
          ...value,
          sub_total:
            value?.gst_applicable === "1"
              ? Number(value?.total_price) - Number(value?.gst)
              : Number(value?.total_price),
        }));
        setQuoteRecord({ ...res?.data, quote_options: quoteOption });
        setLoading(false);
        setCustomerDetail(res?.data?.quote_customer);
      },
      failureFunction: (err) => {
        setLoading(false);
      },
    });
  };

  const handleSelectPackage = (value, option, index, mainIndex) => {
    const selectedPackage = JSON.parse(option.key);

    const tempOptions = form.getFieldValue("options");
    const selectedTempOption = tempOptions[mainIndex];
    const tempPackages = selectedTempOption?.packages;

    tempPackages[index] = {
      package_desc: value,
      package_qty: 1,
      package_price: selectedPackage?.price,
      package_amount: Number(selectedPackage?.price) * 1,
    };

    //temp price
    const tempPrice = tempPackages?.reduce(
      (total, data) => total + Number(data?.package_amount),
      0
    );

    //discount
    const tempIsPercent = selectedTempOption?.is_flat;
    const tempDiscount = selectedTempOption?.discount;
    const tempSubTotal = tempIsPercent
      ? Number(tempPrice) - (Number(tempPrice) * Number(tempDiscount)) / 100
      : Number(tempPrice) - Number(tempDiscount);

    //is gst
    const tempGstApplicable = selectedTempOption?.gst_applicable;
    const tempRadioGst = selectedTempOption?.radio_gst;
    const tempGst =
      tempGstApplicable === 1 && tempRadioGst === 1
        ? parseFloat(tempSubTotal) * 0.1
        : tempGstApplicable === 1 && tempRadioGst === 0
        ? parseFloat(tempSubTotal) / 11
        : 0;

    //include-exclude-gst
    const tempTotalPrice =
      tempRadioGst === 1
        ? Number(tempSubTotal) + Number(tempGst)
        : Number(tempSubTotal);

    tempOptions[mainIndex] = {
      ...selectedTempOption,
      packages: tempPackages,
      price: Number(tempPrice).toFixed(2),
      sub_total: Number(tempSubTotal).toFixed(2),
      gst: Number(tempGst).toFixed(2),
      total_price: Number(tempTotalPrice).toFixed(2),
    };

    form.setFieldsValue({
      options: tempOptions,
    });
  };

  const handlePerformCalculation = (index, mainIndex) => {
    const tempOptions = form.getFieldValue("options");
    const selectedTempOption = tempOptions[mainIndex];
    const tempPackages = selectedTempOption?.packages;
    const selectedTempPackage = tempPackages[index];
    tempPackages[index] = {
      ...selectedTempPackage,
      package_qty: selectedTempPackage?.package_qty,
      package_price: selectedTempPackage?.package_price,
      package_amount:
        Number(selectedTempPackage?.package_price) *
        Number(selectedTempPackage?.package_qty),
    };

    //temp price
    const tempPrice = tempPackages?.reduce(
      (total, data) => total + Number(data?.package_amount),
      0
    );

    // discount
    const tempIsPercent = selectedTempOption?.is_flat;
    const tempDiscount = selectedTempOption?.discount;
    const tempSubTotal = tempIsPercent
      ? Number(tempPrice) - (Number(tempPrice) * Number(tempDiscount)) / 100
      : Number(tempPrice) - Number(tempDiscount);

    //is gst
    const tempGstApplicable = selectedTempOption?.gst_applicable;
    const tempRadioGst = selectedTempOption?.radio_gst;
    const tempGst =
      tempGstApplicable === 1 && tempRadioGst === 1
        ? parseFloat(tempSubTotal) * 0.1
        : tempGstApplicable === 1 && tempRadioGst === 0
        ? parseFloat(tempSubTotal) / 11
        : 0;

    //include-exclude-gst
    const tempTotalPrice =
      tempRadioGst === 1
        ? Number(tempSubTotal) + Number(tempGst)
        : Number(tempSubTotal);

    tempOptions[mainIndex] = {
      ...selectedTempOption,
      packages: tempPackages,
      price: Number(tempPrice).toFixed(2),
      sub_total: Number(tempSubTotal).toFixed(2),
      gst: Number(tempGst).toFixed(2),
      total_price: Number(tempTotalPrice).toFixed(2),
    };

    form.setFieldsValue({
      options: tempOptions,
    });
  };

  const handleSubmitRecord = (values) => {
    dispatch({
      type: "EDIT_MULTI_QUOTE_ATTEMPT",
      payload: {
        id: record?.quote_id,

        formData: {
          branch_id: localStorage.getItem("branchId"),

          customer: values?.customer,
          txt_name: values?.txt_name,
          options: values?.options?.map((data) => ({
            discount: data?.discount,
            is_flat: data?.is_flat ? 0 : 1,
            gst_applicable: data?.gst_applicable,
            radio_gst: data?.radio_gst,
            gst: data?.gst,
            total_price: data?.total_price,
            price: data?.price,
            note: data?.note,
            packages: data?.packages?.map((value) => ({
              ...value,
              package_qty: Number(value?.package_qty) ?? null,
              package_price: Number(value?.package_price) ?? null,
              package_amount: Number(value?.package_amount) ?? null,
            })),
          })),
          description: values?.description,
          note: values?.note,
          finance: values?.finance ? 1 : 0,
          payment_terms: values?.payment_terms,
          timeline: values?.timeline,
          chk_timeline: values?.chk_timeline ? 1 : 0,
          testing: values?.testing,
          payment: values?.payment,
          chk_test: values?.chk_test ? 1 : 0,
          chk_payment: values?.chk_payment ? 1 : 0,
          quote_from: values?.quote_from,
          about_us: values?.about_us,
          contact_by: values?.contact_by,
          date: values?.date,
          docs: values?.docs,
          text_files: [],
          quote_status: values?.quote_status,
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setUpdateMultiQuote(false);
        fetchQuoteList();
      },
      failureFunction: () => {},
    });
  };

  const handleSelectCustomer = (value, option) => {
    const tempCustomer = JSON.parse(option.key);
    setCustomerDetail(tempCustomer);
  };

  return (
    <Drawer
      title="Update Multi Quote"
      placement="right"
      open={updateMultiQuote}
      onClose={() => {
        setUpdateMultiQuote(false);
      }}
      width={1163}
      footer={null}
    >
      {loading ? (
        <Card>
          <Skeleton active={true} />
        </Card>
      ) : (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Form
            layout="vertical"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
              maxWidth: "1024px",
              width: "100%",
            }}
            form={form}
            onFinish={handleSubmitRecord}
            initialValues={{
              customer_type: cType,
              finance: false,
              options: quoteRecord?.quote_options?.map((data) => ({
                ...data,
                is_flat: data?.is_flat === "1" ? false : true,
                gst_applicable: Number(data?.gst_applicable) ?? null,
                radio_gst: Number(data?.is_included) ?? null,
                packages: data?.packages?.map((item) => ({
                  ...item,
                  package_desc: item?.descriptions,
                  package_qty: item?.quantity,
                  package_price: item?.price,
                  package_amount: item?.amount,
                })),
              })),
              docs: quoteRecord?.quote_docs,

              customer: record?.customer_id,
              txt_name: record?.nature_of_quote,
              finance: record?.finance_option === "1" ? true : false,
              payment_terms: record?.payment_term,
              description: record?.description,
              note: record?.note,
              timeline: record?.timeline,
              chk_timeline: record?.chk_timeline === "1" ? true : false,
              testing: record?.testing,
              chk_test: record?.chk_test === "1" ? true : false,
              payment: record?.payment_terms,
              chk_payment: record?.chk_payment === "1" ? true : false,
              quote_status: record?.quote_satus,
              quote_from: record?.quote_from,
              about_us: record?.about_us,
              date: moment(record?.expiry_date, "DD-MM-YYYY") ?? null,
              contact_by: record?.contact_by === "2" ? "Phone" : "Email",
            }}
          >
            <Form.Item
              style={{ width: "100%" }}
              name="customer"
              label="Customer"
            >
              <Select
                onSearch={(value) => {
                  const searchKey = value.trim();
                  const test = formList?.customers?.filter((employee) =>
                    employee.customer_name
                      .toLowerCase()
                      .includes(searchKey.toLowerCase())
                  );
                  setCustomerList(
                    searchKey === "" ? formList?.customers : test
                  );
                }}
                showSearch
                filterOption={false}
                placeholder="Select Customer"
                onChange={handleSelectCustomer}
              >
                {customerList?.map((data) => (
                  <Option key={JSON.stringify(data)} value={data?.customer_id}>
                    {data?.customer_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="txt_name" label="Nature of Project">
              <Input placeholder="Nature of Project" />
            </Form.Item>
            {customerDetail && (
              <Card>
                <p>Customer No: {customerDetail?.customer_no ?? "N/A"}</p>{" "}
                <p>Name: {customerDetail?.customer_name ?? "N/A"}</p>
                <p>Company: {customerDetail?.company_name ?? "N/A"}</p>
                <p>Contact No: {customerDetail?.contact_number ?? "N/A"}</p>
                <p>Email: {customerDetail?.email ?? "N/A"}</p>
              </Card>
            )}
            <div style={{ gridColumn: "span 2" }}>
              <Form.List name="options">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, mainIndex) => (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            background: "#cfcfcf20",
                            padding: "1rem",
                            marginBottom: "1rem",
                            width: "100%",
                            border: "1px dashed #1890ff",
                          }}
                          align="baseline"
                        >
                          <Form.List name={[name, "packages"]}>
                            {(
                              packageFields,
                              { add: addPackage, remove: removePackage }
                            ) => (
                              <>
                                {packageFields.map(
                                  (
                                    {
                                      key: packageKey,
                                      name: packageName,
                                      fieldKey: packageFieldKey,
                                      ...restPackageField
                                    },
                                    index
                                  ) => (
                                    <Space
                                      key={packageKey}
                                      align="baseline"
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns:
                                          "1fr 1fr 1fr 1fr 1fr",
                                        gap: "0 1rem",
                                      }}
                                    >
                                      <Form.Item
                                        {...restPackageField}
                                        name={[packageName, "package_desc"]}
                                        fieldKey={[
                                          packageFieldKey,
                                          "package_desc",
                                        ]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Missing package name",
                                          },
                                        ]}
                                      >
                                        <Select
                                          placeholder="Select package"
                                          onChange={(value, option) =>
                                            handleSelectPackage(
                                              value,
                                              option,
                                              index,
                                              mainIndex
                                            )
                                          }
                                        >
                                          {packageList?.map((data) => (
                                            <Option
                                              key={JSON.stringify(data)}
                                              value={data?.name}
                                            >
                                              {data?.name}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                        {...restPackageField}
                                        name={[packageName, "short_desc"]}
                                        fieldKey={[
                                          packageFieldKey,
                                          "short_desc",
                                        ]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Missing short description",
                                          },
                                        ]}
                                      >
                                        <Input placeholder="Short Description" />
                                      </Form.Item>
                                      <Form.Item
                                        {...restPackageField}
                                        name={[packageName, "package_qty"]}
                                        fieldKey={[
                                          packageFieldKey,
                                          "package_qty",
                                        ]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Missing package quantity",
                                          },
                                        ]}
                                      >
                                        <Input
                                          onChange={(e) =>
                                            handlePerformCalculation(
                                              index,
                                              mainIndex
                                            )
                                          }
                                          placeholder="Package Quantity"
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        {...restPackageField}
                                        name={[packageName, "package_price"]}
                                        fieldKey={[
                                          packageFieldKey,
                                          "package_price",
                                        ]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Missing package price",
                                          },
                                        ]}
                                      >
                                        <Input
                                          onChange={(e) =>
                                            handlePerformCalculation(
                                              index,
                                              mainIndex
                                            )
                                          }
                                          placeholder="Package Price"
                                        />
                                      </Form.Item>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "1rem",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        <Form.Item
                                          {...restPackageField}
                                          name={[packageName, "package_amount"]}
                                          fieldKey={[
                                            packageFieldKey,
                                            "package_amount",
                                          ]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Missing package amount",
                                            },
                                          ]}
                                        >
                                          <Input
                                            readOnly
                                            placeholder="Package Amount"
                                          />
                                        </Form.Item>
                                        <MinusCircleOutlined
                                          style={{
                                            fontSize: "1.5rem",
                                            cursor: "pointer",
                                            color: "red",
                                            height: "fit-content",
                                          }}
                                          onClick={() => {
                                            const tempOptions =
                                              form.getFieldValue("options");
                                            const selectedTempOption =
                                              tempOptions[mainIndex];
                                            const tempPackages =
                                              selectedTempOption?.packages;
                                            const selectedTempPackage =
                                              tempPackages[index];
                                            //temp price
                                            const tempPrice =
                                              tempPackages?.reduce(
                                                (total, data) =>
                                                  total +
                                                  Number(data?.package_amount),
                                                0
                                              );
                                            const newTempPrice =
                                              Number(tempPrice) -
                                              Number(
                                                selectedTempPackage?.package_amount
                                              );

                                            //discount
                                            const tempIsPercent =
                                              selectedTempOption?.is_flat;
                                            const tempDiscount =
                                              selectedTempOption?.discount;

                                            const tempSubTotal = tempIsPercent
                                              ? Number(newTempPrice) -
                                                (Number(newTempPrice) *
                                                  Number(tempDiscount)) /
                                                  100
                                              : Number(newTempPrice) -
                                                Number(tempDiscount);

                                            //is gst
                                            const tempGstApplicable =
                                              selectedTempOption?.gst_applicable;
                                            const tempRadioGst =
                                              selectedTempOption?.radio_gst;
                                            const tempGst =
                                              tempGstApplicable === 1 &&
                                              tempRadioGst === 1
                                                ? parseFloat(tempSubTotal) * 0.1
                                                : tempGstApplicable === 1 &&
                                                  tempRadioGst === 0
                                                ? parseFloat(tempSubTotal) / 11
                                                : 0;

                                            //include-exclude-gst
                                            const tempTotalPrice =
                                              tempRadioGst === 1
                                                ? Number(tempSubTotal) +
                                                  Number(tempGst)
                                                : Number(tempSubTotal);

                                            tempOptions[mainIndex] = {
                                              ...selectedTempOption,
                                              price:
                                                Number(newTempPrice).toFixed(2),
                                              sub_total:
                                                Number(tempSubTotal).toFixed(2),
                                              gst: Number(tempGst).toFixed(2),
                                              total_price:
                                                Number(tempTotalPrice).toFixed(
                                                  2
                                                ),
                                            };

                                            form.setFieldsValue({
                                              options: tempOptions,
                                            });

                                            removePackage(packageName);
                                          }}
                                        />
                                      </div>
                                    </Space>
                                  )
                                )}
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    onClick={() => addPackage()}
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add Package
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>

                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                              gap: "0 1rem",
                              width: "100%",
                            }}
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "price"]}
                              fieldKey={[fieldKey, "price"]}
                              label="Regular Price"
                              rules={[
                                {
                                  required: true,
                                  message: "Regular price is required",
                                },
                              ]}
                            >
                              <Input
                                readOnly
                                type="number"
                                placeholder="Regular price"
                              />
                            </Form.Item>{" "}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                gap: "1rem",
                              }}
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "discount"]}
                                fieldKey={[fieldKey, "discount"]}
                                label="Discount"
                              >
                                <Input
                                  onChange={(e) => {
                                    const tempOptions =
                                      form.getFieldValue("options");
                                    const selectedTempOption =
                                      tempOptions[mainIndex];

                                    const tempPrice = selectedTempOption?.price;
                                    //discount
                                    const tempIsPercent =
                                      selectedTempOption?.is_flat;
                                    const tempDiscount =
                                      selectedTempOption?.discount;
                                    const tempSubTotal = tempIsPercent
                                      ? Number(tempPrice) -
                                        (Number(tempPrice) *
                                          Number(tempDiscount)) /
                                          100
                                      : Number(tempPrice) -
                                        Number(tempDiscount);

                                    //is gst
                                    const tempGstApplicable =
                                      selectedTempOption?.gst_applicable;
                                    const tempRadioGst =
                                      selectedTempOption?.radio_gst;
                                    const tempGst =
                                      tempGstApplicable === 1 &&
                                      tempRadioGst === 1
                                        ? parseFloat(tempSubTotal) * 0.1
                                        : tempGstApplicable === 1 &&
                                          tempRadioGst === 0
                                        ? parseFloat(tempSubTotal) / 11
                                        : 0;

                                    //include-exclude-gst
                                    const tempTotalPrice =
                                      tempRadioGst === 1
                                        ? Number(tempSubTotal) + Number(tempGst)
                                        : Number(tempSubTotal);

                                    tempOptions[mainIndex] = {
                                      ...selectedTempOption,
                                      sub_total:
                                        Number(tempSubTotal).toFixed(2),
                                      gst: Number(tempGst).toFixed(2),
                                      total_price:
                                        Number(tempTotalPrice).toFixed(2),
                                    };

                                    form.setFieldsValue({
                                      options: tempOptions,
                                    });
                                  }}
                                  type="number"
                                  placeholder="Discount"
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                valuePropName="checked"
                                name={[name, "is_flat"]}
                                fieldKey={[fieldKey, "is_flat"]}
                                style={{ marginTop: "1.5rem" }}
                              >
                                <Checkbox
                                  onChange={(e) => {
                                    const tempOptions =
                                      form.getFieldValue("options");
                                    const selectedTempOption =
                                      tempOptions[mainIndex];

                                    const tempPrice = selectedTempOption?.price;
                                    //discount
                                    const tempIsPercent =
                                      selectedTempOption?.is_flat;
                                    const tempDiscount =
                                      selectedTempOption?.discount;
                                    const tempSubTotal = tempIsPercent
                                      ? Number(tempPrice) -
                                        (Number(tempPrice) *
                                          Number(tempDiscount)) /
                                          100
                                      : Number(tempPrice) -
                                        Number(tempDiscount);

                                    //is gst
                                    const tempGstApplicable =
                                      selectedTempOption?.gst_applicable;
                                    const tempRadioGst =
                                      selectedTempOption?.radio_gst;
                                    const tempGst =
                                      tempGstApplicable === 1 &&
                                      tempRadioGst === 1
                                        ? parseFloat(tempSubTotal) * 0.1
                                        : tempGstApplicable === 1 &&
                                          tempRadioGst === 0
                                        ? parseFloat(tempSubTotal) / 11
                                        : 0;

                                    //include-exclude-gst
                                    const tempTotalPrice =
                                      tempRadioGst === 1
                                        ? Number(tempSubTotal) + Number(tempGst)
                                        : Number(tempSubTotal);

                                    tempOptions[mainIndex] = {
                                      ...selectedTempOption,
                                      sub_total:
                                        Number(tempSubTotal).toFixed(2),
                                      gst: Number(tempGst).toFixed(2),
                                      total_price:
                                        Number(tempTotalPrice).toFixed(2),
                                    };

                                    form.setFieldsValue({
                                      options: tempOptions,
                                    });
                                  }}
                                >
                                  Is Percentage?
                                </Checkbox>
                              </Form.Item>
                            </div>
                            <Form.Item
                              {...restField}
                              name={[name, "sub_total"]}
                              fieldKey={[fieldKey, "sub_total"]}
                              label="Sub Total"
                            >
                              <Input
                                readOnly
                                type="number"
                                placeholder="Sub total"
                              />
                            </Form.Item>
                            <Form.Item
                              label="GST Applicable"
                              {...restField}
                              name={[name, "gst_applicable"]}
                              fieldKey={[fieldKey, "gst_applicable"]}
                            >
                              <Radio.Group
                                onChange={(e) => {
                                  const tempGstApplicable = e.target.value;
                                  setIsGstApp(tempGstApplicable);
                                  setGstKeys((prev) =>
                                    tempGstApplicable === 0
                                      ? prev.filter(
                                          (keyValue) => keyValue !== key
                                        )
                                      : prev?.includes(key)
                                      ? prev
                                      : [...prev, key]
                                  );

                                  const tempOptions =
                                    form.getFieldValue("options");
                                  const selectedTempOption =
                                    tempOptions[mainIndex];

                                  const tempPrice = selectedTempOption?.price;
                                  //discount
                                  const tempIsPercent =
                                    selectedTempOption?.is_flat;
                                  const tempDiscount =
                                    selectedTempOption?.discount;
                                  const tempSubTotal = tempIsPercent
                                    ? Number(tempPrice) -
                                      (Number(tempPrice) *
                                        Number(tempDiscount)) /
                                        100
                                    : Number(tempPrice) - Number(tempDiscount);

                                  //is gst
                                  const tempRadioGst =
                                    selectedTempOption?.radio_gst;
                                  const tempGst =
                                    tempGstApplicable === 1 &&
                                    tempRadioGst === 1
                                      ? parseFloat(tempSubTotal) * 0.1
                                      : tempGstApplicable === 1 &&
                                        tempRadioGst === 0
                                      ? parseFloat(tempSubTotal) / 11
                                      : 0;

                                  //include-exclude-gst
                                  const tempTotalPrice =
                                    tempRadioGst === 1
                                      ? Number(tempSubTotal) + Number(tempGst)
                                      : Number(tempSubTotal);

                                  tempOptions[mainIndex] = {
                                    ...selectedTempOption,
                                    gst: Number(tempGst).toFixed(2),
                                    total_price:
                                      Number(tempTotalPrice).toFixed(2),
                                  };

                                  form.setFieldsValue({
                                    options: tempOptions,
                                  });
                                }}
                              >
                                <Radio value={1}>Yes</Radio>
                                <Radio value={0}>No</Radio>
                              </Radio.Group>
                            </Form.Item>
                            {gstKeys?.includes(key) && (
                              <Form.Item
                                {...restField}
                                name={[name, "radio_gst"]}
                                fieldKey={[fieldKey, "radio_gst"]}
                              >
                                <Radio.Group
                                  onChange={(e) => {
                                    const tempRadioGst = e.target.value;
                                    const tempOptions =
                                      form.getFieldValue("options");
                                    const selectedTempOption =
                                      tempOptions[mainIndex];

                                    const tempPrice = selectedTempOption?.price;
                                    //discount
                                    const tempIsPercent =
                                      selectedTempOption?.is_flat;
                                    const tempDiscount =
                                      selectedTempOption?.discount;
                                    const tempSubTotal = tempIsPercent
                                      ? Number(tempPrice) -
                                        (Number(tempPrice) *
                                          Number(tempDiscount)) /
                                          100
                                      : Number(tempPrice) -
                                        Number(tempDiscount);

                                    //is gst
                                    const tempGstApplicable =
                                      selectedTempOption?.gst_applicable;
                                    const tempGst =
                                      tempGstApplicable === 1 &&
                                      tempRadioGst === 1
                                        ? parseFloat(tempSubTotal) * 0.1
                                        : tempGstApplicable === 1 &&
                                          tempRadioGst === 0
                                        ? parseFloat(tempSubTotal) / 11
                                        : 0;
                                    //include-exclude-gst
                                    const tempTotalPrice =
                                      tempRadioGst === 1
                                        ? Number(tempSubTotal) + Number(tempGst)
                                        : Number(tempSubTotal);

                                    tempOptions[mainIndex] = {
                                      ...selectedTempOption,
                                      total_price:
                                        Number(tempTotalPrice).toFixed(2),
                                      gst: Number(tempGst).toFixed(2),
                                    };

                                    form.setFieldsValue({
                                      options: tempOptions,
                                    });
                                  }}
                                >
                                  <Radio value={0}> Price including GST</Radio>
                                  <Radio value={1}>Price excluding GST </Radio>
                                </Radio.Group>
                              </Form.Item>
                            )}
                            {gstKeys?.includes(key) && (
                              <Form.Item
                                {...restField}
                                label="GST"
                                name={[name, "gst"]}
                                fieldKey={[fieldKey, "gst"]}
                              >
                                <Input type="number" placeholder="GST" />
                              </Form.Item>
                            )}
                            <Form.Item
                              {...restField}
                              label="Total Price"
                              name={[name, "total_price"]}
                              fieldKey={[fieldKey, "total_price"]}
                            >
                              <Input type="number" placeholder="Total Price" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              label="Package special note"
                              name={[name, "note"]}
                              fieldKey={[fieldKey, "note"]}
                            >
                              <Input.TextArea rows={3} placeholder="Write..." />
                            </Form.Item>
                            <Button
                              onClick={() => {
                                const newKeys = gstKeys.filter(
                                  (keyValue) => key !== keyValue
                                );
                                setGstKeys(newKeys);
                                remove(name);
                              }}
                              icon={<MinusCircleOutlined />}
                              type="dashed"
                              danger
                              style={{
                                gridColumn: "span 2",
                              }}
                            >
                              Remove Quote Package
                            </Button>
                          </div>
                        </div>
                      )
                    )}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          const newKey =
                            keys.length > 0 ? Math.max(...keys) + 1 : 0;
                          setKeys([...keys, newKey]);
                          setGstKeys((prev) => [...prev, newKey]);
                          add({
                            discount: 0,
                            packages: [{ package_desc: null }],
                            is_flat: false,
                            gst_applicable: 1,
                            radio_gst: 1,
                          });
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Quote Package
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridColumn: "span 2",
                gap: "1rem",
              }}
            >
              <Form.Item
                style={{ marginTop: "1rem" }}
                valuePropName="checked"
                name={"finance"}
              >
                <Checkbox
                  onChange={(e) => {
                    setFinanceSelect(e.target.checked);

                    form.setFieldsValue({
                      payment_terms: e.target.checked === false && null,
                    });
                  }}
                >
                  Finance Options
                </Checkbox>
              </Form.Item>
              {financeSelect && (
                <Form.Item
                  name={"payment_terms"}
                  rules={[
                    { required: true, message: "Payment method is required" },
                  ]}
                  label="Payment Terms"
                >
                  <Select placeholder="Select payment Term">
                    {formList?.timelines?.map((data) => (
                      <Option value={data?.threatre_id}>{data?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>
            <Form.Item
              name="description"
              label="Project Features"
              style={{ gridColumn: "span 2" }}
            >
              <TextArea rows={8} placeholder="Write here..." />
            </Form.Item>{" "}
            <Form.Item
              name="note"
              label="Special Note"
              style={{ gridColumn: "span 2" }}
            >
              <TextArea rows={3} placeholder="Write here..." />
            </Form.Item>
            <Form.Item name={"timeline"} label="Timeline">
              <Select placeholder="Select Timeline">
                {formList?.timelines?.map((data) => (
                  <Option value={data?.threatre_id}>{data?.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{ marginTop: "2rem" }}
              valuePropName="checked"
              name={"chk_timeline"}
            >
              <Checkbox> Don't display in quote confirmation</Checkbox>
            </Form.Item>
            <Form.Item name={"docs"} label="Attach Document">
              <Select mode="tags" placeholder="Select Attach Document">
                {formList?.docs?.map((data) => (
                  <Option value={data?.content_id}>{data?.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={"txt_files"} label="Attachment">
              <Input type="file" placeholder="Attachment" />
            </Form.Item>
            <div style={{ gridColumn: "span 2", display: "flex", gap: "1rem" }}>
              <Form.Item
                name="testing"
                label="Project Testing"
                style={{ width: "100%" }}
              >
                <TextArea rows={3} placeholder="Write here..." />
              </Form.Item>
              <Form.Item
                style={{ marginTop: "2rem" }}
                valuePropName="checked"
                name={"chk_test"}
              >
                <Checkbox> Don't display in quote confirmation</Checkbox>
              </Form.Item>
            </div>
            <div style={{ gridColumn: "span 2", display: "flex", gap: "1rem" }}>
              <Form.Item
                name="payment"
                label="Payment Terms"
                style={{ width: "100%" }}
              >
                <TextArea rows={3} placeholder="Write here..." />
              </Form.Item>
              <Form.Item
                style={{ marginTop: "2rem" }}
                valuePropName="checked"
                name={"chk_payment"}
              >
                <Checkbox> Don't display in quote confirmation</Checkbox>
              </Form.Item>
            </div>
            <Form.Item name={"quote_status"} label="Quote status">
              <Select placeholder="Select quote status">
                {formList?.status?.map((data) => (
                  <Option value={data?.threatre_id}>{data?.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={"quote_from"} label="Quote From">
              <Select placeholder="Select quote from">
                {formList?.quote_from?.map((data) => (
                  <Option value={data?.threatre_id}>{data?.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={"about_us"}
              label="How you know about us?
"
            >
              <Select placeholder="Select know about us">
                {formList?.abouts?.map((data) => (
                  <Option value={data?.threatre_id}>{data?.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                justifyContent: "flex-start",
              }}
            >
              <Form.Item name="date" label="Quote expiry date">
                <DatePicker format={"DD-MM-YYYY"} />
              </Form.Item>
              <Form.Item name={"contact_by"} label="How may we contact you ?">
                <Radio.Group>
                  <Radio value="Phone">Phone</Radio>
                  <Radio value="Email">Email</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <Button
              style={{ width: "fit-content", gridColumn: "span 2" }}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form>
        </div>
      )}
    </Drawer>
  );
};

export default UpdateMultiQuote;
