import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Spin,
  TimePicker,
  Upload,
  message,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

export default function ProjectUpdateStatus({
  isStatusUpdateOpen,
  setIsStatusUpdateOpen,
  record,
  fetchProjectDetail,
}) {
  const dispatch = useDispatch();

  const handleAddStatusUpdate = (values) => {
    dispatch({
      type: "ADD_PROJECT_STATUS_UPDATE_REQUEST",
      payload: {
        id: record?.task_id,
        formData: {
          ...values,
          branch_id: localStorage.getItem("branchId"),
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success("Project status update added");
        setIsStatusUpdateOpen(false);
        fetchProjectDetail();
      },
      failureFunction: () => {},
    });
  };

  return (
    <Modal
      open={isStatusUpdateOpen}
      onCancel={() => {
        setIsStatusUpdateOpen(false);
      }}
      title="Update Project Status"
      footer={false}
    >
      <Form
        onFinish={handleAddStatusUpdate}
        layout="vertical"
        className="grid-container"
      >
        <Form.Item label="Remainder Date" name="reminder_date">
          <DatePicker format={"DD-MM-YYYY"} />
        </Form.Item>
        <Form.Item label="Remainder Time" name="reminder_time">
          <TimePicker format={"hh:mm a"} />
        </Form.Item>
        <Form.Item
          label="Todays Update"
          name="details"
          className="grid-full-child"
          rules={[{ required: true, message: "Update detail is required" }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
}
