import {
  Button,
  Card,
  Divider,
  Drawer,
  Empty,
  Image,
  Tag,
  Timeline,
} from "antd";
import React, { useEffect } from "react";
import { AiOutlineMail } from "react-icons/ai";
import client from "./../../Assets/client.png";
import { useState } from "react";
import { BsChatLeftText } from "react-icons/bs";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SendComposedMail from "../Mailbox/SendComposedMail";
import TaskComponent from "./TaskComponent";
import Discussion from "../../Components/discussion/Discussion";
import ProjectUploadFile from "./ProjectFile/ProjectUploadFile";
import TestingProject from "./ProjectTesting/TestingProject";
import Resourse from "./ProjectResource/Resourse";
import ProjectChecklist from "./ProjectChecklist/ProjectChecklist";
import ProjectUpdateStatus from "./ProjectUpdateStatus";

const ProjectDetails = ({ Record, isDrawerOpen, setIsDrawerOpen }) => {
  const { leadById } = useSelector((store) => store?.LeadReducer);
  const { projectById } = useSelector((store) => store?.ProjectReducer);
  const [isStatusUpdateOpen, setIsStatusUpdateOpen] = useState(false);
  const [record, setRecord] = useState();

  const dispatch = useDispatch();
  const [current, setCurrent] = useState("projectDetail");
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);

  useEffect(() => {
    setRecord(projectById?.project);
  }, [projectById]);

  useEffect(() => {
    fetchProjectDetail();
  }, []);

  const fetchProjectDetail = () => {
    dispatch({
      type: "FETCH_PROJECT_DETAILS_REQUEST",
      payload: Record?.task_id,
      successFunction: () => {},
      failureFunction: () => {},
    });
  };

  const clientDetailsTabList = [
    {
      key: "projectDetail",
      tab: <span style={{ fontSize: "1rem" }}>Project Detail</span>,
    },
    {
      key: "task",
      tab: <span style={{ fontSize: "1rem" }}>Task</span>,
    },

    {
      key: "discussion",
      tab: <span style={{ fontSize: "1rem" }}>Discussion</span>,
    },
    {
      key: "testing",
      tab: <span style={{ fontSize: "1rem" }}>Testing</span>,
    },
    {
      key: "uploadFile",
      tab: <span style={{ fontSize: "1rem" }}>Upload File</span>,
    },
    {
      key: "resource",
      tab: <span style={{ fontSize: "1rem" }}>Resource</span>,
    },
    {
      key: "checklist",
      tab: <span style={{ fontSize: "1rem" }}>Checklist</span>,
    },
  ];

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween" style={{ gap: "1rem" }}>
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };

  return (
    <Drawer
      title="Project Details"
      open={isDrawerOpen}
      onClose={() => {
        setIsDrawerOpen(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.3rem" }} className="drawerStyle">
        <div
          className="flexRowWithoutStyle"
          style={{
            gap: "1rem",
            alignItems: "stretch",
          }}
        >
          {record?.image ? (
            <Image src={record?.image} className={"clientImage"} />
          ) : (
            <Image src={client} className={"clientImage"} />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: "10rem",
              height: "fit-content",
              width: "100%",
            }}
          >
            <div
              className="flexColumnWithoutStyle"
              style={{
                justifyContent: "flex-start",
                position: "relative",
                width: "100%",
                gap: "0.5rem",
              }}
            >
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                {`${record?.product ?? ""} (Project Id: ${record?.order_id})`}
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.4rem", color: "#aaa" }}
              >
                {record?.email && (
                  <span className="flexRowWithGap">
                    <AiOutlineMail
                      style={{
                        fontSize: "16",
                      }}
                    />{" "}
                    {record?.email ? record?.email : "N/A"}
                  </span>
                )}
              </div>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.5rem",
                marginTop: "0.5rem",
                alignSelf: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <button className="emailAndMessage" onClick={() => {}}>
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>
              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Email</span>
              </button>
              <button
                className="emailAndMessage"
                onClick={() => setIsStatusUpdateOpen(true)}
              >
                {/* <AiOutlineMail /> */}
                <span>Update Status</span>
              </button>
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "projectDetail" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <Card
                className="clientCard"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {detailFun("Project Title", `${record?.task_name ?? "N/A"}`)}
                {detailFun("Order Name", `${record?.product ?? "N/A"}`)}

                {detailFun("Order Name", `${record?.product ?? "N/A"}`)}

                {detailFun("Start Date", `${record?.start_date ?? "N/A"}`)}
                {detailFun("End Date", `${record?.end_date ?? "N/A"}`)}

                {detailFun("Email", `${record?.email ?? "N/A"}`)}
                {detailFun(
                  "Assign To",
                  record?.assigned_employees?.map((usr) =>
                    usr?.data?.map((user) => (
                      <Tag style={{ margin: "0.2rem 0.2rem" }}>
                        {user?.first_name} {user?.last_name}
                      </Tag>
                    ))
                  )
                )}

                {detailFun(
                  "Status",
                  (
                    <Tag style={{ background: record?.code }}>
                      {record?.status_name}
                    </Tag>
                  ) ?? "N/A"
                )}
                {detailFun("Description", `${record?.task_detail ?? " N/A"}`)}
              </Card>

              <Card
                className="clientCard"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <h3>Activity Log</h3>
                <Divider style={{ margin: "0.5rem 0" }} />

                <div
                  style={{
                    height: "100%",
                    maxHeight: "18rem",
                    overflowY: "scroll",
                    padding: "0.5rem 0 0 0",
                  }}
                >
                  {projectById?.updates?.length > 0 ? (
                    <Timeline>
                      {projectById?.updates?.map((data) => (
                        <Timeline.Item>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span>
                              {data?.first_name} {data?.last_name} (
                              {data?.added_date})
                            </span>
                            <p>{data?.content}</p>
                          </div>
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  ) : (
                    <Empty />
                  )}
                </div>
              </Card>
            </div>
          )}
          {current === "task" && <TaskComponent projectRecord={Record} />}
          {current === "discussion" && <Discussion record={Record} />}
          {current === "uploadFile" && <ProjectUploadFile record={Record} />}
          {current === "testing" && <TestingProject record={Record} />}
          {current === "resource" && <Resourse record={Record} />}
          {current === "checklist" && <ProjectChecklist record={Record} />}
        </Card>
      </Card>
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}

      {isStatusUpdateOpen && (
        <ProjectUpdateStatus
          record={Record}
          fetchProjectDetail={fetchProjectDetail}
          isStatusUpdateOpen={isStatusUpdateOpen}
          setIsStatusUpdateOpen={setIsStatusUpdateOpen}
        />
      )}
    </Drawer>
  );
};

export default ProjectDetails;
