import { Button, Popconfirm, Space, Table, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import AddUploadFile from "./AddUploadFile";
import { useDispatch, useSelector } from "react-redux";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import UpdateFileStatus from "./UpdateFileStatus";

export default function ProjectUploadFile({ record }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const { uploadfileList } = useSelector((store) => store?.ProjectReducer);

  const [isStatusUpdate, setIsStatusUpdate] = useState(false);
  const [fileRecord, setFileRecord] = useState(null);

  useEffect(() => {
    fetchUploadfileList();
  }, []);

  const fetchUploadfileList = () => {
    dispatch({
      type: "FETCH_UPLOAD_FILE_REQUEST",
      payload: record?.task_id,
      isSuccessFn: true,
      successFunction: (res) => {},
      failureFunction: () => {},
    });
  };

  const deleteRecordData = (record) => {
    dispatch({
      type: "DELETE_UPLOADFILE_RECORD_ATTEMPT",
      payload: {
        id: record?.file_id,
        formData: { file_id: record?.file_id },
      },
      isSuccessFn: false,
      successFunction: (res) => {
        message.success(res?.data);
        fetchUploadfileList();
      },
      failureFunction: (err) => {
        message.error(err?.response?.data?.message);
      },
    });
  };

  const columns = [
    {
      title: "File Title",
      dataIndex: "file_title",
      align: "center",
    },
    {
      title: "File",
      dataIndex: "file_name",
      align: "center",
    },
    {
      title: "Added By",

      // dataIndex: "added_by",
      align: "center",
      render: (dataObj) => `${dataObj?.first_name} ${dataObj?.last_name}`,
    },
    {
      title: "Added Date",
      dataIndex: "added_date",
      align: "center",
    },
    {
      title: "Action",
      fixed: "right",
      align: "center",
      render: (dataObj) => (
        <Space size="middle">
          <Tooltip title="Update Test Status">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsStatusUpdate(true);
                  setFileRecord(dataObj);
                }}
              >
                <EditFilled style={{ color: "blue" }} />
              </div>
            </a>
          </Tooltip>
          <Popconfirm
            title={`Do you want to delete record?`}
            onConfirm={() => {
              deleteRecordData(dataObj);
            }}
            onCancel={() => {}}
            okText="Ok"
            cancelText="Cancel"
          >
            <a>
              <div
                className="bordered"
                onClick={() => {
                  // setIsDrawerOpen(true);
                  //   setRecord(dataObj);
                }}
              >
                <DeleteFilled style={{ color: "red" }} />
              </div>
            </a>
          </Popconfirm>{" "}
        </Space>
      ),
    },
  ];
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      <Button
        style={{ width: "fit-content" }}
        onClick={() => {
          setIsOpenModal(true);
        }}
        type="primary"
      >
        Add Uploadfile
      </Button>
      <Table
        bordered
        columns={columns}
        pagination={false}
        dataSource={uploadfileList?.files}
        scroll={{ x: 1000 }}
      />
      {isOpenModal && (
        <AddUploadFile
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          record={record}
          fetchUploadfileList={fetchUploadfileList}
        />
      )}

      {isStatusUpdate && (
        <UpdateFileStatus
          isStatusUpdate={isStatusUpdate}
          setIsStatusUpdate={setIsStatusUpdate}
          record={fileRecord}
        />
      )}
    </div>
  );
}
