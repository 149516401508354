import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Spin,
  Tag,
  message,
} from "antd";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import parse, { domToReact } from "html-react-parser";
import { useSelector } from "react-redux";
import { scrollToTopFormError } from "../../HelperFunction";
const { Panel } = Collapse;
const { Option } = Select;

const SendComposedMail = ({
  docsList = [],
  forWhatId = null,
  emailList,
  isSendComposedEmailModalVisible,
  setIsSendComposedEmailModalVisible,
  urlPath,
  emailType,
  fetchRecordDetail = () => {},
  formDataUrl = null,
}) => {
  const quillRef = useRef(null);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { emailForm } = useSelector((store) => store?.ProfileReducer);
  const [uploadFile, setUploadFile] = useState(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [formRecord, setFormRecord] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [presetForm, setPresetForm] = useState(null);

  useEffect(() => {
    fetchEmailFormData();
  }, []);

  const fetchEmailFormData = () => {
    dispatch({
      type: "FETCH_EMAIL_FORM_REQUEST",
      payload: { id: forWhatId },
      isSuccessFn: true,
      successFunction: (res) => {
        fetchEmailTemplateContent(res?.data?.data?.defualt_email?.id);

        emailType === "lead" &&
          form.setFieldsValue({
            useremails: [res?.data?.data?.customer_arr?.email],
            subject: res?.data?.data?.subject,
            message: res?.data?.data?.message,
            docs: docsList,
          });
      },
      failureFunction: () => {},
    });
  };

  useEffect(() => {
    formDataUrl &&
      (emailType === "order" ||
        emailType === "order_invoice" ||
        emailType === "event") &&
      dispatch({
        type: "FETCH_EMAIL_FORM_RECORD_REQUEST",
        payload: { getUrl: formDataUrl },
        isSuccessFn: true,
        successFunction: (res) => {
          form.setFieldsValue({
            useremails: [res?.data?.customer_arr?.email],
            subject: res?.data?.subject,
            message: res?.data?.message,
            sms: res?.data?.sms,
          });
          setPresetForm(res?.data);
          setCustomerList(res?.data?.customers);
        },
        failureFunction: () => {},
      });

    formDataUrl &&
      emailType === "quote" &&
      dispatch({
        type: "FETCH_QUOTE_EMAIL_FORM_RECORD_REQUEST",
        payload: { getUrl: formDataUrl },
        isSuccessFn: true,
        successFunction: (res) => {
          form.setFieldsValue({
            useremails: [res?.data?.customer_arr?.email],
            subject: res?.data?.subject,
            message: res?.data?.message,
          });
        },
        failureFunction: () => {},
      });
  }, [formDataUrl]);

  const fetchEmailTemplateContent = (emailId) => {
    dispatch({
      type: "FETCH_EMAIL_CONTENT_REQUEST",
      payload: {
        lead_id: forWhatId,
        email_id: emailId,
        branch_id: localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: async (res) => {
        form.setFieldsValue({
          templates: emailId,
          message: res?.data?.msg,
          subject: res?.data?.subject,
          docs: docsList,
        });
      },
      failureFunction: () => {},
    });
  };

  const sendComposedMailSubmitHandler = (values) => {
    dispatch({
      type: "EMAIL_SEND_REQUEST",
      payload: {
        url: urlPath,
        formData: {
          ...values,
          branch_id: localStorage.getItem("branchId"),
          lead_id: emailType === "lead" ? forWhatId : null,
          copy_me: values?.copy_me?.length > 0 ? true : false,
          send_sms: values?.send_sms?.length > 0 ? true : false,
          files: uploadFile ? [uploadFile] : null,
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success("Email send successfully!");
        setIsSendComposedEmailModalVisible(false);
        fetchRecordDetail();
      },
      failureFunction: () => {},
    });
  };

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    const formData = new FormData();
    formData.append("files", newFile);
    formData.append("branch_id", localStorage.getItem("branchId"));    formData.append("path", "lms_project");

    setIsUploadLoading(true);
    dispatch({
      type: "UPLOAD_FILE_REQUEST",
      payload: formData,
      isSuccessFn: true,
      successFunction: (res) => {
        setUploadFile(res?.data?.source?.src);
        message.success("Upload file successful");
        setIsUploadLoading(false);
      },
      failureFunction: () => {
        setIsUploadLoading(false);
        message.error("Failed to upload file");
      },
    });
  };

  return (
    <Drawer
      title="Compose Email"
      open={isSendComposedEmailModalVisible}
      onClose={() => {
        setIsSendComposedEmailModalVisible(false);
      }}
      footer={null}
      width={630}
    >
      <Form
        layout="vertical"
        onFinish={sendComposedMailSubmitHandler}
        onFinishFailed={scrollToTopFormError}
        colon={true}
        form={form}
        className={"drawerStyle"}
        initialValues={{ useremails: emailList }}
      >
        {emailType !== "event" && (
          <Form.Item
            label="To"
            name={"useremails"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter email address you want to send to!",
              },
            ]}
          >
            <Select mode="tags" placeholder="Select Emails" />
          </Form.Item>
        )}
        <Form.Item
          label="Other Email (enter email seperate by comma)"
          name={"other_email"}
          style={{ width: "100%" }}
        >
          <Input placeholder="Other emails" />
        </Form.Item>
        {/* <Collapse
          size="small"
          style={{ padding: "0", margin: "0s" }}
          bordered={false}
        >
          <Panel header="CC and BCC" key="1">
            <Form.Item label="CC" name={"cc"} style={{ width: "100%" }}>
              <Select
                mode="tags"
                labelInValue
                showSearch
                placeholder="Select Emails"
              />
            </Form.Item>
            <Form.Item label="BCC" name={"bcc"} style={{ width: "100%" }}>
              <Select
                mode="tags"
                labelInValue
                showSearch
                placeholder="Select Emails"
              />
            </Form.Item>
          </Panel>
        </Collapse> */}
        <Form.Item
          label="Choose Email Template"
          name={"templates"}
          style={{ width: "100%" }}
        >
          <Select
            onChange={fetchEmailTemplateContent}
            placeholder="Select Email Template"
          >
            {emailForm?.emails?.map((data) => (
              <Option value={data?.id}>{data?.email_subject}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Subject"
          name={"subject"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a subject!",
            },
          ]}
        >
          <Input placeholder="Email Subject" />
        </Form.Item>
        <Form.Item
          label={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "1rem",
                width: "100%",
              }}
            >
              <span>Message</span>
            </div>
          }
          name={"message"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter a message!",
            },
          ]}
        >
          <ReactQuill
            ref={quillRef}
            style={{
              background: "white",
              height: "200px",
              marginBottom: "2.5rem",
            }}
            modules={{
              toolbar: {
                container: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ align: [] }],
                  ["link", "image"],
                  ["clean"],
                  [{ color: [] }],
                ],
              },
            }}
            theme="snow"
            preserveWhitespace={true}
          />
        </Form.Item>
        {emailType === "event" && (
          <Form.Item
            label={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <span>SMS</span>
              </div>
            }
            name={"sms"}
            style={{ width: "100%" }}
          >
            <ReactQuill
              style={{
                background: "white",
                height: "200px",
                marginBottom: "2.5rem",
              }}
              modules={{
                toolbar: {
                  container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ align: [] }],
                    ["link", "image"],
                    ["clean"],
                    [{ color: [] }],
                  ],
                },
              }}
              theme="snow"
              preserveWhitespace={true}
            />
          </Form.Item>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <Form.Item name={"files"} label="Add New Attachment">
            <Input
              onChange={handleFileChange}
              placeholder="Upload New Attachment"
              type="file"
            />
          </Form.Item>
          {isUploadLoading && (
            <span>
              <Spin /> Loading
            </span>
          )}
        </div>
        <Form.Item name={"docs"} label="Attached Documents">
          <Select mode="tags" placeholder="select document">
            {emailForm?.docs?.map((data) => (
              <Option value={data?.content_id}>{data?.name}</Option>
            ))}
          </Select>
        </Form.Item>
        {emailType === "event" && (
          <Form.Item name={"customers"} label="Customers">
            <Select
              mode="tags"
              onSearch={(value) => {
                const searchKey = value.trim();
                const test = presetForm?.customers?.filter((employee) =>
                  employee.customer_name
                    .toLowerCase()
                    .includes(searchKey.toLowerCase())
                );
                setCustomerList(searchKey === "" ? customerList : test);
              }}
              showSearch
              filterOption={false}
              placeholder="Select Customer"
            >
              {customerList?.map((data) => (
                <Option value={data?.customer_id}>
                  {data?.customer_name}({data?.email})
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <div style={{ display: "flex", gap: "1rem", paddingTop: "1.5rem" }}>
          <Form.Item name="copy_me">
            <Checkbox.Group>
              <Checkbox value={"yes"}>Send copy of email to me</Checkbox>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name="send_sms">
            <Checkbox.Group>
              <Checkbox value={"yes"}>Send SMS</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </div>
        <Button type="primary" htmlType="submit">
          Send
        </Button>
      </Form>
    </Drawer>
  );
};

export default SendComposedMail;
