import { Empty, Modal, Timeline } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export default function InvoiceActivityLog({
  invoiceModalOpen,
  setInvoiceModalOpen,
  visaRecord,
}) {
  const dispatch = useDispatch();
  const [logList, setLoglist] = useState([]);

  console.log("log", logList);

  useEffect(() => {
    dispatch({
      type: "FETCH_INVOICE_LOGS_REQUEST",
      payload: {
        id: visaRecord?.id,
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setLoglist(res?.data?.data);
      },
    });
  }, []);
  return (
    <Modal
      title="Invoice activity log"
      footer={false}
      open={invoiceModalOpen}
      onCancel={() => {
        setInvoiceModalOpen(false);
      }}
    >
      <div
        style={{
          minHeight: "12rem",
          maxHeight: "20rem",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        {logList?.length === 0 ? (
          <Empty />
        ) : (
          <Timeline mode="left">
            {logList?.map((log) => (
              <Timeline.Item
                label={
                  log?.localDateTime
                    ? moment
                        .utc(log?.localDateTime)
                        .local()
                        .format("D MM YYYY, h:mm A")
                    : ""
                }
              >
                <h3> {log?.paymentStatus}</h3>{" "}
                <p>Admin Note: {log?.adminNote}</p>
              </Timeline.Item>
            ))}
          </Timeline>
        )}
      </div>
    </Modal>
  );
}
