import { Form, Input } from "antd";
import React from "react";

export default function AccountInformation() {
  return (
    <Form layout="vertical">
      <Form.Field label="First Name">
        <Input placeholder="First Name" />
      </Form.Field>
      <Form.Field label="Last Name">
        <Input placeholder="Last Name" />
      </Form.Field>
      <Form.Field label="Email">
        <Input placeholder="Email" />
      </Form.Field>
    </Form>
  );
}
