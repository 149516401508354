import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  loading: false,

  assignedTotasks: {},
  myTask: {},
  taskCsv: {},
  taskFormData: "",
  taskListData: "",
  taskById: "",
  dailyTaskList: null,
};

const TaskReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_ASSIGNED_BY_TASK_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ASSIGNED_BY_TASK_REQUEST_SUCCESS": {
      return {
        ...state,
        myTask: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ASSIGNED_BY_TASK_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "FETCH_ASSIGNED_TO_TASK_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ASSIGNED_TO_TASK_REQUEST_SUCCESS": {
      return {
        ...state,
        assignedTotasks: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_ASSIGNED_TO_TASK_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "UPDATE_TASK_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "UPDATE_TASK_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Task updated.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_TASK_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update Task.");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_TASK_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "DELETE_TASK_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Task deleted.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "DELETE_TASK_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to delete Task.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_TASK_STATUS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "TOGGLE_TASK_STATUS_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! Task status changed.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "TOGGLE_TASK_STATUS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to change Task status.");

      message.error("error");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DOWNLOAD_TASK_CSV_REQUEST": {
      message.success("Success! CSV downloaded.");

      return {
        ...state,
        isLoading: true,
      };
    }

    case "DOWNLOAD_TASK_CSV_REQUEST_SUCCESS": {
      return {
        ...state,
        taskCsv: action.payload,
        isLoading: false,
      };
    }
    case "DOWNLOAD_TASK_CSV_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to download csv.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "IMPORT_TASK_CSV_FILE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "IMPORT_TASK_CSV_FILE_REQUEST_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! CSV imported.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "IMPORT_TASK_CSV_FILE_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to import CSV.");

      return {
        ...state,
        isLoading: false,
      };
    }

    //thokyo
    case "FETCH_TASK_FORM_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_TASK_FORM_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        taskFormData: action?.payload,
      };
    }
    case "FETCH_TASK_FORM_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      }
      return {
        ...state,
        isLoading: false,
      };
    }

    case "ADD_TASK_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_TASK_REQUEST_SUCCESS": {
      message.success("Task added, successful");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_TASK_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add Task.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "ADD_PICKUP_JOB_REQUEST": {
      return {
        ...state,
        loading: true,
      };
    }
    case "ADD_PICKUP_JOB_REQUEST_SUCCESS": {
      message.success("Task pickup successful");

      return {
        ...state,
        loading: false,
      };
    }
    case "ADD_PICKUP_JOB_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to pickup task.");

      return {
        ...state,
        loading: false,
      };
    }

    case "ADD_JOB_BASKET_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_JOB_BASKET_REQUEST_SUCCESS": {
      message.success("Job basket added successful");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_JOB_BASKET_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add job basket.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "EDIT_TASK_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "EDIT_TASK_REQUEST_SUCCESS": {
      message.success("Task updated, successful");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "EDIT_TASK_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to edit Task.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_TASK_LIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_TASK_LIST_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        taskListData: action?.payload,
      };
    }
    case "FETCH_TASK_LIST_REQUEST_FAILURE": {
      message?.error(action?.payload?.response?.data?.message);
      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_TASK_DETAILS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_TASK_DETAILS_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        taskById: action?.payload,
      };
    }
    case "FETCH_TASK_DETAILS_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_DAILY_TASK_LIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_DAILY_TASK_LIST_SUCCESS": {
      return {
        ...state,
        dailyTaskList: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_DAILY_TASK_LIST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default TaskReducer;
