import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, Input, Select, message } from "antd";

import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";

const EditNewsletter = ({ isEditNewsletter, setIsEditNewsletter }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSubmitRecord = (values) => {
    dispatch({
      type: "ADD_CUSTOMER_REQUEST",
      payload: { ...values, branch_id: localStorage.getItem("branchId") },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsEditNewsletter(false);
        message.success(res?.data);
      },
      failureFunction: () => {},
    });
  };

  return (
    <Drawer
      title="Edit Newsletter"
      open={isEditNewsletter}
      onClose={() => setIsEditNewsletter(false)}
      footer={null}
      width={1163}
    >
      <Form
        style={{ margin: "0 2rem" }}
        layout="vertical"
        onFinish={handleSubmitRecord}
        form={form}
      >
        <Form.Item label="Title" name="title">
          <Input placeholder="Title" />
        </Form.Item>
        <Form.Item label="Message" name="message">
          <ReactQuill
            style={{
              background: "white",
              marginBottom: "4rem",
              height: "250px",
            }}
            preserveWhitespace={true}
            theme="snow"
          />
        </Form.Item>

        <Form.Item label="Attachment" name="attachment">
          <Input type="file" />
        </Form.Item>

        <Form.Item label="Status" name="status">
          <Select placeholder="Select status">
            <Select.Option value={true}>Active</Select.Option>
            <Select.Option value={false}>Inactive</Select.Option>
          </Select>
        </Form.Item>

        <Button
          style={{ width: "fit-content", gridColumn: "span 2" }}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form>
    </Drawer>
  );
};

export default EditNewsletter;
