import { Button, DatePicker, Form, Input, Modal } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";

function PickupJob({
  isPickupJobVisible,
  setIsPickupJobVisible,
  record,
  fetchTaskList = () => {},
  fetchTaskDetailData = () => {},
}) {
  const dispatch = useDispatch();

  const handlePickupJob = (values) => {
    dispatch({
      type: "ADD_PICKUP_JOB_REQUEST",
      payload: {
        ...values,
        branch_id: localStorage.getItem("branchId"),
        task_id: record?.task_id,
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsPickupJobVisible(false);
        fetchTaskList();
        fetchTaskDetailData();
      },
    });
  };
  return (
    <Modal
      open={isPickupJobVisible}
      onCancel={() => {
        setIsPickupJobVisible(false);
      }}
      title="Pickup Job"
      footer={false}
    >
      <Form
        layout="vertical"
        onFinish={handlePickupJob}
        initialValues={{
          name: record?.task_name,
          details: record?.task_detail,
          end_date:
            record?.end_date !== ""
              ? moment(record?.end_date, "DD-MM-YYYY")
              : null,
        }}
      >
        <Form.Item
          name="name"
          label="Task title"
          rules={[
            {
              required: true,
              message: "Task title is required",
            },
          ]}
        >
          <Input placeholder="Task title" />
        </Form.Item>
        <Form.Item
          name="details"
          label="Task detail"
          rules={[
            {
              required: true,
              message: "Task detail is required",
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="Task detail" />
        </Form.Item>
        <Form.Item
          name="end_date"
          label="End Date"
          rules={[
            {
              required: true,
              message: "End date is required",
            },
          ]}
        >
          <DatePicker format={"DD-MM-YYYY"} />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Pickup Job
        </Button>
      </Form>
    </Modal>
  );
}

export default PickupJob;
