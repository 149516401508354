import { message } from "antd";
const INIT_STATE = {
  isLoading: false,
  projectFormData: "",
  projectListData: "",
  projectChecklist: null,
  projectDiscussionList: null,
  projectTestList: null,
  projectResourceList: null,
  projectById: null,
  uploadfileList: null,
  resourceLogList: null,
  testLogList: null,
  discussionLogList: null,
  uploadfileLogList: null,
};

const ProjectReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ADD_PROJECT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_PROJECT_REQUEST_SUCCESS": {
      message.success(action?.payload);
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_PROJECT_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error. unable to add project");
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    case "EDIT_PROJECT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "EDIT_PROJECT_REQUEST_SUCCESS": {
      message.success(action?.payload);
      return {
        ...state,
        isLoading: false,
      };
    }
    case "EDIT_PROJECT_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error. unable to edit project");
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_PROJECT_FORM_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_PROJECT_FORM_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        projectFormData: action?.payload,
      };
    }
    case "FETCH_PROJECT_FORM_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      }
      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_PROJECT_DETAILS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_PROJECT_DETAILS_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        projectById: action?.payload,
      };
    }
    case "FETCH_PROJECT_DETAILS_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      }
      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_UPLOAD_FILE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_UPLOAD_FILE_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        uploadfileList: action?.payload,
      };
    }
    case "FETCH_UPLOAD_FILE_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      }
      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_CHECKLISTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_CHECKLISTS_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        projectChecklist: action?.payload,
      };
    }
    case "FETCH_CHECKLISTS_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      }
      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_PROJECT_LIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_PROJECT_LIST_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        projectListData: action?.payload,
      };
    }
    case "FETCH_PROJECT_LIST_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      }
      return {
        ...state,
        isLoading: false,
      };
    }

    case "ADD_PROJECT_TEST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_PROJECT_TEST_SUCCESS": {
      message.success(action?.payload);
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_PROJECT_TEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error. unable to add test desciption");
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_PROJECT_DISCUSSION_LIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_PROJECT_DISCUSSION_LIST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        projectDiscussionList: action?.payload,
      };
    }
    case "FETCH_PROJECT_DISCUSSION_LIST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      }
      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_PROJECT_TEST_LIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_PROJECT_TEST_LIST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        projectTestList: action?.payload,
      };
    }
    case "FETCH_PROJECT_TEST_LIST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      }
      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_PROJECT_RESOURCE_LIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_PROJECT_RESOURCE_LIST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        projectResourceList: action?.payload,
      };
    }
    case "FETCH_PROJECT_RESOURCE_LIST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      }
      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_RESOURCE_STATUS_LOG_ATTEMPT": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_RESOURCE_STATUS_LOG_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        resourceLogList: action?.payload,
      };
    }
    case "FETCH_RESOURCE_STATUS_LOG_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      }
      return {
        ...state,
        isLoading: false,
      };
    }

    case "UPDATE_RESOURCE_STATUS_ATTEMPT": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "UPDATE_RESOURCE_STATUS_SUCCESS": {
      message.success(action?.payload);
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_RESOURCE_STATUS_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error. unable to update project resource");
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_TEST_STATUS_LOG_ATTEMPT": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_TEST_STATUS_LOG_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        testLogList: action?.payload,
      };
    }
    case "FETCH_TEST_STATUS_LOG_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      }
      return {
        ...state,
        isLoading: false,
      };
    }

    case "UPDATE_TEST_STATUS_ATTEMPT": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "UPDATE_TEST_STATUS_SUCCESS": {
      message.success(action?.payload);
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_TEST_STATUS_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error. unable to update project test");
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_DISCUSSION_STATUS_LOG_ATTEMPT": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_DISCUSSION_STATUS_LOG_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        discussionLogList: action?.payload,
      };
    }
    case "FETCH_DISCUSSION_STATUS_LOG_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      }
      return {
        ...state,
        isLoading: false,
      };
    }

    case "UPDATE_DISCUSSION_STATUS_ATTEMPT": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "UPDATE_DISCUSSION_STATUS_SUCCESS": {
      message.success(action?.payload);
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_DISCUSSION_STATUS_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error. unable to update project test");
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    case "UPDATE_FILE_STATUS_ATTEMPT": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "UPDATE_FILE_STATUS_SUCCESS": {
      message.success(action?.payload);
      return {
        ...state,
        isLoading: false,
      };
    }
    case "UPDATE_FILE_STATUS_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error. unable to update project test");
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_FILE_STATUS_LOG_ATTEMPT": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_FILE_STATUS_LOG_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        uploadfileLogList: action?.payload,
      };
    }
    case "FETCH_FILE_STATUS_LOG_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      }
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default ProjectReducer;
