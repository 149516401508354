import { Button, Form, Input, Modal, message } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import OtpModal from "../otpModal/OtpModal";
import { useDispatch } from "react-redux";

export default function SessionExpiry() {
  const [isExpire, setIsExpire] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  });

  const checkForInactivity = () => {
    const isExpire = localStorage.getItem("isSessionExpire");

    if (isExpire === "true") {
      setIsExpire(true);
    } else {
      const expiryTime = localStorage.getItem("thokyoSessionTime");
      if (expiryTime < Date.now()) {
        setIsExpire(true);
        localStorage.setItem("isSessionExpire", "true");
      }
    }
  };

  const updateExpiryTime = () => {
    const expiryTime = Date.now() + 300000;
    localStorage.setItem("thokyoSessionTime", expiryTime);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateExpiryTime();

    window.addEventListener("click", updateExpiryTime);
    window.addEventListener("keypress", updateExpiryTime);
    window.addEventListener("scroll", updateExpiryTime);
    window.addEventListener("mousemove", updateExpiryTime);

    return () => {
      window.removeEventListener("click", updateExpiryTime);
      window.removeEventListener("keypress", updateExpiryTime);
      window.removeEventListener("scroll", updateExpiryTime);
      window.removeEventListener("mousemove", updateExpiryTime);
    };
  }, []);

  const handleSubmitOtp = () => {
    const otpValue = Object.values(otp).join("");
    if (otpValue.length != 4) {
      message.error("pin code must be 4 digits");
    } else {
      setIsLoading(true);
      dispatch({
        type: "SUBMIT_PINCODE_ATTEMPT",
        payload: {
          pin_code: otpValue,
          token: "abc123",
          branch_id: localStorage.getItem("branchId"),
        },
        isSuccessFn: true,
        successFunction: (res) => {
          message.success("Success");
          setIsLoading(false);
          setIsExpire(false);
          setOtp({ otp1: "", otp2: "", otp3: "", otp4: "" });
          const expiryTime = Date.now() + 300000;
          localStorage.setItem("thokyoSessionTime", expiryTime);
          localStorage.setItem("isSessionExpire", "false");
        },
        failureFunction: (err) => {
          setIsLoading(false);
          message.error(err?.response?.data?.message);
        },
      });
    }
  };

  return (
    <Modal
      width={"fit-content"}
      open={isExpire}
      footer={false}
      closable={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
          margin: "0.5rem 1.5rem",
        }}
      >
        <h3> Session expire enter pin-code</h3>
        <OtpModal setOtp={setOtp} otp={otp} />
        <Button
          onClick={handleSubmitOtp}
          style={{ width: "100%" }}
          type="primary"
          loading={isLoading}
        >
          Submit
        </Button>
        <Link to="/">Go to Login</Link>
      </div>
    </Modal>
  );
}
