import React, { useEffect, useState } from "react";
import { Card, Drawer, Form, Image, Tag } from "antd";
import { useDispatch } from "react-redux";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import client from "./../../Assets/client.png";

import { GoLocation } from "react-icons/go";

import { useSelector } from "react-redux";
import { BsChatLeftText } from "react-icons/bs";
import SendComposedMail from "../Mailbox/SendComposedMail";
import { UserOutlined } from "@ant-design/icons";
import OrderCard from "./OrderCard";
import AdminOrderNote from "./AdminOrderNote";
import OrderStatusUpdate from "./OrderStatusUpdate";
import OrderCustomerNote from "./OrderCustomerNote";
import OrderTask from "./OrderTask";
import OrderChecklist from "./OrderChecklist";
import OrderInvoice from "./OrderInvoice";

const OrderDetail = ({
  record,
  isDrawerOpen,
  setIsDrawerOpen,
  isInvoice = false,
}) => {
  const { isLoading, quoteById } = useSelector(
    (store) => store?.QuotationReducer
  );
  const dispatch = useDispatch();

  const [current, setCurrent] = useState("order");
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [isStatusUpdate, setIsStatusUpdate] = useState(false);
  const [orderById, setOrderById] = useState(null);

  useEffect(() => {
    fetchOrderById();
  }, []);

  const fetchOrderById = () => {
    dispatch({
      type: "FETCH_ORDER_BY_ID_REQUEST",
      payload: record?.order_id,
      isSuccessFn: true,
      successFunction: (res) => {
        setOrderById(res?.data);
      },
      failureFunction: () => {},
    });
  };

  const clientDetailsTabList = [
    {
      key: "order",
      tab: "Order",
    },
    { key: "customerNote", tab: "Customer Note" },
    { key: "adminNote", tab: "Admin Note" },
    { key: "orderTask", tab: "Task" },
    { key: "checkList", tab: "Checklist" },
    { key: "invoice", tab: "Invoice" },
  ];

  return (
    <Drawer
      title={isInvoice ? "Invoice Detail" : "Order Detail"}
      open={isDrawerOpen}
      onClose={() => {
        setIsDrawerOpen(false);
      }}
      footer={null}
      width={1163}
    >
      <Card
        loading={isLoading}
        style={{ borderRadius: "0.5rem" }}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ gap: "1rem", alignItems: "flex-start" }}
        >
          <Image src={client} className={"clientImage"} />

          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "space-between",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              height: "10rem",
              width: "100%",
              gap: "0.5rem",
            }}
          >
            <span style={{ position: "absolute", top: 0, right: 0 }}>
              <Tag color={record?.order_status_code}>
                {record?.order_status}
              </Tag>
            </span>
            <div>
              <div style={{ fontSize: "20px", fontWeight: "bold" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {" "}
                <span className="flexRowWithGap">
                  <UserOutlined
                    style={{
                      fontSize: "20",
                    }}
                  />
                  {orderById?.customer?.customer_name ?? "N/A"}
                </span>
                <span className="flexRowWithGap">
                  <AiOutlinePhone
                    style={{
                      fontSize: "20",
                    }}
                  />
                  {orderById?.customer?.mobile_number ?? "N/A"}
                </span>
                <span className="flexRowWithGap">
                  <GoLocation
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {orderById?.customer?.billing_address1 ?? "N/A"}
                </span>
                <span className="flexRowWithGap">
                  <AiOutlineMail
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {orderById?.customer?.email ?? "N/A"}
                </span>
              </div>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
              }}
            >
              <button className="emailAndMessage" onClick={() => {}}>
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>
              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Email</span>
              </button>
              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsStatusUpdate(true);
                }}
              >
                <span>Status Update</span>
              </button>
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "order" && (
            <OrderCard
              fetchOrderById={fetchOrderById}
              dataObj={record}
              orderById={orderById}
            />
          )}
          {current === "invoice" && (
            <OrderInvoice
              fetchOrderById={fetchOrderById}
              dataObj={record}
              orderById={orderById}
            />
          )}
          {current === "adminNote" && <AdminOrderNote record={record} />}
          {current === "customerNote" && (
            <OrderCustomerNote
              fetchOrderById={fetchOrderById}
              orderById={orderById}
            />
          )}
          {current === "orderTask" && <OrderTask record={record} />}
          {current === "checkList" && <OrderChecklist record={record} />}
        </Card>
      </Card>
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          urlPath={`/send_order_email/${record?.order_id}`}
          emailList={[]}
          forWhatId={record?.order_id}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
          // formDataUrl={`/order_email_form/${record?.order_id}`}
          emailType="order"
        />
      )}

      {isStatusUpdate && (
        <OrderStatusUpdate
          isStatusUpdate={isStatusUpdate}
          setIsStatusUpdate={setIsStatusUpdate}
          record={record}
          orderById={orderById}
          fetchOrderById={fetchOrderById}
        />
      )}
    </Drawer>
  );
};

export default OrderDetail;
