import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./Responsiveness.css";
import PrivateRoute from "./Helpers/PrivateRoute";
import Contacts from "./Pages/Contacts/Contacts";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Login from "./Pages/Login/Login";
import Leads from "./Pages/Leads/Leads";
import "./scss/my-app.css";
import Companies from "./Pages/Companies/Companies";
import Institute from "./Pages/Education/Institute/Institute";
import RegisterEmail from "./Pages/Signup/RegisterEmail";
import OTPverification from "./Pages/Signup/OTPverification";
import Signup from "./Pages/Signup/Signup";
import ForgotPassword from "./Pages/PasswordReset/ForgotPassword";
import ForgotPasswordOTPverification from "./Pages/PasswordReset/ForgotPasswordOTPverification";
import ChangePassword from "./Pages/PasswordReset/ChangePassword";
import Courses from "./Pages/Courses/Courses";
import Profile from "./Pages/Profile/Profile";
import Appointments from "./Pages/Appointments/Appointments";
import Counsel from "./Pages/Counsel/Counsel";
import HeadAgent from "./Pages/HeadAgent/HeadAgent";
import SubAgent from "./Pages/SubAgent/SubAgent";
import Support from "./Pages/Support/Support";
import RolesAndPermission from "./Pages/RolesAndPermission/RolesAndPermission";
import EmployeeManager from "./Pages/EmployeeManager/EmployeeManager";
import Departments from "./Pages/EmployeeManager/Department/Departments";
import Visas from "./Pages/Visas/Visas";
import Settings from "./Pages/Settings/Settings";
import Consultancies from "./Pages/Consultancies/Consultancies";
import Task from "./Pages/Tasks/Task";
import Resources from "./Pages/Resources/Resources";
import Clients from "./Pages/Clients/Clients";
import Country from "./Pages/Settings/Country";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useEffect } from "react";
import firebase from "./firebase";
import app from "./firebase";
import CollegeFinder from "./Pages/CollegeFinder/CollegeFinder";
import EducationVisa from "./Pages/VisaClass/Education/EducationVisa";
import MigrationVisas from "./Pages/VisaClass/Migration/MigrationVisas";
import VisaTypeCases from "./Pages/VisaClass/VisaTypeCases/VisaTypeCases";
import Todo from "./Pages/Todo/Todo";
import Consultants from "./Pages/Consultants/Consultants";
import OrganizationalSettings from "./Pages/OrganizationalSettings/OrganizationalSettings";
import Templates from "./Pages/OrganizationalSettings/Templates/Templates";
import Email from "./Pages/OrganizationalSettings/Email/Email";
import Attendance from "./Pages/Attendance/Attendance";
import MyCounselling from "./Pages/MyCounselling/MyCounselling";
import MyCases from "./Pages/MyCases/MyCases";
import Branches from "./Pages/Branch/Branches";
import Branch from "./Pages/Branch/Branch/Branch";
import { useSelector } from "react-redux";
import TableSkeleton from "./Helpers/TableSkeleton/tableSkelaton";
import PrivacyPolicy from "./Pages/PrivacyAndTerms/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./Pages/PrivacyAndTerms/TermsConditions/TermsConditions";
import PaymentSuccess from "./Pages/PaymentSuccess/PaymentSuccess";
import SubscriptionSuccess from "./Pages/PaymentSuccess/SubscriptionSuccess";
import SubscriptionFailure from "./Pages/PaymentSuccess/SubscriptionFailure";
import SubscriptionList from "./Pages/Profile/SubscriptionPlan/SubscriptionList";
import DashboardSettings from "./Pages/OrganizationalSettings/DashboardSettings/DashboardSettings";
import LeadSetttings from "./Pages/OrganizationalSettings/LeadSettings/LeadSetttings";
import AppointmentSettings from "./Pages/OrganizationalSettings/AppointmentSettings/AppointmentSettings";
import CaseSettings from "./Pages/OrganizationalSettings/CaseSettings/CaseSettings";
import GeneralSettings from "./Pages/OrganizationalSettings/GeneralSettings/GeneralSettings";
import MonthlyReport from "./Pages/Attendance/MonthlyReport";
import CheckInSettings from "./Pages/OrganizationalSettings/CheckInSettings/CheckInSettings";
import Mailbox from "./Pages/Mailbox/Mailbox";
import AutoreminderSettings from "./Pages/OrganizationalSettings/AutoreminderSettings/AutoreminderSettings";
import Degree from "./Pages/Degree/Degree";
import Campaign from "./Pages/CampaignManager/Campaign";
import CampaignForm from "./Pages/CampaignManager/CampaignForm";
import SuccessPage from "./Pages/CampaignManager/SuccessPage";
import ErrorPage from "./Pages/CampaignManager/ErrorPage";
import EmployeeSettings from "./Pages/OrganizationalSettings/EmployeeSettings/EmployeeSettings";
import Import from "./Pages/OrganizationalSettings/Import/Import";
import AppointmentForm from "./Pages/CampaignManager/AppointmentForm";
import CompanyLogi from "./Pages/Login/CompanyLogin";
import CompanyLogin from "./Pages/Login/CompanyLogin";
import CompanyLoginWithEmail from "./Pages/Login/CompanyLoginWithEmail";
import Quotation from "./Pages/Quotation/Quotation";
import QuotationSettings from "./Pages/OrganizationalSettings/QuotationSettings/QuotationSettings";
import { useDispatch } from "react-redux";
import { getCookie, setLocalStorage } from "./Helpers/FrontendHelper";
import QuickInquiry from "./Pages/QuickInquiry/QuickInquiry";
import axios from "axios";
import { message } from "antd";
import DocumentChecklist from "./Pages/DocumentChecklist/DocumentChecklist";
import ProductService from "./Pages/ProductService/ProductService";
import Project from "./Pages/Projects/Project";
import Events from "./Pages/Events/Events";
import ThokyoLogin from "./Pages/Login/ThokyoLogin";
import Orders from "./Pages/orders/Orders";
import Activity from "./Pages/Activity/Activity";
import Newsletter from "./Pages/Newsletter/Newsletter";
import Users from "./Pages/Users/Users";
import Invoice from "./Pages/Invoice/Invoice";
import Checkin from "./Pages/Checkin/Checkin";
import Security from "./Pages/Security/Security";
import CompanyManager from "./Pages/CompanyManager/CompanyManager";
const App = () => {
  const store = useSelector((state) => {
    return {
      branchState: state.BranchReducer,
      profileState: state.ProfileReducer,
    };
  });
  const dispatch = useDispatch();

  useEffect(async () => {
    if (getCookie("accessToken") !== undefined) {
      dispatch({
        type: "FETCH_PROFILE_REQUEST",
        isSuccessFn: true,
        successFunction: profileSuccessFunction,
      });
    }
    if (
      window.location.pathname.includes("khrouch") &&
      window.location.pathname !== "/khrouch/stripe-payment-cancel" &&
      window.location.pathname !== "/khrouch/campaign-qr-generator" &&
      window.location.pathname !== "/khrouch/stripe-payment-success" &&
      window.location.pathname !== "/khrouch/campaign-appointment"
    ) {
      const redirectId = window.location.pathname.replace("/khrouch/", "");
      try {
        const response = await axios.get(
          // `${process.env.REACT_APP_TEST_URL}v1/api/contact/get-long-url?shortUrl=${redirectId}`,
          `v1/api/contact/get-long-url?shortUrl=${redirectId}`,

          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        window.location.replace(response.data?.longUrl);
      } catch (e) {
        message.error("Error! Unable to redirect to campaign url.");
      }
    }
  }, []);
  const profileSuccessFunction = (response) => {
    const permissionList = Object.entries(
      response?.data?.department?.rolesAndPermissionList
    )?.map((key) => {
      if (key[1] !== null) {
        return key[0];
      }
      return;
    });
    setLocalStorage("menuList", permissionList);
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:id" element={<Login />} />
        {/* <Route path="/" element={<CompanyLogin />} /> */}
        <Route path="/" element={<ThokyoLogin />} />
        <Route path="/email-sign-in" element={<CompanyLoginWithEmail />} />
        <Route path="/register" element={<RegisterEmail />} />
        <Route path="/otpverification" element={<OTPverification />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/password_reset/:id" element={<ForgotPassword />} />
        <Route
          path="/password_reset_otp_verification/:id"
          element={<ForgotPasswordOTPverification />}
        />
        <Route path="/change_password" element={<ChangePassword />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsConditions />} />
        <Route
          path="/my-profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              {/* <Suspense fallback={<TableSkeleton />}> */}
              <Dashboard />
              {/* </Suspense> */}
            </PrivateRoute>
          }
        />
        {localStorage.getItem("menuList")?.includes("CONTACTS") && (
          <Route
            path="/contacts"
            element={
              <PrivateRoute>
                <Contacts
                  isHeadBranch={store.branchState.isHeadBranch}
                  branchRecord={store.branchState.branchRecord}
                />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("LEADS") && (
          <Route
            path="/quick-inquiry"
            element={
              <PrivateRoute>
                <QuickInquiry />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="/leads"
          element={
            <PrivateRoute>
              <Leads
                isHeadBranch={store.branchState.isHeadBranch}
                branchRecord={store.branchState.branchRecord}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/project"
          element={
            <PrivateRoute>
              <Project />
            </PrivateRoute>
          }
        />
        <Route
          path="/activity"
          element={
            <PrivateRoute>
              <Activity />
            </PrivateRoute>
          }
        />
        <Route
          path="/order"
          element={
            <PrivateRoute>
              <Orders />
            </PrivateRoute>
          }
        />

        <Route
          path="/invoice"
          element={
            <PrivateRoute>
              <Invoice />
            </PrivateRoute>
          }
        />
        <Route
          path="/checkin"
          element={
            <PrivateRoute>
              <Checkin />
            </PrivateRoute>
          }
        />
        <Route
          path="/security"
          element={
            <PrivateRoute>
              <Security />
            </PrivateRoute>
          }
        />
        <Route
          path="/company-manager"
          element={
            <PrivateRoute>
              <CompanyManager />
            </PrivateRoute>
          }
        />
        <Route
          path="/quotation"
          element={
            <PrivateRoute>
              <Quotation />
            </PrivateRoute>
          }
        />
        <Route
          path="/event"
          element={
            <PrivateRoute>
              <Events />
            </PrivateRoute>
          }
        />
        {localStorage.getItem("menuList")?.includes("APPOINTMENTS") && (
          <Route
            path="/appointments"
            element={
              <PrivateRoute>
                <Appointments
                  isHeadBranch={store.branchState.isHeadBranch}
                  branchRecord={store.branchState.branchRecord}
                />
              </PrivateRoute>
            }
          />
        )}
        {/* <Route
          path="/counselling"
          element={
            <PrivateRoute>
              <Counsel />
            </PrivateRoute>
          }
        /> */}
        {localStorage.getItem("menuList")?.includes("COMPANIES") && (
          <Route
            path="/companies"
            element={
              <PrivateRoute>
                <Companies />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("INSTITUTES") && (
          <Route
            path="/institute"
            element={
              <PrivateRoute>
                <Institute />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("COURSES") && (
          <Route
            path="/courses"
            element={
              <PrivateRoute>
                <Courses />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("DEGREE") && (
          <Route
            path="/degree"
            element={
              <PrivateRoute>
                <Degree />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("HEAD_AGENTS") && (
          <Route
            path="/head-agent"
            element={
              <PrivateRoute>
                <HeadAgent />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("SUB_AGENTS") && (
          <Route
            path="/sub-agent"
            element={
              <PrivateRoute>
                <SubAgent />
              </PrivateRoute>
            }
          />
        )}
        {
          <Route
            path="/support"
            element={
              <PrivateRoute>
                <Support />
              </PrivateRoute>
            }
          />
        }
        {localStorage.getItem("menuList")?.includes("ROLES") && (
          <Route
            path="/roles-and-permission"
            element={
              <PrivateRoute>
                <RolesAndPermission />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("EMPLOYEES") && (
          <Route
            path="/manage-employees"
            element={
              <PrivateRoute>
                <EmployeeManager />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("DEPARTMENT") && (
          <Route
            path="/manage-department"
            element={
              <PrivateRoute>
                <Departments />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("CASES") && (
          <Route
            path="/cases"
            element={
              <PrivateRoute>
                <Visas
                  isHeadBranch={store.branchState.isHeadBranch}
                  branchRecord={store.branchState.branchRecord}
                />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("CASES") && (
          <Route
            path="/product-services"
            element={
              <PrivateRoute>
                <ProductService
                  isHeadBranch={store.branchState.isHeadBranch}
                  branchRecord={store.branchState.branchRecord}
                />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="/manage-consultancies"
          element={
            <PrivateRoute>
              <Consultancies />
            </PrivateRoute>
          }
        />
        {
          <Route
            path="/tasks"
            element={
              <PrivateRoute>
                <Task />
              </PrivateRoute>
            }
          />
        }
        {localStorage.getItem("menuList")?.includes("RESOURCES") && (
          <Route
            path="/resources"
            element={
              <PrivateRoute>
                <Resources />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="/clients"
          element={
            <PrivateRoute>
              <Clients />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />

        <Route
          path="/newsletter"
          element={
            <PrivateRoute>
              <Newsletter />
            </PrivateRoute>
          }
        />

        {localStorage.getItem("menuList")?.includes("WORKFLOW_SETTINGS") && (
          <Route
            path="/organizationalSettings/country"
            element={
              <PrivateRoute>
                <Country />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("COLLEGE_FINDER") && (
          <Route
            path="/collegeFinder"
            element={
              <PrivateRoute>
                <CollegeFinder />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("EDUCATION_VISAS") && (
          <Route
            path="/education"
            element={
              <PrivateRoute>
                <EducationVisa />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("MIGRATION_VISAS") && (
          <Route
            path="/migration"
            element={
              <PrivateRoute>
                <MigrationVisas />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="/visaTypeCases"
          element={
            <PrivateRoute>
              <VisaTypeCases />
            </PrivateRoute>
          }
        />
        {
          <Route
            path="/todo"
            element={
              <PrivateRoute>
                <Todo />
              </PrivateRoute>
            }
          />
        }
        {localStorage.getItem("menuList")?.includes("CONSULTANTS") && (
          <Route
            path="/manage-consultants"
            element={
              <PrivateRoute>
                <Consultants />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="/organizationalSettings"
          element={
            <PrivateRoute>
              <OrganizationalSettings />
            </PrivateRoute>
          }
        />
        {localStorage.getItem("menuList")?.includes("TEMPLATE_SETTINGS") && (
          <Route
            path="/organizationalSettings/templates"
            element={
              <PrivateRoute>
                <Templates />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("IMPORT_SETTINGS") && (
          <Route
            path="/organizationalSettings/import"
            element={
              <PrivateRoute>
                <Import
                  isHeadBranch={store.branchState.isHeadBranch}
                  branchRecord={store.branchState.branchRecord}
                />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("CHECKIN") && (
          <Route
            path="/attendance"
            element={
              <PrivateRoute>
                <Attendance />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="myCounselling"
          element={
            <PrivateRoute>
              <MyCounselling />
            </PrivateRoute>
          }
        />
        <Route
          path="/myCases"
          element={
            <PrivateRoute>
              <MyCases />
            </PrivateRoute>
          }
        />
        <Route
          path="/branch-cases"
          element={
            <PrivateRoute>
              <Branch />
            </PrivateRoute>
          }
        />
        {localStorage.getItem("menuList")?.includes("BRANCH") && (
          <Route
            path="branch"
            element={
              <PrivateRoute>
                <Branch />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="branch-lead"
          element={
            <PrivateRoute>
              <Branches />
            </PrivateRoute>
          }
        />
        <Route
          path="/branch-appointments"
          element={
            <PrivateRoute>
              <Branches />
            </PrivateRoute>
          }
        />
        <Route
          path="/branch-Clients"
          element={
            <PrivateRoute>
              <Branches />
            </PrivateRoute>
          }
        />
        <Route
          path="/branch-head-agent"
          element={
            <PrivateRoute>
              <Branches />
            </PrivateRoute>
          }
        />
        <Route
          path="/branch-sub-agent"
          element={
            <PrivateRoute>
              <Branches />
            </PrivateRoute>
          }
        />
        <Route
          path="/subscription"
          element={
            <PrivateRoute>
              <SubscriptionList />
            </PrivateRoute>
          }
        />
        {localStorage.getItem("menuList")?.includes("DASHBOARD_SETTINGS") && (
          <Route
            path="/organizationalSettings/dashboard"
            element={
              <PrivateRoute>
                <DashboardSettings />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("LEAD_SETTINGS") && (
          <Route
            path="/organizationalSettings/lead-settings"
            element={
              <PrivateRoute>
                <LeadSetttings />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("APPOINTMENT_SETTINGS") && (
          <Route
            path="/organizationalSettings/appointment-settings"
            element={
              <PrivateRoute>
                <AppointmentSettings />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("CASE_SETTINGS") && (
          <Route
            path="/organizationalSettings/case-settings"
            element={
              <PrivateRoute>
                <CaseSettings />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("GENERAL_SETTINGS") && (
          <Route
            path="/organizationalSettings/general-settings"
            element={
              <PrivateRoute>
                <GeneralSettings />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("CHECKIN_REPORT") && (
          <Route
            path="/report"
            element={
              <PrivateRoute>
                <MonthlyReport />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("CHECKIN_SETTINGS") && (
          <Route
            path="/organizationalSettings/check-in"
            element={
              <PrivateRoute>
                <CheckInSettings />
              </PrivateRoute>
            }
          />
        )}
        {
          <Route
            path="/organizationalSettings/quotation-settings"
            element={
              <PrivateRoute>
                <QuotationSettings />
              </PrivateRoute>
            }
          />
        }
        {localStorage.getItem("menuList")?.includes("EMAIL") && (
          <Route
            path="/mailbox"
            element={
              <PrivateRoute>
                <Mailbox />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("CAMPAIGN") && (
          <Route
            path="/manage-campaign"
            element={
              <PrivateRoute>
                <Campaign />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="/document-checklist"
          element={
            <PrivateRoute>
              <DocumentChecklist />
            </PrivateRoute>
          }
        />
        {localStorage
          .getItem("menuList")
          ?.includes("AUTOREMINDER_SETTINGS") && (
          <Route
            path="/organizationalSettings/autoreminder"
            element={
              <PrivateRoute>
                <AutoreminderSettings />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("menuList")?.includes("EMPLOYEE_SETTINGS") && (
          <Route
            path="/organizationalSettings/employee-settings"
            element={<EmployeeSettings />}
          />
        )}
        <Route
          path="/khrouch/campaign-qr-generator"
          element={<CampaignForm />}
        />
        <Route
          path="/khrouch/campaign-appointment"
          element={<AppointmentForm />}
        />
        <Route path="/submissionSuccess" element={<SuccessPage />} />
        <Route path="/submissionFailed" element={<ErrorPage />} />
        <Route
          path="/khrouch/stripe-payment-success"
          element={
            <PrivateRoute>
              <SubscriptionSuccess />
            </PrivateRoute>
          }
        />
        <Route
          path="/khrouch/stripe-payment-cancel"
          element={
            <PrivateRoute>
              <SubscriptionFailure />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
