import { Button, DatePicker, Form, Modal, message } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";

export default function InvoiceExpiryModal({
  isExpireModalOpen,
  setIsExpireModalOpen,
  record,
}) {
  const dispatch = useDispatch();

  const handleSubmitRecord = (values) => {
    dispatch({
      type: "CHANGE_EXPIRY_DATE_REQUEST",
      payload: {
        id: record?.order_id,
        formData: {
          expiry_date: moment(values?.expiry_date).format("YYYY-MM-DD"),
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data);
        setIsExpireModalOpen(false);
      },
      failureFunction: () => {
        message.error("Failed to set expiry date");
      },
    });
  };

  return (
    <Modal
      title="Change Expiry Date"
      footer={false}
      open={isExpireModalOpen}
      onCancel={() => setIsExpireModalOpen(false)}
      width={300}
    >
      <Form onFinish={handleSubmitRecord} layout="vertical">
        <Form.Item
          rules={[{ required: true, message: "Please select the date" }]}
          label="New Expiry date"
          name="expiry_date"
        >
          <DatePicker style={{ width: "100%" }} format={"DD-MM-YYYY"} />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form>
    </Modal>
  );
}
