import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Steps,
  Tag,
  TimePicker,
} from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import Customer from "./Customer";
import BillingAddress from "./BillingAddress";
import DeliveryAddress from "./DeliveryAddress";

const OrderCustomer = ({
  isAddCustomerModalVisible,
  setIsAddCustomerModalVisible,
}) => {
  const store = useSelector((state) => {
    return {
      timingDropdownState: state.AppointmentsReducer,
      leadState: state.LeadReducer,
    };
  });
  const { TextArea } = Input;
  const { Option } = Select;
  const [current, setCurrent] = useState(0);

  const customerForm = [
    <Customer current={current} setCurrent={setCurrent} />,
    <BillingAddress current={current} setCurrent={setCurrent} />,
    <DeliveryAddress current={current} setCurrent={setCurrent} />,
  ];

  return (
    <Drawer
      title="Add Customer"
      placement="right"
      open={isAddCustomerModalVisible}
      onClose={() => {
        setIsAddCustomerModalVisible(false);
      }}
      width={1163}
      footer={null}
    >
      <Steps
        current={current}
        style={{ marginBottom: "2rem" }}
        items={[
          {
            title: "Contact",
          },
          {
            title: "Billing Address",
          },
          {
            title: "Delivery Address",
          },
        ]}
      />
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {customerForm[current]}
      </div>
    </Drawer>
  );
};

export default OrderCustomer;
