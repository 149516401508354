import React, { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Input,
  Popconfirm,
  Select,
  Space,
  Table,
  Tooltip,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";

import { debounce } from "lodash";
import AddNewsletter from "./AddNewsletter";
import EditNewsletter from "./EditNewletter";
const { Search } = Input;

const Newsletter = () => {
  const { customerList } = useSelector((store) => store?.ClientsReducer);

  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(null);
  const [cascadeType, setCascadeType] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const dispatch = useDispatch();

  const [isAddNewsletter, setIsAddNewsletter] = useState(false);
  const [isEditNewsletter, setIsEditNewsletter] = useState(false);

  useEffect(() => {
    fetchCustomerList();
  }, [page]);

  const fetchCustomerList = (event = null) => {
    dispatch({
      type: "FETCH_CUSTOMER_LIST_REQUEST",
      payload: {
        page: page,
        keyword: event ? event.target.value : "",
      },
      isSuccessFn: true,
      successFunction: (res) => {},
      failureFunction: (err) => {},
    });
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
    },
    {
      title: "Actions",
      align: "center",
      fixed: "right",
      render: (dataObj) => (
        <Space>
          <Tooltip title="Update customer">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsEditNewsletter(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleDebounceChange = debounce(fetchCustomerList, 1500);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleCascadeTask = () => {
    dispatch({
      type: "CASCADE_ATTEMPT",
      payload: {
        endPoint: "customer_cascade_action",
        formData: {
          ids: selectedRowKeys,
          action: cascadeType,
          branch_id: localStorage.getItem("branchId"),
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data);
        setSelectedRowKeys([]);
        setCascadeType(null);
        fetchCustomerList();
      },
      failureFunction: (res) => {
        message.error(res?.response?.data?.message);
      },
    });
  };

  const data = [{ id: 1 }];

  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="leadFlexRow">
          <div style={{ width: "30%" }}>
            <button
              className="button"
              onClick={() => {
                setIsAddNewsletter(true);
              }}
            >
              <span>Add Newsletter</span>
            </button>
          </div>

          <div
            style={{
              display: "flex",
              width: "30%",
              alignItems: "center",
              gap: "0.3rem",
            }}
          >
            <Search
              className="search"
              style={{
                width: "100%",
                borderRadius: "10px ! important",
              }}
              placeholder="Search "
              allowClear
              size="large"
              onChange={handleDebounceChange}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          <>
            {selectedRowKeys?.length > 0 && (
              <Alert
                message={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    Do you want to
                    <Select
                      value={cascadeType}
                      onChange={(value) => setCascadeType(value)}
                      placeholder="Select Action"
                      style={{ width: "8rem" }}
                    >
                      <Select.Option value="delete">Delete</Select.Option>
                      <Select.Option value="publish">Publish</Select.Option>
                      <Select.Option value="unpublish">Unpublish</Select.Option>
                    </Select>
                    {cascadeType && (
                      <Popconfirm
                        title={`Do you want to ${cascadeType} record?`}
                        onConfirm={() => {
                          handleCascadeTask();
                        }}
                        onCancel={() => {}}
                        okText="Ok"
                        cancelText="Cancel"
                      >
                        <Button danger type="primary">
                          Ok
                        </Button>
                      </Popconfirm>
                    )}
                  </div>
                }
                type="warning"
              />
            )}
            <Table
              bordered
              columns={columns}
              pagination={false}
              scroll={{ x: 1000 }}
              //   dataSource={customerList?.customers?.map((data) => ({
              //     ...data,
              //     key: data?.customer_id,
              //   }))}
              rowSelection={rowSelection}
              dataSource={data}
            />
          </>
        </div>
      </div>

      {isAddNewsletter && (
        <AddNewsletter
          isAddNewsletter={isAddNewsletter}
          setIsAddNewsletter={setIsAddNewsletter}
        />
      )}

      {isEditNewsletter && (
        <EditNewsletter
          isEditNewsletter={isEditNewsletter}
          setIsEditNewsletter={setIsEditNewsletter}
        />
      )}
    </SiderDemo>
  );
};

export default Newsletter;
