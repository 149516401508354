import React, { useEffect, useState } from "react";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { Space, Table, Tooltip } from "antd";
import AddEvent from "./AddEvent";
import UpdateEvent from "./UpdateEvent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import { AiOutlineEye } from "react-icons/ai";
import EventDetails from "./EventDetails";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Token from "../../Components/tokenComponent/Token";

export default function Events() {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [record, setRecord] = useState({});
  const dispatch = useDispatch();
  const { eventList } = useSelector((store) => store?.EventsReducer);
  const { globalBranchId } = useSelector((store) => store?.ProfileReducer);

  const [selectSlot, setSelectSlot] = useState(null);
  const [events, setEvents] = useState([]);
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  const handleSelectSlot = (slotInfo) => {
    setIsAddModalVisible(true);
    setSelectSlot(slotInfo.start);
  };

  const handleUpdateSelectSlot = (value) => {
    setRecord(value);
    setIsEditModalVisible(true);
  };

  const localizer = momentLocalizer(moment);

  useEffect(() => {
    fetchEventsData();
  }, [globalBranchId]);

  const fetchEventsData = () => {
    dispatch({
      type: "FETCH_ALL_EVENTS_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: (res) => {
        setEvents(
          res?.data?.all_events?.map((event) => ({
            ...event,
            title: event?.event_name,
            start:
              moment(event?.event_date, "DD-MM-YYYY").format(
                "YYYY-MM-DDTHH:mm:ss"
              ) ?? null,
            end:
              moment(event?.end_date, "DD-MM-YYYY").format(
                "YYYY-MM-DDTHH:mm:ss"
              ) ?? null,
          }))
        );
      },
      failureFunction: (err) => {
        err?.response?.data?.message === "Pin code expired." &&
          setIsTokenExpired(true);
      },
    });
  };

  return (
    <SiderDemo>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Calendar
          localizer={localizer}
          events={events}
          style={{ height: 600, cursor: "pointer" }}
          selectable={true}
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleUpdateSelectSlot}
          startAccessor={(event) => {
            return new Date(event.start);
          }}
          endAccessor={(event) => {
            return new Date(event.end);
          }}
          components={{
            event: CustomEvent,
          }}
        />
      </div>

      {isAddModalVisible && (
        <AddEvent
          selectSlot={selectSlot}
          isAddModalVisible={isAddModalVisible}
          setIsAddModalVisible={setIsAddModalVisible}
          fetchEventsData={fetchEventsData}
        />
      )}
      {isEditModalVisible && (
        <UpdateEvent
          record={record}
          isEditModalVisible={isEditModalVisible}
          setIsEditModalVisible={setIsEditModalVisible}
          fetchEventsData={fetchEventsData}
        />
      )}

      {isDrawerOpen && (
        <EventDetails
          record={record}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      )}

      {isTokenExpired && (
        <Token
          isTokenExpired={isTokenExpired}
          setIsTokenExpired={setIsTokenExpired}
        />
      )}
    </SiderDemo>
  );
}

export const CustomEvent = ({ event }) => {
  return (
    <p
      style={{
        padding: "0.2rem",
        borderRadius: "0.4rem",
        background: event?.code ?? "black",
        height: "fit-content !important",
      }}
    >
      {moment(event?.event_time, "HH:mm:ss").format("hh:mm A") ?? null} -
      {event?.title}
    </p>
  );
};
