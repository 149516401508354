import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Radio,
  notification,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Webcam from "react-webcam";

export default function AddCheckinModal({
  isCheckedIn,
  setIsCheckedIn,
  record,
  fetchCheckinRecord,
}) {
  const webcamRef = useRef(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [capturedImage, setCapturedImage] = useState(null);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);
  const [isLeaveChecked, setIsLeaveChecked] = useState(false);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    fetchCheckinUserFormData();
  }, []);

  const fetchCheckinUserFormData = () => {
    dispatch({
      type: "FETCH_CHECKIN_USER_FORMDATA_ATTEMPT",
      payload: {
        userId: record?.userid,
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setFormData(res?.data);
      },
      failureFunction: (err) => {},
    });
  };

  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);

    try {
      const apiKey = "1df1919d585d68";
      const response = await axios.get(`https://ipinfo.io?token=${apiKey}`);
      const [lat, lng] = response.data.loc.split(",");
      setLocation({ latitude: parseFloat(lat), longitude: parseFloat(lng) });
    } catch (err) {
      setError("Failed to get location: " + err.message);
    }
  }, [webcamRef]);

  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleCheckin = (values) => {
    if (capturedImage) {
      const imageBlob = dataURLToBlob(capturedImage);
      const formData = new FormData();
      formData.append("files", imageBlob, ".png");
      formData.append("branch_id", localStorage.getItem("branchId"));
      formData.append("path", "checkin_photos");

      dispatch({
        type: "UPLOAD_FILE_REQUEST",
        payload: formData,
        isSuccessFn: true,
        successFunction: (res) => {
          dispatch({
            type: "CHECKIN_USER_ATTEMPT",
            payload: {
              userid: record?.userid,
              branch_id: localStorage.getItem("branchId"),
              ...values,
              txt_lat: location?.latitude,
              txt_lng: location?.longitude,
              file_name: res?.data?.source?.name,
              date: dayjs().format("DD-MM-YYYY"),
              time: dayjs().format("hh:mm"),
              daysheet_id: 0,
              txt_files: null,
            },
            isSuccessFn: true,
            successFunction: (res) => {
              setIsCheckedIn(false);
              fetchCheckinRecord();
            },
            failureFunction: (err) => {},
          });
        },
        failureFunction: () => {},
      });
    } else {
      notification["warning"]({
        message: "",
        description: "Please capture a photo",
        placement: "top",
      });
    }
  };

  return (
    <Modal
      open={isCheckedIn}
      onCancel={() => {
        setIsCheckedIn(false);
      }}
      closable={false}
      footer={false}
      width={550}
      maskClosable={false}
      title={<span>CHECK IN:- {dayjs().format("ddd, D MMM YYYY")}</span>}
    >
      <Form
        initialValues={{ from_home: false, is_leave: false }}
        onFinish={handleCheckin}
        layout="vertical"
        form={form}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={240}
              height={180}
            />
            <Button htmlType="button" onClick={capture}>
              Capture Photo
            </Button>
          </div>

          {capturedImage && (
            <div>
              <img
                width={240}
                height={180}
                src={capturedImage}
                alt="Captured"
              />
            </div>
          )}
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <Form.Item valuePropName="checked" name="from_home">
            <Checkbox>Work from home</Checkbox>
          </Form.Item>
          <Form.Item valuePropName="checked" name="is_leave">
            <Checkbox
              onChange={(e) => {
                const isChecked = e.target.checked;
                setIsLeaveChecked(isChecked);
                !isChecked && form.setFieldsValue({ txt_files: null });
              }}
            >
              Is leave
            </Checkbox>
          </Form.Item>
        </div>
        {isLeaveChecked && (
          <Form.Item label="Leave Type" name="txt_files">
            <Radio.Group>
              {formData?.leave_types?.map((data) => (
                <Radio value={data}> {data} </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item
          label="Assigned Tasks"
          name="assigned_task"
          valuePropName="checked"
        >
          <Checkbox.Group
            onChange={(value, event) => {}}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.25rem",
              maxHeight: "15rem",
              overflowY: "scroll",
            }}
          >
            {formData?.assigned_tasks?.map((data) => (
              <Checkbox
                onClick={(e, option) => {
                  const isChecked = e.target.checked;
                  const tempNote = form.getFieldValue("checkInNote");
                  isChecked &&
                    form.setFieldsValue({
                      checkInNote: tempNote
                        ? `${tempNote} \n ${e.target.value}`
                        : `${e.target.value}`,
                    });
                }}
                key={data?.task_name}
                value={data?.task_name}
              >
                {data?.task_name}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "checkin note is required" }]}
          label="Note"
          name="checkInNote"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <div style={{ display: "flex", flexDirection: "row", gap: "0.25rem" }}>
          <Button type="primary" htmlType="submit">
            Check In
          </Button>
          <Button
            type="danger"
            onClick={() => {
              setIsCheckedIn(false);
            }}
          >
            Close
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
