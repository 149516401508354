import React, { useEffect, useState } from "react";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { useDispatch } from "react-redux";
import Token from "../../Components/tokenComponent/Token";
import { List, Pagination, Typography } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";

function Activity() {
  const dispatch = useDispatch();
  const { globalBranchId } = useSelector((store) => store?.ProfileReducer);

  const { activityList } = useSelector((store) => store?.LeadReducer);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);

  const onShowSizeChange = (current) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage(current);
  };

  useEffect(() => {
    setTotalRecord(activityList?.total_activities);
  }, [activityList]);

  useEffect(() => {
    fetchActivityList();
  }, [page, globalBranchId]);

  const fetchActivityList = () => {
    dispatch({
      type: "FETCH_ACTIVITY_LIST_REQUEST",
      payload: { page: page },
      isSuccessFn: false,
      successFunction: (res) => {},
      failureFunction: (err) => {
        err?.response?.data?.message === "Pin code expired." &&
          setIsTokenExpired(true);
      },
    });
  };
  return (
    <SiderDemo>
      <List
        header={<div>Activity List</div>}
        // footer={<div>Footer</div>}
        bordered
        dataSource={activityList?.activities ?? []}
        renderItem={(item) => (
          <List.Item
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "flex-start",
            }}
          >
            <Typography.Text
              style={{
                padding: "0.3rem 0.5rem",
                background: "#ffe58f",
                width: "fit-content",
              }}
              mark
            >
              {item?.user_type}
            </Typography.Text>{" "}
            <div style={{ flex: 1 }}>
              <span>
                {moment(item?.action_date).format("DD MMM YYYY hh:mm a") ??
                  null}
              </span>
              <p> {item?.content}</p> <p> {item?.title}</p>
            </div>
          </List.Item>
        )}
      />

      <Pagination
        style={{
          marginTop: "1rem",
          marginBottom: "1rem",
          alignSelf: "flex-end",
        }}
        // showSizeChanger
        current={page}
        // defaultPageSize={pageSize}
        onChange={onShowSizeChange}
        total={totalRecord}
      />
      {isTokenExpired && (
        <Token
          isTokenExpired={isTokenExpired}
          setIsTokenExpired={setIsTokenExpired}
        />
      )}
    </SiderDemo>
  );
}

export default Activity;
