import { Pagination, Popconfirm, Space, Table, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import AddDiscussion from "./AddDiscussion";
import DiscussionDetails from "./DicussionDetail";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { FaBedPulse } from "react-icons/fa6";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import UpdateDiscussionStatus from "./UpdateDiscussionStatus";

export default function Discussion({ record }) {
  const dispatch = useDispatch();
  const [isAddDiscussionVisible, setIsAddDiscussionVisible] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { projectDiscussionList } = useSelector(
    (store) => store?.ProjectReducer
  );
  const [isStatusUpdate, setIsStatusUpdate] = useState(false);
  const [discussionRecord, setIsDiscussionRecord] = useState(false);

  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchProjectDiscussion();
  }, [page]);

  useEffect(() => {
    setTotalRecord(projectDiscussionList?.total_disscussion);
  }, [projectDiscussionList]);

  const fetchProjectDiscussion = () => {
    dispatch({
      type: "FETCH_PROJECT_DISCUSSION_LIST_REQUEST",
      payload: { id: record?.task_id, page: page },
      isSuccessFn: false,
      successFunction: (res) => {},
      failureFunction: (err) => {},
    });
  };

  const deleteRecordData = (record) => {
    dispatch({
      type: "DELETE_DISCUSSION_RECORD_ATTEMPT",
      payload: {
        id: record?.discussion_id,
        formData: { discussion_id: record?.discussion_id },
      },
      isSuccessFn: false,
      successFunction: (res) => {
        message.success(res?.data);
        fetchProjectDiscussion();
      },
      failureFunction: (err) => {
        message.error(err?.response?.data?.message);
      },
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage(current);
  };

  const columns = [
    {
      title: "Discussion Title",
      dataIndex: "discussion_title",
      width: 300,
      sorter: (a, b) => {
        if (a.taskTitle < b.taskTitle) {
          return -1;
        }
        if (a.taskTitle > b.taskTitle) {
          return 1;
        }
        return 0;
      },
      align: "center",
    },
    {
      title: "Discussion details",
      dataIndex: "discussion_description",
      align: "center",
    },
    {
      title: "Added by",
      dataIndex: "addedBy",
      align: "center",
    },
    {
      title: "Added date",
      dataIndex: "added_date",
      align: "center",
    },
    {
      title: "Actions",
      align: "center",
      fixed: "right",
      render: (dataObj) => (
        <Space size="middle">
          <Tooltip title="Update Discussion Status">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsStatusUpdate(true);
                  setIsDiscussionRecord(dataObj);
                }}
              >
                <EditFilled style={{ color: "blue" }} />
              </div>
            </a>
          </Tooltip>
          <Popconfirm
            title={`Do you want to delete record?`}
            onConfirm={() => {
              deleteRecordData(dataObj);
            }}
            onCancel={() => {}}
            okText="Ok"
            cancelText="Cancel"
          >
            <a>
              <div className="bordered">
                <DeleteFilled style={{ color: "red" }} />
              </div>
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <div style={{ width: "30%" }}>
        <button
          className="button"
          onClick={() => {
            setIsAddDiscussionVisible(true);
          }}
        >
          <span>Add discussion</span>
        </button>
      </div>
      <Table
        columns={columns}
        bordered
        dataSource={projectDiscussionList?.disscussions}
        scroll={{ x: 1000 }}
        pagination={false}
      />
      <Pagination
        style={{
          marginTop: "1rem",
          marginBottom: "1rem",
          alignSelf: "flex-end",
        }}
        // showSizeChanger
        current={page}
        // defaultPageSize={pageSize}
        onChange={onShowSizeChange}
        total={totalRecord}
      />
      {isAddDiscussionVisible && (
        <AddDiscussion
          record={record}
          isAddDiscussionVisible={isAddDiscussionVisible}
          setIsAddDiscussionVisible={setIsAddDiscussionVisible}
          fetchProjectDiscussion={fetchProjectDiscussion}
        />
      )}
      {isDrawerOpen && (
        <DiscussionDetails
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      )}
      {isStatusUpdate && (
        <UpdateDiscussionStatus
          isStatusUpdate={isStatusUpdate}
          setIsStatusUpdate={setIsStatusUpdate}
          record={discussionRecord}
        />
      )}
    </div>
  );
}
