import {
  Button,
  Card,
  DatePicker,
  Form,
  Modal,
  Popconfirm,
  Table,
  Tag,
  message,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect } from "react";
import { MdOutlineDelete } from "react-icons/md";
import { useDispatch } from "react-redux";

export default function InvoiceReminderModal({
  isReminderModalOpen,
  setIsReminderModalOpen,
  record,
  orderById,
  fetchOrderApi,
}) {
  const [form] = Form.useForm();

  const columns = [
    {
      title: "Reminder Dates	",
      dataIndex: "reminder_date",
      align: "center",
      render: (data) => (data ? dayjs(data).format("DD-MM-YYYY") : ""),
    },
    {
      title: "Is Sent	",
      dataIndex: "is_sent",
      align: "center",
      render: (data) =>
        data === "1" ? (
          <Tag color="green">Sent</Tag>
        ) : (
          <Tag color="red">Not sent</Tag>
        ),
    },
    {
      title: "Action	",
      align: "center",
      render: (dataObj) => (
        <Popconfirm
          title={`Do you want to delete remainder date?`}
          onConfirm={() => {
            deleteReminderDate(dataObj);
          }}
          onCancel={() => {}}
          okText="Ok"
          cancelText="Cancel"
        >
          <MdOutlineDelete
            color="red"
            style={{ fontSize: "1rem", cursor: "pointer" }}
          />
        </Popconfirm>
      ),
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    fetchInvoiceReminder();
  }, []);

  const fetchInvoiceReminder = (values) => {
    dispatch({
      type: "FETCH_INVOICE_REMINDER_ATTEMPT",
      payload: record?.order_id,

      isSuccessFn: true,
      successFunction: (res) => {
        console.log("res ", res);
      },
      failureFunction: () => {},
    });
  };

  const handleSubmitRecord = (values) => {
    dispatch({
      type: "ADD_REMINDER_DATE_REQUEST",
      payload: {
        id: record?.order_id,
        formData: {
          reminder_date: moment(values?.reminder_date).format("YYYY-MM-DD"),
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data);
        fetchOrderApi();
        form.resetFields();
      },
      failureFunction: () => {
        message.error("Failed to add reminder date");
      },
    });
  };

  const deleteReminderDate = (value) => {
    dispatch({
      type: "DELETE_REMINDER_DATE_ATTEMPT",
      payload: value?.id,
      isSuccessFn: true,
      successFunction: (res) => {
        fetchOrderApi();
        message.success(res?.data);
      },
      failureFunction: () => {},
    });
  };

  return (
    <Modal
      title="Get paid on time by scheduling payment reminders for your customer:
      "
      footer={false}
      open={isReminderModalOpen}
      onCancel={() => setIsReminderModalOpen(false)}
      width={500}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Table
          bordered
          pagination={false}
          dataSource={orderById?.orders?.reminder_dates}
          columns={columns}
        />
        <Card>
          <Form form={form} onFinish={handleSubmitRecord} layout="vertical">
            <Form.Item
              rules={[{ required: true, message: "Please select the date" }]}
              label="New Reminder date"
              name={"reminder_date"}
            >
              <DatePicker style={{ width: "100%" }} format={"DD-MM-YYYY"} />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form>
        </Card>
      </div>
    </Modal>
  );
}
