import React, { useEffect, useState } from "react";
import { CgHello } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import SiderDemo from "../../Components/Layout/SiderDemo";
import test from "./../../Assets/course.png";
import { Pie } from "@ant-design/plots";
import { Alert, Avatar, Badge, Divider, Progress, Tooltip } from "antd";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
import { MdDateRange } from "react-icons/md";
import DashboardHeader from "./DashboardHeader";
import { BiCube } from "react-icons/bi";
import { SlGraduation, SlLayers } from "react-icons/sl";
import { IoLayersOutline } from "react-icons/io5";
import { BsBagCheck, BsBuilding, BsTags } from "react-icons/bs";
import DashboardComponentAnalysis from "./DashboardComponentAnalysis";
import DashboardComponentList from "./DashboardComponentList";
import moment from "moment";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { GrDocumentVerified } from "react-icons/gr";
import { FaUserTie } from "react-icons/fa";
import DashboardSkeleton from "../../Helpers/TableSkeleton/DashboardSkeleton";

const Dashboard = () => {
  const store = useSelector((state) => {
    return {
      dashboardState: state.DashboardReducer,
      MyCounsellingState: state.MyCounsellingAndCasesReducer,
      VisasState: state.MyCounsellingAndCasesReducer,
      branchState: state.BranchReducer,
    };
  });
  const dispatch = useDispatch();
  const [todaysAppointment, setTodaysAppointment] = useState([]);
  const [pendingAppointment, setPendingAppointment] = useState([]);

  // useEffect(() => {
  //   if (
  //     localStorage.getItem("branchId") !== "null" ||
  //     localStorage.getItem("branchId") !== null ||
  //     store.branchState?.branchRecord.id !== "null" ||
  //     store.branchState?.branchRecord.id !== null
  //   ) {
  //     // dispatch({
  //     //   type: "FETCH_PROFILE_REQUEST",

  //     // });
  //     dispatch({
  //       type: "FETCH_DASHBOARD_DATA_REQUEST",
  //       payload: {
  //         branchId:
  //           store.branchState?.branchRecord.id ??
  //           localStorage.getItem("branchId"),
  //       },
  //     });
  //     dispatch({
  //       type: "FETCH_DASHBOARD_DATA_LIST_REQUEST",
  //       payload: {
  //         branchId:
  //           store.branchState?.branchRecord.id ??
  //           localStorage.getItem("branchId"),
  //       },
  //     });
  //     dispatch({ type: "FETCH_MY_COUNSELLING_REQUEST" });

  //     // dispatch({
  //     //   type: "FETCH_MY_CASES_IN_PROGRESS_REQUEST",
  //     //   payload: "IN_PROGRESS",
  //     // });
  //   }
  // }, [store.branchState?.branchRecord]);

  let data;
  data = [
    {
      // Appointment
      type: "Appointment",
      value: store.dashboardState.dashboardData?.appointmentLeads,
      color: "white",
    },

    {
      // Win
      type: "Win",
      value: store.dashboardState.dashboardData?.winLeads,
    },
    {
      // Lose
      type: "Lose",
      value: store.dashboardState.dashboardData?.loseLeads,
    },
    {
      // Pending
      type: "Pending",
      value: store.dashboardState.dashboardData?.pendingLeads,
    },
  ];

  const config = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };

  data = [
    {
      type: "Not Assigned",
      // value: 10,
      value: store.dashboardState.dashboardData?.totalCaseNotAssigned
        ? store.dashboardState.dashboardData?.totalCaseNotAssigned
        : 0,
    },
    {
      type: "In Progress",
      // value: 5,
      value: store.dashboardState.dashboardData?.totalCaseInProgress
        ? store.dashboardState.dashboardData?.totalCaseInProgress
        : 0,
    },
    {
      type: "Completed",
      // value: 5,
      value: store.dashboardState.dashboardData?.totalCaseCompleted
        ? store.dashboardState.dashboardData?.totalCaseCompleted
        : 0,
    },
  ];
  const config2 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };

  data = [
    {
      type: "Active",
      // value: 10,
      value: store.dashboardState.dashboardData?.totalActiveContact
        ? store.dashboardState.dashboardData?.totalActiveContact
        : 0,
    },
    {
      type: "Inactive",
      // value: 5,
      value:
        store.dashboardState.dashboardData?.totalAllContact !== null &&
        store.dashboardState.dashboardData?.totalActiveContact !== null
          ? store.dashboardState.dashboardData?.totalAllContact -
            store.dashboardState.dashboardData?.totalActiveContact
          : 0,
    },
  ];

  const config3 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };
  data = [
    {
      type: "Active",
      // value: 10,
      value: store.dashboardState.dashboardData?.activeClients
        ? store.dashboardState.dashboardData?.activeClients
        : 0,
    },
    {
      type: "Inactive",
      // value: 5,
      value:
        store.dashboardState.dashboardData?.totalClients !== null &&
        store.dashboardState.dashboardData?.activeClients !== null
          ? store.dashboardState.dashboardData?.totalClients -
            store.dashboardState.dashboardData?.activeClients
          : 0,
    },
  ];

  const config4 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };
  data = [
    {
      type: "Pending",
      // value: 10,
      value: store.dashboardState.dashboardData?.pendingAppointment
        ? store.dashboardState.dashboardData?.pendingAppointment
        : 0,
    },
    {
      type: "Confirmed",
      // value: 5,
      value: store.dashboardState.dashboardData?.confirmedAppointment
        ? store.dashboardState.dashboardData?.confirmedAppointment
        : 0,
    },
    {
      type: "Completed",
      // value: 5,
      value: store.dashboardState.dashboardData?.completedAppointment
        ? store.dashboardState.dashboardData?.completedAppointment
        : 0,
    },
  ];
  const config5 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };

  data = [
    {
      type: "Active",
      // value: 10,
      value: store.dashboardState.dashboardData?.activeCompany
        ? store.dashboardState.dashboardData?.activeCompany
        : 0,
    },
    {
      type: "Inactive",
      // value: 5,
      value:
        store.dashboardState.dashboardData?.allCompany !== null &&
        store.dashboardState.dashboardData?.activeCompany !== null
          ? store.dashboardState.dashboardData?.allCompany -
            store.dashboardState.dashboardData?.activeCompany
          : 0,
    },
  ];

  const config6 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };
  data = [
    {
      type: "Active",
      // value: 10,
      value: store.dashboardState.dashboardData?.activeCourses
        ? store.dashboardState.dashboardData?.activeCourses
        : 0,
    },
    {
      type: "Inactive",
      // value: 5,
      value:
        store.dashboardState.dashboardData?.allCourses !== null &&
        store.dashboardState.dashboardData?.activeCourses !== null
          ? store.dashboardState.dashboardData?.allCourses -
            store.dashboardState.dashboardData?.activeCourses
          : 0,
    },
  ];

  const config7 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };
  data = [
    {
      type: "Active",
      // value: 10,
      value: store.dashboardState.dashboardData?.activeInstitute
        ? store.dashboardState.dashboardData?.activeInstitute
        : 0,
    },
    {
      type: "Inactive",
      // value: 5,
      value:
        store.dashboardState.dashboardData?.activeInstitute !== null &&
        store.dashboardState.dashboardData?.activeInstitute !== null
          ? store.dashboardState.dashboardData?.activeInstitute -
            store.dashboardState.dashboardData?.activeInstitute
          : 0,
    },
  ];

  const config8 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };
  data = [
    {
      type: "Active",
      // value: 10,
      value: store.dashboardState.dashboardData?.activeConsultancyEmployee
        ? store.dashboardState.dashboardData?.activeConsultancyEmployee
        : 0,
    },
    {
      type: "Inactive",
      // value: 5,
      value:
        store.dashboardState.dashboardData?.totalConsultancyEmployee !== null &&
        store.dashboardState.dashboardData?.activeConsultancyEmployee !== null
          ? store.dashboardState.dashboardData?.totalConsultancyEmployee -
            store.dashboardState.dashboardData?.activeConsultancyEmployee
          : 0,
    },
  ];

  const config9 = {
    legend: false,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 16,
        color: "white",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };

  const dashboardVisibleOptions =
    store.dashboardState?.dashboardDataList?.dashboardSettingResponse;

  useEffect(() => {
    const currentDate = moment().format("YYYY-MM-DD");
    if (dashboardVisibleOptions?.latestAppointment) {
      setTodaysAppointment(
        store.dashboardState?.dashboardDataList?.recentAppointments?.data?.filter(
          (appo) =>
            appo.appointmentDate === currentDate &&
            appo.appointmentStatus === "CONFIRMED"
        )
      );

      setPendingAppointment(
        store.dashboardState?.dashboardDataList?.recentAppointments?.data?.filter(
          (appo) => appo.appointmentStatus === "PENDING"
        )
      );
    }
  }, [dashboardVisibleOptions?.latestAppointment]);

  return (
    <SiderDemo dashboard={true}>
      {store.dashboardState?.isLoading ? (
        <DashboardSkeleton />
      ) : (
        <div>
          {store.dashboardState?.dashboardDataList?.announcements?.length > 0 &&
            store.dashboardState?.dashboardDataList?.announcements?.map(
              (dataObj, index) => {
                return (
                  <Alert
                    style={{ borderRadius: "5px", marginBottom: "0.5rem" }}
                    key={index}
                    type={
                      dataObj?.announcementType === "INFORMATIVE"
                        ? "success"
                        : dataObj?.announcementType === "ALERT"
                        ? "error"
                        : "info"
                    }
                    message={<div>{dataObj.announcementText}</div>}
                    closable
                  />
                );
              }
            )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.8rem",
            }}
          >
            <div className="new-dashboardcard-container">
              {dashboardVisibleOptions?.counterLeads === true && (
                <Link to={"/leads"}>
                  <DashboardHeader
                    title={"LEADS"}
                    end={store.dashboardState.dashboardData?.allLeads}
                    dashboardHeaderIcon={
                      <BiCube className="dashboardIconStyle" />
                    }
                  />
                </Link>
              )}
              {dashboardVisibleOptions?.counterCases === true && (
                <Link to={"/cases"}>
                  <DashboardHeader
                    title={"CASES"}
                    end={store.dashboardState.dashboardData?.totalCases}
                    dashboardHeaderIcon={
                      <IoLayersOutline className="dashboardIconStyle" />
                    }
                  />
                </Link>
              )}

              {dashboardVisibleOptions?.counterContact === true && (
                <Link to={"/contacts"}>
                  <DashboardHeader
                    title={"CONTACTS"}
                    end={store.dashboardState.dashboardData?.totalAllContact}
                    dashboardHeaderIcon={
                      <BsTags className="dashboardIconStyle" />
                    }
                  />
                </Link>
              )}
              {dashboardVisibleOptions?.counterClient === true && (
                <Link to={"/clients"}>
                  <DashboardHeader
                    title={"CLIENTS"}
                    end={store.dashboardState.dashboardData?.totalClients ?? 0}
                    dashboardHeaderIcon={
                      <BsBagCheck className="dashboardIconStyle" />
                    }
                  />
                </Link>
              )}
              {dashboardVisibleOptions?.counterCompanies === true && (
                <Link to={"/companies"}>
                  <DashboardHeader
                    title={"COMPANIES"}
                    end={store.dashboardState.dashboardData?.allCompany ?? 0}
                    dashboardHeaderIcon={
                      <HiOutlineBuildingLibrary className="dashboardIconStyle" />
                    }
                  />
                </Link>
              )}
              {dashboardVisibleOptions?.counterAppointment === true && (
                <Link to={"/appointments"}>
                  <DashboardHeader
                    title={"APPOINTMENTS"}
                    end={
                      store.dashboardState.dashboardData?.allAppointments ?? 0
                    }
                    dashboardHeaderIcon={
                      <GrDocumentVerified className="dashboardIconStyle" />
                    }
                  />
                </Link>
              )}
              {dashboardVisibleOptions?.counterCourses === true && (
                <Link to={"/courses"}>
                  <DashboardHeader
                    title={"COURSES"}
                    end={store.dashboardState.dashboardData?.allCourses ?? 0}
                    dashboardHeaderIcon={
                      <SlGraduation className="dashboardIconStyle" />
                    }
                  />
                </Link>
              )}
              {dashboardVisibleOptions?.counterInstitute === true && (
                <Link to={"/institute"}>
                  <DashboardHeader
                    title={"INSTITUTES"}
                    end={store.dashboardState.dashboardData?.allInstitute ?? 0}
                    dashboardHeaderIcon={
                      <BsBuilding className="dashboardIconStyle" />
                    }
                  />
                </Link>
              )}
              {dashboardVisibleOptions?.counterEmployees === true && (
                <Link to={"/manage-employees"}>
                  <DashboardHeader
                    title={"EMPLOYEES"}
                    end={
                      store.dashboardState.dashboardData
                        ?.totalConsultancyEmployee ?? 0
                    }
                    dashboardHeaderIcon={
                      <FaUserTie className="dashboardIconStyle" />
                    }
                  />
                </Link>
              )}
            </div>
            <div className="new-dashboardcard-container">
              {dashboardVisibleOptions?.analysisLeads === true && (
                <DashboardComponentAnalysis
                  title={"LEAD ANALYSIS"}
                  subtitle1Count={
                    store.dashboardState.dashboardData?.appointmentLeads
                  }
                  subtitle2Count={store.dashboardState.dashboardData?.winLeads}
                  subtitle3Count={store.dashboardState.dashboardData?.loseLeads}
                  subtitle4Count={
                    store.dashboardState.dashboardData?.pendingLeads
                  }
                  subtitle1={"APPOINTMENTS"}
                  subtitle2={"WINS"}
                  subtitle3={"LOSE"}
                  subtitle4={"PENDINGS"}
                  graph={<Pie {...config} />}
                />
              )}
              {dashboardVisibleOptions?.analysisCases === true && (
                <DashboardComponentAnalysis
                  title={"CASE ANALYSIS"}
                  subtitle1Count={
                    store.dashboardState.dashboardData?.totalCaseNotAssigned
                  }
                  subtitle2Count={
                    store.dashboardState.dashboardData?.totalCaseInProgress
                  }
                  subtitle3Count={
                    store.dashboardState.dashboardData?.totalCaseCompleted
                  }
                  subtitle1={"NOT ASSIGNED"}
                  subtitle2={"IN PROGRESS"}
                  subtitle3={"COMPLETED"}
                  graph={<Pie {...config2} />}
                />
              )}
              {dashboardVisibleOptions?.analysisContact === true && (
                <DashboardComponentAnalysis
                  title={"CONTACT ANALYSIS"}
                  subtitle1Count={
                    store.dashboardState.dashboardData?.totalActiveContact
                  }
                  subtitle2Count={
                    store.dashboardState.dashboardData?.totalAllContact -
                    store.dashboardState.dashboardData?.totalActiveContact
                  }
                  subtitle1={"ACTIVE"}
                  subtitle2={"INACTIVE"}
                  graph={<Pie {...config3} />}
                />
              )}
              {dashboardVisibleOptions?.analysisClient === true && (
                <DashboardComponentAnalysis
                  title={"CLIENT ANALYSIS"}
                  subtitle1Count={
                    store.dashboardState.dashboardData?.activeClients
                  }
                  subtitle2Count={
                    store.dashboardState.dashboardData?.inactiveClients
                  }
                  subtitle1={"ACTIVE"}
                  subtitle2={"INACTIVE"}
                  graph={<Pie {...config4} />}
                />
              )}
              {dashboardVisibleOptions?.analysisAppointment === true && (
                <DashboardComponentAnalysis
                  title={"APPOINTMENT ANALYSIS"}
                  subtitle1Count={
                    store.dashboardState.dashboardData?.pendingAppointment
                  }
                  subtitle2Count={
                    store.dashboardState.dashboardData?.confirmedAppointment
                  }
                  subtitle3Count={
                    store.dashboardState.dashboardData?.completedAppointment
                  }
                  subtitle1={"PENDING"}
                  subtitle2={"CONFIRMED"}
                  subtitle3={"CONFIRMED"}
                  graph={<Pie {...config5} />}
                />
              )}
              {dashboardVisibleOptions?.analysisCompanies === true && (
                <DashboardComponentAnalysis
                  title={"COMPANY ANALYSIS"}
                  subtitle1Count={
                    store.dashboardState.dashboardData?.allCompany
                  }
                  subtitle2Count={
                    store.dashboardState.dashboardData?.allCompany -
                    store.dashboardState.dashboardData?.activeCompany
                  }
                  subtitle1={"ACTIVE"}
                  subtitle2={"INACTIVE"}
                  graph={<Pie {...config6} />}
                />
              )}
              {dashboardVisibleOptions?.analysisCourses === true && (
                <DashboardComponentAnalysis
                  title={"COURSES ANALYSIS"}
                  subtitle1Count={
                    store.dashboardState.dashboardData?.activeCourses
                  }
                  subtitle2Count={
                    store.dashboardState.dashboardData?.allCourses -
                    store.dashboardState.dashboardData?.activeCourses
                  }
                  subtitle1={"ACTIVE"}
                  subtitle2={"INACTIVE"}
                  graph={<Pie {...config7} />}
                />
              )}
              {dashboardVisibleOptions?.analysisInstitute === true && (
                <DashboardComponentAnalysis
                  title={"INSTITUTE ANALYSIS"}
                  subtitle1Count={
                    store.dashboardState.dashboardData?.activeInstitute
                  }
                  subtitle2Count={
                    store.dashboardState.dashboardData?.allInstitute -
                    store.dashboardState.dashboardData?.activeInstitute
                  }
                  subtitle1={"ACTIVE"}
                  subtitle2={"INACTIVE"}
                  graph={<Pie {...config8} />}
                />
              )}
              {dashboardVisibleOptions?.analysisEmployees === true && (
                <DashboardComponentAnalysis
                  title={"EMPLOYEE ANALYSIS"}
                  subtitle1Count={
                    store.dashboardState.dashboardData
                      ?.activeConsultancyEmployee
                  }
                  subtitle2Count={
                    store.dashboardState.dashboardData
                      ?.totalConsultancyEmployee -
                    store.dashboardState.dashboardData
                      ?.activeConsultancyEmployee
                  }
                  subtitle1={"ACTIVE"}
                  subtitle2={"INACTIVE"}
                  graph={<Pie {...config9} />}
                />
              )}
            </div>
            <div className="new-dashboardcard-datacontainer">
              {dashboardVisibleOptions?.latestAppointment && (
                <DashboardComponentList
                  title={"LATEST APPOINTMENTS"}
                  todaysAppointment={todaysAppointment}
                  pendingAppointment={pendingAppointment}
                  isAppointment={true}
                  appointmentList={
                    store.dashboardState?.dashboardDataList?.recentAppointments
                      ?.data
                  }
                  myAppointmentList={
                    store.MyCounsellingState?.myCounselling?.data
                  }
                  totalMyCounselling={
                    store.MyCounsellingState?.myCounselling?.totalData
                  }
                />
              )}
              {dashboardVisibleOptions?.latestContact && (
                <DashboardComponentList
                  title={"LATEST CONTACTS"}
                  contactList={
                    store.dashboardState?.dashboardDataList?.recentContacts
                      ?.data
                  }
                />
              )}
              {dashboardVisibleOptions?.latestCases && (
                <DashboardComponentList
                  title={"LATEST CASES"}
                  caseInProgressList={
                    store.dashboardState?.dashboardDataList?.inProgressCases
                      ?.data
                  }
                  caseNotAssignedList={
                    store.dashboardState?.dashboardDataList?.notAssignedCases
                      ?.data
                  }
                  caseCompletedList={
                    store.dashboardState?.dashboardDataList?.completedCases
                      ?.data
                  }
                  isCase={true}
                />
              )}
              {dashboardVisibleOptions?.latestClient && (
                <DashboardComponentList
                  title={"LATEST CLIENTS"}
                  clientList={
                    store.dashboardState?.dashboardDataList?.latestCustomer
                      ?.data
                  }
                />
              )}
              {dashboardVisibleOptions?.latestLeads && (
                <DashboardComponentList
                  title={"LATEST LEADS"}
                  leadList={
                    store.dashboardState?.dashboardDataList?.latestLead?.data
                  }
                />
              )}
              {/* <DashboardComponentList
                title={"ANNOUNCEMENTS"}
                announcements={
                  store.dashboardState?.dashboardDataList?.announcements
                }
              /> */}
              <DashboardComponentList
                title={"ASSIGNED TASKS"}
                taskAssigned={
                  store.dashboardState?.dashboardDataList?.allAssignedToTasks
                    ?.data
                }
              />
              <DashboardComponentList
                title={"REMINDERS"}
                // taskAssigned={
                //   store.dashboardState?.dashboardDataList?.allAssignedToTasks?.data
                // }
              />
            </div>
            {/* <div style={{ backgroundColor: "white" }}>
          <Calendar
            view="week"
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
          />
        </div> */}
          </div>
        </div>
      )}
    </SiderDemo>
  );
};

export default Dashboard;
