import { Button, DatePicker, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AddAboutUs from "../../Leads/AboutUs/AddAboutUs";
import AddServiceSource from "./AddServiceSource";
import { scrollToTopFormError } from "../../../HelperFunction";
import moment from "moment";

export default function ProductServiceInfo({
  serviceType,
  serviceData,
  setServiceData,
  setCurrent,
  setIsAddServicesVisible,
  fetchProductService,
  setIsEditServicesVisible,
}) {
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
      quoteState: state.QuotationReducer,
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [isAddAboutUsModalVisible, setIsAddAboutUsModalVisible] =
    useState(false);
  const [aboutUsList, setAboutUsList] = useState([]);

  const [isAddSSVisible, setIsAddSSVisible] = useState(false);
  const [transition, setTransition] = useState("");

  useEffect(() => {
    dispatch({
      type: "FETCH_LEADS_DROPDOWN_REQUEST",
      payload: {
        fromInstituteVisaCase: true,
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setAboutUsList(res?.data?.aboutUs);
      },
    });
    dispatch({
      type: "FETCH_CASES_DROPDOWN_REQUEST",
      payload: {
        branchId: localStorage.getItem("branchId"),
      },
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: "FETCH_SERVICE_SOURCE_REQUEST",
      payload: {},
    });
  }, []);

  console.log("record", serviceData);

  const handleSubmitService = (values) => {
    setServiceData((prev) => {
      return {
        ...prev,
        ...values,
      };
    });

    transition === "back" && setCurrent((prev) => 1);
    if (transition === "next") {
      const responsePayload = {
        id: serviceData?.id,
        customerId: serviceData?.customerId ? serviceData?.customerId : null,
        natureOfProject: serviceData?.natureOfProject,
        grossPrice: null,
        discount: serviceData?.discount,
        isPercentage:
          serviceData?.packageDiscountType === "PERCENTAGE" ? true : false,
        gstApplicable: serviceData?.gstApplicable,
        gstAmount: serviceData?.gstAmount ? serviceData?.gstAmount : null,
        totalAmount: null,
        isFinanceOptions: true,
        paymentTermsInMonths: serviceData?.paymentTermsInMonths
          ? serviceData?.paymentTermsInMonths
          : null,
        description: serviceData?.description,
        newCustomerRequest: {
          firstName: serviceData?.firstName,
          lastName: serviceData?.lastName,
          email: serviceData?.email,
          mobile: serviceData?.mobile ? serviceData?.mobile : null,
          referral: serviceData?.referral,
          countryOfResidence: serviceData?.countryOfResidence,
          address: serviceData?.address,
          phone: serviceData?.phone ? serviceData?.phone : null,
        },
        paymentTermsInfo: serviceData?.paymentTermsInfo,
        displayPaymentTermsInfoInServiceConfirmation:
          serviceData?.displayPaymentTermsInfoInServiceConfirmation,
        serviceUpdate: serviceData?.serviceUpdate,
        displayServiceUpdateInServiceConfirmation:
          serviceData?.displayServiceUpdateInServiceConfirmation,
        specialNote: serviceData?.specialNote,
        associatedTimelineId: serviceData?.associatedTimelineId
          ? serviceData?.associatedTimelineId
          : null,
        associatedTimeline: serviceData?.associatedTimelineName,
        // orderSource: serviceData?.serviceSourceId,
        // serviceStatus: serviceData?.serviceStatus,
        // aboutUsId: serviceData?.aboutUsId,
        // serviceExpiryDate: serviceData?.serviceExpiryDate,
        // modeOfContact: serviceData?.modeOfContact,
        // productPackageDetailsRequests:
        //   serviceData?.servicePackage?.length > 1
        //     ? serviceData?.servicePackage
        //     : serviceData?.servicePackage?.length === 1 &&
        //       serviceData?.servicePackage[0]?.packageId !== null
        //     ? serviceData?.servicePackage
        //     : [],
        // productPackageDetailsRequests:
        //   serviceData?.servicePackage?.length > 0
        //     ? serviceData?.servicePackage
        //     : null,
        productPackageDetailsRequests:
          serviceData?.servicePackage?.length === 0 ||
          (serviceData?.servicePackage?.length === 1 &&
            serviceData?.servicePackage[0]?.packageName === "")
            ? null
            : serviceData?.servicePackage,
        orderSource: values?.serviceSourceId,
        serviceStatus: values?.serviceStatus,
        aboutUsId: serviceData?.aboutUsId ? serviceData?.aboutUsId : null,
        aboutUs: values?.aboutUs,
        // serviceExpiryDate: values?.serviceExpiryDate
        //   ? moment(values?.serviceExpiryDate).format("DD-MM-YYYY")
        //   : null,
        serviceStartDate: values?.serviceStartDate,
        serviceExpiryDate: values?.serviceExpiryDate,
        modeOfContact: values?.modeOfContact,
      };

      serviceType == "add" &&
        dispatch({
          type: "ADD_SERVICE_REQUEST",
          payload: responsePayload,
          isSuccessFn: true,
          successFunction: () => {
            fetchProductService();
            setIsAddServicesVisible(false);
          },
        });

      serviceType == "edit" &&
        dispatch({
          type: "UPDATE_SERVICE_REQUEST",
          payload: responsePayload,
          isSuccessFn: true,
          successFunction: () => {
            fetchProductService();
            setIsEditServicesVisible(false);
          },
        });
    }
  };
  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmitService}
        onFinishFailed={scrollToTopFormError}
        initialValues={{
          serviceStatus: serviceData?.serviceStatus,
          serviceSourceName: serviceData?.serviceSourceName,
          aboutUs: serviceData?.aboutUs,
          serviceStartDate: serviceData?.serviceStartDate,
          serviceExpiryDate: serviceData?.serviceExpiryDate,
          modeOfContact: serviceData?.modeOfContact,
        }}
        style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}
      >
        <Form.Item
          label="Service Status"
          name={"serviceStatus"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Service Status is required",
            },
          ]}
        >
          <Select>
            <Option value="PENDING">Pending</Option>
            <Option value="IN_PROGRESS">In Progress</Option>
            <Option value="COMPLETED">Completed</Option>
            <Option value="CANCELLED">Cancelled</Option>
            <Option value="HOLD">Hold</Option>
          </Select>
        </Form.Item>

        <div
          className="flexRowWithoutStyle"
          style={{ gap: "0.3rem", width: "100%" }}
        >
          <Form.Item
            label="Service Source"
            name={"serviceSourceName"}
            style={{ width: "100%" }}
          >
            <Select
              onChange={(value, option) => {
                setServiceData((prev) => {
                  return {
                    ...prev,
                    serviceSourceId: option?.key,
                  };
                });
              }}
            >
              {store.quoteState?.serviceSource?.data?.map((dataObj) => {
                return (
                  <Option key={dataObj.id} value={dataObj?.srouceName}>
                    {dataObj.sourceName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <a
            className="plusButton"
            onClick={() => {
              setIsAddSSVisible(true);
            }}
          >
            <AiOutlinePlus className="iconColor" />
          </a>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ gap: "0.3rem", width: "100%" }}
        >
          <Form.Item
            label="About Us"
            name={"aboutUs"}
            style={{ width: "100%" }}
          >
            <Select
              onChange={(value, option) => {
                setServiceData((prev) => {
                  return {
                    ...prev,
                    aboutUsId: option?.key,
                  };
                });
              }}
            >
              {/* {store.leadState?.leadsDropdown?.aboutUs?.map((dataObj) => {
                return (
                  <Option key={dataObj.id} value={dataObj?.aboutUs}>
                    {dataObj.aboutUs}
                  </Option>
                );
              })} */}

              {aboutUsList?.map((dataObj) => {
                return (
                  <Option key={dataObj.id} value={dataObj?.aboutUs}>
                    {dataObj.aboutUs}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <a
            className="plusButton"
            onClick={() => {
              setIsAddAboutUsModalVisible(true);
            }}
          >
            <AiOutlinePlus className="iconColor" />
          </a>
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Form.Item
            label="Service Start Date"
            name={"serviceStartDate"}
            style={{ width: "100%" }}
          >
            <DatePicker
              disabledDate={(currentDate) => {
                const date = new Date();
                return currentDate < date;
              }}
              style={{ width: "100%" }}
              format={"DD-MM-YYYY"}
            />
          </Form.Item>
          <Form.Item
            label="Service Expiry Date"
            name={"serviceExpiryDate"}
            style={{ width: "100%" }}
          >
            <DatePicker
              disabledDate={(currentDate) => {
                const date = new Date();
                return currentDate < date;
              }}
              style={{ width: "100%" }}
              format={"DD-MM-YYYY"}
            />
          </Form.Item>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Mode of Contact"
            name={"modeOfContact"}
            style={{ width: "100%" }}
          >
            <Select placeholder="Select Mode of Contact">
              <Option value="EMAIL">Email</Option>
              <Option value="PHONE">Phone</Option>
            </Select>
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gridColumn: "span 2",
          }}
        >
          <Button
            type="outline"
            onClick={() => {
              setTransition("back");
            }}
            htmlType="submit"
          >
            Back
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setTransition("next");
            }}
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </Form>

      {isAddAboutUsModalVisible && (
        <AddAboutUs
          isAddAboutUsModalVisible={isAddAboutUsModalVisible}
          setIsAddAboutUsModalVisible={setIsAddAboutUsModalVisible}
        />
      )}
      {isAddSSVisible && (
        <AddServiceSource
          isAddSSVisible={isAddSSVisible}
          setIsAddSSVisible={setIsAddSSVisible}
        />
      )}
    </div>
  );
}
