import { Button, Card, Divider, Drawer, Image, Tag } from "antd";
import React, { useEffect } from "react";
import { AiOutlineMail } from "react-icons/ai";
import client from "./../../Assets/client.png";
import { useState } from "react";
import { BsChatLeftText } from "react-icons/bs";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SendComposedMail from "../../Pages/Mailbox/SendComposedMail";
import DiscussionStatusUpdate from "./DiscussionStatusUpdate";

const DiscussionDetails = ({ Record, isDrawerOpen, setIsDrawerOpen }) => {
  const { leadById } = useSelector((store) => store?.LeadReducer);
  const [record, setRecord] = useState(Record);
  const dispatch = useDispatch();
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [isStatusUpdateVisible, setIsStatusUpdateVisible] = useState(false);

  useEffect(() => {}, []);

  const fetchLeadDetailData = () => {
    dispatch({
      type: "FETCH_LEADS_DETAILS_REQUEST",
      payload: Record?.lead_id,
    });
  };

  const clientDetailsTabList = [
    {
      key: "discussionDetail",
      tab: <span style={{ fontSize: "1rem" }}>Discussion Detail</span>,
    },
  ];

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween" style={{ gap: "1rem" }}>
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };

  return (
    <Drawer
      title="Discussion Details"
      open={isDrawerOpen}
      onClose={() => {
        setIsDrawerOpen(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.3rem" }} className="drawerStyle">
        <div
          className="flexRowWithoutStyle"
          style={{
            gap: "1rem",
            alignItems: "stretch",
          }}
        >
          {record?.image ? (
            <Image src={record?.image} className={"clientImage"} />
          ) : (
            <Image src={client} className={"clientImage"} />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: "10rem",
              height: "fit-content",
              width: "100%",
            }}
          >
            <div
              className="flexColumnWithoutStyle"
              style={{
                justifyContent: "flex-start",
                position: "relative",
                width: "100%",
                gap: "0.5rem",
              }}
            >
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                {`${record?.product ?? ""} (Project Id: ${record?.order_id})`}
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.4rem", color: "#aaa" }}
              >
                {record?.email && (
                  <span className="flexRowWithGap">
                    <AiOutlineMail
                      style={{
                        fontSize: "16",
                      }}
                    />{" "}
                    {record?.email ? record?.email : "N/A"}
                  </span>
                )}
              </div>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.5rem",
                marginTop: "0.5rem",
                alignSelf: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <button className="emailAndMessage" onClick={() => {}}>
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>
              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Email</span>
              </button>
              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsStatusUpdateVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Status update</span>
              </button>
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          onTabChange={(key) => {}}
        >
          <Card
            className="clientCard"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {detailFun("Project Title", `${record?.task_name ?? "N/A"}`)}
            {detailFun("Order Name", `${record?.product ?? "N/A"}`)}

            {detailFun("Order Name", `${record?.product ?? "N/A"}`)}

            {detailFun("Start Date", `${record?.start_date ?? "N/A"}`)}
            {detailFun("End Date", `${record?.end_date ?? "N/A"}`)}

            {detailFun("Email", `${record?.email ?? "N/A"}`)}
            {detailFun(
              "Status",
              (
                <Tag style={{ background: record?.code }}>
                  {record?.status_name}
                </Tag>
              ) ?? "N/A"
            )}
            {detailFun("Description", `${record?.task_detail ?? " N/A"}`)}
          </Card>
        </Card>
      </Card>
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
      {isStatusUpdateVisible && (
        <DiscussionStatusUpdate
          isStatusUpdateVisible={isStatusUpdateVisible}
          setIsStatusUpdateVisible={setIsStatusUpdateVisible}
        />
      )}
    </Drawer>
  );
};

export default DiscussionDetails;
