import { Drawer, Steps } from "antd";
import React, { useState } from "react";
import ServiceClientInfo from "./ServiceClientInfo";
import ServicePackageInfo from "./ServicePackageInfo";
import ProductServiceInfo from "./ProductServiceInfo";
import moment from "moment";

export default function EditServices({
  record,
  isEditServicesVisible,
  setIsEditServicesVisible,
  fetchProductService,
}) {
  const [current, setCurrent] = useState(0);
  console.log("record record", record);
  const [serviceData, setServiceData] = useState({
    // clientType: "EXISTING",
    id: record?.id,
    customerId: record?.customerId?.id,
    firstName: record?.customerId?.firstName,
    lastName: record?.customerId?.lastName,
    email: record?.customerId?.email,
    mobile: record?.customerId?.mobile,
    phone: record?.customerId?.phone,
    address: record?.customerId?.address,
    referral: "",
    countryOfResidence: record?.customerId?.countryOfResidence,

    natureOfProject: record?.natureOfProject,
    servicePackage:
      record?.productPackagesDetails?.length > 0
        ? record?.productPackagesDetails?.map((pkg) => ({
            packageName: pkg?.packageName,
            quantity: pkg?.quantity,
            unitPrice: pkg?.unitPrice,
            totalAmount: pkg?.totalAmount,
            packageId: pkg?.casePackageId,
          }))
        : [
            {
              packageName: "",
              quantity: "",
              unitPrice: "",
              totalAmount: "",
              packageId: "",
            },
          ],
    isDiscountAllowed: record?.discount ? true : false,
    discount: record?.discount,
    packageDiscountType: record?.isPercentage ? "PERCENTAGE" : "FLAT",
    gstApplicable: record?.gstApplicable,
    gstAmount: record?.gstAmount,
    associatedTimelineId: record?.associatedTimelineId?.id,
    associatedTimelineName: record?.associatedTimelineName?.name,
    specialNote: record?.specialNote,
    description: record?.description,
    paymentTermsInMonths: record?.paymentTermsInMonths,
    paymentTermsInfo: record?.paymentTermsInfo,
    displayPaymentTermsInfoInServiceConfirmation:
      record?.displayPaymentTermsInfoInServiceConfirmation,
    serviceUpdate: record?.serviceUpdate,
    displayServiceUpdateInServiceConfirmation:
      record?.displayServiceUpdateInServiceConfirmation,

    serviceStatus: record?.serviceStatus,
    aboutUs: record?.aboutUs,
    serviceStartDate: record?.serviceStartDate
      ? moment(record?.serviceStartDate)
      : null,
    serviceExpiryDate: record?.serviceExpiryDate
      ? moment(record?.serviceExpiryDate)
      : null,
    modeOfContact: record?.modeOfContact,
  });

  const forms = [
    <ServiceClientInfo
      serviceData={serviceData}
      setServiceData={setServiceData}
      setCurrent={setCurrent}
    />,
    <ServicePackageInfo
      serviceData={serviceData}
      setServiceData={setServiceData}
      setCurrent={setCurrent}
    />,
    <ProductServiceInfo
      serviceType="edit"
      serviceData={serviceData}
      setServiceData={setServiceData}
      setCurrent={setCurrent}
      fetchProductService={fetchProductService}
      setIsEditServicesVisible={setIsEditServicesVisible}
    />,
  ];
  return (
    <Drawer
      title="Update Service"
      open={isEditServicesVisible}
      onClose={() => {
        setIsEditServicesVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <div className="ClientDrawerStyle">
        <Steps
          current={current}
          onChange={(e) => {
            setCurrent(e);
          }}
          items={[
            {
              title: "Step 1",
              description: "CLIENT INFORMATION",
            },
            {
              title: "Step 2",
              description: "PACKAGE INFORMATION",
            },
            {
              title: "Step 3",
              description: "SERVICE INFORMATION",
            },
          ]}
        />
      </div>
      <div className="ClientDrawerStyle"> {forms[current]}</div>
    </Drawer>
  );
}
