import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../Assets/ausneplogo.png";
import {
  Avatar,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Popconfirm,
  Table,
  Timeline,
  message,
} from "antd";
import moment from "moment";
import axios from "axios";
import SendComposedMail from "../Mailbox/SendComposedMail";
import InvoiceExpiryModal from "./InvoiceExpiryModal";
import InvoiceReminderModal from "./InquireReminderDate";
import OrderPayment from "./OrderPayment";

export default function OrderInvoice({ fetchOrderById, dataObj, orderById }) {
  const { thokyoProfile } = useSelector((store) => store?.ProfileReducer);

  const dispatch = useDispatch();
  const [pdfFile, setPdfFile] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [isInvoiceReminderSend, setISInvoiceReminderSend] = useState(false);
  const [isExpireModalOpen, setIsExpireModalOpen] = useState(false);
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
  const [isOrderPaymentOpen, setOrderPaymentOpen] = useState(false);
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      profileState: state.ProfileReducer,
      clientState: state.ClientsReducer,
    };
  });

  const printPdf = () => {
    const blob = base64ToBlob(pdfFile);
    const url = window.URL.createObjectURL(blob);
    // Open a new window with the PDF content
    const printWindow = window.open(url, "_blank");

    // Wait for the window to load before triggering the print dialog
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        // Close the window after printing
        printWindow.close();
        window.URL.revokeObjectURL(url);
      };
    };
  };

  const base64ToBlob = (base64Data) => {
    const binaryData = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    return new Blob([uint8Array], { type: "application/pdf" });
  };

  const handleDownloadOrderPdf = () => {
    dispatch({
      type: "DOWNLOAD_ORDER_PDF_ATTEMPT",
      payload: dataObj?.order_id,
      isSuccessFn: true,
      successFunction: async (res) => {
        setIsloading(false);

        const a = document.createElement("a");
        a.href = res?.data?.url;
        a.download = "Order2291.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      failureFunction: (err) => {
        setIsloading(false);
      },
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <div
        style={{ display: "flex", justifyContent: "flex-end", gap: "0.5rem" }}
      >
        <Button onClick={() => setIsExpireModalOpen(true)}>
          Invoice expiry date
        </Button>
        <Button onClick={() => setIsReminderModalOpen(true)}>
          Invoice reminder
        </Button>
      </div>
      <div
        style={{
          padding: "0.5rem",
          background: "#ecedf060",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1.5rem 1rem",
            border: "1px solid #00000010",
          }}
        >
          <OrderCardHeader record={orderById?.orders} profile={thokyoProfile} />
          <Divider />
          <OrderCardBody record={orderById?.orders} />
          <h4
            style={{
              margin: "1rem 0",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <strong>Nature of Project:</strong>
            {orderById?.orders?.product}{" "}
          </h4>
          <OrderCardProjectFeature
            record={orderById?.orders}
            companyData={store?.profileState?.profile}
          />

          <OrderPackageDescription
            record={orderById?.orders}
            companyData={store?.profileState?.profile}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "1rem 0",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={() => setISInvoiceReminderSend(true)} type="primary">
            Send a reminder
          </Button>{" "}
          <Button onClick={() => setOrderPaymentOpen(true)} type="primary">
            Record payment
          </Button>{" "}
          <Button onClick={handleDownloadOrderPdf} type="primary">
            Download
          </Button>{" "}
          <Button
            onClick={() => setIsSendComposedEmailModalVisible(true)}
            type="primary"
          >
            Submit invoice
          </Button>{" "}
          <Button onClick={printPdf} type="outline">
            Print
          </Button>
        </div>
      </div>

      {isInvoiceReminderSend && (
        <SendComposedMail
          urlPath={`/send_invoice_reminder/${dataObj?.order_id}`}
          emailList={[]}
          forWhatId={dataObj?.order_id}
          isSendComposedEmailModalVisible={isInvoiceReminderSend}
          setIsSendComposedEmailModalVisible={setISInvoiceReminderSend}
          formDataUrl={`/invoice_reminder/${dataObj?.order_id}`}
          emailType="order_invoice"
        />
      )}

      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          urlPath={`/send_invoice/${dataObj?.order_id}`}
          emailList={[]}
          forWhatId={dataObj?.order_id}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
          formDataUrl={`/invoice_preview/${dataObj?.order_id}`}
          emailType="order_invoice"
        />
      )}

      {isExpireModalOpen && (
        <InvoiceExpiryModal
          isExpireModalOpen={isExpireModalOpen}
          setIsExpireModalOpen={setIsExpireModalOpen}
          record={orderById?.orders}
        />
      )}

      {isReminderModalOpen && (
        <InvoiceReminderModal
          isReminderModalOpen={isReminderModalOpen}
          setIsReminderModalOpen={setIsReminderModalOpen}
          record={orderById?.orders}
          orderById={orderById}
          fetchOrderApi={fetchOrderById}
        />
      )}
      {isOrderPaymentOpen && (
        <OrderPayment
          fetchOrderApi={fetchOrderById}
          isOrderPaymentOpen={isOrderPaymentOpen}
          setOrderPaymentOpen={setOrderPaymentOpen}
          record={orderById?.orders}
        />
      )}
    </div>
  );
}

export const OrderCardHeader = ({ record, profile }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div>
        <h2 style={{ margin: 0 }}>Invoice</h2>
        <h3 style={{ margin: 0 }}>#{record?.order_number}</h3>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "0",
        }}
      >
        <p style={{ margin: 0 }}>{profile?.company_name}</p>
        <p style={{ margin: 0 }}>{profile?.address}</p>
        <p style={{ margin: 0 }}>Phone: {profile?.phone}</p>
        <p style={{ margin: 0 }}>{profile?.email}</p>
        <img
          style={{ width: "10rem", height: "auto" }}
          src={logo}
          alt="companyLogo"
        />
      </div>
    </div>
  );
};

export const OrderCardBody = ({ record, companyData }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div>
        <h4>
          <strong>Attn:</strong> {record?.customer_name}{" "}
        </h4>
        <p style={{ margin: 0 }}>{record?.quote?.company_name}</p>
        <p style={{ margin: 0 }}>
          {record?.quote_customer?.billing_address1},
          {record?.quote_customer?.billing_suburb}
        </p>

        <p style={{ margin: 0 }}>
          <strong>Phone:</strong> {record?.quote_customer?.contact_number}
        </p>
        <p style={{ margin: 0 }}>
          <strong>Email:</strong> {record?.quote_customer?.email}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <p style={{ margin: 0 }}>Order date: {record?.order_date}</p>
        <p style={{ margin: 0 }}>Due date: {record?.due_date}</p>
      </div>
    </div>
  );
};

export const OrderCardProjectFeature = ({ record, companyData }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h4>
        <strong>Project Features:</strong>
      </h4>{" "}
    </div>
  );
};

export const OrderPackageDescription = ({ record, companyData }) => {
  return (
    <div>
      <Divider style={{ margin: "1rem 0 0.2rem" }} />
      <div style={{ display: "grid", gridTemplateColumns: "2fr 1fr 1fr 1fr" }}>
        <h4 style={{ margin: "0", fontWeight: "bolder", fontWeight: "bolder" }}>
          Descriptions:
        </h4>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Quantity:
        </h4>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Price: (AUD){" "}
        </h4>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Total: (AUD)
        </h4>
      </div>
      <Divider style={{ margin: "0.2rem 0" }} />
      {record?.products?.map((pkg) => {
        return (
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr 1fr 1fr",
              }}
            >
              <span>{pkg?.descriptions}</span>
              <span
                style={{ margin: "0", textAlign: "end", paddingRight: "1rem" }}
              >
                {pkg?.quantity}
              </span>
              <span
                style={{ margin: "0", textAlign: "end", paddingRight: "1rem" }}
              >
                {pkg?.price}
              </span>
              <span
                style={{ margin: "0", textAlign: "end", paddingRight: "1rem" }}
              >
                {pkg?.amount}
              </span>
            </div>
            <Divider style={{ margin: "0.2rem 0" }} />
          </div>
        );
      })}
      <div>
        <h4 style={{ margin: "0", textAlign: "end" }}>
          Regular Price: {record?.regular_price} (AUD)
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>
      <div>
        <h4 style={{ margin: "0", textAlign: "end", color: "red" }}>
          Discount: {record?.discount}{" "}
          {/* {record?.is_flat === "0" ? "(%)" : "(AUD)"} */}
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>
      <div>
        <h4 style={{ margin: "0", textAlign: "end" }}>
          Sub Total: {record?.price} (AUD)
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>

      <div>
        <h4 style={{ margin: "0", textAlign: "end" }}>
          GST: {record?.gst} (AUD)
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>
      <div>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Total Price: {record?.total_price}(AUD)
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>
    </div>
  );
};
