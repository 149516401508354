import { Button, DatePicker, Form, Input, Modal, Spin, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export default function OrderAdminNote({
  isAddAdminNote,
  setIsAddAdminNote,
  record,
}) {
  const dispatch = useDispatch();
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchPresetOrderForm();
  }, []);

  const fetchPresetOrderForm = () => {
    dispatch({
      type: "FETCH_EDIT_ORDER_ATTEMPT",
      payload: record?.order_id,
      isSuccessFn: true,
      successFunction: (res) => {
        console.log("res data", res);
        form.setFieldsValue({
          reminder_date: res?.data?.order?.reminder_date
            ? moment(res?.data?.order?.reminder_date)
            : null,
        });
      },
      failureFunction: (err) => {},
    });
  };

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    const formData = new FormData();
    formData.append("files", newFile);
    formData.append("branch_id", localStorage.getItem("branchId"));
    formData.append("path", "lms_project");

    setIsUploadLoading(true);
    dispatch({
      type: "UPLOAD_FILE_REQUEST",
      payload: formData,
      isSuccessFn: true,
      successFunction: (res) => {
        setUploadFile(res?.data?.source?.src);
        message.success("Upload file successful");
        setIsUploadLoading(false);
      },
      failureFunction: () => {
        setIsUploadLoading(false);
        message.error("Failed to upload file");
      },
    });
  };

  const handleSubmitAdminNote = (values) => {
    setLoading(true);
    dispatch({
      type: "ADD_ORDER_ADMIN_NOTE_ATTEMPT",
      payload: {
        id: record?.order_id,
        formData: {
          ...values,
          branch_id: localStorage.getItem("branchId"),
          file_name: uploadFile ?? null,
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data);
        setIsAddAdminNote();
        setLoading(false);
      },
      failureFunction: (err) => {
        setLoading(false);
      },
    });
  };
  return (
    <Modal
      title="Add Admin Note"
      open={isAddAdminNote}
      onCancel={() => setIsAddAdminNote(false)}
      width={500}
      footer={false}
    >
      <Form form={form} onFinish={handleSubmitAdminNote} layout="vertical">
        <Form.Item label="Reminder Date" name="reminder_date">
          <DatePicker format={"DD-MM-YYYY"} placeholder="Reminder Date" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Admin note is required" }]}
          label="Admin Note"
          name="admin_note"
        >
          <Input.TextArea rows={4} placeholder="Write..." />
        </Form.Item>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <Form.Item name={"files"} label="Add New Attachment">
            <Input
              onChange={handleFileChange}
              placeholder="Upload New Attachment"
              type="file"
            />
          </Form.Item>
          {isUploadLoading && (
            <span>
              <Spin /> Loading
            </span>
          )}
        </div>
        <Button loading={loading} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
}
