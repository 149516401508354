import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Segmented,
  Select,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AddTask from "./AddTask";
import moment from "moment";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";

export default function DailyTask() {
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const { dailyTaskList, isLoading } = useSelector(
    (store) => store?.TaskReducer
  );
  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false);
  const [taskDate, setTaskDate] = useState(moment());
  const [empId, setEmpId] = useState("");
  const [dailyTaskStatus, setDailyTaskStatus] = useState("active");

  useEffect(() => {
    fetchDailyTask();
  }, [taskDate, empId, dailyTaskStatus]);

  const fetchDailyTask = () => {
    dispatch({
      type: "FETCH_DAILY_TASK_LIST_REQUEST",
      payload: {
        date: moment(taskDate).format("YYYY-MM-DD") ?? "",
        emp: empId,
        status: dailyTaskStatus,
      },
      isSuccessFn: true,
      successFunction: (res) => {},
    });
  };

  const columns = [
    {
      title: "Task Title",
      dataIndex: "tasktitle",
      sorter: (a, b) => {
        if (a.taskTitle < b.taskTitle) {
          return -1;
        }
        if (a.taskTitle > b.taskTitle) {
          return 1;
        }
        return 0;
      },
      align: "center",
      width: 250,
    },
    {
      title: "Project",
      dataIndex: "project_title",
      align: "center",
      width: 200,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      align: "center",
      width: 150,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      align: "center",
      width: 150,
    },
    {
      title: "Task Status",
      align: "center",
      render: (data) => (
        <Tag
          style={{
            background: data?.status_code,
            color: "white",
          }}
        >
          {data?.status_name}
        </Tag>
      ),
    },
    // {
    //   title: "Priority",
    //   align: "center",
    //   render: (dataObj) => (
    //     <Tag style={{ background: dataObj?.priority_code, color: "white" }}>
    //       {dataObj?.priority}
    //     </Tag>
    //   ),
    // },
    // {
    //   title: "Added by",
    //   dataIndex: "status_name",
    //   align: "center",
    // },
  ];

  return (
    <div>
      <div
        style={{
          marginBottom: "0.5rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {" "}
        <div>
          <DatePicker
            placeholder="Daily Task Date"
            format={"DD-MM-YYYY"}
            onChange={(value) => setTaskDate(value)}
            value={taskDate}
            size="large"
          />
          <Select
            value={empId}
            style={{ width: "10rem" }}
            onChange={(value) => {
              setEmpId(value);
            }}
            placeholder="Choose Employee"
            size="large"
          >
            <Select.Option value={""}>All Employee</Select.Option>
            {dailyTaskList?.users?.map((data) => (
              <Select.Option value={data?.userid}>
                {data?.first_name} {data?.last_name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <Segmented
          value={dailyTaskStatus}
          options={[
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>Active [{dailyTaskList?.task_active ?? 0}]</div>
                </div>
              ),
              value: "active",
            },

            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>Completed [{dailyTaskList?.task_completed ?? 0}]</div>
                </div>
              ),
              value: "completed",
            },
          ]}
          onChange={(value) => {
            setDailyTaskStatus(value);
          }}
        />{" "}
      </div>{" "}
      {isLoading ? (
        <TableSkeleton />
      ) : (
        dailyTaskList?.emp_users?.map((emp, index) => (
          <Collapse
            key={index}
            style={{
              width: "100%",
            }}
            defaultActiveKey={[0]}
          >
            <Panel
              header={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  <span style={{ span: 1 }}>
                    {emp?.first_name} {emp?.last_name}
                  </span>
                  <Button
                    onClick={() => {
                      setIsAddTaskModalVisible(true);
                    }}
                  >
                    Add Task
                  </Button>
                </div>
              }
            >
              <Table
                columns={columns}
                bordered
                pagination={false}
                // dataSource={dailyTaskList?.projects?.filter(
                //   (tsk) => tsk?.user_id === emp?.userid
                // )}
                dataSource={emp?.tasks}
              />
            </Panel>
          </Collapse>
        ))
      )}
      {isAddTaskModalVisible && (
        <AddTask
          isAddTaskModalVisible={isAddTaskModalVisible}
          setIsAddTaskModalVisible={setIsAddTaskModalVisible}
          fetchDailyTask={fetchDailyTask}
        />
      )}
    </div>
  );
}
