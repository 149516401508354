import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../Assets/ausneplogo.png";
import {
  Avatar,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Popconfirm,
  Table,
  Timeline,
  message,
} from "antd";
import moment from "moment";
import SendComposedMail from "../Mailbox/SendComposedMail";

export default function QuotationCard({ record, fetchQuotationById }) {
  const { thokyoProfile } = useSelector((store) => store?.ProfileReducer);

  const dispatch = useDispatch();
  const [pdfFile, setPdfFile] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [pdfUrlLink, setPdfUrlLink] = useState(null);
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      profileState: state.ProfileReducer,
      clientState: state.ClientsReducer,
    };
  });
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);

  useEffect(() => {
    record && downloadQuotePdf();
  }, [record]);

  useEffect(() => {
    dispatch({
      type: "FETCH_QOUTEPDFFILE_REQUEST",
      payload: record?.quote_id,
      isSuccessFn: true,
      successFunction: (res) => {
        setPdfFile(res?.data);
      },
    });
  }, []);

  const printPdf = () => {
    const blob = base64ToBlob(pdfFile);
    const url = window.URL.createObjectURL(blob);
    // Open a new window with the PDF content
    const printWindow = window.open(url, "_blank");

    // Wait for the window to load before triggering the print dialog
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        // Close the window after printing
        printWindow.close();
        window.URL.revokeObjectURL(url);
      };
    };
  };

  const base64ToBlob = (base64Data) => {
    const binaryData = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    return new Blob([uint8Array], { type: "application/pdf" });
  };

  const handleConfirmApproveQuote = () => {
    dispatch({
      type: "REQUEST_APPROVE_QUOTE_REQUEST",
      payload: record?.quote_id,
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data?.message);
        fetchQuotationById();
      },
    });
  };

  const downloadQuotePdf = () => {
    dispatch({
      type: "DOWNLOAD_QUOTE_PDF_ATTEMPT",
      payload: record?.quote?.quote_id,
      isSuccessFn: true,
      successFunction: async (res) => {
        setIsloading(false);
        setPdfUrlLink(res?.data?.pdf);
      },
      failureFunction: (err) => {},
    });
  };

  const handleAddComment = (values) => {
    dispatch({
      type: "ADD_ADMIN_COMMENT_ATTEMPT",
      payload: {
        id: record?.quote?.quote_id,
        formData: {
          comment: values?.comment,
          file_name: "",
          branch_id: localStorage.getItem("branchId"),
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data);
        fetchQuotationById();
      },
    });
  };

  return (
    <div
      style={{ display: "grid", gridTemplateColumns: "4fr 1fr", gap: "1rem" }}
    >
      <div>
        <div
          style={{
            padding: "0.5rem",
            background: "#ecedf060",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "1.5rem 1rem",
              border: "1px solid #00000010",
            }}
          >
            <QuotationCardHeader record={record} profile={thokyoProfile} />
            <Divider />
            <QuotationCardBody record={record} />
            <h4
              style={{
                margin: "1rem 0",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <strong>Nature of Project:</strong>
              {record?.quote?.nature_of_quote}{" "}
            </h4>
            <QuotationCardProjectFeature
              record={record}
              companyData={store?.profileState?.profile}
            />
            {record?.quote?.is_multi_optioned === "1" ? (
              <div>
                {record?.quote_options?.map((data) => (
                  <MultiQuotationPackageDescription
                    record={data}
                    companyData={store?.profileState?.profile}
                  />
                ))}

                <div>
                  <Divider style={{ margin: "0.2rem 0" }} />

                  <h4 style={{ margin: "0" }}>Payment Terms:</h4>
                </div>
                <div>
                  <Divider style={{ margin: "0.2rem 0" }} />

                  <h4 style={{ margin: "0" }}>
                    Thank you for the opportunity to quote on the above project!
                  </h4>
                </div>
              </div>
            ) : (
              <QuotationPackageDescription
                record={record}
                companyData={store?.profileState?.profile}
              />
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "1rem 0",
          }}
        >
          <Popconfirm
            placement="topLeft"
            title="Do you want to approve the quote?"
            onConfirm={handleConfirmApproveQuote}
          >
            {(record?.quoteStatus === "WIN" ||
              record?.quoteStatus == "QUOTE_SENT") && (
              <Button type="primary">Approve</Button>
            )}
          </Popconfirm>

          <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            <Button type="primary">
              <a href={pdfUrlLink} download={"quote"}>
                Download
              </a>
            </Button>
            <Button
              onClick={() => setIsSendComposedEmailModalVisible(true)}
              type="primary"
            >
              Submit Quote
            </Button>
            <Button onClick={printPdf} type="outline">
              Print
            </Button>
          </div>
        </div>
      </div>
      <div>
        <Form onFinish={handleAddComment} layout="vertical">
          <Form.Item
            rules={[{ required: true, message: "Comment is required" }]}
            name="comment"
            label={"Comment"}
          >
            <Input.TextArea placeholder="Write..." rows={3} />
          </Form.Item>
          <Form.Item name="reminder_date">
            <DatePicker
              placeholder="Reminder Date"
              format={"DD-MM-YYYY"}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
        <Divider style={{ margin: "1rem 0 0.5rem" }} />
        <h3>Comments({record?.total_comments ?? 0}):</h3>
        <Timeline>
          {record?.quote?.comments?.map((data) => (
            <Timeline.Item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "0.5rem",
                  width: "100%",
                }}
              >
                <Avatar style={{ color: "blue" }}>{data?.from}</Avatar>
                <span>{data?.comment}</span>
              </div>
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          urlPath={`/send_quote_email/${record?.quote?.quote_id}`}
          emailList={[record?.quote_customer?.email]}
          forWhatId={record?.quote?.quote_id}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
          emailType="quote"
          formDataUrl={`/quote_email/${record?.quote?.quote_id}`}
        />
      )}
    </div>
  );
}

export const QuotationCardHeader = ({ record, profile }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div>
        <h2 style={{ margin: 0 }}>QUOTE</h2>
        <h3 style={{ margin: 0 }}>#{record?.quote?.quote_id}</h3>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "0",
        }}
      >
        <p style={{ margin: 0 }}>{profile?.company_name}</p>
        <p style={{ margin: 0 }}>{profile?.address}</p>
        <p style={{ margin: 0 }}>Phone: {profile?.phone}</p>
        <p style={{ margin: 0 }}>{profile?.email}</p>
        <img
          style={{ width: "10rem", height: "auto" }}
          src={logo}
          alt="companyLogo"
        />
      </div>
    </div>
  );
};

export const QuotationCardBody = ({ record, companyData }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div>
        <h4>
          <strong>Attn:</strong> {record?.quote_customer?.customer_name}{" "}
        </h4>
        <p style={{ margin: 0 }}>{record?.quote?.company_name}</p>
        <p style={{ margin: 0 }}>
          {record?.quote_customer?.billing_address1},
          {record?.quote_customer?.billing_suburb}
        </p>

        <p style={{ margin: 0 }}>
          <strong>Phone:</strong> {record?.quote_customer?.contact_number}
        </p>
        <p style={{ margin: 0 }}>
          <strong>Email:</strong> {record?.quote_customer?.email}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <h4>
          <strong>{record?.quote?.status}</strong>
        </h4>
        <p style={{ margin: 0 }}>Quote date: {record?.quote?.quote_date}</p>
        <p style={{ margin: 0 }}>
          Quote expiry date: {record?.quote?.expiry_date}
        </p>
      </div>
    </div>
  );
};

export const QuotationCardProjectFeature = ({ record, companyData }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "",
      }}
    >
      <h4>
        <strong>Project Features:</strong>
      </h4>{" "}
    </div>
  );
};

export const QuotationPackageDescription = ({ record, companyData }) => {
  return (
    <div>
      <Divider style={{ margin: "1rem 0 0.2rem" }} />
      <div style={{ display: "grid", gridTemplateColumns: "2fr 1fr 1fr 1fr" }}>
        <h4 style={{ margin: "0", fontWeight: "bolder", fontWeight: "bolder" }}>
          Descriptions:
        </h4>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Quantity:
        </h4>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Price: (AUD){" "}
        </h4>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Total: (AUD)
        </h4>
      </div>

      <Divider style={{ margin: "0.2rem 0" }} />
      {record?.quote_packages?.map((pkg) => {
        return (
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr 1fr 1fr",
              }}
            >
              <span>{pkg?.descriptions}</span>
              <span
                style={{ margin: "0", textAlign: "end", paddingRight: "1rem" }}
              >
                {pkg?.quantity}
              </span>
              <span
                style={{ margin: "0", textAlign: "end", paddingRight: "1rem" }}
              >
                {pkg?.price}
              </span>
              <span
                style={{ margin: "0", textAlign: "end", paddingRight: "1rem" }}
              >
                {pkg?.amount}
              </span>
            </div>
            <Divider style={{ margin: "0.2rem 0" }} />
          </div>
        );
      })}

      <div>
        <h4 style={{ margin: "0", textAlign: "end" }}>
          Discount: {record?.quote?.discount}
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>
      <div>
        <h4 style={{ margin: "0", textAlign: "end" }}>
          GST: {record?.quote?.gst}
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>
      <div>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Total Price: {record?.quote?.total_price}
          AUD
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>
      <div>
        <h4 style={{ margin: "0" }}>
          Thank you for the opportunity to quote on the above project!
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>
    </div>
  );
};

export const MultiQuotationPackageDescription = ({ record, companyData }) => {
  return (
    <div>
      <Divider style={{ margin: "1rem 0 0.2rem" }} />
      <div style={{ display: "grid", gridTemplateColumns: "2fr 1fr 1fr 1fr" }}>
        <h4 style={{ margin: "0", fontWeight: "bolder", fontWeight: "bolder" }}>
          Descriptions:
        </h4>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Quantity:
        </h4>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Price: (AUD){" "}
        </h4>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Total: (AUD)
        </h4>
      </div>

      <Divider style={{ margin: "0.2rem 0" }} />
      {record?.packages?.map((pkg) => {
        return (
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr 1fr 1fr",
              }}
            >
              <span>{pkg?.descriptions}</span>
              <span
                style={{ margin: "0", textAlign: "end", paddingRight: "1rem" }}
              >
                {pkg?.quantity}
              </span>
              <span
                style={{ margin: "0", textAlign: "end", paddingRight: "1rem" }}
              >
                {pkg?.price}
              </span>
              <span
                style={{ margin: "0", textAlign: "end", paddingRight: "1rem" }}
              >
                {pkg?.amount}
              </span>
            </div>
            <Divider style={{ margin: "0.2rem 0" }} />
          </div>
        );
      })}

      <div>
        <h4 style={{ margin: "0", textAlign: "end" }}>
          Regular Price: {record?.price ?? 0} AUD
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>

      {Number(record?.discount) !== 0 && (
        <div>
          <h4 style={{ margin: "0", textAlign: "end", color: "red" }}>
            Discount: {record?.discount ?? 0}{" "}
            {record?.is_flat === "1" ? "AUD" : "%"}
          </h4>
          <Divider style={{ margin: "0.2rem 0" }} />
        </div>
      )}
      <div>
        <h4 style={{ margin: "0", textAlign: "end" }}>
          Sub Total:{" "}
          {record?.is_flat === "1"
            ? (Number(record?.price) - Number(record?.discount)).toFixed(2)
            : (
                Number(record?.price) -
                (Number(record?.price) * Number(record?.discount)) / 100
              ).toFixed(2)}{" "}
          AUD
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>
      <div>
        <h4 style={{ margin: "0", textAlign: "end" }}>
          GST: {record?.gst ?? 0} AUD
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>
      <div>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Total Price: {record?.total_price}
          AUD
        </h4>
      </div>
    </div>
  );
};
