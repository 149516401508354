import React, { useEffect, useState } from "react";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { Button, Image, Input, Table, Tabs, Tag } from "antd";
import AddCheckinModal from "./AddCheckinModal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { FaBullseye } from "react-icons/fa";
import dayjs from "dayjs";
import { ClockCircleOutlined } from "@ant-design/icons";
import CheckoutModal from "./CheckoutModal";
import { debounce } from "lodash";

export default function Checkin() {
  const dispatch = useDispatch();
  const { checkinList } = useSelector((store) => store?.CheckinReducer);
  const { globalBranchId } = useSelector((store) => store?.ProfileReducer);

  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [isCheckedOut, setIsCheckedOut] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const columns = [
    {
      title: "Employee Name",
      align: "center",
      width: 200,
      render: (dataObj) => (
        <span>
          {dataObj?.first_name} {dataObj?.last_name}
        </span>
      ),
    },
    {
      title: "Check In Time",
      align: "center",
      width: 180,
      render: (dataObj) => (
        <div>
          <span>
            {dataObj?.day_sheets[0]?.checkin_at
              ? dayjs(dataObj?.day_sheets[0]?.checkin_at).format("hh:mm A")
              : null}
          </span>
          <p>{dataObj?.day_sheets[0]?.location ?? null}</p>
        </div>
      ),
    },
    {
      title: "Check In",
      align: "center",
      width: 120,
      render: (dataObj) => {
        const regex = /\.png/;
        const hasCheckin = regex.test(dataObj?.day_sheets[0]?.photo);

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                borderRadius: "6px",
              }}
              type="primary"
              onClick={() => {
                setSelectedRecord(dataObj);
                setIsCheckedIn(true);
              }}
              icon={<ClockCircleOutlined />}
            ></Button>
            {hasCheckin && <Image src={dataObj?.day_sheets[0]?.photo} />}
          </div>
        );
      },
    },
    {
      title: "Check Out Time",
      align: "center",
      width: 150,
      render: (dataObj) => (
        <span>
          {dataObj?.day_sheets[0]?.checkout_at
            ? dayjs(dataObj?.day_sheets[0]?.checkout_at).format("hh:mm A")
            : null}
        </span>
      ),
    },
    {
      title: "Check Out",
      align: "center",
      width: 120,
      render: (dataObj) => {
        const regex = /\.png/;
        const hasCheckout = regex.test(dataObj?.day_sheets[0]?.photo_checkout);

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: "#47a447",
                color: "white",
                borderRadius: "6px",
              }}
              icon={<ClockCircleOutlined />}
              onClick={() => {
                setSelectedRecord(dataObj);
                setIsCheckedOut(true);
              }}
            ></Button>
            {hasCheckout && (
              <Image src={dataObj?.day_sheets[0]?.photo_checkout} />
            )}
          </div>
        );
      },
    },
    {
      title: "Daily Standup",
      width: 180,
      align: "center",
      render: (dataObj) => <span>{dataObj?.day_sheets[0]?.checkin_note}</span>,
    },
    {
      title: "Updates",
      align: "center",
      width: 250,
      render: (dataObj) => (
        <div
          dangerouslySetInnerHTML={{
            __html: dataObj?.day_sheets[0]?.checkout_note ?? null,
          }}
        ></div>
      ),
    },
    {
      title: "Work from home?",
      align: "center",
      width: 150,
      fixed: "right",
      render: (dataObj) => (
        <span>{dataObj?.day_sheets[0]?.work_from_home}</span>
      ),
    },
  ];

  useEffect(() => {
    fetchCheckinRecord();
  }, [globalBranchId, selectedDate]);

  const fetchCheckinRecord = (event = null) => {
    dispatch({
      type: "FETCH_CHECKIN_LIST_ATTEMPT",
      payload: {
        date: selectedDate,
        keyword: event ? event?.target.value : "",
      },
      isSuccessFn: true,
      successFunction: (res) => {},
      failureFunction: (err) => {},
    });
  };

  const handleDebounceSearch = debounce(fetchCheckinRecord, 1000);

  return (
    <SiderDemo>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <h2>
          {dayjs(checkinList?.prev_start_date).format("dddd, D MMM YYYY")}-
          {dayjs(checkinList?.default_date).format("dddd, D MMM YYYY")}
        </h2>
        <Input.Search
          size="large"
          style={{ width: "15rem" }}
          placeholder="Search Client"
          onChange={handleDebounceSearch}
        />
      </div>
      <Tabs
        defaultActiveKey="1"
        items={checkinList?.periods?.map((date) => ({
          key: date,
          value: date,
          label: dayjs(date).format("ddd, D MMMM YYYY"),
        }))}
        onChange={(value) => {
          setSelectedDate(value);
        }}
        defaultValue={selectedDate}
        indicator={{
          align: "start",
        }}
      />

      <Table
        columns={columns}
        bordered
        pagination={false}
        dataSource={checkinList?.users ?? []}
        scroll={{ x: 1000 }}
      />

      {isCheckedIn && (
        <AddCheckinModal
          isCheckedIn={isCheckedIn}
          setIsCheckedIn={setIsCheckedIn}
          record={selectedRecord}
          fetchCheckinRecord={fetchCheckinRecord}
        />
      )}

      {isCheckedOut && (
        <CheckoutModal
          isCheckedOut={isCheckedOut}
          setIsCheckedOut={setIsCheckedOut}
          record={selectedRecord}
          fetchCheckinRecord={fetchCheckinRecord}
        />
      )}
    </SiderDemo>
  );
}
