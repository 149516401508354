import { Button, Form, Input, InputNumber, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const Token = ({ isTokenExpired, setIsTokenExpired }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchEmailToken();
  }, []);

  const fetchEmailToken = () => {
    dispatch({
      type: "FETCH_EMAIL_TOKEN_REQUEST",
      payload: {},
      successFunction: () => {},
      failureFunction: (err) => {},
    });
  };

  const handleSubmitToken = (values) => {
    setIsLoading(true);
    dispatch({
      type: "SUBMIT_EMAIL_TOKEN_REQUEST",
      payload: { ...values, branch_id: localStorage.getItem("branchId") },
      successFunction: () => {
        setIsTokenExpired(false);
        setIsLoading(false);
      },
      failureFunction: (err) => {
        setIsLoading(false);
      },
    });
  };

  return (
    <Modal
      open={isTokenExpired}
      onCancel={() => setIsTokenExpired(false)}
      footer={false}
      width={"20rem"}
      closable={false}
    >
      <Form onFinish={handleSubmitToken} style={{ margin: "0.5rem 0.5rem" }}>
        <Form.Item
          rules={[{ required: true, message: "Token is required!!!" }]}
          label="Token"
          name={"token"}
        >
          <InputNumber
            placeholder="Token"
            style={{ width: "100%" }}
            type="number"
          />
        </Form.Item>{" "}
        <Button
          loading={isLoading}
          style={{ width: "100%" }}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form>

      <div style={{ textAlign: "center" }}>Or</div>
      <div style={{ textAlign: "center" }}>
        {" "}
        <Link to={"/"}>Instead go Login ?</Link>
      </div>
    </Modal>
  );
};

export default Token;
