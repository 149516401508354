import React, { useEffect, useRef, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  Image,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import AddClient from "./AddClient";
import ClientDetails from "./ClientDetails";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";

import EditCustomer from "./EditCustomer";
import { debounce } from "lodash";
const { Search } = Input;

const Clients = () => {
  const { customerList, isLoading } = useSelector(
    (store) => store?.ClientsReducer
  );
  const { globalBranchId } = useSelector((store) => store?.ProfileReducer);

  const [isAddClientModalVisible, setIsAddClientModalVisible] = useState(false);
  const [isUpdateClientsModalVisible, setIsUpdateClientsModalVisible] =
    useState(false);
  const [isClientDetailsModelVisible, setIsClientDetailsModelVisible] =
    useState(false);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(null);
  const [cascadeType, setCascadeType] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);

  const dispatch = useDispatch();

  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
  };

  useEffect(() => {
    fetchCustomerList();
  }, [page, globalBranchId]);

  const fetchCustomerList = (event = null) => {
    dispatch({
      type: "FETCH_CUSTOMER_LIST_REQUEST",
      payload: {
        page: page,
        keyword: event ? event.target.value : "",
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setTotalRecord(res?.data?.total_leads ?? 0);
      },
      failureFunction: (err) => {},
    });
  };

  const columns = [
    {
      title: "Customer Number",
      dataIndex: "customer_no",
      align: "center",
    },
    {
      title: "Sales Rep",
      align: "center",
      render: (dataObj) =>
        `${dataObj?.first_name ?? ""} ${dataObj?.last_name ?? ""}`,
    },
    {
      title: "Name",
      dataIndex: "customer_name",
      align: "center",
    },
    {
      title: "Company	",
      dataIndex: "company_name",
      align: "center",
    },

    {
      title: "Phone	",
      dataIndex: "mobile_number",

      align: "center",
    },
    {
      title: "Email	",
      dataIndex: "email",
      align: "center",
    },
    {
      title: "ABN	",
      dataIndex: "abn",
      align: "center",
    },
    {
      title: "Mail Chimp	",
      width: 100,
      align: "center",
    },
    {
      title: "Actions",
      align: "center",
      fixed: "right",
      render: (dataObj) => (
        <Space>
          <Tooltip title="Update customer">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsUpdateClientsModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleDebounceChange = debounce(fetchCustomerList, 1500);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleCascadeTask = () => {
    dispatch({
      type: "CASCADE_ATTEMPT",
      payload: {
        endPoint: "customer_cascade_action",
        formData: {
          ids: selectedRowKeys,
          action: cascadeType,
          branch_id: localStorage.getItem("branchId"),
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data);
        setSelectedRowKeys([]);
        setCascadeType(null);
        fetchCustomerList();
      },
      failureFunction: (res) => {
        message.error(res?.response?.data?.message);
      },
    });
  };

  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="leadFlexRow">
          <div style={{ width: "30%" }}>
            <button
              className="button"
              onClick={() => {
                setIsAddClientModalVisible(true);
              }}
            >
              <span>Add Customer</span>
            </button>
          </div>

          <div
            style={{
              display: "flex",
              width: "30%",
              alignItems: "center",
              gap: "0.3rem",
            }}
          >
            <Search
              className="search"
              style={{
                width: "100%",
                borderRadius: "10px ! important",
              }}
              placeholder="Search customer"
              allowClear
              size="large"
              onChange={handleDebounceChange}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          <>
            {selectedRowKeys?.length > 0 && (
              <Alert
                message={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    Do you want to
                    <Select
                      value={cascadeType}
                      onChange={(value) => setCascadeType(value)}
                      placeholder="Select Action"
                      style={{ width: "8rem" }}
                    >
                      <Select.Option value="delete">Delete</Select.Option>
                      <Select.Option value="publish">Publish</Select.Option>
                      <Select.Option value="unpublish">Unpublish</Select.Option>
                    </Select>
                    {cascadeType && (
                      <Popconfirm
                        title={`Do you want to ${cascadeType} record?`}
                        onConfirm={() => {
                          handleCascadeTask();
                        }}
                        onCancel={() => {}}
                        okText="Ok"
                        cancelText="Cancel"
                      >
                        <Button danger type="primary">
                          Ok
                        </Button>
                      </Popconfirm>
                    )}
                  </div>
                }
                type="warning"
              />
            )}
            {isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                bordered
                columns={columns}
                pagination={false}
                scroll={{ x: 1000 }}
                dataSource={customerList?.customers?.map((data) => ({
                  ...data,
                  key: data?.customer_id,
                }))}
                rowSelection={rowSelection}
              />
            )}
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              current={page}
              onChange={onShowSizeChange}
              total={totalRecord}
            />
          </>
        </div>
      </div>
      {isAddClientModalVisible && (
        <AddClient
          isAddClientModalVisible={isAddClientModalVisible}
          setIsAddClientModalVisible={setIsAddClientModalVisible}
          fetchCustomerList={fetchCustomerList}
        />
      )}

      {isUpdateClientsModalVisible && (
        <EditCustomer
          record={record}
          isUpdateClientsModalVisible={isUpdateClientsModalVisible}
          setIsUpdateClientsModalVisible={setIsUpdateClientsModalVisible}
          fetchCustomerList={fetchCustomerList}
        />
      )}

      {isClientDetailsModelVisible && (
        <ClientDetails
          Record={record}
          isClientDetailsModelVisible={isClientDetailsModelVisible}
          setIsClientDetailsModelVisible={setIsClientDetailsModelVisible}
        />
      )}
    </SiderDemo>
  );
};

export default Clients;
