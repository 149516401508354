import { CodeSandboxOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MdEmail } from "react-icons/md";

export default function OrderPayment({
  isOrderPaymentOpen,
  setOrderPaymentOpen,
  record,
  fetchOrderList = () => {},
}) {
  const dispatch = useDispatch();
  const [orderForm, setOrderForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch({
      type: "FETCH_ORDER_PAYMENT_FORM_ATTEMPT",
      payload: record?.order_id,
      isSuccessFn: true,
      successFunction: (res) => {
        setOrderForm(res?.data);
        form.setFieldsValue({
          invoice_status: res?.data?.result?.invoice_status_id,
        });
      },
      failureFunction: (err) => {},
    });
  }, []);

  const handleSubmitRecord = (values) => {
    setLoading(true);
    dispatch({
      type: "ADD_ORDER_PAYMENT_ATTEMPT",
      payload: {
        id: record?.order_id,
        formData: {
          ...values,
          branch_id: localStorage.getItem("branchId"),
          sendemail: values?.sendemail ? values?.sendemail : false,
          copy_me: values?.copy_me ? values?.copy_me : false,
          paid: Number(values?.paid) ?? null,
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data);
        setOrderPaymentOpen(false);
        setLoading(false);
        fetchOrderList();
      },
      failureFunction: (err) => {
        setLoading(false);
        message.error(
          err?.response?.data?.messsage
            ? err?.response?.data?.message
            : "Failed, order payment!"
        );
      },
    });
  };

  const handleSendEmail = (dataObj) => {
    dispatch({
      type: "SEND_ORDER_PAYMENT_EMAIL_ATTEMPT",
      payload: dataObj?.payment_id,
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data);
      },
      failureFunction: (err) => {},
    });
  };

  const columns = [
    {
      title: "Date	",
      dataIndex: "paid_date",
      align: "center",
    },
    {
      title: "Amount	($)	",
      dataIndex: "amount",
      align: "center",
    },
    {
      title: "Payment Method		",
      dataIndex: "payment_method",
      align: "center",
    },
    {
      title: "Note		",
      dataIndex: "note",
      align: "center",
    },
    {
      title: "Payment By",
      align: "center",
      render: (data) => (
        <span>
          {data?.first_name} {data?.last_name}
        </span>
      ),
    },
    {
      title: "Action	",
      align: "center",
      fixed: "right",
      render: (dataObj) => (
        <Popconfirm
          title="Do you want to send email?"
          onConfirm={() => {
            handleSendEmail(dataObj);
          }}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <MdEmail
            style={{ fontSize: "1.2rem", cursor: "pointer", color: "#188ff4" }}
          />
        </Popconfirm>
      ),
    },
  ];

  return (
    <Modal
      title="Order Payment"
      open={isOrderPaymentOpen}
      onCancel={() => setOrderPaymentOpen(false)}
      width={600}
      footer={false}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Table
          bordered
          pagination={false}
          columns={columns}
          dataSource={orderForm?.payments}
          scroll={{ x: 800 }}
        />
        <Card>
          <Form
            initialValues={{
              due: record?.due_amount,
            }}
            onFinish={handleSubmitRecord}
            layout="vertical"
            form={form}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              <Form.Item
                style={{ width: "100%" }}
                label="Due Amount ($)"
                name="due"
              >
                <Input readOnly placeholder="Due Amount" />
              </Form.Item>
              <Form.Item
                style={{ width: "100%" }}
                label="Amount Paid ($)"
                name="paid"
                rules={[{ required: true, message: "Paid amount is required" }]}
              >
                <Input type="number" placeholder="Amount Paid" />
              </Form.Item>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              <Form.Item
                style={{ width: "100%" }}
                label="Payment Method"
                name="payment_method"
                rules={[
                  { required: true, message: "Payment method is required" },
                ]}
              >
                <Select placeholder="Select payment method">
                  {orderForm?.payment_methods?.map((data) => (
                    <Select.Option value={data}>{data}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ width: "100%" }}
                label="Invoice"
                name="invoice_status"
                rules={[{ required: true, message: "Invoice is required" }]}
              >
                <Select placeholder="Select invoice">
                  {orderForm?.invoices?.map((data) => (
                    <Select.Option value={data?.status_id}>
                      {data?.status_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <Form.Item
              rules={[{ required: true, message: "Note is required" }]}
              label="Special Note"
              name="note"
            >
              <Input.TextArea rows={4} placeholder="Write..." />
            </Form.Item>
            <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              <Form.Item valuePropName="checked" name="sendemail">
                <Checkbox> Send email automatically.</Checkbox>
              </Form.Item>

              <Form.Item valuePropName="checked" name="copy_me">
                <Checkbox> Send copy of email to me.</Checkbox>
              </Form.Item>
            </div>

            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form>
        </Card>
      </div>
    </Modal>
  );
}
