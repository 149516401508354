import { CheckSquareOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import UpdateInvoiceStatus from "./UpdateInvoiceStatus";
import axios from "axios";
import { getCookie } from "../../Helpers/FrontendHelper";
import InvoiceActivityLog from "./InvoiceActivityLog";

export default function CasePaymentPlan({
  visaRecord,
  fetchCasePaymentPlanDetail,
}) {
  const visaState = useSelector((store) => store.VisasReducer);
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
      documentChecklistState: state?.DocumentCheckListReducer,
    };
  });
  const dispatch = useDispatch();
  const { Option } = Select;

  const [dataObj, setDataObj] = useState(null);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [openStatusModel, setOpenStatusModel] = useState(false);
  const [otherPayment, setOtherPayment] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);
  const columns = [
    {
      title: "Term",
      align: "index",
      render: (text, record, index) => <span>{index + 1} </span>,
    },
    {
      title: "From Date",
      dataIndex: "planStartMonth",
      align: "center",
      render: (data) => (data ? data : "N/A"),
    },
    {
      title: "To Date",
      dataIndex: "planEndMonth",
      align: "center",
      render: (data) => (data ? data : "N/A"),
    },
    {
      title: "Net Tuition Fee",
      dataIndex: "planPaymentAmount",
      align: "center",
      render: (data) => (data ? data : "-"),
    },
    {
      title: "Material Fee",
      dataIndex: "materialFee",
      align: "center",
      render: (data) => (data ? data : "-"),
    },
    {
      title: "Additional Fee",
      dataIndex: "additionalFee",
      align: "center",
      render: (data) => (data ? data : "-"),
    },

    {
      title: "Total",
      align: "center",
      render: (data) => (
        <span>
          {(
            parseFloat(data?.planPaymentAmount) +
            data?.materialFee +
            data?.additionalFee
          ).toFixed(2)}
        </span>
      ),
    },
    {
      title: "Invoice Status",
      dataIndex: "paymentStatus",
      align: "center",
      render: (data) => <Tag color="blue">{data}</Tag>,
    },

    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (data) => (
        <CheckSquareOutlined
          title="Approve Payment"
          onClick={() => {
            setDataObj(data);
            setOpenStatusModel(true);
          }}
          style={{ cursor: "pointer", fontSize: "1.2rem", color: "#1890ff" }}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchPaymentProcess();
  });

  const fetchPaymentProcess = async () => {
    const token = getCookie("accessToken");
    await axios
      .get(`/v1/api/cases/payment-plan/isOnePaid/${visaRecord?.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setButtonDisable(res?.data);
      })
      .catch((err) => {})
      .finally();
  };

  const handleGeneratePaymentPlan = (values) => {
    dispatch({
      type: "UPDATE_PAYMENT_PLAN_BY_CASE",
      payload: {
        id: visaRecord?.id,
        value: {
          commenceDate: values?.commenceDate
            ? moment(values?.commenceDate)
            : null,
          wantToPayFor: values?.wantToPayFor,
          planFor: values?.planFor,
          subAgentId: values?.subAgentId,
          superAgentId: values?.superAgentId ? [values?.superAgentId] : null,

          isScholarshipAllowed: visaRecord?.isScholarshipAllowed,
          scholarShipAmount: visaRecord?.scholarShipAmount,
          scholarshipType: visaRecord?.scholarShipType,
          collegeIntake: visaRecord?.collegeIntake?.id,
          courseId: visaRecord?.courses?.id,
          currentPayAmount:
            values?.planFor === "TUITION_FEE"
              ? visaRecord?.netTotalFee
              : visaRecord?.totalAmount,
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        fetchCasePaymentPlanDetail();
        message.success("Generate paying Fee, Success!");
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "1rem",
        }}
      >
        <Card
          className="clientCard"
          style={{ width: "100%", padding: "0", margin: "0" }}
        >
          {visaRecord?.visaCategory === "EDUCATION" &&
          visaRecord?.isOffShore === false ? (
            <>
              <div className="flexRowSpaceBetween">
                Institute:
                <span>{visaRecord?.institute?.instituteName ?? "N/A"}</span>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
              <div className="flexRowSpaceBetween">
                Course:
                <span>{visaRecord?.courses?.name ?? "N/A"}</span>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
              <div className="flexRowSpaceBetween">
                Degree:
                <span>{visaRecord?.courses?.degree?.name ?? "N/A"}</span>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
              <div className="flexRowSpaceBetween">
                Onshore Fee:
                <span>{visaRecord?.courses?.onShoreFee ?? "N/A"}</span>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem 0" }} />
              <div className="flexRowSpaceBetween">
                Onshore Application Fee:
                <span>
                  {visaRecord.courses?.onshoreApplicationFee ?? "N/A"}
                </span>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem 0" }} />
              <div className="flexRowSpaceBetween">
                Onshore Material Fee:
                <span>{visaRecord?.courses?.onshoreMaterialFee ?? "N/A"}</span>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem 0" }} />
              <div className="flexRowSpaceBetween">
                Onshore Additional Fee:
                <span>
                  {visaRecord?.courses?.onshoreAdditionalFee ?? "N/A"}
                </span>
              </div>

              {visaRecord.courses?.onshoreIsDiscount === true && (
                <>
                  {visaRecord.courses?.onshoreDiscountAmount !== "" &&
                    visaRecord.courses?.onshoreDiscountAmount !== null &&
                    visaRecord.courses?.onshoreDiscountAmount !== undefined &&
                    visaRecord.courses?.onshoreDiscountType !== "" &&
                    visaRecord.courses?.onshoreDiscountType !== null &&
                    visaRecord.courses?.onshoreDiscountType !== undefined && (
                      <>
                        <Divider
                          orientationMargin="0"
                          style={{ margin: "0.5rem 0" }}
                        />
                        <div className="flexRowSpaceBetween">
                          Onshore Discount:
                          <span>{`${
                            visaRecord.courses?.onshoreDiscountAmount
                          } ${
                            visaRecord.courses?.onshoreDiscountType === "FLAT"
                              ? "FLAT"
                              : visaRecord.courses?.onshoreDiscountType ===
                                "PERCENTAGE"
                              ? "%"
                              : null
                          }`}</span>
                        </div>
                      </>
                    )}
                </>
              )}

              {visaRecord.courses?.onshoreIsScholarship === true && (
                <>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                  {visaRecord.courses?.onshoreScholarshipAmount !== "" &&
                    visaRecord.courses?.onshoreScholarshipAmount !== null &&
                    visaRecord.courses?.onshoreScholarshipAmount !==
                      undefined &&
                    visaRecord.courses?.onshoreScholarshipType !== "" &&
                    visaRecord.courses?.onshoreScholarshipType !== null &&
                    visaRecord.courses?.onshoreScholarshipType !==
                      undefined && (
                      <div className="flexRowSpaceBetween">
                        Onshore Scholarship:
                        <span>{`${
                          visaRecord.courses?.onshoreScholarshipAmount
                        } ${
                          visaRecord.courses?.onshoreScholarshipType === "FLAT"
                            ? "FLAT"
                            : visaRecord.courses?.onshoreScholarshipType ===
                              "PERCENTAGE"
                            ? "%"
                            : null
                        }`}</span>
                      </div>
                    )}
                </>
              )}
            </>
          ) : null}
          {visaRecord?.visaCategory === "EDUCATION" &&
          visaRecord?.isOffShore === true ? (
            <>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
              <div className="flexRowSpaceBetween">
                Offshore Fee:
                <span>{visaRecord?.courses?.offShoreFee ?? "N/A"}</span>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
              <div className="flexRowSpaceBetween">
                Offshore Application Fee:
                <span>
                  {visaRecord.courses?.offshoreApplicationFee ?? "N/A"}
                </span>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
              <div className="flexRowSpaceBetween">
                Offshore Material Fee:
                <span>{visaRecord?.courses?.offshoreMaterialFee ?? "N/A"}</span>
              </div>
              <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
              <div className="flexRowSpaceBetween">
                Offshore Additional Fee:
                <span>
                  {visaRecord?.courses?.offshoreAdditionalFee ?? "N/A"}
                </span>
              </div>

              {visaRecord.courses?.offshoreIsDiscount === true && (
                <>
                  {visaRecord.courses?.offshoreDiscountAmount !== "" &&
                    visaRecord.courses?.offshoreDiscountAmount !== null &&
                    visaRecord.courses?.offshoreDiscountAmount !== undefined &&
                    visaRecord.courses?.offshoreDiscountType !== "" &&
                    visaRecord.courses?.offshoreDiscountType !== null &&
                    visaRecord.courses?.offshoreDiscountType !== undefined && (
                      <>
                        <Divider
                          orientationMargin="0"
                          style={{ margin: "0.5rem" }}
                        />
                        <div className="flexRowSpaceBetween">
                          Onshore Discount:
                          <span>{`${
                            visaRecord.courses?.offshoreDiscountAmount
                          } ${
                            visaRecord.courses?.offshoreDiscountType === "FLAT"
                              ? "FLAT"
                              : visaRecord.courses?.offshoreDiscountType ===
                                "PERCENTAGE"
                              ? "%"
                              : null
                          }`}</span>
                        </div>
                      </>
                    )}
                </>
              )}

              {visaRecord.courses?.offshoreIsScholarship === true && (
                <>
                  {visaRecord.courses?.offshoreScholarshipAmount !== "" &&
                    visaRecord.courses?.offshoreScholarshipAmount !== null &&
                    visaRecord.courses?.offshoreScholarshipAmount !==
                      undefined &&
                    visaRecord.courses?.offshoreScholarshipType !== "" &&
                    visaRecord.courses?.offshoreScholarshipType !== null &&
                    visaRecord.courses?.offshoreScholarshipType !==
                      undefined && (
                      <>
                        <Divider
                          orientationMargin="0"
                          style={{ margin: "0.5rem" }}
                        />
                        <div className="flexRowSpaceBetween">
                          Onshore Scholarship:
                          <span>{`${
                            visaRecord.courses?.offshoreScholarshipAmount
                          } ${
                            visaRecord.courses?.offshoreScholarshipType ===
                            "FLAT"
                              ? "FLAT"
                              : visaRecord.courses?.offshoreScholarshipType ===
                                "PERCENTAGE"
                              ? "%"
                              : null
                          }`}</span>
                        </div>
                      </>
                    )}
                </>
              )}
            </>
          ) : null}
          <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

          <div className="flexRowSpaceBetween">
            Grand Total:
            <span>{` ${visaRecord.totalAmount ?? 0} ${
              visaRecord?.currency ?? ""
            }`}</span>
          </div>

          <div style={{ flex: "1", marginTop: "4rem" }}>
            <Button
              onClick={() => setInvoiceModalOpen(true)}
              style={{ width: "100%" }}
              type="outline"
            >
              View Invoice log
            </Button>
          </div>
        </Card>
        <Card
          className="clientCard"
          style={{ width: "100%", padding: "0", margin: "0" }}
        >
          <Form
            initialValues={{
              commenceDate: visaRecord?.commenceDate
                ? moment(visaRecord?.commenceDate)
                : null,
              completionDate: visaRecord?.completionDate
                ? moment(visaRecord?.completionDate)
                : null,
              wantToPayFor: visaRecord?.wantToPayFor,
            }}
            layout="vertical"
            onFinish={handleGeneratePaymentPlan}
          >
            <div
              style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}
            >
              <Form.Item
                label="Assign Sub Agent"
                name={"subAgentId"}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Select
                  placeholder="Select Sub Agent"
                  onChange={(value) => {
                    // setVisaCaseData((prev) => ({
                    //   ...prev,
                    //   subAgentId: value,
                    // }));
                  }}
                >
                  {store.caseState?.caseDropdown?.subAgent?.map((dataObj) => (
                    <Option key={dataObj.id} value={dataObj.id}>
                      {dataObj.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Assign Super Agent"
                name={"superAgentId"}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Select
                  placeholder="Select Super Agent"
                  // onChange={(value) => {
                  //   setVisaCaseData((prev) => ({
                  //     ...prev,
                  //     superAgentId: value,
                  //   }));
                  // }}
                >
                  {store.caseState?.caseDropdown?.headAgent?.map((dataObj) => (
                    <Option key={dataObj.id} value={dataObj.id}>
                      {dataObj.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}
            >
              <Form.Item
                label="Commence Date"
                name={"commenceDate"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Commence Date is required",
                  },
                ]}
              >
                <DatePicker
                  placeholder="Commence Date"
                  style={{ width: "100%" }}
                  format={"DD-MM-YYYY"}
                />
              </Form.Item>
              <Form.Item
                label="Completion Date"
                name={"completionDate"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Completion Date is required",
                  },
                ]}
              >
                <DatePicker
                  placeholder="Completion Date"
                  style={{ width: "100%" }}
                  format={"DD-MM-YYYY"}
                />
              </Form.Item>
            </div>
            <div style={{ gap: "0.3rem", width: "100%" }}>
              <Form.Item
                label="Student want to pay for"
                name={"wantToPayFor"}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Select Student want to pay for",
                  },
                ]}
              >
                <Radio.Group
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  <Radio value={"MONTHLY"}>Monthly</Radio>
                  <Radio value={"SEMESTER"}>One Semester</Radio>
                  <Radio value={"TRIMESTER"}>Tri-semester</Radio>
                  <Radio value={"YEARLY"}>One Year</Radio>
                  {/* <Radio value={"OTHERS"}>Others</Radio> */}
                </Radio.Group>
              </Form.Item>
              {otherPayment === "OTHERS" && (
                <Form.Item
                  name={"wantToPayDuration"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter the duration that student want to pay for!",
                    },
                  ]}
                >
                  <Input placeholder="Enter wants to pay for" />
                </Form.Item>
              )}{" "}
            </div>
            <Form.Item
              label={
                <span>
                  Calculate Plan For{" "}
                  <span
                    style={{
                      color: "blue",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    ({otherPayment && visaRecord?.currency}{" "}
                    {otherPayment === "TUITION_FEE"
                      ? visaRecord?.netTotalFee
                      : otherPayment === "TOTAL_FEE"
                      ? visaRecord?.totalAmount
                      : ""}
                    )
                  </span>
                </span>
              }
              name={"planFor"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Select calculate plan for",
                },
              ]}
            >
              <Radio.Group
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
                onChange={(e) => {
                  setOtherPayment(e.target.value);
                }}
              >
                <Radio value={"TUITION_FEE"}>Tuition Fee</Radio>
                <Radio value={"TOTAL_FEE"}>Total Fee</Radio>
              </Radio.Group>
            </Form.Item>

            <Button
              disabled={buttonDisable}
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
            >
              Generate Paying Fee
            </Button>
          </Form>
        </Card>
      </div>

      <Table
        columns={columns}
        dataSource={visaState?.casePaymentPlanById}
        pagination={false}
      />

      {openStatusModel && (
        <UpdateInvoiceStatus
          dataObj={dataObj}
          fetchPaymentProcess={fetchPaymentProcess}
          openStatusModel={openStatusModel}
          setOpenStatusModel={setOpenStatusModel}
          fetchCasePaymentPlanDetail={fetchCasePaymentPlanDetail}
        />
      )}

      {invoiceModalOpen && (
        <InvoiceActivityLog
          invoiceModalOpen={invoiceModalOpen}
          setInvoiceModalOpen={setInvoiceModalOpen}
          visaRecord={visaRecord}
        />
      )}
    </div>
  );
}
