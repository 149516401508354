import { Form, Modal, Select, Input, Button } from "antd";
import DomHandler from "domhandler";
import React from "react";
import { useDispatch } from "react-redux";

export default function UpdateInvoiceStatus({
  dataObj,
  openStatusModel,
  setOpenStatusModel,
  fetchCasePaymentPlanDetail,
  fetchPaymentProcess,
}) {
  const { Option } = Select;
  const { TextArea } = Input;
  const dispatch = useDispatch();

  const UpdateInovoiceStatus = (values) => {
    dispatch({
      type: "UPDATE_PAYMENTSTATUS_REQUEST",
      payload: { id: dataObj?.id, formData: values },
      isSuccessFn: true,
      successFunction: (res) => {
        setOpenStatusModel(false);
        fetchPaymentProcess();
        fetchCasePaymentPlanDetail();
      },
    });
  };
  return (
    <Modal
      open={openStatusModel}
      onCancel={() => {
        setOpenStatusModel(false);
      }}
      title="Update Invoice Status"
      footer={false}
    >
      <Form
        initialValues={{
          paymentStatus: dataObj?.paymentStatus,
        }}
        layout="vertical"
        onFinish={UpdateInovoiceStatus}
      >
        <Form.Item label="Invoice Status" name="paymentStatus">
          <Select placeholder="Select invoice status">
            <Option value={"Pending"}>Pending</Option>
            <Option value={"Deposit Taken"}>Deposit Taken</Option>
            <Option value={"Paid"}> Paid</Option>
            <Option value={"Cancelled"}>Cancelled</Option>
            <Option value={"Hold"}>Hold</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Customer Note" name="customerNote">
          <TextArea rows={4} placeholder="write something ...." />
        </Form.Item>
        <Form.Item label="Admin Note" name="adminNote">
          <TextArea rows={4} placeholder="write something ...." />
        </Form.Item>
        <Button htmlType="submit" type="primary">
          Update
        </Button>
      </Form>
    </Modal>
  );
}
