import { Card, Table, Tag } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function OrderTask({ record }) {
  const dispatch = useDispatch();
  const { orderTaskList } = useSelector((store) => store?.OrderReducer);

  console.log("order task", orderTaskList);

  const columns = [
    {
      title: "Task title",
      dataIndex: "task_name",
      align: "center",
    },

    {
      title: "Assign To",
      dataIndex: "",
      align: "center",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      align: "center",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      align: "center",
    },
    {
      title: "Added By",
      dataIndex: "added_by",
      align: "center",
    },
    {
      title: "Priority",
      align: "center",
      render: (data) => (
        <Tag style={{ background: data?.priority_code, color: "white" }}>
          {data?.priority}
        </Tag>
      ),
    },
    {
      title: "Status",
      align: "center",
      render: (data) => (
        <Tag style={{ background: data?.status_code, color: "white" }}>
          {data?.status_name}
        </Tag>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
    },
  ];

  useEffect(() => {
    fetchOrderTask();
  }, []);

  const fetchOrderTask = () => {
    dispatch({
      type: "FETCH_ORDER_TASK_ATTEMPT",
      payload: record?.order_id,
      isSuccessFn: true,
      successFunction: (res) => {},
      failureFunction: () => {},
    });
  };

  return (
    <Card>
      <Table
        pagination={false}
        bordered
        columns={columns}
        dataSource={orderTaskList?.tasks}
      />
    </Card>
  );
}
