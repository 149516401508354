import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  TimePicker,
  Upload,
  message,
} from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function UpdateTaskStatus({
  isStatusUpdateOpen,
  setIsStatusUpdateOpen,
  fetchTaskDetailData,
  record,
}) {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { dailyTaskList, isLoading } = useSelector(
    (store) => store?.TaskReducer
  );
  const [uploadFile, setUploadFile] = useState(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const taskStatus = record?.status?.find(
    (data) => data?.status_id === record?.task_detail?.task_status
  )?.status_name;

  const handleAddStatusUpdate = (values) => {
    dispatch({
      type: "UPDATE_TASK_STATUS_REQUEST",
      payload: {
        id: record?.task_detail?.task_id,
        formData: {
          ...values,
          branch_id: localStorage.getItem("branchId"),
          file_name: uploadFile ?? "",
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success("Task status update added");
        setIsStatusUpdateOpen(false);
        fetchTaskDetailData();
      },
      failureFunction: () => {},
    });
  };

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    const formData = new FormData();
    formData.append("files", newFile);
    formData.append("branch_id", localStorage.getItem("branchId"));
    formData.append("path", "lms_project");

    setIsUploadLoading(true);
    dispatch({
      type: "UPLOAD_FILE_REQUEST",
      payload: formData,
      isSuccessFn: true,
      successFunction: (res) => {
        setUploadFile(res?.data?.source?.src);
        message.success("Upload file successful");
        setIsUploadLoading(false);
      },
      failureFunction: () => {
        setIsUploadLoading(false);
        message.error("Failed to upload file");
      },
    });
  };

  return (
    <Modal
      open={isStatusUpdateOpen}
      onCancel={() => {
        setIsStatusUpdateOpen(false);
      }}
      title="Update Task Status"
      footer={false}
    >
      <Form
        onFinish={handleAddStatusUpdate}
        layout="vertical"
        className="grid-container"
      >
        <Form.Item
          className="grid-full-child"
          label="Select Employee"
          name="employee_id"
        >
          <Select placeholder="Select Employee">
            {dailyTaskList?.users?.map((data) => (
              <Option value={data?.userid}>
                {data?.first_name} {data?.last_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
          }}
          className="grid-full-child"
        >
          <Form.Item label="Remainder Date" name="date">
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item label="Remainder Time" name="time">
            <TimePicker format={"hh:mm a"} />
          </Form.Item>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
          }}
          className="grid-full-child"
        >
          <Form.Item
            label="Estimate Hours"
            name="estimated_hours"
            rules={[
              {
                required:
                  taskStatus?.trim("")?.toLowerCase() === "assigned"
                    ? true
                    : false,
                message: "Estimate Hours is required",
              },
            ]}
          >
            <InputNumber placeholder="Estimate hours" />
          </Form.Item>
          <Form.Item
            label="Actual Hours"
            name="actual_hours"
            rules={[
              {
                required:
                  taskStatus?.trim("")?.toLowerCase() === "completed"
                    ? true
                    : false,
                message: "Estimate Hours is required",
              },
            ]}
          >
            <InputNumber placeholder="Actual hours" />
          </Form.Item>
        </div>
        <Form.Item
          label="Todays Update"
          name="detail"
          className="grid-full-child"
          rules={[{ required: true, message: "Update detail is required" }]}
        >
          <Input.TextArea rows={4} placeholder="Write todays update" />
        </Form.Item>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            alignItems: "center",
          }}
          className="grid-full-child"
        >
          <Form.Item
            className="grid-full-child"
            name="file_name"
            label="Attachment"
          >
            <Input type="file" onChange={handleFileChange} />
          </Form.Item>
          {isUploadLoading && (
            <span>
              <Spin /> Loading
            </span>
          )}
        </div>
        <Form.Item
          name="is_completed"
          className="grid-full-child"
          valuePropName="checked"
        >
          <Checkbox>Mark as complete</Checkbox>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
}
