import React, { useDebugValue, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Switch,
  Tag,
  TimePicker,
} from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import SendComposedMail from "../Mailbox/SendComposedMail";

const UpdateEvent = ({
  isEditModalVisible,
  setIsEditModalVisible,
  record,
  fetchEventsData,
}) => {
  const { eventForm } = useSelector((store) => store?.EventsReducer);
  const { TextArea } = Input;
  const { Option } = Select;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);

  useEffect(() => {
    fetchEventForm();
  }, []);

  const fetchEventForm = () => {
    dispatch({
      type: "FETCH_EVENT_FORM_REQUEST",
      payload: {},
      isSuccessFn: false,
      successFunction: (res) => {},
    });
  };

  const handleEditEvents = (values) => {
    dispatch({
      type: "EDIT_EVENT_REQUEST",
      payload: {
        id: record?.event_id,
        formData: {
          ...values,
          reminder_time: values?.reminder_time ? [values?.reminder_time] : null,
          branch_id: localStorage.getItem("branchId") ?? null,
          event_date: moment(values?.event_date).format("YYYY-MM-DD") ?? null,
          end_date: moment(values?.end_date).format("YYYY-MM-DD") ?? null,
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsEditModalVisible(false);
        fetchEventsData();
      },
    });
  };

  const disableDates = (current) => {
    const startDate = form.getFieldValue("event_date");
    return current && current < startDate.startOf("day");
  };

  return (
    <Drawer
      title={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <span>Update Event</span>
          <Button
            type="primary"
            onClick={() => {
              setIsSendComposedEmailModalVisible(true);
            }}
          >
            Send Email
          </Button>
        </div>
      }
      placement="right"
      open={isEditModalVisible}
      onClose={() => {
        setIsEditModalVisible(false);
      }}
      width={1163}
      footer={null}
    >
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Form
          layout="vertical"
          form={form}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
            maxWidth: "1024px",
            width: "100%",
          }}
          onFinish={handleEditEvents}
          initialValues={{
            event_name: record?.event_name,
            details: record?.event_details,
            event_date: record?.event_date
              ? moment(record?.event_date, "DD-MM-YYYY")
              : null,
            event_time: record?.event_time
              ? moment(record?.event_time, "HH:mm:ss a")
              : null,
            end_date: record?.end_date
              ? moment(record?.end_date, "DD-MM-YYYY")
              : null,
            end_time: record?.end_time
              ? moment(record?.end_time, "HH:mm:ss a")
              : null,
            event_status: record?.event_status,
            event_action: record?.action,
            reminder_time: parseInt(record?.reminder_time) ?? null,
            invitation_via: record?.invitation_via,
          }}
        >
          <Form.Item
            name="event_name"
            label="Title"
            rules={[
              {
                required: true,
                message: "Event name is required",
              },
            ]}
          >
            <Input placeholder="Enter Title" />
          </Form.Item>
          <Form.Item
            name="details"
            label="Description"
            // style={{ gridColumn: "span 2" }}
            rules={[{ required: true, message: "Description is requred" }]}
          >
            <TextArea rows={1} placeholder="Write here..." />
          </Form.Item>
          <Form.Item name={"allDay"} label="All Day">
            <Switch />
          </Form.Item>
          <Form.Item name="invitation_via">
            <Checkbox style={{ marginTop: "2rem" }}>
              {" "}
              Send email automatically
            </Checkbox>
          </Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              style={{ width: "100%" }}
              name="event_date"
              label="Event Date"
              rules={[
                { required: true, message: "Event start-date is required" },
              ]}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                disabled
                style={{ width: "100%" }}
                placeholder="Select Event Date"
              />
            </Form.Item>
            <Form.Item
              style={{ width: "100%" }}
              name="event_time"
              label="Event Time"
              rules={[
                { required: true, message: "Event start-time is required" },
              ]}
            >
              <TimePicker
                use12Hours
                format="h:mm a"
                style={{ width: "100%" }}
                placeholder="Select Event Time"
              />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Form.Item
              style={{ width: "100%" }}
              name="end_date"
              label="Event End Date"
              rules={[
                { required: true, message: "Event end-date is required" },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                format={"DD-MM-YYYY"}
                disabledDate={disableDates}
                placeholder="Select Event End Date"
              />
            </Form.Item>
            <Form.Item
              style={{ width: "100%" }}
              name="end_time"
              label="Event End Time"
              rules={[
                { required: true, message: "Event end-time is required" },
              ]}
            >
              <TimePicker
                use12Hours
                format="h:mm a"
                style={{ width: "100%" }}
                placeholder="Select End Time"
              />
            </Form.Item>
          </div>
          <Form.Item
            name="event_status"
            label="Status"
            rules={[{ required: true, message: "Event status is required" }]}
          >
            <Select placeholder="Select Status">
              {eventForm?.status?.map((status) => (
                <Option value={status?.status_id}>{status?.status_name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="event_action"
            label="Action"
            rules={[{ required: true, message: "Event action is required" }]}
          >
            <Input placeholder="Action" />
          </Form.Item>
          <Form.Item name="invitation_emails" label="Emails">
            <Select placeholder="Emails" mode="tags"></Select>
          </Form.Item>
          <Form.Item
            style={{ gridColumn: "span 2" }}
            name="reminder_time"
            label="Reminder Time"
            rules={[{ required: true, message: "Remainder time is requred" }]}
          >
            <Radio.Group
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              {eventForm?.reminder_times?.map((time) => (
                <Radio.Button
                  style={{ borderRadius: "1rem" }}
                  value={time?.value}
                >
                  {time?.label}{" "}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Button
            style={{ width: "fit-content" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form>
      </div>

      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          urlPath={`/send_event_mail/${record?.event_id}`}
          emailList={[record?.email]}
          forWhatId={record?.event_id}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
          emailType="event"
          formDataUrl={`/event_mail_preview/${record?.event_id}`}
        />
      )}
    </Drawer>
  );
};

export default UpdateEvent;
