import { Card, Empty, Timeline } from "antd";
import React from "react";

export default function AdminNoteList({ record }) {
  return (
    <Card>
      <Timeline mode={"left"}>
        {record?.quote?.admin_notes?.length > 0 ? (
          record?.quote?.admin_notes?.map((data) => (
            <Timeline.Item>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "0" }}
              >
                <p
                  style={{
                    margin: "0",
                    padding: "0",
                    color: "grey",
                    fontStyle: "italic",
                    fontSize: "15px",
                  }}
                >
                  {data?.admin_note}
                </p>
                <p style={{ fontSize: "12px", margin: "0", padding: "0" }}>
                  {data?.first_name} {data?.last_name},
                  <span> {data?.date}</span>{" "}
                </p>
              </div>
            </Timeline.Item>
          ))
        ) : (
          <Empty description="No admin notes" />
        )}
      </Timeline>
    </Card>
  );
}
