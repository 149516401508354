import { Drawer, Steps } from "antd";
import React, { useState } from "react";
import AccountInformation from "./AccountInformation";

export default function AddCompany({ isAddCompany, setIsAddCompany }) {
  const [current, setCurrent] = useState(0);

  const form = [<AccountInformation setCurrent={setCurrent} />];

  return (
    <Drawer
      open={isAddCompany}
      onClose={() => {
        setIsAddCompany(false);
      }}
      width={1163}
      title="Add Company"
    >
      <Steps
        current={current}
        items={[
          {
            title: "Account Info",
          },
          {
            title: "Profile Info",
          },
          {
            title: "Billing Info",
          },
        ]}
      />
      {form[current]}
    </Drawer>
  );
}
