import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Button, Segmented, Space, Table, Tag, Tooltip } from "antd";

import moment from "moment";
import { useSelector } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import { AiOutlineEye } from "react-icons/ai";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import AddTask from "../Tasks/AddTask";
import UpdateTask from "../Tasks/UpdateTask";
import ViewTask from "../Tasks/ViewTask";

export default function TaskComponent({ projectRecord }) {
  const dispatch = useDispatch();
  const { taskListData, isLoading } = useSelector(
    (store) => store?.TaskReducer
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [record, setRecord] = useState("");

  const [events, setEvents] = useState([]);
  const [selectSlot, setSelectSlot] = useState(null);

  useEffect(() => {
    fetchTaskList();
    fetchEmployeeTaskList();
  }, []);

  const fetchTaskList = () => {
    dispatch({
      type: "FETCH_TASK_LIST_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: (res) => {
        setEvents(
          res?.data?.tasks?.map((task) => ({
            ...task,
            title: task?.task_name,
            start:
              moment(task?.start_date, "DD-MM-YYYY").format(
                "YYYY-MM-DDTHH:mm:ss"
              ) ?? null,
            end:
              moment(task?.end_date, "DD-MM-YYYY").format(
                "YYYY-MM-DDTHH:mm:ss"
              ) ?? null,
          }))
        );
      },
    });
  };

  const fetchEmployeeTaskList = () => {
    dispatch({
      type: "FETCH_EMPLOYEE_TASK_LIST_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: (res) => {},
    });
  };

  const columns = [
    {
      title: "Task Title",
      dataIndex: "tasktitle",
      width: 300,
      sorter: (a, b) => {
        if (a.taskTitle < b.taskTitle) {
          return -1;
        }
        if (a.taskTitle > b.taskTitle) {
          return 1;
        }
        return 0;
      },
      align: "center",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      align: "center",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      align: "center",
    },
    {
      title: "Task Status",
      dataIndex: "status_name",
      align: "center",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      align: "center",
    },
    {
      title: "Assign To",
      dataIndex: "assigned_employees",
      sorter: (a, b) => {
        if (a.assignedTo < b.assignedTo) {
          return -1;
        }
        if (a.assignedTo > b.assignedTo) {
          return 1;
        }
        return 0;
      },
      align: "center",
      render: (empList) =>
        empList?.map((emp) => (
          <Tag>
            {emp?.first_name} {emp?.last_name}
          </Tag>
        )),
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      align: "center",
      render: (data) => {
        return data?.length > 0 ? (
          <div
            style={{ display: "flex", gap: "0.2rem", flexDirection: "column" }}
          >
            {data?.map((item) => (
              <li
                style={{
                  padding: "0.1rem 0.5rem",
                  borderRadius: "0.5rem",
                  border: "1px solid #ccc",
                  background: "#e6f4ff30",
                  cursor: "pointer",
                }}
              >
                {item?.email}
              </li>
            ))}
          </div>
        ) : (
          "N/A"
        );
      },
    },

    {
      title: "Actions",
      align: "center",
      fixed: "right",
      render: (dataObj) => (
        <Space size="middle">
          <Tooltip title="Update Task">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsEditModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>

          <Tooltip title="Task Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsDrawerOpen(true);
                  setRecord(dataObj);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="flexColumnwithoutStyle">
        <div className="leadFlexRow">
          <div style={{ width: "30%" }}>
            <button
              className="button"
              onClick={() => {
                setIsAddTaskModalVisible(true);
              }}
            >
              <span>Add Task</span>
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1rem",
          }}
        >
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered
              columns={columns}
              pagination={false}
              dataSource={taskListData?.tasks}
              scroll={{ x: 1000 }}
            />
          )}
        </div>
      </div>
      {isAddTaskModalVisible && (
        <AddTask
          projectId={projectRecord?.task_id}
          isAddTaskModalVisible={isAddTaskModalVisible}
          setIsAddTaskModalVisible={setIsAddTaskModalVisible}
        />
      )}
      {isEditModalVisible && (
        <UpdateTask
          record={record}
          isEditModalVisible={isEditModalVisible}
          setIsEditModalVisible={setIsEditModalVisible}
          fetchTaskList={fetchTaskList}
        />
      )}

      {isDrawerOpen && (
        <ViewTask
          record={record}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      )}
    </div>
  );
}
