import { Button, Form, Input, Modal } from "antd";
import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { getCookie } from "../../Helpers/FrontendHelper";

export default function EditDocumentChecklist({
  setDocumentActive,
  handleGetDocumentChecklist,
  documentActive,
  isEditModalVisible,
  setIsEditModalVisible,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const handleOk = () => {
    setIsEditModalVisible(false);
    form.resetFields();
    setDocumentActive("");
  };

  console.log("testing testing", documentActive);

  const handleCancel = () => {
    setIsEditModalVisible(false);
    form.resetFields();
    setDocumentActive("");
  };

  const handleEditDocumentChecklist = (values) => {
    dispatch({
      type: "UPDATE_DOCUMENT_CHECKLIST_REQUEST",
      payload: { id: documentActive?.id, name: values?.name },
      isSuccessFn: true,
      successFunction: successFunction,
    });
  };

  const successFunction = () => {
    form.resetFields();
    handleGetDocumentChecklist();
    setDocumentActive("");
    setIsEditModalVisible(false);
  };

  http: return (
    <Modal
      title="Edit Document Checklist"
      open={isEditModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <Form
        form={form}
        initialValues={{
          name: documentActive?.name,
        }}
        layout="vertical"
        onFinish={handleEditDocumentChecklist}
      >
        <Form.Item
          name="name"
          label="Document Name"
          rules={[
            {
              required: true,
              message: "Document name is required",
            },
          ]}
        >
          <Input placeholder="Document name" />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Edit Document Checklist
        </Button>
      </Form>
    </Modal>
  );
}
