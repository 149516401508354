import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Tag,
  TimePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  MinusCircleOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";

const AddQuotation = ({
  isAddQuotationModalVisible,
  setIsAddQuotationModalVisible,
  fetchQuoteList,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const { Option } = Select;
  const [isAddCustomerModalVisible, setIsAddCustomerModalVisible] =
    useState(false);
  const [cType, setcType] = useState("exist");
  const [formList, setFormList] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [isGstApp, setIsGstApp] = useState(1);
  const [financeSelect, setFinanceSelect] = useState(false);
  const [customerDetail, setCustomerDetail] = useState(null);

  useEffect(() => {
    function handleFetchApi() {
      fetchQuotePresetForm();
    }

    handleFetchApi();
  }, []);

  const fetchQuotePresetForm = () => {
    dispatch({
      type: "FETCH_QUOTE_PRESET_FORM_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: (res) => {
        setFormList(res?.data);
        setCustomerList(res?.data?.customers);
        setPackageList(res?.data?.package_types);
      },
      failureFunction: (err) => {},
    });
  };

  const handleSelectPackage = (value, option, index) => {
    const tempPackage = JSON.parse(option?.key);
    const tempPackageList = form.getFieldValue("packages");
    tempPackageList[index] = {
      package_desc: value,
      package_qty: 1,
      package_price: tempPackage?.price,
      package_amount: parseFloat(tempPackage?.price) * 1,
    };

    const tempPrice = tempPackageList?.reduce(
      (total, data) => total + parseFloat(data?.package_amount),
      0
    );

    const tempDiscount = form.getFieldValue("discount");
    const tempIsPercentage = form.getFieldValue("is_flat");

    const tempSubTotal = tempIsPercentage
      ? parseFloat(tempPrice) -
        (parseFloat(tempPrice) * parseFloat(tempDiscount)) / 100
      : parseFloat(tempPrice) - parseFloat(tempDiscount);

    const tempGstApplicable = form.getFieldValue("gst_applicable");
    const tempRadioGst = form.getFieldValue("radio_gst");

    const tempGst =
      tempGstApplicable === 1 && tempRadioGst === 1
        ? parseFloat(tempSubTotal) * 0.1
        : tempGstApplicable === 1 && tempRadioGst === 0
        ? parseFloat(tempSubTotal) / 11
        : 0;

    const tempTotalPrice =
      tempRadioGst === 1
        ? parseFloat(tempSubTotal) + parseFloat(tempGst)
        : parseFloat(tempSubTotal);

    form.setFieldsValue({
      packages: tempPackageList,
      price: Number(tempPrice).toFixed(2),
      sub_total: Number(tempSubTotal).toFixed(2),
      gst: Number(tempGst).toFixed(2),
      total_price: Number(tempTotalPrice).toFixed(2),
    });
  };

  const handlePerformCalculation = (index) => {
    const tempPackage = form.getFieldValue("packages");
    const changePackage = tempPackage[index];
    tempPackage[index] = {
      ...changePackage,
      package_amount:
        parseFloat(changePackage?.package_qty) *
        parseFloat(changePackage?.package_price),
    };

    const tempPrice = tempPackage?.reduce(
      (total, data) => total + parseFloat(data?.package_amount),
      0
    );

    const tempDiscount = form.getFieldValue("discount");
    const tempIsPercentage = form.getFieldValue("is_flat");

    const tempSubTotal = tempIsPercentage
      ? parseFloat(tempPrice) -
        (parseFloat(tempPrice) * parseFloat(tempDiscount)) / 100
      : parseFloat(tempPrice) - parseFloat(tempDiscount);

    const tempGstApplicable = form.getFieldValue("gst_applicable");
    const tempRadioGst = form.getFieldValue("radio_gst");

    const tempGst =
      tempGstApplicable === 1 && tempRadioGst === 1
        ? parseFloat(tempSubTotal) * 0.1
        : tempGstApplicable === 1 && tempRadioGst === 0
        ? parseFloat(tempSubTotal) / 11
        : 0;

    const tempTotalPrice =
      tempRadioGst === 1
        ? parseFloat(tempSubTotal) + parseFloat(tempGst)
        : parseFloat(tempSubTotal);

    form.setFieldsValue({
      packages: tempPackage,
      price: Number(tempPrice).toFixed(2),
      sub_total: Number(tempSubTotal).toFixed(2),
      gst: Number(tempGst).toFixed(2),
      total_price: Number(tempTotalPrice).toFixed(2),
    });
  };

  const handleSubmitRecord = (values) => {
    dispatch({
      type: "ADD_QUOTE_ATTEMPT",
      payload: {
        ...values,
        chk_payment: values?.chk_payment ? 1 : 0,
        chk_timeline: values?.chk_timeline ? 1 : 0,
        chk_test: values?.chk_test ? 1 : 0,
        finance: values?.finance ? 1 : 0,
        is_flat: values?.is_flat ? 0 : 1,
        branch_id: localStorage.getItem("branchId"),
        packages: values?.packages?.map((data) => ({
          ...data,
          package_qty: parseFloat(data?.package_qty) ?? null,
          package_price: parseFloat(data?.package_price) ?? null,
          package_amount: parseFloat(data?.package_amount) ?? null,
        })),
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsAddQuotationModalVisible(false);
        fetchQuoteList();
      },
      failureFunction: () => {},
    });
  };

  const handleSelectCustomer = (value, option) => {
    const tempCustomer = JSON.parse(option.key);
    setCustomerDetail(tempCustomer);
  };

  return (
    <Drawer
      title="Add Single Quote"
      placement="right"
      open={isAddQuotationModalVisible}
      onClose={() => {
        setIsAddQuotationModalVisible(false);
      }}
      width={1163}
      footer={null}
    >
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Form
          layout="vertical"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
            maxWidth: "1024px",
            width: "100%",
          }}
          form={form}
          onFinish={handleSubmitRecord}
          initialValues={{
            customer_type: cType,
            discount: 0,
            is_flat: false,
            gst_applicable: 1,
            radio_gst: 1,
            finance: false,
            quote_status:"18"
          }}
        >
          <Form.Item
            style={{ width: "100%", gridColumn: "span 2" }}
            name="customer_type"
            label="Customer Type"
            rules={[{ required: true, message: "Select quote option" }]}
          >
            <Radio.Group onChange={(e) => setcType(e.target.value)}>
              <Radio value={"exist"}>Existing Customer</Radio>
              <Radio value={"new"}>New Customer</Radio>
            </Radio.Group>
          </Form.Item>
          {cType === "new" && (
            <div style={{ width: "100%", gridColumn: "span 2" }}>
              new customer section
            </div>
          )}
          <Form.Item style={{ width: "100%" }} name="customer" label="Customer">
            <Select
              onSearch={(value) => {
                const searchKey = value.trim();
                const test = formList?.customers?.filter((employee) =>
                  employee.customer_name
                    .toLowerCase()
                    .includes(searchKey.toLowerCase())
                );
                setCustomerList(searchKey === "" ? formList?.customers : test);
              }}
              showSearch
              filterOption={false}
              placeholder="Select Customer"
              onChange={handleSelectCustomer}
            >
              {customerList?.map((data) => (
                <Option key={JSON.stringify(data)} value={data?.customer_id}>
                  {data?.customer_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="txt_name" label="Nature of Project">
            <Input placeholder="Nature of Project" />
          </Form.Item>
          {customerDetail && (
            <Card>
              <p>Customer No: {customerDetail?.customer_no ?? "N/A"}</p>{" "}
              <p>Name: {customerDetail?.customer_name ?? "N/A"}</p>
              <p>Company: {customerDetail?.company_name ?? "N/A"}</p>
              <p>Contact No: {customerDetail?.contact_number ?? "N/A"}</p>
              <p>Email: {customerDetail?.email ?? "N/A"}</p>
            </Card>
          )}
          <div
            style={{
              gridColumn: "span 2",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "0.3rem",
            }}
          >
            <Form.List name="packages">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => (
                      <Space
                        key={key}
                        align="baseline"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 2fr 1fr 1fr 1fr",
                          gap: "0.5rem",
                        }}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "package_desc"]}
                          fieldKey={[fieldKey, "package_desc"]}
                          style={{ width: "100%" }}
                        >
                          <Select
                            onSearch={(value) => {
                              const searchKey = value.trim();
                              const test = formList?.package_types?.filter(
                                (data) =>
                                  data?.name
                                    ?.toLowerCase()
                                    .includes(searchKey.toLowerCase())
                              );
                              setPackageList(
                                searchKey === ""
                                  ? formList?.package_types
                                  : test
                              );
                            }}
                            showSearch
                            filterOption={false}
                            placeholder="Select package"
                            onChange={(value, option) =>
                              handleSelectPackage(value, option, index)
                            }
                          >
                            {packageList?.map((data) => (
                              <Option
                                key={JSON.stringify(data)}
                                // value={data?.threatre_id}
                                value={data?.name}
                              >
                                {data?.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "short_desc"]}
                          fieldKey={[fieldKey, "short_desc"]}
                        >
                          <Input placeholder="Short Description" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "package_qty"]}
                          fieldKey={[fieldKey, "package_qty"]}
                          rules={[
                            { required: true, message: "Quantity is required" },
                          ]}
                        >
                          <Input
                            onChange={(e) => handlePerformCalculation(index)}
                            type="number"
                            placeholder="Quantity"
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "package_price"]}
                          fieldKey={[fieldKey, "package_price"]}
                          rules={[
                            { required: true, message: "price is required" },
                          ]}
                        >
                          <Input
                            onChange={(e) => handlePerformCalculation(index)}
                            type="number"
                            placeholder="Price"
                          />
                        </Form.Item>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: "0.5rem",
                          }}
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "package_amount"]}
                            fieldKey={[fieldKey, "package_amount"]}
                            rules={[
                              { required: true, message: "Total is required" },
                            ]}
                          >
                            <Input readOnly type="number" placeholder="Total" />
                          </Form.Item>
                          <MinusCircleOutlined
                            style={{
                              fontSize: "1.5rem",
                              cursor: "pointer",
                              color: "red",
                            }}
                            onClick={() => {
                              const tempPackageList =
                                form.getFieldValue("packages");
                              const tempPrice = form.getFieldValue("price");
                              const tempPackage = tempPackageList[index];

                              const newTempPrice =
                                parseFloat(tempPrice) -
                                parseFloat(tempPackage?.package_amount);

                              const tempDiscount =
                                form.getFieldValue("discount");
                              const tempIsPercentage =
                                form.getFieldValue("is_flat");

                              const tempSubTotal = tempIsPercentage
                                ? parseFloat(newTempPrice) -
                                  (parseFloat(newTempPrice) *
                                    parseFloat(tempDiscount)) /
                                    100
                                : parseFloat(newTempPrice) -
                                  parseFloat(tempDiscount);

                              const tempGstApplicable =
                                form.getFieldValue("gst_applicable");
                              const tempRadioGst =
                                form.getFieldValue("radio_gst");

                              const tempGst =
                                tempGstApplicable === 1 && tempRadioGst === 1
                                  ? parseFloat(tempSubTotal) * 0.1
                                  : tempGstApplicable === 1 &&
                                    tempRadioGst === 0
                                  ? parseFloat(tempSubTotal) / 11
                                  : 0;

                              const tempTotalPrice =
                                tempRadioGst === 1
                                  ? parseFloat(tempSubTotal) +
                                    parseFloat(tempGst)
                                  : parseFloat(tempSubTotal);

                              form.setFieldsValue({
                                price: Number(newTempPrice).toFixed(2),
                                sub_total: Number(tempSubTotal).toFixed(2),
                                gst: Number(tempGst).toFixed(2),
                                total_price: Number(tempTotalPrice).toFixed(2),
                              });

                              remove(name);
                            }}
                          />
                        </div>
                      </Space>
                    )
                  )}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Package
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
          <Form.Item
            name={"price"}
            label="Regular Price"
            rules={[{ required: true, message: "Regular price is required" }]}
          >
            <Input readOnly type="number" placeholder="Regular price" />
          </Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Form.Item name={"discount"} label="Discount">
              <InputNumber
                onChange={(value) => {
                  const tempIsPercentage = form.getFieldValue("is_flat");
                  const tempPrice = form.getFieldValue("price");
                  const tempSubTotal = tempIsPercentage
                    ? parseFloat(tempPrice) -
                      (parseFloat(tempPrice) * value) / 100
                    : parseFloat(tempPrice) - value;

                  const tempGstApplicable =
                    form.getFieldValue("gst_applicable");
                  const tempRadioGst = form.getFieldValue("radio_gst");

                  const tempGst =
                    tempGstApplicable === 1 && tempRadioGst === 1
                      ? parseFloat(tempSubTotal) * 0.1
                      : tempGstApplicable === 1 && tempRadioGst === 0
                      ? parseFloat(tempSubTotal) / 11
                      : 0;

                  const tempTotalPrice =
                    tempRadioGst === 1
                      ? parseFloat(tempSubTotal) + parseFloat(tempGst)
                      : parseFloat(tempSubTotal);

                  form.setFieldsValue({
                    sub_total: Number(tempSubTotal).toFixed(2),
                    gst: Number(tempGst).toFixed(2),
                    total_price: Number(tempTotalPrice).toFixed(2),
                  });
                }}
                type="number"
                placeholder="Discount"
              />
            </Form.Item>
            <Form.Item
              style={{ marginTop: "1.5rem" }}
              valuePropName="checked"
              name={"is_flat"}
            >
              <Checkbox
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  const tempPrice = form.getFieldValue("price");
                  const tempDiscount = form.getFieldValue("discount");

                  const tempSubTotal = isChecked
                    ? parseFloat(tempPrice) -
                      (parseFloat(tempPrice) * parseFloat(tempDiscount)) / 100
                    : parseFloat(tempPrice) - parseFloat(tempDiscount);

                  const tempGstApplicable =
                    form.getFieldValue("gst_applicable");
                  const tempRadioGst = form.getFieldValue("radio_gst");

                  const tempGst =
                    tempGstApplicable === 1 && tempRadioGst === 1
                      ? parseFloat(tempSubTotal) * 0.1
                      : tempGstApplicable === 1 && tempRadioGst === 0
                      ? parseFloat(tempSubTotal) / 11
                      : 0;

                  const tempTotalPrice =
                    tempRadioGst === 1
                      ? parseFloat(tempSubTotal) + parseFloat(tempGst)
                      : parseFloat(tempSubTotal);

                  form.setFieldsValue({
                    sub_total: Number(tempSubTotal).toFixed(2),
                    gst: tempGst.toFixed(2),
                    total_price: Number(tempTotalPrice).toFixed(2),
                  });
                }}
              >
                Is Percentage?
              </Checkbox>
            </Form.Item>
          </div>
          <Form.Item name={"sub_total"} label="Sub Total">
            <Input readOnly type="number" placeholder="Sub total" />
          </Form.Item>
          <Form.Item name={"gst_applicable"} label="GST Applicable">
            <Radio.Group
              onChange={(e) => {
                const tempGstApplicable = e.target.value;
                setIsGstApp(tempGstApplicable);

                const tempSubTotal = form.getFieldValue("sub_total");
                const tempRadioGst = form.getFieldValue("radio_gst");

                const tempGst =
                  tempGstApplicable === 1 && tempRadioGst === 1
                    ? parseFloat(tempSubTotal) * 0.1
                    : tempGstApplicable === 1 && tempRadioGst === 0
                    ? parseFloat(tempSubTotal) / 11
                    : 0;

                const tempTotalPrice =
                  tempRadioGst === 1
                    ? parseFloat(tempSubTotal) + parseFloat(tempGst)
                    : parseFloat(tempSubTotal);

                form.setFieldsValue({
                  gst: tempGstApplicable === 1 ? Number(tempGst).toFixed(2) : 0,
                  total_price: Number(tempTotalPrice).toFixed(2),
                });
              }}
            >
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {isGstApp === 1 && (
            <div
              style={{
                gridColumn: "span 2",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1rem",
              }}
            >
              <Form.Item name={"radio_gst"}>
                <Radio.Group
                  onChange={(e) => {
                    const tempRadioGst = e.target.value;
                    const tempSubTotal = form.getFieldValue("sub_total");
                    const tempGstApplicable =
                      form.getFieldValue("gst_applicable");

                    // const tempGst = form.getFieldValue("gst");

                    const tempGst =
                      tempGstApplicable === 1 && tempRadioGst === 1
                        ? parseFloat(tempSubTotal) * 0.1
                        : tempGstApplicable === 1 && tempRadioGst === 0
                        ? parseFloat(tempSubTotal) / 11
                        : 0;

                    form.setFieldsValue({
                      gst: Number(tempGst).toFixed(2),
                      total_price:
                        tempRadioGst === 1
                          ? (
                              parseFloat(tempSubTotal) + parseFloat(tempGst)
                            ).toFixed(2)
                          : parseFloat(tempSubTotal).toFixed(2),
                    });
                  }}
                >
                  <Radio value={0}> Price including GST</Radio>
                  <Radio value={1}>Price excluding GST </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item name={"gst"} label="GST">
                <Input type="number" placeholder="GST" />
              </Form.Item>
            </div>
          )}
          <Form.Item name={"total_price"} label="Total Price">
            <Input type="number" placeholder="Total Price" />
          </Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <Form.Item
              style={{ marginTop: "1rem" }}
              valuePropName="checked"
              name={"finance"}
            >
              <Checkbox
                onChange={(e) => {
                  setFinanceSelect(e.target.checked);

                  form.setFieldsValue({
                    payment_terms: e.target.checked === false && null,
                  });
                }}
              >
                Finance Options
              </Checkbox>
            </Form.Item>
            {financeSelect && (
              <Form.Item
                name={"payment_terms"}
                rules={[
                  { required: true, message: "Payment method is required" },
                ]}
                label="Payment Terms"
              >
                <Select placeholder="Select payment Term">
                  {formList?.timelines?.map((data) => (
                    <Option value={data?.threatre_id}>{data?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </div>
          <Form.Item
            name="description"
            label="Project Features"
            style={{ gridColumn: "span 2" }}
          >
            <TextArea rows={8} placeholder="Write here..." />
          </Form.Item>{" "}
          <Form.Item
            name="note"
            label="Special Note"
            style={{ gridColumn: "span 2" }}
          >
            <TextArea rows={3} placeholder="Write here..." />
          </Form.Item>
          <Form.Item name={"timeline"} label="Timeline">
            <Select placeholder="Select Timeline">
              {formList?.timelines?.map((data) => (
                <Option value={data?.threatre_id}>{data?.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ marginTop: "2rem" }}
            valuePropName="checked"
            name={"chk_timeline"}
          >
            <Checkbox> Don't display in quote confirmation</Checkbox>
          </Form.Item>
          <Form.Item name={"docs"} label="Attach Document">
            <Select mode="tags" placeholder="Select Attach Document">
              {formList?.docs?.map((data) => (
                <Option value={data?.content_id}>{data?.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={"txt_files"} label="Attachment">
            <Input type="file" placeholder="Attachment" />
          </Form.Item>
          <div style={{ gridColumn: "span 2", display: "flex", gap: "1rem" }}>
            <Form.Item
              name="testing"
              label="Project Testing"
              style={{ width: "100%" }}
            >
              <TextArea rows={3} placeholder="Write here..." />
            </Form.Item>
            <Form.Item
              style={{ marginTop: "2rem" }}
              valuePropName="checked"
              name={"chk_test"}
            >
              <Checkbox> Don't display in quote confirmation</Checkbox>
            </Form.Item>
          </div>
          <div style={{ gridColumn: "span 2", display: "flex", gap: "1rem" }}>
            <Form.Item
              name="payment"
              label="Payment Terms"
              style={{ width: "100%" }}
            >
              <TextArea rows={3} placeholder="Write here..." />
            </Form.Item>
            <Form.Item
              style={{ marginTop: "2rem" }}
              valuePropName="checked"
              name={"chk_payment"}
            >
              <Checkbox> Don't display in quote confirmation</Checkbox>
            </Form.Item>
          </div>
          <Form.Item name={"quote_status"} label="Quote status">
            <Select placeholder="Select quote status">
              {formList?.status?.map((data) => (
                <Option value={data?.threatre_id}>{data?.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={"quote_from"} label="Quote From">
            <Select placeholder="Select quote from">
              {formList?.quote_from?.map((data) => (
                <Option value={data?.threatre_id}>{data?.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={"about_us"}
            label="How you know about us?
"
          >
            <Select placeholder="Select know about us">
              {formList?.abouts?.map((data) => (
                <Option value={data?.threatre_id}>{data?.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              justifyContent: "flex-start",
            }}
          >
            <Form.Item name="date" label="Quote expiry date">
              <DatePicker format={"DD-MM-YYYY"} />
            </Form.Item>
            <Form.Item name={"contact_by"} label="How may we contact you ?">
              <Radio.Group>
                <Radio value="Phone">Phone</Radio>
                <Radio value="Email">Email</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <Button
            style={{ width: "fit-content", gridColumn: "span 2" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form>
      </div>
    </Drawer>
  );
};

export default AddQuotation;
