import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Radio,
  Select,
  Space,
  message,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

const AddClient = ({
  isAddClientModalVisible,
  setIsAddClientModalVisible,
  fetchCustomerList,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formList, setFormList] = useState(null);
  const [isReferralSelected, setIsReferralSelected] = useState(false);

  useEffect(() => {
    fetchCustomerPresetForm();
  }, []);

  const fetchCustomerPresetForm = () => {
    dispatch({
      type: "FETCH_CUSTOMER_PRESET_FORM",
      payload: {},
      isSuccessFn: true,
      successFunction: (res) => {
        setFormList(res?.data);
      },
      failureFunction: () => {},
    });
  };

  const handleSubmitRecord = (values) => {
    dispatch({
      type: "ADD_CUSTOMER_REQUEST",
      payload: { ...values, branch_id: localStorage.getItem("branchId") },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsAddClientModalVisible(false);
        message.success(res?.data);
        fetchCustomerList();
      },
      failureFunction: () => {},
    });
  };

  return (
    <Drawer
      title="Add Customer"
      open={isAddClientModalVisible}
      onClose={() => setIsAddClientModalVisible(false)}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "0 1rem",
          margin: "0 1.5rem",
        }}
        onFinish={handleSubmitRecord}
        form={form}
      >
        <h2
          style={{
            gridColumn: "span 2",
            background: "#cfcfcf60",
            padding: "0.5rem 1rem",
          }}
        >
          Effective Lead Management System : Customer : [New]
        </h2>
        <Form.Item label="Account Manager" name="account_manager">
          <Select placeholder="Select">
            {formList?.account_managers?.map((data) => (
              <Option value={data?.userid}>
                {data?.first_name} {data?.last_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Company Name" name="company_name">
          <Input placeholder="Company name" />
        </Form.Item>
        <Form.Item label="Phone Number" name="contact_number">
          <Input type="number" placeholder="Phone number" />
        </Form.Item>
        <Form.Item label="Country" name="account_country">
          <Select showSearch placeholder="Select">
            {formList?.countries?.map((data) => (
              <Option value={data?.country_id}>{data?.country_name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="ABN" name="abn">
          <Input placeholder="ABN" />
        </Form.Item>
        <Form.Item label="Referral" name="referral">
          <Select placeholder="Select">
            {formList?.referrals?.map((data) => (
              <Option value={data?.userid}>
                {data?.first_name} {data?.last_name} ({data?.email}){" "}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Name" name="customer_name">
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item label="Sales Rep" name="sales_rep">
          <Select placeholder="Select">
            {formList?.sales_rep?.map((data) => (
              <Option value={data?.userid}>
                {data?.first_name} {data?.last_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Mobile Number" name="mobile_number">
          <Input type="number" placeholder="Mobile Number" />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item valuePropName="checked" name="hide_email">
          <Checkbox> hide in order confirmation and invoice</Checkbox>
        </Form.Item>
        <div style={{ gridColumn: "span 2" }}>
          <Form.List name="more_customer_name">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "3fr 3fr 3fr auto",
                      gap: "1rem",
                      width: "100%",
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "more_customer_name"]}
                      fieldKey={[fieldKey, "more_customer_name"]}
                      rules={[{ required: true, message: "Missing name" }]}
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "more_customer_email"]}
                      fieldKey={[fieldKey, "more_customer_email"]}
                      rules={[
                        { required: true, message: "Missing email" },
                        { type: "email", message: "Invalid email" },
                      ]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "more_mobile_number"]}
                      fieldKey={[fieldKey, "more_mobile_number"]}
                    >
                      <Input placeholder="Mobile Number" />
                    </Form.Item>
                    <MinusCircleOutlined
                      title="Remove"
                      style={{
                        fontSize: "1.5rem",
                        cursor: "pointer",
                        color: "red",
                        marginBottom: "1.5rem",
                      }}
                      onClick={() => remove(name)}
                    />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Contact
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
        <h2
          style={{
            gridColumn: "span 2",
            background: "#cfcfcf60",
            padding: "0.5rem 1rem",
          }}
        >
          Billing Address
        </h2>
        <Form.Item label="Address 1" name="bill_address_1">
          <Input placeholder="Address 1" />
        </Form.Item>
        <Form.Item label="Address 2" name="bill_address_2">
          <Input placeholder="Address 2" />
        </Form.Item>
        <Form.Item label="Suburb" name="bill_suburb">
          <Input placeholder="Suburb" />
        </Form.Item>
        <Form.Item label="Post Code" name="bill_postcode">
          <Input placeholder="Post Code" />
        </Form.Item>
        <Form.Item label="Country" name="bill_country">
          <Select placeholder="Select">
            {formList?.countries?.map((data) => (
              <Option value={data?.country_id}>{data?.country_name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="State" name="bill_state">
          <Select placeholder="Select">
            {formList?.states?.map((data) => (
              <Option value={data?.state_id}>{data?.state_name}</Option>
            ))}
          </Select>
        </Form.Item>
        <h2
          style={{
            gridColumn: "span 2",
            background: "#cfcfcf60",
            padding: "0.5rem 1rem",
          }}
        >
          Delivery Address
        </h2>
        <Form.Item
          name={"billing"}
          style={{ gridColumn: "span 2" }}
          valuePropName="checked"
          onChange={(e) => {
            const isChecked = e.target.checked;

            const tempFormRecord = form.getFieldsValue([
              "bill_address_1",
              "bill_address_2",
              "bill_suburb",
              "bill_postcode",
              "bill_country",
              "bill_state",
            ]);

            form.setFieldsValue(
              isChecked
                ? {
                    delivery_address_1: tempFormRecord?.bill_address_1,
                    delivery_address_2: tempFormRecord?.bill_address_2,
                    delivery_suburb: tempFormRecord?.bill_suburb,
                    delivery_postcode: tempFormRecord?.bill_postcode,
                    delivery_country: tempFormRecord?.bill_country,
                    delivery_state: tempFormRecord?.bill_state,
                  }
                : {
                    delivery_address_1: null,
                    delivery_address_2: null,
                    delivery_suburb: null,
                    delivery_postcode: null,
                    delivery_country: null,
                    delivery_state: null,
                  }
            );
          }}
        >
          <Checkbox>Same as Billing address</Checkbox>
        </Form.Item>
        <Form.Item label="Address 1" name="delivery_address_1">
          <Input placeholder="Address 1" />
        </Form.Item>
        <Form.Item label="Address 2" name="delivery_address_2">
          <Input placeholder="Address 2" />
        </Form.Item>
        <Form.Item label="Suburb" name="delivery_suburb">
          <Input placeholder="Suburb" />
        </Form.Item>
        <Form.Item label="Post Code" name="delivery_postcode">
          <Input placeholder="Post Code" />
        </Form.Item>
        <Form.Item label="Country" name="delivery_country">
          <Select placeholder="Select">
            {formList?.countries?.map((data) => (
              <Option value={data?.country_id}>{data?.country_name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="State" name={"delivery_state"}>
          <Select placeholder="Select">
            {formList?.states?.map((data) => (
              <Option value={data?.state_id}>{data?.state_name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          onChange={(e) => {
            setIsReferralSelected(e.target.checked);
          }}
          valuePropName="checked"
          name="chk_referral"
        >
          <Checkbox> Add to Referral List</Checkbox>
        </Form.Item>
        {isReferralSelected && (
          <Form.Item label="Password" name="txt_password">
            <Input placeholder="Password" />
          </Form.Item>
        )}
        <Button
          style={{ width: "fit-content", gridColumn: "span 2" }}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form>
    </Drawer>
  );
};

export default AddClient;
