import React, { useEffect, useState } from "react";
import SiderDemo from "../../Components/Layout/SiderDemo";
import {
  Badge,
  Button,
  Input,
  Pagination,
  Segmented,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { AiOutlineEye } from "react-icons/ai";
import { MdOutlineEditNote } from "react-icons/md";
import { FaCircleDollarToSlot } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import UpdateOrder from "../orders/UpdateOrder";
import OrderPayment from "../orders/OrderPayment";
import OrderAdminNote from "../orders/OrderAdminNote";
import OrderDetail from "../orders/OrderDetail";
import { debounce } from "lodash";
import SendCombineInvoice from "./SendCombineInvoice";

export default function Invoice() {
  const dispatch = useDispatch();
  const { invoiceList, isLoading } = useSelector(
    (store) => store?.OrderReducer
  );
  const { globalBranchId } = useSelector((store) => store?.ProfileReducer);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isOrderPaymentOpen, setOrderPaymentOpen] = useState(false);
  const [isAddAdminNote, setIsAddAdminNote] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openCombineInvoice, setOpenCombineInvoice] = useState(false);
  const [invoiceNature, setInvoiceNature] = useState("");

  const [record, setRecord] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);

  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
  };

  useEffect(() => {
    fetchInvoiceList();
  }, [page, globalBranchId, invoiceNature]);

  const fetchInvoiceList = (event = null) => {
    dispatch({
      type: "FETCH_INVOICE_LIST_REQUEST",
      payload: {
        page: page,
        keyword: event ? event.target.value : "",
        invoice: invoiceNature,
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setTotalRecord(res?.data?.all_invoices);
      },
    });
  };

  const columns = [
    {
      title: "Job/Support Number",
      align: "center",
      width: 150,
      render: (dataObj) => (
        <Tooltip title="Preview Invoice">
          <a>
            <div
              className="bordered"
              onClick={() => {
                setRecord(dataObj);
                dataObj?.job_type?.toLowerCase() === "order" &&
                  setIsDrawerOpen(true);
              }}
            >
              {dataObj?.invoice_number}
            </div>
          </a>
        </Tooltip>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "customer",
      align: "center",
      width: 150,
    },

    {
      title: "Job Type",
      dataIndex: "job_type",
      align: "center",
      width: 150,
    },
    {
      title: "Title",
      dataIndex: "title",
      align: "center",
      width: 150,
    },
    {
      title: "Price ($)",
      dataIndex: "total_price",
      align: "center",
      width: 150,
      render: (data) => (data ? Number(data).toFixed(2) : ""),
    },
    {
      title: "Paid ($)",
      align: "center",
      width: 150,
      render: (dataObj) => (
        <span>
          {(
            Number(dataObj?.total_price) ??
            0 - Number(dataObj?.due_amount) ??
            0
          ).toFixed(2)}
        </span>
      ),
    },
    {
      title: "Due ($)",
      dataIndex: "due_amount",
      align: "center",
      width: 150,
      render: (data) => (data ? Number(data).toFixed(2) : ""),
    },
    {
      title: "Invoiced Date",
      dataIndex: "expiry_date",
      align: "center",
      width: 150,
      render: (data) => (data ? dayjs(data).format("DD-MM-YYYY") : null),
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      align: "center",
      width: 150,
      render: (data) => (data ? dayjs(data).format("DD-MM-YYYY") : null),
    },

    {
      title: "Invoice Status",
      dataIndex: "invoice_status",
      align: "center",
      width: 150,

      render: (data) => {
        const tempStatus = invoiceList?.invoice_status?.find(
          (inv) => inv?.status_id === data
        );
        return (
          <Tag
            style={{
              background: tempStatus?.color_code ?? "black",
              color: "white",
            }}
          >
            {tempStatus?.status_name}
          </Tag>
        );
      },
    },

    {
      title: "Job/Supprt Status",
      align: "center",
      fixed: "right",
      width: 150,
      dataIndex: "support_status",
    },

    {
      title: "Action",
      align: "center",
      width: 200,
      fixed: "right",
      render: (dataObj) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.8rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Edit">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  dataObj?.job_type?.toLowerCase() === "order" &&
                    setIsEditModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>{" "}
          <Tooltip title="Payment">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  dataObj?.job_type?.toLowerCase() === "order" &&
                    setOrderPaymentOpen(true);
                }}
              >
                <FaCircleDollarToSlot />
              </div>
            </a>
          </Tooltip>
          {/* <Tooltip title="Order detail">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setRecord(dataObj);
                      setIsDrawerOpen(true);
                    }}
                  >
                    <VscPreview />
                  </div>
                </a>
              </Tooltip> */}
          <Tooltip title="Support Seen">
            <Badge showZero count={dataObj?.counter ?? 0} overflowCount={99}>
              <a>
                <div className="bordered">
                  <AiOutlineEye className="bordered" />{" "}
                </div>{" "}
              </a>
            </Badge>
          </Tooltip>
          <Tooltip title="Admin Note">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  dataObj?.job_type?.toLowerCase() === "order" &&
                    setIsAddAdminNote(true);
                }}
              >
                <MdOutlineEditNote style={{ fontSize: "1.2rem" }} />
              </div>
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleDebounceChange = debounce(fetchInvoiceList, 1000);

  return (
    <SiderDemo>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Segmented
          style={{
            marginBottom: "0.5rem",
            width: "fit-content",
          }}
          options={[
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>All [{invoiceList?.all_invoices ?? 0}]</div>
                </div>
              ),
              value: "",
            },

            ...(invoiceList?.invoice_status?.map((data) => ({
              label: (
                <div style={{ padding: 4 }}>
                  <div>
                    {data?.status_name} [{data?.number_invoice ?? 0}]
                  </div>
                </div>
              ),
              value: data?.status_id,
            })) ?? []),
          ]}
          onChange={(value) => {
            setInvoiceNature(value);
          }}
          value={invoiceNature}
        />

        <Input.Search
          className="search"
          style={{ maxWidth: "18rem" }}
          placeholder="Search Invoice"
          allowClear
          size="large"
          onChange={handleDebounceChange}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginBottom: "0.5rem",
        }}
      >
        <Button.Group>
          <Button onClick={() => setOpenCombineInvoice(true)} type="dashed">
            All in one due invoice
          </Button>
          <Button type="primary">Account setting</Button>
        </Button.Group>
      </div>

      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={columns}
          bordered
          scroll={{ x: 1000 }}
          pagination={false}
          dataSource={invoiceList?.invoices ?? []}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Pagination
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            alignSelf: "flex-end",
          }}
          // showSizeChanger
          current={page}
          // defaultPageSize={pageSize}
          onChange={onShowSizeChange}
          total={totalRecord}
        />
      </div>
      {isEditModalVisible && (
        <UpdateOrder
          record={{ ...record, order_id: record?.invoice_id }}
          isEditModalVisible={isEditModalVisible}
          setIsEditModalVisible={setIsEditModalVisible}
          fetchinvoiceList={fetchInvoiceList}
        />
      )}
      {isOrderPaymentOpen && (
        <OrderPayment
          fetchinvoiceList={fetchInvoiceList}
          isOrderPaymentOpen={isOrderPaymentOpen}
          setOrderPaymentOpen={setOrderPaymentOpen}
          record={{ ...record, order_id: record?.invoice_id }}
        />
      )}
      {isAddAdminNote && (
        <OrderAdminNote
          isAddAdminNote={isAddAdminNote}
          setIsAddAdminNote={setIsAddAdminNote}
          record={{ ...record, order_id: record?.invoice_id }}
        />
      )}
      {isDrawerOpen && (
        <OrderDetail
          isDrawerOpen={isDrawerOpen}
          isInvoice={true}
          setIsDrawerOpen={setIsDrawerOpen}
          record={{ ...record, order_id: record?.invoice_id }}
        />
      )}

      {openCombineInvoice && (
        <SendCombineInvoice
          openCombineInvoice={openCombineInvoice}
          setOpenCombineInvoice={setOpenCombineInvoice}
        />
      )}
    </SiderDemo>
  );
}
