import { message } from "antd";
const INIT_STATE = {
  isLoading: false,
  orderList: null,
  orderTaskList: null,
  orderCheckList: null,
  invoiceList: null,
};

const OrderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_ORDER_LIST_REQUEST":
    case "FETCH_INVOICE_LIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ORDER_LIST_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        orderList: action?.payload,
      };
    }
    case "FETCH_ORDER_LIST_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error. unable to fetch orders");
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_INVOICE_LIST_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        invoiceList: action?.payload,
      };
    }
    case "FETCH_INVOICE_LIST_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error. unable to fetch invoices");
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    case "ADD_ORDER_ATTEMPT":
    case "EDIT_ORDER_ATTEMPT": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_ORDER_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! order added.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_ORDER_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to add order.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "EDIT_ORDER_SUCCESS": {
      action.payload.message
        ? message.success(action.payload.message)
        : message.success("Success! order updated.");

      return {
        ...state,
        isLoading: false,
      };
    }
    case "EDIT_ORDER_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to update order.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_ORDER_TASK_ATTEMPT": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ORDER_TASK_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        orderTaskList: action?.payload,
      };
    }
    case "FETCH_ORDER_TASK_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error. unable to fetch orders task");
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_ORDER_CHECKLIST_ATTEMPT": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ORDER_CHECKLIST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        orderCheckList: action?.payload,
      };
    }
    case "FETCH_ORDER_CHECKLIST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error. unable to fetch orders checklist");
      }

      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default OrderReducer;
