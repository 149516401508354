import React, { useState } from "react";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { Table } from "antd";
import AddCompany from "./AddCompany";

export default function CompanyManager() {
  const [isAddCompany, setIsAddCompany] = useState(false);

  const columns = [
    {
      title: "Title",
      align: "center",
    },
    {
      title: "Company Name",
      align: "center",
    },
    {
      title: "Phone",
      align: "center",
    },
    {
      title: "Email",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
    },
  ];

  return (
    <SiderDemo>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <button
          className="button"
          style={{ width: "fit-content" }}
          onClick={() => {
            setIsAddCompany(true);
          }}
        >
          <span>Add</span>
        </button>
        <Table
          columns={columns}
          pagination={false}
          scroll={{ x: 1000 }}
          bordered
        />
      </div>
      {isAddCompany && (
        <AddCompany
          isAddCompany={isAddCompany}
          setIsAddCompany={setIsAddCompany}
        />
      )}
    </SiderDemo>
  );
}
