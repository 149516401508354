import { Button, Form, Input, InputNumber, Modal } from "antd";
import React from "react";

const AddManager = ({
  isAddManagerModalVisible,
  setIsAddManagerModalVisible,
}) => {
  const handleAddManager = (values) => {
    alert("success");
  };
  return (
    <Modal
      title="Add Account Manager"
      footer={false}
      open={isAddManagerModalVisible}
      onCancel={() => {
        setIsAddManagerModalVisible(false);
      }}
    >
      <Form
        layout="vertical"
        style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}
        onFinish={handleAddManager}
      >
        <Form.Item label="First Name" name="firstName">
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item label="Last Name" name="lastName">
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input type="email" placeholder="Email" />
        </Form.Item>
        <Form.Item label="Phone" name="phone">
          <InputNumber
            type="number"
            placeholder="Phone"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item label="Password" name="password">
          <Input.Password type="password" placeholder="password" />
        </Form.Item>
        <Form.Item label="Confirm Password" name="cPassword">
          <Input.Password type="password" placeholder="Confirm password" />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
};

export default AddManager;
