import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Tag,
} from "antd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

const UpdateTask = ({
  isEditModalVisible,
  setIsEditModalVisible,
  record,
  fetchTaskList,
}) => {
  const dispatch = useDispatch();
  const { taskFormData } = useSelector((store) => store?.TaskReducer);

  const [employeeList, setEmployeeList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [totalEmployee, setTotalEmployee] = useState([]);

  useEffect(() => {
    fetchTaskForm();
    fetchProjectEmployee(record?.project_id);
  }, []);

  const fetchTaskForm = () => {
    dispatch({
      type: "FETCH_TASK_FORM_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: (res) => {
        setProjectList(res?.data?.projects);
      },
    });
  };

  const fetchProjectEmployee = (projectId) => {
    dispatch({
      type: "FETCH_PROJECT_EMPLOYEE_REQUEST",
      payload: { id: projectId },
      isSuccessFn: true,
      successFunction: (res) => {
        setEmployeeList(res?.data?.users?.flatMap((obj) => obj.data));
        setTotalEmployee(res?.data?.users?.flatMap((obj) => obj.data));
      },
    });
  };

  const handleEditTask = (values) => {
    dispatch({
      type: "EDIT_TASK_REQUEST",
      payload: {
        id: record?.task_id,
        formData: values,
        branch_id: localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsEditModalVisible(false);
        fetchTaskList();
      },
    });
  };

  return (
    <Drawer
      title="Update Task"
      open={isEditModalVisible}
      onClose={() => {
        setIsEditModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        className="grid-container"
        style={{ margin: "0 1.5rem" }}
        onFinish={handleEditTask}
        initialValues={{
          project: record?.project_id,
          assign_to: record?.assigned_employees?.map((data) => data?.userid),
          title: record?.tasktitle,
          detail: record?.task_detail,
          priority: record?.priority,
          status: record?.task_status,
          start_date: record?.start_date
            ? moment(record?.start_date, "DD-MM-YYYY")
            : null,
          end_date: record?.end_date
            ? moment(record?.end_date, "DD-MM-YYYY")
            : null,
          estimated_hours: parseInt(record?.estimated_hours) ?? null,
        }}
      >
        <Form.Item
          className="grid-full-child"
          name="project"
          label="Project"
          rules={[{ required: true, message: "Project is requried" }]}
        >
          <Select
            onChange={fetchProjectEmployee}
            placeholder="Select Project"
            onSearch={(value) => {
              const searchKey = value.trim();
              const test = taskFormData?.projects?.filter((data) =>
                data.task_name.toLowerCase().includes(searchKey.toLowerCase())
              );
              setProjectList(searchKey === "" ? taskFormData?.projects : test);
            }}
            showSearch
            filterOption={false}
          >
            {projectList?.map((project) => (
              <Select.Option value={project?.id}>
                {project?.task_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Task title is requried" }]}
        >
          <Input placeholder="Enter Title" />
        </Form.Item>

        <Form.Item name="assign_to" label="Assigned User">
          <Select
            // onChange={fetchProjectEmployee}
            mode="multiple"
            placeholder="Select Assigned User"
            onSearch={(value) => {
              const searchKey = value.trim();
              const test = totalEmployee?.filter((data) =>
                `${data?.first_name} ${data?.last_name}`
                  .toLowerCase()
                  .includes(searchKey.toLowerCase())
              );
              setEmployeeList(searchKey === "" ? totalEmployee : test);
            }}
            showSearch
            filterOption={false}
          >
            {employeeList?.map((emp) => (
              <Select.Option value={emp?.userid}>
                {emp?.first_name} {emp?.last_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="detail"
          label="Description"
          rules={[{ required: true, message: "Task detail is requried" }]}
          className="grid-full-child"
        >
          <TextArea rows={8} placeholder="Write here..." />
        </Form.Item>
        <div className="grid-container">
          <Form.Item
            name="priority"
            rules={[{ required: true, message: "Task priority is requried" }]}
            label="Priority"
          >
            <Radio.Group>
              <Radio.Button value="High">High</Radio.Button>
              <Radio.Button value="Low">Low</Radio.Button>
              <Radio.Button value="Medium">Medium</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Estimate Hours" name="estimated_hours">
            <InputNumber type="number" placeholder="Estimate hours" />
          </Form.Item>
        </div>
        <Form.Item
          name="status"
          rules={[{ required: true, message: "Task status is requried" }]}
          label="Support Status"
        >
          <Select placeholder="Select Support Status">
            {taskFormData?.status?.map((stat) => (
              <Select.Option value={stat?.status_id}>
                {stat?.status_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="start_date"
          label="Start Date"
          rules={[{ required: true, message: "Start date is required" }]}
        >
          <DatePicker
            style={{ width: "100%" }}
            format={"DD-MM-YYYY"}
            placeholder="Select Start Date"
          />
        </Form.Item>
        <Form.Item
          name="end_date"
          label="End Date"
          rules={[{ required: true, message: "End date is required" }]}
        >
          <DatePicker
            format={"DD-MM-YYYY"}
            style={{ width: "100%" }}
            placeholder="Start End Date"
          />
        </Form.Item>

        <Button
          style={{ width: "fit-content" }}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form>
    </Drawer>
  );
};

export default UpdateTask;
