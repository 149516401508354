import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  customerList: null,
  customerRecord: null,
};

const ClientsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_CUSTOMER_LIST_REQUEST":
    case "FETCH_CUSTOMER_RECORD_ATTEMPT": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_CUSTOMER_LIST_REQUEST_SUCCESS": {
      return {
        ...state,
        customerList: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CUSTOMER_LIST_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to fetch customer");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_CUSTOMER_RECORD_SUCCESS": {
      return {
        ...state,
        customerRecord: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CUSTOMER_RECORD_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to fetch record");

      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default ClientsReducer;
