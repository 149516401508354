import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ThokyoLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((store) => store?.LoginReducer);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleSumitLoginForm = (values) => {
    dispatch({
      type: "LOGIN_REQUEST_ATTEMPT",
      payload: {
        ...values,
        token: "asdfsda",
        device_type: "web",
        device_identifier: "asdfsdafsda",
        user_type: "company",
      },
      isSuccessFn: true,
      successFunction: (res) => {
        console.log("res login", res);
        const expiryTime = Date.now() + 300000;
        localStorage.setItem("thokyoSessionTime", expiryTime);
        localStorage.setItem("isSessionExpire", "false");
        localStorage.setItem("thokyoUserId", res?.data?.data?.profile?.userid);
        navigate("/dashboard");
      },
    });
  };
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        background: "#1890ff10",
      }}
    >
      <Form
        layout="vertical"
        style={{
          padding: "1.5rem",
          maxWidth: "20rem",
          width: "100%",
          //   border: "1px solid #1890ff40",
          borderRadius: "0.5rem",
          background: "white",
        }}
        onFinish={handleSumitLoginForm}
      >
        <h1 style={{ fontWeight: "normal", color: "#1890ff" }}>
          Sign in to continue
        </h1>
        <Form.Item
          style={{ borderRadius: "0.5rem" }}
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Email is required!",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          style={{ borderRadius: "0.5rem" }}
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Password is required!",
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Button
          htmlType="submit"
          loading={isLoading}
          style={{ width: "100%", borderRadius: "1rem" }}
          type="primary"
        >
          LOGIN
        </Button>
      </Form>
    </div>
  );
};

export default ThokyoLogin;
