import React, { useEffect, useMemo } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Radio, Select } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import AddInstitute from "../../Education/Institute/AddInstitute";
import AddCourse from "../../Courses/AddCourse";
import AddVisaSubCategory from "../AddVisaSubCategory";
import AddDegree from "../AddDegree";
import CaseSummary from "./CaseSummary";
import { scrollToTopFormError } from "../../../HelperFunction";
const { Option } = Select;

const VisaInstituteInfo = ({
  visaCaseData,
  setVisaCaseData,
  current,
  setCurrent,
}) => {
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
      VisaClassState: state.VisaClassReducer,
    };
  });
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [visaSubclassList, setVisaSubclassList] = useState([]);

  useEffect(() => {
    if (visaCaseData?.visaType === "EDUCATION") {
      fetchEducationVisaSubClassRecord();
    } else {
      fetchMigrationVisaSubclassRecord();
    }
  }, []);

  const fetchEducationVisaSubClassRecord = () => {
    dispatch({
      type: "FETCH_EDUCATION_VISAS_REQUEST",
      payload: {
        page: 1,
        pageSize: 100,
        branchId: localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setVisaSubclassList(res?.data?.data);
      },
    });
  };

  const fetchMigrationVisaSubclassRecord = () => {
    dispatch({
      type: "FETCH_MIGRATION_VISAS_REQUEST",
      payload: {
        page: 1,
        pageSize: 100,
        branchId: localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setVisaSubclassList(res?.data?.data);
      },
    });
  };

  useEffect(() => {
    dispatch({ type: "FETCH_LEADS_DROPDOWN_REQUEST" });

    if (
      visaCaseData.countryId === null ||
      visaCaseData.countryId === "" ||
      visaCaseData.countryId === undefined
    ) {
      dispatch({
        type: "FETCH_CASES_DROPDOWN_REQUEST",
        payload: {
          branchId: localStorage.getItem("branchId"),
          fromInstituteVisaCase: true,
          visaSuccessFunction: visaSuccessFunction,
        },
      });
    } else {
      dispatch({
        type: "FETCH_CASES_DROPDOWN_REQUEST",
        payload: {
          branchId: localStorage.getItem("branchId"),
        },
      });
      //added later
      dispatch({
        type: "FETCH_COUNTRY_WORKFLOW_BY_ID_REQUEST",
        payload: {
          id: visaCaseData.countryId,
          visaCategory: visaCaseData.visaType,
        },
        payload2: successFunctionforDefaultWorkflow,
      });
      //later
    }
  }, []);
  useEffect(() => {
    if (visaCaseData.currentStatus && visaCaseData.countryId) {
      filterInstituteBasedOnLocationAndCountry();
    }
  }, [visaCaseData.currentStatus, visaCaseData.countryId]);
  const filterInstituteBasedOnLocationAndCountry = () => {
    dispatch({
      type: "FETCH_INSTITUTE_BASED_ON_LOCATION_AND_COUNTRY_REQUEST",
      payload: {
        location: visaCaseData.currentStatus,
        countryId: visaCaseData.countryId,
      },
    });
    dispatch({ type: "CLEAR_INSTITUTE_SEARCH_DROPDOWN" });
  };
  const [
    isAddVisaSubCategoryModalVisible,
    setIsAddVisaSubCategoryModalVisible,
  ] = useState(false);

  const [isAddCourseModalVisible, setIsAddCourseModalVisible] = useState(false);
  const [isAddDegreeModalVisible, setIsAddDegreeModalVisible] = useState(false);
  const [isAddInstitutionModalVisible, setIsAddInstitutionModalVisible] =
    useState(false);
  const [field1Error, setField1Error] = useState(false);

  const visaSuccessFunction = (response) => {
    response?.data?.country?.map((dataObj) => {
      if (dataObj.isDefault) {
        setVisaCaseData((previousData) => {
          return {
            ...previousData,
            countryId: dataObj.id,
            country: dataObj.name,
            workFlowId: null,
            workFlow: null,
            workFlowStatusId: null,
            workFlowStatus: null,
          };
        });
        dispatch({
          type: "FETCH_COUNTRY_WORKFLOW_BY_ID_REQUEST",
          payload: {
            id: dataObj.id,
            visaCategory: visaCaseData.visaType,
          },
          payload2: successFunctionforDefaultWorkflow,
        });
      }
    });
  };
  const successFunctionforDefaultWorkflow = (response) => {
    response?.data?.data?.map((dataObj) => {
      if (dataObj.isDefault === true) {
        setVisaCaseData((previousData) => {
          return {
            ...previousData,
            workFlowId: dataObj.id,
            workFlow: dataObj.statusName,
            workflowDocumentCheckList: dataObj.documentCheckList,
            documentCheckList: [
              // ...previousData?.documentCheckList,
              ...dataObj?.documentCheckList?.map(
                (data) => data?.documentCheckListId
              ),
            ],
          };
        });
        dispatch({
          type: "FETCH_WORKFLOW_STATUS_BY_ID_REQUEST",
          payload: {
            id: dataObj.id,
            fromVisaCases: true,
            responseFirstnodeSelectFn: responseFirstnodeSelectFn,
          },
        });
      }
    });
  };

  const responseFirstnodeSelectFn = (response) => {
    response?.data?.data?.map((dataObj) => {
      if (dataObj.isFirstState) {
        setVisaCaseData((previousData) => {
          return {
            ...previousData,
            workFlowStatusId: dataObj.id,
            workFlowStatus: dataObj.statusName,
          };
        });
      }
    });
    console.log("responseFirstnodeSelectFn", response);
  };

  const fetchDegreeByInstituteId = (id) => {
    dispatch({
      type: "FETCH_DEGREE_BY_INSTITUTE_ID_REQUEST",
      payload: id,
      isSuccessFn: true,
      successFunction: ({ data }) => {},
    });
  };

  const addVisaCasesSubmitHandler = () => {
    setCurrent(2);
  };
  useMemo(() => {
    let sum = 0;
    // packages.map((item, index) => {
    //   sum = sum + +item.totalPrice;
    //   return sum;
    // });
    if (visaCaseData.visaType === "EDUCATION") {
      if (visaCaseData.currentStatus === "ONSHORE") {
        let discountAmount =
          visaCaseData?.course?.onshoreDiscountType === "PERCENTAGE"
            ? +visaCaseData?.course?.onshoreDiscountAmount *
              0.01 *
              +visaCaseData?.course?.onShoreFee
            : visaCaseData?.course?.onshoreDiscountType === "FLAT"
            ? +visaCaseData?.course?.onshoreDiscountAmount
            : 0;
        let scholarshipAmount =
          visaCaseData?.course?.onshoreScholarshipType === "PERCENTAGE"
            ? +visaCaseData?.course?.onshoreScholarshipAmount *
              0.01 *
              +visaCaseData?.course?.onShoreFee
            : visaCaseData?.course?.onshoreScholarshipType === "FLAT"
            ? +visaCaseData?.course?.onshoreScholarshipAmount
            : 0;
        sum =
          sum +
          +visaCaseData?.course?.onShoreFee -
          discountAmount -
          scholarshipAmount +
          +visaCaseData?.course?.onshoreAdditionalFee +
          +visaCaseData?.course?.onshoreMaterialFee +
          +visaCaseData?.course?.onshoreApplicationFee;
      } else if (visaCaseData.currentStatus === "OFFSHORE") {
        let discountAmount =
          visaCaseData?.course?.offshoreDiscountType === "PERCENTAGE"
            ? +visaCaseData?.course?.offshoreDiscountAmount *
              0.01 *
              +visaCaseData?.course?.offShoreFee
            : visaCaseData?.course?.offshoreDiscountType === "FLAT"
            ? +visaCaseData?.course?.offshoreDiscountAmount
            : 0;
        let scholarshipAmount =
          visaCaseData?.course?.offshoreScholarshipType === "PERCENTAGE"
            ? +visaCaseData?.course?.offshoreScholarshipAmount *
              0.01 *
              +visaCaseData?.course?.offShoreFee
            : visaCaseData?.course?.offshoreScholarshipType === "FLAT"
            ? +visaCaseData?.course?.offshoreScholarshipAmount
            : 0;
        sum =
          sum +
          +visaCaseData?.course?.offShoreFee -
          discountAmount -
          scholarshipAmount +
          +visaCaseData?.course?.offshoreAdditionalFee +
          +visaCaseData?.course?.offshoreMaterialFee +
          +visaCaseData?.course?.offshoreApplicationFee;
      }
    }

    setVisaCaseData((previousData) => {
      return {
        ...previousData,
        fee: sum,
        feeWithoutPackage: sum,
      };
    });
  }, [visaCaseData.course]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexFlow: "wrap",
          gap: "1rem",
          alignItems: "stretch",
        }}
      >
        <div style={{ width: "100%", display: "flex", flex: 1 }}>
          <Form
            layout="vertical"
            onFinish={addVisaCasesSubmitHandler}
            onFinishFailed={scrollToTopFormError}
            colon={true}
            form={form}
            className="drawerStyle"
            initialValues={{
              isScholarshipAllowed: visaCaseData?.isScholarshipAllowed,
              scholarshipType: visaCaseData?.scholarshipType,
              scholorship: visaCaseData?.scholorship,
            }}
            fields={[
              {
                name: ["visaType"],
                value: visaCaseData.visaType,
              },
              {
                name: ["commenceDate"],
                value: visaCaseData.commenceDate,
              },
              {
                name: ["visaSubClassId"],
                value: visaCaseData.visaSubClass,
              },
              {
                name: ["country"],
                value: visaCaseData.country,
              },
              {
                name: ["currentStatus"],
                value: visaCaseData.currentStatus,
              },
              {
                name: ["searchInstitute"],
                value: visaCaseData.searchInstitute,
              },
              {
                name: ["workFlowId"],
                value: visaCaseData.workFlow,
              },
              {
                name: ["workFlowStatusId"],
                value: visaCaseData.workFlowStatus,
              },
              {
                name: ["instituteId"],
                value: visaCaseData.instituteName,
              },

              {
                name: ["degree"],
                value: visaCaseData.degreeName,
              },
              {
                name: ["courseId"],
                value: visaCaseData.courseName,
              },
              {
                name: ["wantToPayFor"],
                value: visaCaseData.wantToPayFor,
              },
              {
                name: ["collegeIntake"],
                value: visaCaseData.Intake,
              },

              {
                name: ["fee"],
                value: visaCaseData.wantToPayFee,
              },
              {
                name: ["netTotalFee"],
                value: visaCaseData?.netTotalFee,
              },
            ]}
          >
            <div
              style={{
                border: "1px solid #cfcfcf",
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
              }}
            >
              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="Country To Study"
                  name={"country"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter country to study!",
                    },
                  ]}
                  help={
                    field1Error ? (
                      <span style={{ color: "red" }}>
                        Please select country first !
                      </span>
                    ) : (
                      ""
                    )
                  }
                >
                  <Select
                    optionFilterProp="children"
                    showSearch
                    filterOption={(input, option) => {
                      return (option?.children?.props?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    onChange={() => {
                      setField1Error(false);
                      setVisaCaseData((prev) => ({
                        ...prev,
                        visaSubClassId: null,
                        visaSubClass: null,
                        workFlow: null,
                        workFlowId: null,
                        workFlowStatusId: null,
                        workFlowStatus: null,
                      }));
                    }}
                  >
                    {store.caseState.caseDropdown?.country?.map((dataObj) => {
                      return (
                        <Option key={dataObj.id}>
                          <div
                            onClick={() => {
                              dispatch({
                                type: "FETCH_COUNTRY_WORKFLOW_BY_ID_REQUEST",
                                payload: {
                                  id: dataObj?.id,
                                  visaCategory: visaCaseData?.visaType,
                                },
                                payload2: successFunctionforDefaultWorkflow,
                              });
                              setVisaCaseData((previousData) => {
                                return {
                                  ...previousData,
                                  countryId: dataObj.id,
                                  country: dataObj.name,

                                  workFlowId: null,
                                  workFlow: null,
                                  workFlowStatusId: null,
                                  workFlowStatus: null,
                                  instituteId: null,
                                  instituteName: null,
                                  degreeId: null,
                                  degreeName: null,
                                  courseId: null,
                                  courseName: null,
                                  courseIntakes: [],
                                  course: null,
                                  IntakeId: null,
                                  Intake: null,
                                  courseFee: null,
                                  fee: 0,
                                };
                              });

                              dispatch({
                                type: "FETCH_COUNTRY_WORKFLOW_BY_ID_REQUEST",
                                payload: {
                                  id: dataObj.id,
                                  visaCategory: visaCaseData.visaType,
                                },
                                payload2: successFunctionforDefaultWorkflow,
                              });
                              dispatch({
                                type: "CLEAR_INSTITUTE_SEARCH_DROPDOWN",
                              });
                            }}
                            className={"selectOptionDiv"}
                          >
                            {dataObj.name}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Application Type"
                  name={"currentStatus"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter current status!",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setVisaCaseData((previousData) => {
                        return {
                          ...previousData,
                          currentStatus: e.target.value,
                          instituteId: null,
                          instituteName: null,
                          degreeId: null,
                          degreeName: null,
                          courseId: null,
                          courseName: null,
                          courseIntakes: [],
                          course: null,
                          IntakeId: null,
                          Intake: null,
                          courseFee: null,
                          fee: 0,
                        };
                      });
                      dispatch({ type: "CLEAR_INSTITUTE_SEARCH_DROPDOWN" });
                    }}
                  >
                    <Radio value={"ONSHORE"}>Onshore</Radio>
                    <Radio value={"OFFSHORE"}>Offshore</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              <div
                className="flexRowWithoutStyle"
                style={{ justifyContent: "space-between", gap: "1rem" }}
              >
                <Form.Item
                  label="Visa Type"
                  name={"visaType"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter visa category!",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setVisaCaseData((previousData) => {
                        return {
                          ...previousData,
                          visaType: e.target.value,

                          workFlow: null,
                          workFlowId: null,
                          workFlowStatusId: null,
                          workFlowStatus: null,
                          visaSubClassId: null,
                          visaSubClass: null,
                        };
                      });
                      if (e.target.value === "MIGRATION") {
                        setVisaCaseData((previousData) => {
                          return {
                            ...previousData,
                            courseId: null,
                          };
                        });
                        fetchMigrationVisaSubclassRecord();
                      } else {
                        fetchEducationVisaSubClassRecord();
                      }

                      if (
                        visaCaseData.countryId !== null &&
                        visaCaseData.countryId !== ""
                      ) {
                        dispatch({
                          type: "FETCH_COUNTRY_WORKFLOW_BY_ID_REQUEST",
                          payload: {
                            id: visaCaseData.countryId,
                            visaCategory: e.target.value,
                          },
                          payload2: successFunctionforDefaultWorkflow,
                        });
                      }
                    }}
                  >
                    <Radio value={"EDUCATION"}>Education</Radio>
                    <Radio value={"MIGRATION"}>Migration</Radio>
                  </Radio.Group>
                </Form.Item>

                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.3rem", width: "100%" }}
                >
                  <Form.Item
                    label="Visa Sub Category"
                    name={"visaSubClassId"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter visa sub category!",
                      },
                    ]}
                  >
                    {visaCaseData.visaType === "EDUCATION" ? (
                      <Select>
                        {/* {store.caseState.caseDropdown?.visaSubClass?.map( */}
                        {visaSubclassList
                          ?.filter(
                            (data) => data?.country === visaCaseData?.country
                          )
                          ?.map((dataObj) => {
                            return (
                              <>
                                <Option key={dataObj.id}>
                                  <div
                                    onClick={() => {
                                      setVisaCaseData((previousData) => {
                                        return {
                                          ...previousData,
                                          visaSubClassId: dataObj.id,
                                          visaSubClass: dataObj.subClass,
                                        };
                                      });
                                    }}
                                    className={"selectOptionDiv"}
                                  >
                                    {dataObj.subClass}
                                  </div>
                                </Option>
                              </>
                            );
                          })}
                      </Select>
                    ) : visaCaseData.visaType === "MIGRATION" ? (
                      <Select>
                        {/* {store.caseState.caseDropdown?.visaSubClassForMigration?.map( */}
                        {visaSubclassList
                          ?.filter(
                            (data) => data?.country === visaCaseData?.country
                          )
                          ?.map?.((dataObj) => {
                            return (
                              <Option key={dataObj.id}>
                                <div
                                  onClick={() => {
                                    setVisaCaseData((previousData) => {
                                      return {
                                        ...previousData,
                                        visaSubClassId: dataObj.id,
                                        visaSubClass: dataObj.subClass,
                                      };
                                    });
                                  }}
                                  className={"selectOptionDiv"}
                                >
                                  {dataObj.subClass}
                                </div>
                              </Option>
                            );
                          })}
                      </Select>
                    ) : null}
                  </Form.Item>
                  <a
                    className="plusButton"
                    onClick={() => {
                      visaCaseData?.country
                        ? setIsAddVisaSubCategoryModalVisible(true)
                        : setField1Error(true);
                    }}
                  >
                    <AiOutlinePlus className="iconColor" />
                  </a>
                </div>
              </div>

              <div
                className="flexRowWithoutStyle"
                style={{
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <div
                  className="flexColumnWithoutStyle"
                  style={{
                    justifyContent: "space-between",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  <Form.Item
                    label="Work Flow"
                    name={"workFlowId"}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter workflow!",
                      },
                    ]}
                  >
                    <Select
                      onChange={(value, option) => {
                        const tempObj = JSON.parse(option?.key);
                        console.log("This is workflow id change", tempObj);
                        setVisaCaseData((prev) => ({
                          ...prev,
                          documentCheckList: [
                            ...tempObj?.documentCheckList?.map(
                              (data) => data?.documentCheckListId
                            ),
                          ],
                        }));
                      }}
                    >
                      {store.caseState.countryWorkflow?.data?.map((dataObj) => {
                        return (
                          <Option
                            key={JSON.stringify(dataObj)}
                            value={dataObj?.id}
                          >
                            <div
                              onClick={(e) => {
                                //   setWorkFlow(dataObj);
                                setVisaCaseData((previousData) => {
                                  return {
                                    ...previousData,
                                    workFlowId: dataObj.id,
                                    workFlow: dataObj.statusName,
                                    workflowDocumentCheckList:
                                      dataObj.documentCheckList,
                                  };
                                });
                                dispatch({
                                  type: "FETCH_WORKFLOW_STATUS_BY_ID_REQUEST",
                                  payload: {
                                    id: dataObj.id,
                                    fromVisaCases: true,
                                    responseFirstnodeSelectFn:
                                      responseFirstnodeSelectFn,
                                  },
                                });
                              }}
                              className={"selectOptionDiv"}
                            >
                              {dataObj.statusName}
                            </div>
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <Form.Item
                  label="Work Flow Status"
                  name={"workFlowStatusId"}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter current status!",
                    },
                  ]}
                >
                  <Select>
                    {store.caseState.workflowStatuses?.data?.map((dataObj) => {
                      return (
                        <Option key={dataObj.id}>
                          <div
                            onClick={() => {
                              setVisaCaseData((previousData) => {
                                return {
                                  ...previousData,
                                  workFlowStatusId: dataObj.id,
                                  workFlowStatus: dataObj.statusName,
                                };
                              });
                            }}
                            className={"selectOptionDiv"}
                          >
                            {dataObj.statusName}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </div>

            {visaCaseData.visaType === "EDUCATION" ? (
              <div
                style={{
                  border: "1px solid #cfcfcf",
                  padding: "1rem",
                  borderRadius: "1rem",
                }}
              >
                <div className="flexCenter">
                  <Form.Item name={"searchInstitute"} style={{ width: "100%" }}>
                    <Select
                      showSearch
                      className="caseSearch"
                      placeholder={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <AiOutlineSearch fontSize={20} />
                          Search institutes
                        </div>
                      }
                      style={{
                        width: "100%",
                        // margin: "1rem 0",
                        borderRadius: "25px",
                      }}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={(e) => {
                        dispatch({
                          type: "SEARCH_INSTITUTE_FOR_COURSES_REQUEST",
                          payload: {
                            keyword: e,
                            shore: visaCaseData.currentStatus,
                            countryId: visaCaseData.countryId,
                            branchId: localStorage.getItem("branchId"),
                          },
                        });
                      }}
                      onChange={(e) => {
                        console.log("handle change", e);
                      }}
                      notFoundContent={null}
                      disabled={
                        visaCaseData.currentStatus === "" ||
                        visaCaseData.currentStatus === null ||
                        visaCaseData.currentStatus === undefined ||
                        visaCaseData.countryId === "" ||
                        visaCaseData.countryId === null ||
                        visaCaseData.countryId === undefined
                      }
                      // options={["anish", "banjara"]}
                    >
                      {store.caseState.searchedInstitutes?.map((dataObj) => {
                        return (
                          <Option key={dataObj.id}>
                            <div
                              onClick={() => {
                                let tempNetTotalFee = null;
                                if (visaCaseData.currentStatus === "ONSHORE") {
                                  if (
                                    dataObj?.onshoreScholarshipType === "FLAT"
                                  ) {
                                    tempNetTotalFee =
                                      dataObj?.onShoreFee -
                                      dataObj?.onshoreScholarshipAmount;
                                  } else {
                                    tempNetTotalFee =
                                      dataObj?.onShoreFee -
                                      dataObj?.onShoreFee *
                                        (dataObj?.onshoreScholarshipAmount /
                                          100);
                                  }
                                } else if (
                                  visaCaseData.currentStatus === "OFFSHORE"
                                ) {
                                  if (
                                    dataObj?.offshoreScholarshipType === "FLAT"
                                  ) {
                                    tempNetTotalFee =
                                      dataObj?.offShoreFee -
                                      dataObj?.offshoreScholarshipAmount;
                                  } else {
                                    tempNetTotalFee =
                                      dataObj?.offShoreFee -
                                      dataObj?.offShoreFee *
                                        (dataObj?.offshoreScholarshipAmount /
                                          100);
                                  }
                                }

                                setVisaCaseData((previousData) => {
                                  return {
                                    ...previousData,
                                    instituteId: dataObj?.institute?.id,
                                    instituteName: dataObj?.institute?.name,
                                    degreeId: dataObj?.degree?.id,
                                    degreeName: dataObj?.degree?.name,
                                    courseId: dataObj?.id,
                                    courseName: dataObj?.name,
                                    courseIntakes:
                                      dataObj?.collegeIntakeResponseList,
                                    course: dataObj,
                                    courseFee:
                                      visaCaseData.currentStatus === "ONSHORE"
                                        ? dataObj.onShoreFee
                                        : visaCaseData.currentStatus ===
                                          "OFFSHORE"
                                        ? dataObj.offShoreFee
                                        : null,
                                    IntakeId: "",
                                    searchInstitute: null,
                                    Intake: "",
                                    fee: 0,
                                    coursesChecklist:
                                      visaCaseData.currentStatus === "OFFSHORE"
                                        ? dataObj?.offShoreCheckList?.map(
                                            (data) => data?.documentCheckListId
                                          )
                                        : visaCaseData.currentStatus ===
                                          "ONSHORE"
                                        ? dataObj?.onShoreCheckList?.map(
                                            (data) => data?.documentCheckListId
                                          )
                                        : [],
                                    netTotalFee: tempNetTotalFee,
                                  };
                                });
                                //   dispatch({
                                //     type: "FETCH_DEGREE_BY_INSTITUTE_REQUEST",
                                //     payload: {
                                //       institute: dataObj.id,
                                //     },
                                //   });
                                //   dispatch({
                                //     type: "CLEAR_COURSE_REQUEST",
                                //   });
                              }}
                              className={"selectOptionDiv"}
                              style={{ fontSize: "12px" }}
                            >
                              {`${dataObj.institute?.name} (Course: ${
                                dataObj?.name
                              }) (Degree: ${
                                dataObj.degree?.name
                              }) (Intake: ${dataObj?.collegeIntakeResponseList?.map(
                                (dataObj, index) =>
                                  `${index === 0 ? "" : ","}${
                                    dataObj.intakeSessionName
                                  }`
                              )}
                        )`}
                              {/* {`${dataObj.institute?.name} (${dataObj?.name} $
                        {dataObj.degree?.name} $
                        {dataObj?.collegeIntakeResponseList?.map(
                          (dataObj, index) =>
                            `${index === 0 ? "" : ","}${
                              dataObj.intakeSessionName
                            }`
                        )}
                        )} */}
                            </div>
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.3rem", width: "100%" }}
                  >
                    <Form.Item
                      label="Institute"
                      name={"instituteId"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter a institute!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a institute"
                        optionFilterProp="children"
                        onSearch={(e) => {
                          console.log("On search Courses", e);
                        }}
                        filterOption={(input, option) => {
                          console.log("input and option", input, option);
                          return (option?.value ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                        onChange={(value, option) => {
                          fetchDegreeByInstituteId(option?.key);
                        }}
                      >
                        {store.caseState.filterInstitutes?.map((dataObj) => {
                          return (
                            <Option
                              key={dataObj.id}
                              value={dataObj.instituteName}
                            >
                              <div
                                onClick={() => {
                                  //   setInstituteName(dataObj.instituteName);

                                  setVisaCaseData((previousData) => {
                                    return {
                                      ...previousData,
                                      instituteId: dataObj.id,
                                      instituteName: dataObj.instituteName,
                                      degreeId: "",
                                      degreeName: "",
                                      courseId: "",
                                      courseName: "",
                                      courseIntakes: [],
                                      course: null,
                                      courseFee: null,
                                      IntakeId: "",
                                      Intake: "",
                                      fee: 0,
                                    };
                                  });
                                  // dispatch({
                                  //   type: "FETCH_DEGREE_BY_INSTITUTE_REQUEST",
                                  //   payload: {
                                  //     institute: dataObj.id,
                                  //   },
                                  // });
                                  dispatch({
                                    type: "CLEAR_COURSE_REQUEST",
                                  });

                                  // dispatch({
                                  //   type: "FETCH_COURSES_BY_INSTITUTE_AND_DEGREE_REQUEST",
                                  //   payload: {
                                  //     institute: dataObj.name,
                                  //     degree: degreeName,
                                  //   },
                                  // });
                                }}
                                className={"selectOptionDiv"}
                              >
                                {dataObj.instituteName}
                              </div>
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <a
                      className="plusButton"
                      onClick={() => {
                        setIsAddInstitutionModalVisible(true);
                      }}
                    >
                      <AiOutlinePlus className="iconColor" />
                    </a>
                  </div>
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.3rem", width: "100%" }}
                  >
                    <Form.Item
                      label="Degree"
                      name={"degree"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Select the degree",
                        },
                      ]}
                    >
                      <Select>
                        {store.caseState?.instituteDegree?.data?.map(
                          (dataObj) => {
                            return (
                              <Option key={dataObj.id}>
                                <div
                                  onClick={() => {
                                    //   setDegreeName(dataObj.degreeName);

                                    setVisaCaseData((previousData) => {
                                      return {
                                        ...previousData,
                                        degreeId: dataObj.id,
                                        degreeName: dataObj.degreeName,
                                        courseId: "",
                                        courseName: "",
                                        courseIntakes: [],
                                        course: null,
                                        IntakeId: "",
                                        Intake: "",
                                        courseFee: null,
                                        fee: 0,
                                      };
                                    });
                                    dispatch({
                                      type: "FETCH_COURSES_BY_INSTITUTE_AND_DEGREE_REQUEST",
                                      payload: {
                                        branchId:
                                          localStorage.getItem("branchId"),
                                        formData: {
                                          institute: visaCaseData.instituteName,
                                          degree: dataObj.degreeName,
                                        },
                                      },
                                    });
                                  }}
                                  className={"selectOptionDiv"}
                                >
                                  {dataObj.degreeName}
                                </div>
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    {/* <a
                      className="plusButton"
                      onClick={() => {
                        setIsAddDegreeModalVisible(true);
                      }}
                    >
                      <AiOutlinePlus className="iconColor" />
                    </a> */}
                  </div>
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <div
                    className="flexRowWithoutStyle"
                    style={{ gap: "0.3rem", width: "100%" }}
                  >
                    <Form.Item
                      label="Course"
                      name={"courseId"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Select the course",
                        },
                      ]}
                    >
                      <Select
                        onChange={(value, option) => {
                          const tempObj = JSON.parse(option?.key);
                          const applicationType =
                            form.getFieldValue("currentStatus");
                          if (applicationType == "OFFSHORE") {
                            setVisaCaseData((prev) => ({
                              ...prev,
                              coursesChecklist: tempObj?.offShoreCheckList?.map(
                                (data) => data?.documentCheckListId
                              ),
                            }));
                          } else if (applicationType == "ONSHORE") {
                            setVisaCaseData((prev) => ({
                              ...prev,
                              coursesChecklist: tempObj?.onShoreCheckList?.map(
                                (data) => data?.documentCheckListId
                              ),
                            }));
                          }
                        }}
                      >
                        {store.caseState.coursesByInstituteAndDegree?.data?.map(
                          (dataObj) => {
                            return (
                              <Option
                                key={JSON.stringify(dataObj)}
                                value={dataObj?.id}
                              >
                                <div
                                  onClick={() => {
                                    let tempNetTotalFee = null;
                                    if (
                                      visaCaseData.currentStatus === "ONSHORE"
                                    ) {
                                      if (
                                        dataObj?.onshoreScholarshipType ===
                                        "FLAT"
                                      ) {
                                        tempNetTotalFee =
                                          dataObj?.onShoreFee -
                                          dataObj?.onshoreScholarshipAmount;
                                      } else {
                                        tempNetTotalFee =
                                          dataObj?.onShoreFee -
                                          dataObj?.onShoreFee *
                                            (dataObj?.onshoreScholarshipAmount /
                                              100);
                                      }
                                    } else if (
                                      visaCaseData.currentStatus === "OFFSHORE"
                                    ) {
                                      if (
                                        dataObj?.offshoreScholarshipType ===
                                        "FLAT"
                                      ) {
                                        tempNetTotalFee =
                                          dataObj?.offShoreFee -
                                          dataObj?.offshoreScholarshipAmount;
                                      } else {
                                        tempNetTotalFee =
                                          dataObj?.offShoreFee -
                                          dataObj?.offShoreFee *
                                            (dataObj?.offshoreScholarshipAmount /
                                              100);
                                      }
                                    }
                                    setVisaCaseData((previousData) => {
                                      return {
                                        ...previousData,
                                        courseId: dataObj.id,
                                        courseName: dataObj.name,
                                        courseIntakes:
                                          dataObj.collegeIntakeResponseList,
                                        course: dataObj,
                                        courseFee:
                                          visaCaseData.currentStatus ===
                                          "ONSHORE"
                                            ? dataObj.onShoreFee
                                            : visaCaseData.currentStatus ===
                                              "OFFSHORE"
                                            ? dataObj.offShoreFee
                                            : null,
                                        netTotalFee: tempNetTotalFee,
                                      };
                                    });
                                  }}
                                  className={"selectOptionDiv"}
                                >
                                  {dataObj.name}
                                </div>
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                    <a
                      className="plusButton"
                      onClick={() => {
                        setIsAddCourseModalVisible(true);
                      }}
                    >
                      <AiOutlinePlus className="iconColor" />
                    </a>
                  </div>
                  {visaCaseData.courseIntakes !== null ? (
                    <Form.Item
                      label="Intake"
                      name={"collegeIntake"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Select the intake",
                        },
                      ]}
                    >
                      <Select>
                        {visaCaseData?.courseIntakes?.map((dataObj) => {
                          return (
                            <Option key={dataObj.id} value={dataObj.id}>
                              <div
                                onClick={() => {
                                  // setCourse(dataObj);
                                  setVisaCaseData((previousData) => {
                                    return {
                                      ...previousData,
                                      IntakeId: dataObj.id,
                                      Intake: dataObj.intakeSessionName,
                                    };
                                  });
                                }}
                                className={"selectOptionDiv"}
                              >
                                {dataObj.intakeSessionName}
                              </div>
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  ) : null}
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{
                    alignItems: "flex-start",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  <Form.Item
                    label="Net Total Fee"
                    name={"netTotalFee"}
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Net Total Fee" readOnly />
                  </Form.Item>
                  <Form.Item
                    label="Student Paying Fee"
                    name={"fee"}
                    style={{ width: "100%" }}
                  >
                    <Input
                      type="number"
                      placeholder="Student Paying Fee"
                      onChange={(e) => {
                        setVisaCaseData((previousData) => {
                          return {
                            ...previousData,
                            wantToPayFee: e.target.value,
                          };
                        });
                      }}
                    />
                  </Form.Item>
                </div>

                <div
                  className="flexRowWithoutStyle"
                  style={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <Form.Item
                    name={"isScholershipAllowed"}
                    valuePropName="checked"
                    style={{ width: "100%" }}
                  >
                    <Checkbox
                      onChange={(e) => {
                        setVisaCaseData((previousData) => {
                          return {
                            ...previousData,
                            isScholarshipAllowed: e.target.checked,
                          };
                        });
                      }}
                    >
                      is Scholarship Allowed?
                    </Checkbox>
                  </Form.Item>
                  {visaCaseData?.isScholarshipAllowed && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          gap: "0.5rem",
                        }}
                      >
                        <Form.Item
                          label="Scholarship amount"
                          name={"scholorship"}
                          style={{ width: "100%" }}
                        >
                          <Input
                            type="number"
                            placeholder="Scholorship"
                            onChange={(e) => {
                              setVisaCaseData((previousData) => {
                                return {
                                  ...previousData,
                                  scholorship: e.target.value,
                                };
                              });
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label={"Type"}
                          name={"scholarshipType"}
                          style={{ width: "100%" }}
                        >
                          <Radio.Group
                            onChange={(e) => {
                              setVisaCaseData((previousData) => {
                                return {
                                  ...previousData,
                                  scholarshipType: e.target.value,
                                };
                              });
                            }}
                          >
                            <Radio value={"FLAT"}>is Flat</Radio>
                            <Radio value={"PERCENTAGE"}>is Percentage</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : null}
            {visaCaseData.visaType === "EDUCATION" && (
              <div
                style={{
                  gap: "1rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #cfcfcf",
                  borderRadius: "1rem",
                  margin: "1rem 0",
                  padding: "1rem",
                }}
              >
                <div
                  // className="flexColumnWithoutStyle"
                  style={{ gap: "0.3rem", width: "100%" }}
                >
                  <Form.Item
                    label="Student want to pay for"
                    name={"wantToPayFor"}
                    style={{ width: "100%", margin: "0" }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Select student wants to pay for",
                    //   },
                    // ]}
                  >
                    <Radio.Group
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        margin: "0 1rem 0 0",
                      }}
                      onChange={(e) => {
                        setVisaCaseData((previousData) => {
                          return {
                            ...previousData,
                            wantToPayFor: e.target.value,
                          };
                        });
                      }}
                    >
                      <Radio value={"MONTHLY"}>Monthly</Radio>
                      <Radio value={"SEMESTER"}>One Semester</Radio>
                      <Radio value={"TRIMESTER"}>Tri-semester</Radio>
                      <Radio value={"YEARLY"}>One Year</Radio>
                      {/* <Radio value={"OTHERS"}>Others</Radio> */}
                    </Radio.Group>
                  </Form.Item>
                  {visaCaseData?.wantToPayFor === "OTHERS" && (
                    <Form.Item
                      name={"wantToPayDuration"}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message:
                            "Please enter the duration that student want to pay for!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter wants to pay for"
                        onChange={(e) => {
                          setVisaCaseData((previousData) => {
                            return {
                              ...previousData,
                              wantToPayDuration: e.target.value,
                            };
                          });
                        }}
                      />
                    </Form.Item>
                  )}
                </div>
                <Form.Item
                  label="Commence Date"
                  name={"commenceDate"}
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select the commence date",
                  //   },
                  // ]}
                >
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      console.log(e);
                      setVisaCaseData((previousData) => {
                        return {
                          ...previousData,
                          commenceDate: e,
                        };
                      });
                    }}
                  />
                </Form.Item>
              </div>
            )}

            <div
              className="flexRowWithoutStyle"
              style={{ justifyContent: "space-between", gap: "1rem" }}
            >
              {current === 1 && (
                <Form.Item>
                  <Button
                    onClick={() => {
                      setCurrent(0);
                    }}
                  >
                    Back
                  </Button>
                </Form.Item>
              )}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Next
                </Button>
              </Form.Item>
            </div>
          </Form>
          {isAddInstitutionModalVisible && (
            <AddInstitute
              fromCasesShoreType={visaCaseData.currentStatus}
              fromCountryId={visaCaseData.countryId}
              isAddInstituteModalVisible={isAddInstitutionModalVisible}
              setIsAddInstituteModalVisible={setIsAddInstitutionModalVisible}
            />
          )}
          {isAddCourseModalVisible && (
            <AddCourse
              fromCases={{
                institute: visaCaseData.instituteName,
                degree: visaCaseData.degreeName,
              }}
              isAddCourseModalVisible={isAddCourseModalVisible}
              setIsAddCourseModalVisible={setIsAddCourseModalVisible}
            />
          )}
          {isAddVisaSubCategoryModalVisible && (
            <AddVisaSubCategory
              fetchEducationVisaSubClassRecord={
                fetchEducationVisaSubClassRecord
              }
              fetchMigrationVisaSubclassRecord={
                fetchMigrationVisaSubclassRecord
              }
              country={visaCaseData?.country}
              isAddVisaSubCategoryModalVisible={
                isAddVisaSubCategoryModalVisible
              }
              setIsAddVisaSubCategoryModalVisible={
                setIsAddVisaSubCategoryModalVisible
              }
            />
          )}
          {isAddDegreeModalVisible && (
            <AddDegree
              fromCases={visaCaseData.instituteId}
              isAddDegreeModalVisible={isAddDegreeModalVisible}
              setIsAddDegreeModalVisible={setIsAddDegreeModalVisible}
            />
          )}
        </div>
        <div
          style={{
            width: "30%",
          }}
        >
          <CaseSummary current={current} visaCaseData={visaCaseData} />
        </div>
      </div>
    </>
  );
};

export default VisaInstituteInfo;
