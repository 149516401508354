import React, { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  Alert,
  Badge,
  Button,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Radio,
  Segmented,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
  message,
} from "antd";
import { MdOutlineDisabledVisible, MdOutlineEditNote } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import AddQuotation from "./AddQuotation";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import { AiOutlineCheckCircle, AiOutlineEye } from "react-icons/ai";
import QuotationDetails from "./QuotationDetails";
import UpdateQuotation from "./UpdateQuotation";
import { FaCopy } from "react-icons/fa6";
import AddDuplicateQuote from "./AddDuplicateQuote";
import ApproveQuoteModal from "./ApproveQuoteModal";
import AddMultiQuotation from "./AddMultiQuote";
import UpdateMultiQuote from "./UpdateMultiQuote";
import CreateDuplicateMultiQuote from "./CreateDuplicateMultiQuote";
import ApproveMultiQuoteModal from "./ApproveMultiQuoteModal";
import AddAdminNote from "./AddAdminNote";
import { VscPreview } from "react-icons/vsc";
import { debounce } from "lodash";

const { confirm } = Modal;

const Quotation = () => {
  const { quoteList, isLoading } = useSelector(
    (store) => store?.QuotationReducer
  );
  const { globalBranchId } = useSelector((store) => store?.ProfileReducer);

  const [isAddQuotationModalVisible, setIsAddQuotationModalVisible] =
    useState(false);
  const [isUpdateQuotationModalVisible, setIsUpdateQuotationModalVisible] =
    useState(false);
  const [isQuotationDetailsModelVisible, setIsQuotationDetailsModelVisible] =
    useState(false);
  const [isAddDuplicateQuote, setIsAddDuplicateQuote] = useState(false);
  const [isAddDuplicateMultiQuote, setIsAddDuplicateMultiQuote] =
    useState(false);

  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isApproveMultiQuote, setIsApproveMultiQuote] = useState(false);

  const [confirmVisible, setConfirmVisible] = useState(false);
  const [updateMultiQuote, setUpdateMultiQuote] = useState(false);

  const [record, setRecord] = useState({});

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [isAddAdminNote, setIsAddAdminNote] = useState(false);
  const [noteType, setNoteType] = useState(null);
  const [quoteNature, setQuoteNature] = useState("");
  const [quoteType, setQuoteType] = useState();
  const [singleQuoteModal, setSingleQuoteModal] = useState(false);
  const [multiQuoteModal, setMultiQuoteModal] = useState(false);

  const [cascadeType, setCascadeType] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    setTotalRecord(quoteList?.total_quotes);
  }, [quoteList]);

  useEffect(() => {
    fetchQuoteList();
  }, [page, quoteNature, globalBranchId]);

  const fetchQuoteList = (event = null) => {
    dispatch({
      type: "FETCH_QUOTE_LIST_REQUEST",
      payload: {
        page: page,
        keyword: event ? event.target.value : "",
        quote_type: quoteNature,
      },
      isSuccessFn: true,
      successFunction: (res) => {},
      failureFunction: (err) => {},
    });
  };

  const handleOk = () => {
    if (quoteType === "single") {
      setSingleQuoteModal(true);
      setQuoteType(null);
    } else if (quoteType === "multiple") {
      setMultiQuoteModal(true);
      setQuoteType(null);
    }
  };

  const columns = [
    {
      title: "Quote Number",
      dataIndex: "quote_number",
      align: "center",
      width: 120,
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      align: "center",
      width: 150,
    },
    {
      title: "Nature of Quote",
      dataIndex: "nature_of_quote",
      align: "center",
      width: 150,
    },
    {
      title: "Total",
      dataIndex: "total_price",
      align: "center",
      width: 150,
    },
    {
      title: "Quote Date",
      dataIndex: "quote_date",
      align: "center",
      width: 150,
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      align: "center",
      width: 150,
    },
    {
      title: "Quote Status",
      align: "center",
      width: 150,
      render: (data) => <Tag color={data?.color_code}>{data?.status}</Tag>,
    },
    {
      title: "Actions",
      align: "center",
      width: 200,
      fixed: "right",
      render: (dataObj) => (
        <Space size="middle">
          <Tooltip title="Update quotation">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  dataObj?.is_multi_optioned === "1"
                    ? setUpdateMultiQuote(true)
                    : setIsUpdateQuotationModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Create quote copy">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  dataObj?.is_multi_optioned === "1"
                    ? setIsAddDuplicateMultiQuote(true)
                    : setIsAddDuplicateQuote(true);
                }}
              >
                <FaCopy />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Approve quotation">
            <a>
              <AiOutlineCheckCircle
                onClick={() => {
                  setRecord(dataObj);
                  dataObj?.is_multi_optioned === "1"
                    ? setIsApproveMultiQuote(true)
                    : setIsApproveModalOpen(true);
                }}
              />
            </a>
          </Tooltip>

          <Tooltip title="Quotation Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setIsQuotationDetailsModelVisible(true);
                }}
              >
                <VscPreview />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="Quote Seen">
            <Badge showZero count={dataObj?.quoteseen} overflowCount={99}>
              <a>
                <div className="bordered">
                  <AiOutlineEye className="bordered" />{" "}
                </div>
              </a>
            </Badge>
          </Tooltip>

          <Tooltip title="Admin Note">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setRecord(dataObj);
                  setNoteType(
                    dataObj?.is_multi_optioned === "1" ? "multi" : "single"
                  );
                  setIsAddAdminNote(true);
                }}
              >
                <MdOutlineEditNote style={{ fontSize: "1.2rem" }} />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };

  const handleDebounceChange = debounce(fetchQuoteList, 1500);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleCascadeTask = () => {
    dispatch({
      type: "CASCADE_ATTEMPT",
      payload: {
        endPoint: "quote_cascade_action",
        formData: {
          ids: selectedRowKeys,
          action: cascadeType,
          branch_id: localStorage.getItem("branchId"),
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data);
        setSelectedRowKeys([]);
        setCascadeType(null);
        fetchQuoteList();
      },
      failureFunction: (res) => {
        message.error(res?.response?.data?.message);
      },
    });
  };

  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Segmented
            style={{
              marginBottom: "0.5rem",
            }}
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>All quotes [{quoteList?.all_quotes}]</div>
                  </div>
                ),
                value: "",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>
                      Need to send quote [{quoteList?.need_to_send_quotes}]
                    </div>
                  </div>
                ),
                value: "17",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Sent quote [{quoteList?.sent_quotes}]</div>
                  </div>
                ),
                value: "19",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>
                      Today expiry quote [{quoteList?.expired_today_quotes}]
                    </div>
                  </div>
                ),
                value: "today",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>
                      Tomorrow expiry quote [
                      {quoteList?.expired_tomorrow_quotes}]
                    </div>
                  </div>
                ),
                value: "tomorrow",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div> Expired quote [{quoteList?.expired_all_quotes}]</div>
                  </div>
                ),
                value: "all",
              },
            ]}
            onChange={(value) => {
              setQuoteNature(value);
            }}
            value={quoteNature}
          />
        </div>
        <div className="flexRow" style={{ justifyContent: "space-between" }}>
          <button className="button" onClick={() => setConfirmVisible(true)}>
            <span>Add Quote</span>
          </button>

          <Input.Search
            className="search"
            style={{ maxWidth: "18rem" }}
            placeholder="Search Quote"
            allowClear
            size="large"
            onChange={handleDebounceChange}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1rem",
          }}
        >
          {selectedRowKeys?.length > 0 && (
            <Alert
              message={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  Do you want to
                  <Select
                    value={cascadeType}
                    onChange={(value) => setCascadeType(value)}
                    placeholder="Select Action"
                    style={{ width: "8rem" }}
                  >
                    <Select.Option value="delete">Delete</Select.Option>
                    <Select.Option value="publish">Publish</Select.Option>
                    <Select.Option value="unpublish">Unpublish</Select.Option>
                  </Select>
                  {cascadeType && (
                    <Popconfirm
                      title={`Do you want to ${cascadeType} record?`}
                      onConfirm={() => {
                        handleCascadeTask();
                      }}
                      onCancel={() => {}}
                      okText="Ok"
                      cancelText="Cancel"
                    >
                      <Button danger type="primary">
                        Ok
                      </Button>
                    </Popconfirm>
                  )}
                </div>
              }
              type="warning"
            />
          )}
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              bordered
              columns={columns}
              dataSource={quoteList?.quotes?.map((data) => ({
                ...data,
                key: data?.quote_id,
              }))}
              pagination={false}
              scroll={{ x: 1000 }}
              rowSelection={rowSelection}
            />
          )}
          <Pagination
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
              alignSelf: "flex-end",
            }}
            current={page}
            onChange={onShowSizeChange}
            total={totalRecord}
          />
        </div>
        <Modal
          title="Choose Quote Type?"
          visible={confirmVisible}
          onCancel={() => setConfirmVisible(false)}
          footer={[
            <Button key="back" onClick={() => setConfirmVisible(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                setConfirmVisible(false);
                handleOk();
              }}
            >
              OK
            </Button>,
          ]}
        >
          <Radio.Group
            onChange={(e) => setQuoteType(e.target.value)}
            value={quoteType}
          >
            <Radio value="single">Single Quote</Radio>
            <Radio value="multiple">Multiple Quote</Radio>
          </Radio.Group>
        </Modal>
      </div>
      {singleQuoteModal && (
        <AddQuotation
          fetchQuoteList={fetchQuoteList}
          isAddQuotationModalVisible={singleQuoteModal}
          setIsAddQuotationModalVisible={setSingleQuoteModal}
        />
      )}

      {multiQuoteModal && (
        <AddMultiQuotation
          fetchQuoteList={fetchQuoteList}
          isAddQuotationModalVisible={multiQuoteModal}
          setIsAddQuotationModalVisible={setMultiQuoteModal}
        />
      )}
      {isQuotationDetailsModelVisible && (
        <QuotationDetails
          record={record}
          isQuotationDetailsModelVisible={isQuotationDetailsModelVisible}
          setIsQuotationDetailsModelVisible={setIsQuotationDetailsModelVisible}
        />
      )}

      {isAddDuplicateMultiQuote && (
        <CreateDuplicateMultiQuote
          record={record}
          isAddDuplicateMultiQuote={isAddDuplicateMultiQuote}
          setIsAddDuplicateMultiQuote={setIsAddDuplicateMultiQuote}
          fetchQuoteList={fetchQuoteList}
        />
      )}

      {isUpdateQuotationModalVisible && (
        <UpdateQuotation
          record={record}
          isUpdateQuotationModalVisible={isUpdateQuotationModalVisible}
          setIsUpdateQuotationModalVisible={setIsUpdateQuotationModalVisible}
          fetchQuoteList={fetchQuoteList}
        />
      )}

      {updateMultiQuote && (
        <UpdateMultiQuote
          updateMultiQuote={updateMultiQuote}
          setUpdateMultiQuote={setUpdateMultiQuote}
          record={record}
          fetchQuoteList={fetchQuoteList}
        />
      )}

      {isAddDuplicateQuote && (
        <AddDuplicateQuote
          record={record}
          isAddDuplicateQuote={isAddDuplicateQuote}
          setIsAddDuplicateQuote={setIsAddDuplicateQuote}
          fetchQuoteList={fetchQuoteList}
        />
      )}

      {isApproveModalOpen && (
        <ApproveQuoteModal
          record={record}
          isApproveModalOpen={isApproveModalOpen}
          setIsApproveModalOpen={setIsApproveModalOpen}
          fetchQuoteList={fetchQuoteList}
        />
      )}

      {isApproveMultiQuote && (
        <ApproveMultiQuoteModal
          record={record}
          isApproveMultiQuote={isApproveMultiQuote}
          setIsApproveMultiQuote={setIsApproveMultiQuote}
          fetchQuoteList={fetchQuoteList}
        />
      )}

      {isAddAdminNote && (
        <AddAdminNote
          isAddAdminNote={isAddAdminNote}
          setIsAddAdminNote={setIsAddAdminNote}
          record={record}
        />
      )}
    </SiderDemo>
  );
};

export default Quotation;
