import { Button, Form, Input, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getCookie } from "../../../Helpers/FrontendHelper";
import axios from "axios";
import { AiOutlinePlus } from "react-icons/ai";
import { useSelector } from "react-redux";
import AddCountry from "../../Leads/Country/AddCountry";
import AddReferral from "../../Leads/Referral/AddReferral";
import { useDispatch } from "react-redux";
import { responsiveArray } from "antd/lib/_util/responsiveObserve";
import { scrollToTopFormError } from "../../../HelperFunction";

export default function ServiceClientInfo({
  serviceType,
  serviceData,
  setServiceData,
  setCurrent,
}) {
  const { Option } = Select;
  const dispatch = useDispatch();
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      caseState: state.VisasReducer,
    };
  });
  const [countryList, setCountryList] = useState([]);
  const [referenceList, setReferenceList] = useState([]);

  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isAddReferralModalVisible, setIsAddReferralModalVisible] =
    useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch({
      type: "FETCH_LEADS_DROPDOWN_REQUEST",
      payload: {
        fromInstituteVisaCase: true,
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setCountryList(res?.data?.countries);
        setReferenceList(res?.data?.references);
      },
    });
    dispatch({
      type: "FETCH_CASES_DROPDOWN_REQUEST",
      payload: {
        branchId: localStorage.getItem("branchId"),
      },
    });
  }, [isAddReferralModalVisible]);

  const handleSubmitCustomerInfo = (values) => {
    setCurrent((prev) => prev + 1);
    setServiceData((prev) => {
      return {
        ...prev,
        ...values,
      };
    });
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmitCustomerInfo}
        onFinishFailed={scrollToTopFormError}
        style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}
        initialValues={{
          clientType: serviceData?.clientType,
          firstName: serviceData?.firstName,
          lastName: serviceData?.lastName,
          email: serviceData?.email,
          mobile: serviceData?.mobile,
          phone: serviceData?.phone,
          address: serviceData?.address,
          referral: serviceData?.referral,
          countryOfResidence: serviceData?.countryOfResidence,
        }}
      >
        {serviceType === "add" && (
          <div
            style={{
              justifyContent: "space-between",
              gap: "1rem",
              display: "flex",
              gridColumn: "span 2",
              flexDirection: "column",
            }}
          >
            <Form.Item
              label="Client Type"
              name={"clientType"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select client type",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => {
                  setServiceData((prev) => {
                    return {
                      ...prev,
                      clientType: e.target.value,
                      customerId: "",
                    };
                  });
                  const clientFormValue = {
                    firstName: "",
                    lastName: "",
                    email: "",
                    mobile: "",
                    phone: "",
                    referral: "",
                    countryOfResidence: "",
                    address: "",
                    customerId: "",
                  };
                  form.setFieldsValue(clientFormValue);
                }}
              >
                <Radio value={"EXISTING"}>Existing Client</Radio>
                <Radio value={"NEW"}>New Client</Radio>
              </Radio.Group>
            </Form.Item>

            {serviceData?.clientType === "EXISTING" && (
              <>
                <Form.Item
                  label="Client"
                  name={"customerId"}
                  style={{ style: "50%" }}
                >
                  <Select
                    showSearch
                    placeholder="Select a Client"
                    optionFilterProp="children"
                    onSearch={(e) => {
                      console.log("On search", e);
                    }}
                    filterOption={(input, option) => {
                      console.log("input and option", input, option);
                      return (option?.value ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    onChange={(value, option) => {
                      console.log("testing case", JSON.parse(option?.key));
                      const tempObj = JSON.parse(option?.key);
                      const clientFormValue = {
                        firstName: tempObj?.firstName,
                        lastName: tempObj?.lastName,
                        email: tempObj?.email,
                        mobile: tempObj?.mobile,
                        phone: tempObj?.phone,
                        referral: tempObj?.referral?.id,
                        countryOfResidence: tempObj?.countryOfResidence,
                        address: tempObj?.address,
                        // customerId: tempObj?.id,
                      };
                      form.setFieldsValue(clientFormValue);
                    }}
                  >
                    {store.caseState.caseDropdown?.client?.map((dataObj) => {
                      return (
                        <Option
                          key={JSON.stringify(dataObj)}
                          // value={`${dataObj?.firstName ?? ""} ${
                          //   dataObj?.lastName ?? ""
                          // } ${
                          //   dataObj.email !== null &&
                          //   dataObj.email !== "" &&
                          //   dataObj.email !== undefined
                          //     ? `(${dataObj.email})`
                          //     : dataObj.mobile !== null &&
                          //       dataObj.mobile !== "" &&
                          //       dataObj.mobile !== undefined
                          //     ? `(${dataObj.mobile})`
                          //     : ""
                          // }`}
                          value={dataObj?.id}
                        >
                          {`${dataObj?.firstName ?? ""} ${
                            dataObj?.lastName ?? ""
                          } ${
                            dataObj.email !== null &&
                            dataObj.email !== "" &&
                            dataObj.email !== undefined
                              ? `(${dataObj.email})`
                              : dataObj.mobile !== null &&
                                dataObj.mobile !== "" &&
                                dataObj.mobile !== undefined
                              ? `(${dataObj.mobile})`
                              : ""
                          }`}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </>
            )}
          </div>
        )}
        <Form.Item
          label="First Name"
          name={"firstName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter first name!",
            },
          ]}
        >
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name={"lastName"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter last name!",
            },
          ]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>

        <Form.Item
          label="Mobile Number"
          name={"mobile"}
          style={{ width: "100%" }}
          rules={[
            {
              pattern: /^[\d]{9,11}$/,
              message: "Mobile number should contain 9 to 11 numbers",
            },
            {
              validator: async (_, value) => {
                const token = getCookie("accessToken");
                const response = await axios.get(
                  `v2/api/client/branch/customer/validate-mobile/${localStorage.getItem(
                    "branchId"
                  )}?mobile=${value}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + token,
                    },
                  }
                );
                if (
                  response?.data === false ||
                  value === "" ||
                  value === null ||
                  value === undefined
                ) {
                  return Promise.resolve();
                } else if (
                  response?.data === true &&
                  serviceData?.clientType === "NEW"
                ) {
                  return Promise.reject(
                    new Error("Entered mobile number already exists")
                  );
                }
              },
            },
          ]}
          validateTrigger={["onBlur"]}
        >
          <Input type="number" placeholder="Mobile Number" />
        </Form.Item>

        <Form.Item
          label="Email"
          name={"email"}
          rules={[
            {
              type: "email",
              message: "Please enter valid email",
            },
            {
              validator: async (_, value) => {
                const token = getCookie("accessToken");
                const response = await axios.get(
                  `v2/api/client/branch/customer/validate-email/${localStorage.getItem(
                    "branchId"
                  )}?email=${value}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + token,
                    },
                  }
                );
                console.log("response frm email", response);
                if (
                  response?.data === false ||
                  value === "" ||
                  value === null ||
                  value === undefined
                ) {
                  return Promise.resolve();
                } else if (
                  response?.data === true &&
                  serviceData?.clientType === "NEW"
                ) {
                  return Promise.reject(
                    new Error("Entered email already exists")
                  );
                }
              },
            },
          ]}
          validateTrigger={["onBlur"]}
        >
          <Input type="email" placeholder="E.g. name@domainname.com" />
        </Form.Item>

        <Form.Item
          label="Phone"
          name={"phone"}
          style={{ width: "100%" }}
          rules={[
            {
              pattern: /^[\d]{9,11}$/,
              message: "Mobile number should contain 9 to 11 numbers",
            },
          ]}
        >
          <Input type="number" placeholder="Phone number" />
        </Form.Item>
        <Form.Item label="Address" name={"address"} style={{ width: "100%" }}>
          <Input placeholder="Address" />
        </Form.Item>

        <div
          className="flexRowWithoutStyle"
          style={{ gap: "0.3rem", width: "100%" }}
        >
          <Form.Item
            label="Country of Residence"
            name={"countryOfResidence"}
            style={{ width: "100%" }}
          >
            <Select
              optionFilterProp="children"
              showSearch
              filterOption={(input, option) => {
                return (option?.children?.props?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {/* {store.leadState?.leadsDropdown?.countries?.map((dataObj) => {
                return (
                  <Option value={dataObj.countryName}>
                    {dataObj.countryName}
                  </Option>
                );
              })} */}

              {countryList?.map((dataObj) => {
                return (
                  <Option value={dataObj.countryName}>
                    {dataObj.countryName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <a
            className="plusButton"
            onClick={() => {
              setIsAddCountryModalVisible(true);
            }}
          >
            <AiOutlinePlus className="iconColor" />
          </a>
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ gap: "0.3rem", width: "100%" }}
        >
          <Form.Item
            label="Referral"
            name={"referral"}
            style={{ width: "100%" }}
          >
            <Select>
              {store.leadState?.leadsDropdown?.references?.map((dataObj) => {
                return (
                  <Option key={dataObj.id}>
                    {dataObj.firstName} {dataObj.lastName}
                  </Option>
                );
              })}
              {/* {referenceList?.map((dataObj) => {
                return (
                  <Option key={dataObj.id}>
                    {dataObj.firstName} {dataObj.lastName}
                  </Option>
                );
              })}{" "} */}
            </Select>
          </Form.Item>
          <a
            className="plusButton"
            onClick={() => {
              setIsAddReferralModalVisible(true);
            }}
          >
            <AiOutlinePlus className="iconColor" />
          </a>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gridColumn: "span 2",
          }}
        >
          <Form.Item
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    (getFieldValue("email") === undefined ||
                      getFieldValue("email") === "") &&
                    (getFieldValue("mobile") === undefined ||
                      getFieldValue("mobile") === "")
                  ) {
                    return Promise.reject(
                      new Error("Please enter either email or mobile number!")
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            hasFeedback
          >
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </Form.Item>
        </div>
      </Form>
      {isAddCountryModalVisible && (
        <AddCountry
          isAddCountryModalVisible={isAddCountryModalVisible}
          setIsAddCountryModalVisible={setIsAddCountryModalVisible}
        />
      )}

      {isAddReferralModalVisible && (
        <AddReferral
          isAddReferralModalVisible={isAddReferralModalVisible}
          setIsAddReferralModalVisible={setIsAddReferralModalVisible}
        />
      )}
    </div>
  );
}
