import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  isAuthenticated: false,
  loginData: {},
  isCompanyAuthenticated: false,
  isCompanyEmailValidated: false,
  isEmailCompanyAuthenticated: false,
  companyLogoDetails: {},
};

const LoginReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "USER_AUTHENTICATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "USER_AUTHENTICATION_REQUEST_SUCCESS": {
      return {
        ...state,
        isAuthenticated: true,
        loginData: action.payload,
        isLoading: false,
      };
    }
    case "USER_AUTHENTICATION_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error! Unable to login.");
      }

      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
      };
    }
    case "COMPANY_AUTHENTICATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "COMPANY_AUTHENTICATION_REQUEST_SUCCESS": {
      if (!action.payload) {
        message.error("Error! Unable to continue with the given domain.");
      }
      return {
        ...state,
        isCompanyAuthenticated: action.payload,
        isLoading: false,
      };
    }
    case "COMPANY_AUTHENTICATION_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      }
      return {
        ...state,
        isCompanyAuthenticated: false,
        isLoading: false,
      };
    }
    case "COMPANY_EMAIL_VALIDATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "COMPANY_EMAIL_VALIDATION_REQUEST_SUCCESS": {
      if (!action.payload) {
        message.error("Error! Unable to continue with the given email.");
      }
      return {
        ...state,
        isCompanyEmailValidated: action.payload,
        isLoading: false,
      };
    }
    case "COMPANY_EMAIL_VALIDATION_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      }
      return {
        ...state,
        isCompanyEmailValidated: false,
        isLoading: false,
      };
    }
    case "COMPANY_EMAIL_AUTHENTICATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "COMPANY_EMAIL_AUTHENTICATION_REQUEST_SUCCESS": {
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
      return {
        ...state,
        isAuthenticated: true,
        loginData: action.payload,
        isLoading: false,
      };
    }
    case "COMPANY_EMAIL_AUTHENTICATION_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error! Unable to login.");
      }
      return {
        ...state,
        isEmailCompanyAuthenticated: false,
        isLoading: false,
      };
    }
    case "COMPANY_DOMAIN_AUTHENTICATION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "COMPANY_DOMAIN_AUTHENTICATION_REQUEST_SUCCESS": {
      return {
        ...state,
        isAuthenticated: true,
        loginData: action.payload,
        isLoading: false,
      };
    }
    case "COMPANY_DOMAIN_AUTHENTICATION_REQUEST_FAILURE": {
      if (action.payload.response?.data?.message) {
        message.error(action.payload.response?.data?.message);
      } else if ((action.payload.message = "Network Error!")) {
        message.error("Network Error");
      } else {
        message.error("Error! Unable to login.");
      }
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
      };
    }
    case "CLEAR_AUTHENTICATION_REQUEST": {
      return {
        ...state,
        isCompanyAuthenticated: false,
        isAuthenticated: false,
        isCompanyEmailValidated: false,
        isLoading: false,
      };
    }
    case "COMPANY_DETAILS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "COMPANY_DETAILS_REQUEST_SUCCESS": {
      return {
        ...state,
        companyLogoDetails: action.payload,
        isLoading: false,
      };
    }
    case "COMPANY_DETAILS_REQUEST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to load company logo.");

      return {
        ...state,
        isLoading: false,
      };
    }

    case "CLEAR_AUTHENTICATION_REQUEST": {
      return {
        ...state,
        isCompanyAuthenticated: false,
        isAuthenticated: false,
        isCompanyEmailValidated: false,
        isLoading: false,
      };
    }
    case "LOGIN_REQUEST_ATTEMPT": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "LOGIN_REQUEST_ATTEMPT_SUCCESS": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "LOGIN_REQUEST_ATTEMPT_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to login");

      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default LoginReducer;
