import React from "react";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { Button } from "antd";

export default function Security() {
  return (
    <SiderDemo>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <div
          style={{
            border: "2px solid #cfcfcf",
            padding: "1rem",
            borderRadius: "1rem",
          }}
        >
          <h3>Two Factor Authentication</h3>
          <p>
            Two factor authentication significantly increase your account
            security by requesting additional six digit when logging in that is
            generated by Authenticator app in your mobile device. If someone was
            obtained your login details they still would not be able to access
            ur account with out this code when two factor authentication is
            enabled.
          </p>
          <p>
            We highly recommended that two factor authentication is enabled on
            your account at all times.
          </p>
          <Button type="primary">Enable Two Factor authentication</Button>
        </div>

        <div
          style={{
            border: "2px solid #cfcfcf",
            padding: "1rem",
            borderRadius: "1rem",
          }}
        >
          <h3>Token Authentication</h3>
          <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            <Button type="primary">Turn Off Token authentication</Button>
            <Button type="primary">Reset Token</Button>
          </div>
        </div>
      </div>
    </SiderDemo>
  );
}
