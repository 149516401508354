import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Tag,
  TimePicker,
} from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { flatMap } from "lodash";

const EditProject = ({
  isEditModalVisible,
  setIsEditModalVisible,
  record,
  fetchProjectList,
}) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { projectFormData } = useSelector((store) => store?.ProjectReducer);
  const [isExisting, setIsExisting] = useState(
    record?.is_existing === "1" ? true : false
  );
  const [companyList, setCompanyList] = useState([]);
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    setOrderList([
      ...projectFormData?.orders,
      {
        product: record?.product,
        company_name: record?.company_name,
        customer_name: record?.customer_name,
        order_id: record?.order_id,
      },
    ]);
  }, [projectFormData]);

  useEffect(() => {
    setCompanyList(
      record?.assigned_employees
        ?.filter((cmp) => cmp?.data?.length > 0 === true)
        ?.map((data) => data?.company_id)
    );

    record?.assigned_employees?.map((cmp) => {
      form.setFieldsValue({
        [cmp?.company_id]: cmp?.data?.map((urs) => urs?.userid),
      });
    });
  }, []);

  useEffect(() => {
    fetchProjectForm();
  }, []);

  const handleCompanyList = (e) => {
    !e.target.checked &&
      form.setFieldsValue({
        [e.target.value]: [],
      });

    e.target.checked
      ? setCompanyList((prev) => [...prev, e.target.value])
      : setCompanyList((prev) => [
          ...prev?.filter((data) => data !== e.target.value),
        ]);
  };

  const fetchProjectForm = () => {
    dispatch({
      type: "FETCH_PROJECT_FORM_REQUEST",
      payload: {},
      isSuccessFn: false,
      successFunction: (res) => {},
    });
  };

  const handleEditProject = (values) => {
    dispatch({
      type: "EDIT_PROJECT_REQUEST",
      payload: {
        id: record?.task_id,
        formData: {
          ...values,
          is_existing_order: values?.is_existing_order === true ? "yes" : "no",
          companies: companyList,
          assign_to:
            companyList?.length > 0
              ? flatMap(companyList?.map((cname) => values?.[cname]))
              : [],
        },
        branch_id: localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsEditModalVisible(false);
        fetchProjectList();
      },
    });
  };

  return (
    <Drawer
      title="Edit Project"
      placement="right"
      open={isEditModalVisible}
      onClose={() => {
        setIsEditModalVisible(false);
      }}
      width={1163}
      footer={null}
    >
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Form
          layout="vertical"
          form={form}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
            maxWidth: "1024px",
            width: "100%",
          }}
          onFinish={handleEditProject}
          initialValues={{
            is_existing_order: record?.is_existing === "1" ? true : false,
            title: record?.task_name,
            status: record?.status_id,
            start_date: record?.start_date
              ? moment(record?.start_date, "DD-MM-YYYY")
              : null,
            end_date: record?.end_date
              ? moment(record?.end_date, "DD-MM-YYYY")
              : null,
            detail: record?.task_detail,
            order: record?.order_id,
          }}
        >
          <Form.Item
            style={{ gridColumn: "span 2" }}
            name="is_existing_order"
            label="Is Existing Order?"
          >
            <Radio.Group onChange={(e) => setIsExisting(e.target.value)}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {isExisting && (
            <Form.Item
              name="order"
              label="Order"
              style={{ gridColumn: "span 2" }}
            >
              <Select
                onSearch={(value) => {
                  const searchKey = value.trim();
                  const tempOrderList = [
                    ...projectFormData?.orders,
                    {
                      product: record?.product,
                      company_name: record?.company_name,
                      customer_name: record?.customer_name,
                      order_id: record?.order_id,
                    },
                  ];
                  const test = tempOrderList?.filter((data) =>
                    data.product
                      ?.toLowerCase()
                      .includes(searchKey.toLowerCase())
                  );
                  setOrderList(searchKey === "" ? tempOrderList : test);
                }}
                showSearch
                filterOption={false}
                placeholder="Select Order"
              >
                {orderList?.map((ord) => (
                  <Select.Option value={ord?.order_id}>
                    {ord?.product}: {ord?.company_name} {ord?.customer_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <span style={{ gridColumn: "span 2" }}>Assign To</span>
          {projectFormData?.users?.map((cmpy) => (
            <div style={{ gridColumn: "span 2" }}>
              <Checkbox
                onChange={handleCompanyList}
                key={cmpy?.company}
                value={cmpy?.company_id}
                checked={companyList?.includes(cmpy?.company_id)}
              >
                {cmpy?.company}
              </Checkbox>
              {companyList?.includes(cmpy?.company_id) && (
                <Form.Item name={`${cmpy?.company_id}`}>
                  <Checkbox.Group
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gap: "0.5rem",
                      marginLeft: "1.5rem",
                    }}
                  >
                    {cmpy?.data?.map((usr) => (
                      <Checkbox value={usr?.userid}>
                        {usr?.first_name} {usr?.last_name}{" "}
                        <Tag>{usr?.employee_type}</Tag>
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              )}
            </div>
          ))}
          <Form.Item
            rules={[{ required: true, message: "Project title is requried" }]}
            name="title"
            label="Project Title"
          >
            <Input placeholder="Project Title" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Project status is requried" }]}
            name="status"
            label="Status"
          >
            <Select placeholder="Select Status">
              {projectFormData?.status?.map((stat) => (
                <Select.Option value={stat?.status_id}>
                  {stat?.status_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="start_date" label="Start Date">
            <DatePicker
              style={{ width: "100%" }}
              format={"DD-MM-YYYY"}
              placeholder="Select Start Date"
            />
          </Form.Item>
          <Form.Item name="end_date" label="End Date">
            <DatePicker
              style={{ width: "100%" }}
              format={"DD-MM-YYYY"}
              placeholder="Start End Date"
            />
          </Form.Item>

          <Form.Item
            name="detail"
            rules={[{ required: true, message: "Project detail is requried" }]}
            label="Project Description"
            style={{ gridColumn: "span 2" }}
          >
            <TextArea rows={8} placeholder="Write here..." />
          </Form.Item>

          <Button
            style={{ width: "fit-content" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form>
      </div>
    </Drawer>
  );
};

export default EditProject;
