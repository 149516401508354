import { Button, Form, Input, Modal, Select, Spin, message } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function AddChecklist({
  isOpenModal,
  setIsOpenModal,
  record,
  fetchChecklists,
}) {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { projectChecklist } = useSelector((store) => store?.ProjectReducer);

  const handleAddProjecTest = (values) => {
    dispatch({
      type: "ADD_PROJECT_CHECKLIST_REQUEST",
      payload: {
        id: record?.task_id,
        formData: { ...values, branch_id: localStorage.getItem("branchId") },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsOpenModal(false);
        message.success("checklist added successful");
        fetchChecklists();
      },
      failureFunction: () => {},
    });
  };

  return (
    <Modal
      open={isOpenModal}
      onCancel={() => setIsOpenModal(false)}
      title="Add Checklist"
      footer={false}
    >
      <Form layout="vertical" onFinish={handleAddProjecTest}>
        <Form.Item label="Checklist" name={"checklists"}>
          <Select
            mode="tags"
            placeholder="Select Checklist"
            options={projectChecklist?.all_checklists?.map((data) => ({
              label: <span>{data?.title}</span>,
              title: data?.title,
              options: data?.checklists?.map((record) => ({
                label: <span>{record?.content}</span>,
                value: record?.content_id,
              })),
            }))}
          />
        </Form.Item>

        <Button htmlType="submit" type="primary">
          Submit
        </Button>
      </Form>
    </Modal>
  );
}
