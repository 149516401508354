import { Button, Form, Input, Modal, Spin, message } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

export default function AddUploadFile({
  isOpenModal,
  setIsOpenModal,
  record,
  fetchUploadfileList,
}) {
  const dispatch = useDispatch();
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    const formData = new FormData();
    formData.append("files", newFile);
    formData.append("branch_id", localStorage.getItem("branchId"));
    formData.append("path", "lms_project");

    setIsUploadLoading(true);
    dispatch({
      type: "UPLOAD_FILE_REQUEST",
      payload: formData,
      isSuccessFn: true,
      successFunction: (res) => {
        setUploadFile(res?.data?.source?.src);
        message.success("Upload file successful");
        setIsUploadLoading(false);
      },
      failureFunction: () => {
        setIsUploadLoading(false);
        message.error("Failed to upload file");
      },
    });
  };

  const handleAddUploadFile = (value) => {
    dispatch({
      type: "ADD_UPLOAD_FILE_REQUEST",
      payload: {
        id: record?.task_id,
        formData: {
          file_title: value?.file_title,
          file_name: uploadFile ?? null,
          branch_id: localStorage.getItem("branchId"),
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success("upload-file is added");
        setIsOpenModal(false);
        fetchUploadfileList();
      },
      failureFunction: () => {
        message.error("failed to add upload-file");
      },
    });
  };

  return (
    <Modal
      open={isOpenModal}
      onCancel={() => setIsOpenModal(false)}
      title="Add project file"
      footer={false}
    >
      <Form onFinish={handleAddUploadFile} layout="vertical">
        <Form.Item label="File Title" name={"file_title"}>
          <Input placeholder="File Title" />
        </Form.Item>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <Form.Item
            rules={[{ required: true, message: "File is required" }]}
            name={"files"}
            label="Add New Attachment"
          >
            <Input
              onChange={handleFileChange}
              placeholder="Upload New Attachment"
              type="file"
            />
          </Form.Item>
          {isUploadLoading && (
            <span>
              <Spin /> Loading
            </span>
          )}
        </div>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
}
