import { Button, Popconfirm, Space, Table, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import AddResource from "./AddResource";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import UpdateResourceStatus from "./UpdateResourceStatus";

export default function Resourse({ record }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [isStatusUpdate, setIsStatusUpdate] = useState(false);
  const [resourceRecord, setResourceRecord] = useState(null);

  const { projectResourceList } = useSelector((store) => store?.ProjectReducer);

  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchProjectResourceList();
  }, [page]);

  useEffect(() => {
    setTotalRecord(projectResourceList?.total_resources);
  }, [projectResourceList]);

  const fetchProjectResourceList = () => {
    dispatch({
      type: "FETCH_PROJECT_RESOURCE_LIST_REQUEST",
      payload: { id: record?.task_id, page: page },
      isSuccessFn: false,
      successFunction: (res) => {},
      failureFunction: (err) => {},
    });
  };

  const deleteRecordData = (record) => {
    dispatch({
      type: "DELETE_RESOURCE_RECORD_ATTEMPT",
      payload: {
        id: record?.id,
        formData: { resource_id: record?.id },
      },
      isSuccessFn: false,
      successFunction: (res) => {
        message.success(res?.data);
        fetchProjectResourceList();
      },
      failureFunction: (err) => {
        message.error(err?.response?.data?.message);
      },
    });
  };

  const columns = [
    {
      title: "Resource Title",
      dataIndex: "resource_title",
      align: "center",
    },
    {
      title: "File",
      dataIndex: "resource_name",
      align: "center",
    },
    {
      title: "Added by",
      align: "center",
      render: (data) => `${data?.first_name} ${data?.last_name}`,
    },
    {
      title: "Added Date",
      dataIndex: "added_date",
      align: "center",
    },
    {
      title: "Actions",
      align: "center",
      fixed: "right",
      render: (dataObj) => (
        <Space size="middle">
          <Tooltip title="Update resource status">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsStatusUpdate(true);
                  setResourceRecord(dataObj);
                }}
              >
                <EditFilled style={{ color: "blue" }} />
              </div>
            </a>
          </Tooltip>
          <Popconfirm
            title={`Do you want to delete record?`}
            onConfirm={() => {
              deleteRecordData(dataObj);
            }}
            onCancel={() => {}}
            okText="Ok"
            cancelText="Cancel"
          >
            <a>
              <div className="bordered">
                <DeleteFilled style={{ color: "red" }} />
              </div>
            </a>
          </Popconfirm>{" "}
        </Space>
      ),
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      {" "}
      <Button
        style={{ width: "fit-content" }}
        type="primary"
        onClick={() => setIsOpenModal(true)}
      >
        Add Resource
      </Button>
      <Table
        columns={columns}
        bordered
        scroll={{ x: 1000 }}
        dataSource={projectResourceList?.resources}
        pagination={false}
      />
      {isOpenModal && (
        <AddResource
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          record={record}
          fetchProjectResourceList={fetchProjectResourceList}
        />
      )}
      {isStatusUpdate && (
        <UpdateResourceStatus
          isStatusUpdate={isStatusUpdate}
          setIsStatusUpdate={setIsStatusUpdate}
          record={resourceRecord}
        />
      )}
    </div>
  );
}
