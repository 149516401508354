import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  checkinList: null,
};

const CheckinReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_CHECKIN_LIST_ATTEMPT": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "FETCH_CHECKIN_LIST_SUCCESS": {
      return {
        ...state,
        checkinList: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_CHECKIN_LIST_FAILURE": {
      action.payload?.response?.data?.message
        ? message.error(action.payload?.response?.data?.message)
        : message.error("Error! Unable to fetch checkin record");

      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default CheckinReducer;
