import { Button, Form, Modal, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
const { Option } = Select;

export default function SendCombineInvoice({
  openCombineInvoice,
  setOpenCombineInvoice,
}) {
  const dispatch = useDispatch();
  const [formList, setFormList] = useState(null);
  const [customerList, setCustomerList] = useState([]);

  useEffect(() => {
    fetchCustomerList();
  }, []);

  const fetchCustomerList = () => {
    dispatch({
      type: "FETCH_QUOTE_PRESET_FORM_REQUEST",
      payload: {},
      isSuccessFn: true,
      successFunction: (res) => {
        setCustomerList(res?.data?.customers);
        setFormList(res?.data);
      },
      failureFunction: (err) => {},
    });
  };

  const handleSubmitCombineInvoice = (values) => {
    dispatch({
      type: "SEND_COMBINE_INVOICE_ATTEMPT",
      payload: { ...values, branch_id: localStorage.getItem("branchId") },
      isSuccessFn: true,
      successFunction: (res) => {
        setOpenCombineInvoice(false);
        message.success("Combine invoice send successfully");
      },
      failureFunction: (err) => {
        message.error(err?.response?.data?.message);
      },
    });
  };

  return (
    <Modal
      open={openCombineInvoice}
      onCancel={() => {
        setOpenCombineInvoice(false);
      }}
      title="Send combine invoice"
      footer={false}
      closable={false}
    >
      <Form
        onFinish={handleSubmitCombineInvoice}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <Form.Item
          style={{ width: "100%" }}
          label="Customer"
          name={"customer_id"}
          rules={[{ required: true, message: "Please select customer" }]}
        >
          <Select
            onSearch={(value) => {
              const searchKey = value.trim();
              const test = formList?.customers?.filter((employee) =>
                employee.customer_name
                  .toLowerCase()
                  .includes(searchKey.toLowerCase())
              );
              setCustomerList(searchKey === "" ? formList?.customers : test);
            }}
            showSearch
            filterOption={false}
            placeholder="Select Customer"
          >
            {customerList?.map((data) => (
              <Option value={data?.customer_id}>{data?.customer_name}</Option>
            ))}
          </Select>
        </Form.Item>
        <div style={{ display: "flex", flexDirection: "row", gap: "0.25rem" }}>
          <Button htmlType="submit" type="primary">
            Send invoice
          </Button>
          <Button onClick={() => setOpenCombineInvoice(false)} type="danger">
            Cancel{" "}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
