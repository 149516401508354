import React, { useState } from "react";
import SiderDemo from "../../Components/Layout/SiderDemo";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import { Input, Segmented, Space, Table, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export default function Users() {
  const [isAddUser, setIsAddUser] = useState(false);
  const [isEditUser, setIsEditUser] = useState(false);

  const data = [{ user_no: 1 }];

  const columns = [
    {
      title: "User No",
      dataIndex: "user_no",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "center",
    },
    {
      title: "Group",
      dataIndex: "group",
      align: "center",
    },
    {
      title: "User since",
      dataIndex: "user_since",
      align: "center",
    },
    {
      title: "Last login",
      dataIndex: "last_login",
      align: "center",
    },
    {
      title: "Actions",
      align: "center",
      render: (dataObj) => (
        <Space
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <Tooltip title="Edit user">
            <EditOutlined
              onClick={() => {
                setIsEditUser(true);
              }}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
          <Tooltip title="Delete user record">
            <DeleteOutlined style={{ cursor: "pointer", color: "red" }} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <SiderDemo>
      <div className="leadFlexRow">
        <button
          className="button"
          onClick={() => {
            setIsAddUser(true);
          }}
        >
          <span>Add User</span>
        </button>

        <Segmented
          style={{
            marginBottom: "0.5rem",
          }}
          options={[
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>Active</div>
                </div>
              ),
              value: "active",
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>Inactive</div>
                </div>
              ),
              value: "inactive",
            },
          ]}
          onChange={(value) => {}}
        />
        <Input.Search
          size="large"
          style={{ width: "15rem" }}
          placeholder="Search user"
        />
      </div>

      <Table
        style={{ marginTop: "1rem" }}
        columns={columns}
        pagination={false}
        scroll={{ x: 1000 }}
        dataSource={data}
      />

      {isAddUser && (
        <AddUser isAddUser={isAddUser} setIsAddUser={setIsAddUser} />
      )}

      {isEditUser && (
        <EditUser isEditUser={isEditUser} setIsEditUser={setIsEditUser} />
      )}
    </SiderDemo>
  );
}
