import React, { useEffect, useState } from "react";
import SiderDemo from "../../Components/Layout/SiderDemo";
import AddProject from "./AddProject";
import {
  Alert,
  Button,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import EditProject from "./EditProject";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { EditOutlined, PoweroffOutlined } from "@ant-design/icons";
import { AiOutlineEye } from "react-icons/ai";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import ProjectDetails from "./ProjectDetail";
import Token from "../../Components/tokenComponent/Token";
import { debounce } from "lodash";

export default function Project() {
  const dispatch = useDispatch();
  const { isLoading, projectListData } = useSelector(
    (store) => store?.ProjectReducer
  );
  const { globalBranchId } = useSelector((store) => store?.ProfileReducer);

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [record, setRecord] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [projectStatus, setProjectStatus] = useState("10");
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [cascadeType, setCascadeType] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    projectStatus === "10" && setTotalRecord(projectListData?.projects_pending);
    projectStatus === "" && setTotalRecord(projectListData?.projects_all);
    projectStatus === "11" &&
      setTotalRecord(projectListData?.projects_inprogress);

    projectStatus === "13" &&
      setTotalRecord(projectListData?.projects_completed);

    projectStatus === "deadline-exceeded" &&
      setTotalRecord(projectListData?.projects_deadline_exceeded);
  }, [projectListData]);

  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage(current);
    setPageSize(pageSize);
  };

  useEffect(() => {
    fetchProjectList();
  }, [page, globalBranchId, projectStatus]);

  const fetchProjectList = (event = null) => {
    dispatch({
      type: "FETCH_PROJECT_LIST_REQUEST",
      payload: {
        page: page,
        keyword: event ? event.target.value : "",
        status: projectStatus,
      },
      isSuccessFn: false,
      successFunction: (res) => {},
      failureFunction: (err) => {
        err?.response?.data?.message === "Pin code expired." &&
          setIsTokenExpired(true);
      },
    });
  };

  useEffect(() => {
    fetchProjectForm();
  }, []);

  const fetchProjectForm = () => {
    dispatch({
      type: "FETCH_PROJECT_FORM_REQUEST",
      payload: {},
      isSuccessFn: false,
      successFunction: (res) => {},
    });
  };

  const handleCascadeTask = () => {
    dispatch({
      type: "CASCADE_PROJECT_ATTEMPT",
      payload: {
        ids: selectedRowKeys,
        action: cascadeType,
        branch_id: localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data);
        setSelectedRowKeys([]);
        setCascadeType(null);
        fetchProjectList();
      },
      failureFunction: (res) => {
        message.error(res?.response?.data?.message);
      },
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const columns = [
    {
      title: "S.N",
      dataIndex: "task_id",
      align: "center",
    },
    {
      title: "Project Title",
      dataIndex: "task_name",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status_name",
      align: "center",
    },
    {
      title: "Assign To",
      dataIndex: "assigned_employees",
      align: "center",
      width: 250,
      render: (assigned_employees) =>
        assigned_employees?.map((usr) =>
          usr?.data?.map((user) => (
            <Tag style={{ margin: "0.2rem 0.2rem" }}>
              {user?.first_name} {user?.last_name}
            </Tag>
          ))
        ),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      align: "center",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (dataObj) => (
        <Space size="middle">
          <Tooltip title="Update Project">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsEditModalVisible(true);
                  setRecord(dataObj);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip>

          <Tooltip title="Project Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsDrawerOpen(true);
                  setRecord(dataObj);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleDebounceChange = debounce(fetchProjectList, 1500);

  return (
    <SiderDemo>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Segmented
          style={{
            height: "fit-content",
            width: "fit-content",
            alignSelf: "flex-end",
          }}
          // options={statusList?.map((data) => ({
          //   label: (
          //     <div style={{ padding: 4 }}>
          //       <div>
          //         {data?.status_name} [
          //         {data?.status_name === "Pending"
          //           ? projectListData?.projects_pending
          //           : data?.status_name === "In Progress"
          //           ? projectListData?.projects_inprogress
          //           : data?.status_name === "Completed"
          //           ? projectListData?.projects_completed
          //           : data?.status_name === "Deadline Exceeded"
          //           ? projectListData?.projects_deadline_exceeded
          //           : 0}
          //         ]
          //       </div>
          //     </div>
          //   ),
          //   value: data?.status_id,
          // }))}
          value={projectStatus}
          onChange={(value) => {
            setProjectStatus(value);
          }}
          options={[
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>Pending [{projectListData?.projects_pending ?? 0}]</div>
                </div>
              ),
              value: "10",
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>
                    In Progress [{projectListData?.projects_inprogress ?? 0}]
                  </div>
                </div>
              ),
              value: "11",
            },

            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>
                    Completed [{projectListData?.projects_completed ?? 0}]
                  </div>
                </div>
              ),
              value: "13",
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>
                    Deadline Exceeded[
                    {projectListData?.projects_deadline_exceeded ?? 0}]
                  </div>
                </div>
              ),
              value: "deadline-exceeded",
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>ALL [{projectListData?.projects_all ?? 0}]</div>
                </div>
              ),
              value: "",
            },
          ]}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <button
              className="button"
              onClick={() => {
                setIsAddModalVisible(true);
              }}
            >
              <span>Add Project</span>
            </button>
          </div>{" "}
          <Input.Search
            className="search"
            style={{ maxWidth: "18rem" }}
            placeholder="Search Project"
            allowClear
            size="large"
            onChange={handleDebounceChange}
          />
        </div>
        {selectedRowKeys?.length > 0 && (
          <Alert
            message={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                Do you want to
                <Select
                  value={cascadeType}
                  onChange={(value) => setCascadeType(value)}
                  placeholder="Select Action"
                  style={{ width: "8rem" }}
                >
                  <Select.Option value="delete">Delete</Select.Option>
                  <Select.Option value="publish">Publish</Select.Option>
                  <Select.Option value="unpublish">Unpublish</Select.Option>
                </Select>
                {cascadeType && (
                  <Popconfirm
                    title={`Do you want to ${cascadeType} record?`}
                    onConfirm={() => {
                      handleCascadeTask();
                    }}
                    onCancel={() => {}}
                    okText="Ok"
                    cancelText="Cancel"
                  >
                    <Button danger type="primary">
                      Ok
                    </Button>
                  </Popconfirm>
                )}
              </div>
            }
            type="warning"
          />
        )}
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <Table
            bordered
            pagination={false}
            columns={columns}
            rowSelection={rowSelection}
            dataSource={projectListData?.projects?.map((data) => ({
              ...data,
              key: data?.task_id,
            }))}
          />
        )}
        <Pagination
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            alignSelf: "flex-end",
          }}
          current={page}
          onChange={onShowSizeChange}
          total={totalRecord}
        />
      </div>
      {isAddModalVisible && (
        <AddProject
          isAddModalVisible={isAddModalVisible}
          setIsAddModalVisible={setIsAddModalVisible}
          fetchProjectList={fetchProjectList}
        />
      )}
      {isEditModalVisible && (
        <EditProject
          record={record}
          isEditModalVisible={isEditModalVisible}
          setIsEditModalVisible={setIsEditModalVisible}
          fetchProjectList={fetchProjectList}
        />
      )}

      {isDrawerOpen && (
        <ProjectDetails
          Record={record}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      )}

      {isTokenExpired && (
        <Token
          isTokenExpired={isTokenExpired}
          setIsTokenExpired={setIsTokenExpired}
        />
      )}
    </SiderDemo>
  );
}
