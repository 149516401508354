import { Button, DatePicker, Form, Input, Modal, message } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export default function AddAdminNote({
  isAddAdminNote,
  setIsAddAdminNote,
  record,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [quoteRecord, setQuoteRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchQuoteEditForm();
  }, []);

  useEffect(() => {
    quoteRecord &&
      form.setFieldsValue({
        reminder_date:
          moment(quoteRecord?.quote_detail?.remainder_date) ?? null,
      });
  }, [quoteRecord]);

  const fetchQuoteEditForm = () => {
    setIsLoading(true);
    dispatch({
      type: "FETCH_QUOTE_EDIT_FORM_ATTEMPT",
      payload: record?.quote_id,
      isSuccessFn: true,
      successFunction: (res) => {
        setQuoteRecord(res?.data);
        setIsLoading(false);
      },
      failureFunction: (err) => {
        setIsLoading(false);
      },
    });
  };

  const handleSubmitAdminNote = (values) => {
    dispatch({
      type: "ADD_ADMIN_NOTE_ATTEMPT",
      payload: {
        id: record?.quote_id,
        formData: {
          ...values,
          branch_id: localStorage.getItem("branchId"),
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data);
        setIsAddAdminNote();
      },
      failureFunction: (err) => {},
    });
  };
  return (
    <Modal
      title="Add Admin Note"
      open={isAddAdminNote}
      onCancel={() => setIsAddAdminNote(false)}
      width={500}
      footer={false}
    >
      <Form form={form} onFinish={handleSubmitAdminNote} layout="vertical">
        <Form.Item label="Reminder Date" name="reminder_date">
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Admin note is required" }]}
          label="Admin Note"
          name="admin_note"
        >
          <Input.TextArea rows={4} placeholder="Write..." />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
}
