import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Tag,
} from "antd";
import { useDispatch } from "react-redux";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import client from "./../../Assets/client.png";

import { GoLocation } from "react-icons/go";

import { useSelector } from "react-redux";
import QuotationCard from "./QuotationCard";
import { BsChatLeftText } from "react-icons/bs";
import SendComposedMail from "../Mailbox/SendComposedMail";
import { UserOutlined } from "@ant-design/icons";
import AdminNoteList from "./AdminNoteList";

const QuotationDetails = ({
  record,
  isQuotationDetailsModelVisible,
  setIsQuotationDetailsModelVisible,
}) => {
  const { isLoading, quoteById } = useSelector(
    (store) => store?.QuotationReducer
  );

  const dispatch = useDispatch();
  useEffect(() => {
    fetchQuotationById();
  }, []);

  const fetchQuotationById = () => {
    dispatch({
      type: "FETCH_QUOTAtION_BY_ID_REQUEST",
      payload: {
        quoteId: record?.quote_id,
      },
    });
  };
  const [form] = Form.useForm();
  const [current, setCurrent] = useState("quotation");

  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);
  const [isClientDetailsModelVisible, setIsClientDetailsModelVisible] =
    useState(false);
  const [isStatusHistoryModelVisible, setIsStatusHistoryModelVisible] =
    useState(false);

  const clientDetailsTabList = [
    {
      key: "quotation",
      tab: "Quotation ",
    },
    { key: "adminNote", tab: "Admin Note" },
  ];

  return (
    <Drawer
      title="Quote Details"
      open={isQuotationDetailsModelVisible}
      onClose={() => {
        setIsQuotationDetailsModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card
        loading={isLoading}
        style={{ borderRadius: "0.5rem" }}
        className="drawerStyle"
      >
        <div
          className="flexRowWithoutStyle"
          style={{ gap: "1rem", alignItems: "flex-start" }}
        >
          <Image src={client} className={"clientImage"} />

          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "space-between",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              height: "10rem",
              width: "100%",
              gap: "0.5rem",
            }}
          >
            <span style={{ position: "absolute", top: 0, right: 0 }}>
              <Tag color={record?.color_code}>{record?.status}</Tag>
            </span>
            <div>
              <div style={{ fontSize: "20px", fontWeight: "bold" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {" "}
                <span className="flexRowWithGap">
                  <UserOutlined
                    style={{
                      fontSize: "20",
                    }}
                  />
                  {quoteById?.quote_customer?.customer_name
                    ? quoteById?.quote_customer?.customer_name
                    : "N/A"}
                </span>
                <span className="flexRowWithGap">
                  <AiOutlinePhone
                    style={{
                      fontSize: "18",
                    }}
                  />
                  {quoteById?.quote_customer?.mobile_number
                    ? quoteById?.quote_customer?.mobile_number
                    : "N/A"}
                </span>
                <span className="flexRowWithGap">
                  <GoLocation
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {quoteById?.quote_customer?.billing_address1
                    ? quoteById?.quote_customer?.billing_address1
                    : "N/A"}
                </span>
                <span className="flexRowWithGap">
                  <AiOutlineMail
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {quoteById?.quote_customer?.email
                    ? quoteById?.quote_customer?.email
                    : "N/A"}
                </span>
              </div>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
              }}
            >
              <button className="emailAndMessage" onClick={() => {}}>
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>
              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Email</span>
              </button>
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "quotation" && (
            <QuotationCard
              fetchQuotationById={fetchQuotationById}
              record={quoteById}
            />
          )}
          {current === "adminNote" && <AdminNoteList record={quoteById} />}
        </Card>
      </Card>
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          urlPath={`/send_quote_email/${record?.quote_id}`}
          emailList={[quoteById?.quote_customer?.email]}
          forWhatId={quoteById?.quote?.quote_id}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
          emailType="quote"
          // formDataUrl={`/quote_email/${record?.quote_id}`}
        />
      )}
    </Drawer>
  );
};

export default QuotationDetails;
