import { Button, Form, Input, Modal, Spin, message } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

export default function AddProjectTesting({
  isOpenModal,
  setIsOpenModal,
  record,
  fetchProjectTestList,
}) {
  const dispatch = useDispatch();
  const [uploadFile, setUploadFile] = useState(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  console.log("record", record);

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    const formData = new FormData();
    formData.append("files", newFile);
    formData.append("path", "lms_project");

    formData.append("branch_id", localStorage.getItem("branchId"));
    setIsUploadLoading(true);
    dispatch({
      type: "UPLOAD_FILE_REQUEST",
      payload: formData,
      isSuccessFn: true,
      successFunction: (res) => {
        setUploadFile(res?.data?.source?.src);
        message.success("Upload file successful");
        setIsUploadLoading(false);
      },
      failureFunction: () => {
        setIsUploadLoading(false);
        message.error("Failed to upload file");
      },
    });
  };

  const handleAddProjecTest = (values) => {
    dispatch({
      type: "ADD_PROJECT_TEST_REQUEST",
      payload: {
        id: record?.task_id,
        formData: {
          ...values,
          file_name: uploadFile ?? null,
          branch_id: localStorage.getItem("branchId"),
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsOpenModal(false);
        fetchProjectTestList();
      },
      failureFunction: () => {},
    });
  };

  return (
    <Modal
      open={isOpenModal}
      onCancel={() => setIsOpenModal(false)}
      title="Add Test"
      footer={false}
    >
      <Form layout="vertical" onFinish={handleAddProjecTest}>
        <Form.Item
          rules={[{ required: true, message: "Test description is required" }]}
          label="Test Description"
          name={"testing_description"}
        >
          <Input.TextArea rows={4} placeholder="Write test description" />
        </Form.Item>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <Form.Item label="Upload File" name={"file_name"}>
            <Input onChange={handleFileChange} type="file" />
          </Form.Item>
          {isUploadLoading && (
            <span>
              <Spin /> Loading
            </span>
          )}
        </div>
        <Button htmlType="submit" type="primary">
          Submit
        </Button>
      </Form>
    </Modal>
  );
}
