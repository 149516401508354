import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Spin,
  TimePicker,
  Upload,
  message,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

export default function UpdateLeadStatus({
  isStatusUpdateOpen,
  setIsStatusUpdateOpen,
  record,
  fetchLeadDetailData,
}) {
  const dispatch = useDispatch();
  const [uploadFile, setUploadFile] = useState(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const handleAddStatusUpdate = (values) => {
    dispatch({
      type: "ADD_LEADS_STATUS_UPDATE_REQUEST",
      payload: {
        id: record?.lead?.lead_id,
        formData: {
          ...values,
          branch_id: localStorage.getItem("branchId"),
          file_name: uploadFile ?? null,
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success("Lead status update added");
        setIsStatusUpdateOpen(false);
        fetchLeadDetailData();
      },
    });
  };

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    const formData = new FormData();
    formData.append("files", newFile);
    formData.append("branch_id", localStorage.getItem("branchId"));
    formData.append("path", "lms_project");
    setIsUploadLoading(true);
    dispatch({
      type: "UPLOAD_FILE_REQUEST",
      payload: formData,
      isSuccessFn: true,
      successFunction: (res) => {
        setUploadFile(res?.data?.source?.src);
        message.success("Upload file successful");
        setIsUploadLoading(false);
      },
      failureFunction: () => {
        setIsUploadLoading(false);
        message.error("Failed to upload file");
      },
    });
  };

  return (
    <Modal
      open={isStatusUpdateOpen}
      onCancel={() => {
        setIsStatusUpdateOpen(false);
      }}
      title="Update Lead Status"
      footer={false}
    >
      <Form
        onFinish={handleAddStatusUpdate}
        layout="vertical"
        className="grid-container"
      >
        <Form.Item label="Remainder Date" name="reminder_date">
          <DatePicker format={"DD-MM-YYYY"} />
        </Form.Item>
        <Form.Item label="Remainder Time" name="reminder_time">
          <TimePicker format={"hh:mm a"} />
        </Form.Item>
        <Form.Item
          label="Todays Update"
          name="comment"
          className="grid-full-child"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            alignItems: "center",
          }}
          className="grid-full-child"
        >
          {" "}
          <Form.Item name="file_name" label="Attachment">
            <Input type="file" onChange={handleFileChange} />
          </Form.Item>
          {isUploadLoading && (
            <span>
              <Spin /> Loading
            </span>
          )}
        </div>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
}
