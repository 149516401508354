import { message } from "antd";

const INIT_STATE = {
  isLoading: false,
  eventForm: "",
  eventList: "",
  eventById: "",
};

const EventsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_EVENT_FORM_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_EVENT_FORM_REQUEST_SUCCESS": {
      return {
        ...state,
        eventForm: action.payload,
        isLoading: false,
      };
    }
    case "FETCH_EVENT_FORM_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "ADD_EVENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "ADD_EVENT_REQUEST_SUCCESS": {
      message.success("Event added successfully");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "ADD_EVENT_REQUEST_FAILURE": {
      message.error(action?.payload?.message);
      return {
        ...state,
        isLoading: false,
      };
    }

    case "EDIT_EVENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "EDIT_EVENT_REQUEST_SUCCESS": {
      message.success("Event update successfully");
      return {
        ...state,
        isLoading: false,
      };
    }
    case "EDIT_EVENT_REQUEST_FAILURE": {
      message.error(action?.payload?.message);
      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_ALL_EVENTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_ALL_EVENTS_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        eventList: action?.payload,
      };
    }
    case "FETCH_ALL_EVENTS_REQUEST_FAILURE": {
      message?.error(action?.payload?.response?.data?.message);
      return {
        ...state,
        isLoading: false,
      };
    }

    case "FETCH_EVENT_DETAILS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "FETCH_EVENT_DETAILS_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        eventById: action?.payload,
      };
    }
    case "FETCH_EVENT_DETAILS_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default EventsReducer;
