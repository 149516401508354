import { Button, Input, Modal, Select, Form, message } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function AddDiscussion({
  isAddDiscussionVisible,
  setIsAddDiscussionVisible,
  fetchProjectDiscussion,
  record,
}) {
  const dispatch = useDispatch();
  const { projectFormData } = useSelector((store) => store?.ProjectReducer);

  console.log("record", record);

  const handleAddDiscussion = (values) => {
    dispatch({
      type: "ADD_DISCUSSION_REQUEST",
      payload: { id: record?.task_id, formData: values },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success("Discussion added successful");
        setIsAddDiscussionVisible(false);
        fetchProjectDiscussion();
      },
      failureFunction: () => {
        message.error("fail to add project disucssion");
      },
    });
  };
  return (
    <Modal
      open={isAddDiscussionVisible}
      onCancel={() => setIsAddDiscussionVisible(false)}
      footer={false}
      title="Add project discussion"
    >
      <Form layout="vertical" onFinish={handleAddDiscussion}>
        <Form.Item
          name="title"
          label="Discussion Title"
          rules={[
            {
              required: true,
              message: "Discussion title is required",
            },
          ]}
        >
          <Input placeholder="Title" />
        </Form.Item>
        <Form.Item name="detail" label="Comment">
          <Input.TextArea rows={4} placeholder="Comment" />
        </Form.Item>
        <Form.Item
          name="status"
          label="Status"
          rules={[
            {
              required: true,
              message: "Discussion status is required",
            },
          ]}
        >
          <Select placeholder="Select Status">
            {projectFormData?.status?.map((stat) => (
              <Select.Option value={stat?.status_id}>
                {stat?.status_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
}
