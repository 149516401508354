import {
  Button,
  Card,
  Divider,
  Drawer,
  Image,
  Modal,
  Tag,
  Space,
  Tooltip,
  Table,
  Popconfirm,
  Timeline,
} from "antd";
import React, { useEffect } from "react";
import {
  formattedDate,
  formattedDateTime,
  fourTagResponses,
  threeTagResponses,
} from "../../HelperFunction";
import client from "../../Assets/client.png";
import {
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineCopy,
  AiOutlineEye,
  AiOutlineHistory,
  AiOutlineDownload,
  AiOutlinePlus,
  AiOutlineCheckCircle,
  AiOutlineFilePdf,
} from "react-icons/ai";
import { CiMobile3 } from "react-icons/ci";
import { FiFlag } from "react-icons/fi";

import { GoLocation } from "react-icons/go";
import { useState } from "react";
import {
  ArrowUpOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
} from "@ant-design/icons";
import { BsChatLeftText } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CountUp from "react-countup";

import UpdateCustomerInfo from "./UpdateCustomerInfo";
import UpdateCustomerCourse from "./UpdateCustomerCourse";
import UpdateCustomerQualification from "./UpdateCustomerQualification";
import UpdateCustomerDocuments from "./UpdateCustomerDocuments";
import UpdateCustomerExperience from "./UpdateCustomerExperience";
import LeadDetails from "../Leads/LeadDetails";
import EditCustomerQualification from "./EditCustomerQualification";
import EditCustomerExperience from "./EditCustomerExperience";
import UpdateLead from "../Leads/UpdateLead";
import StatusHistory from "../Leads/StatusHistory";
import UpdateAppointment from "../Appointments/UpdateAppointment";
import AppointmentDetails from "../Appointments/AppointmentDetails";
import { CgAssign } from "react-icons/cg";
import CaseStatusHistory from "../Visas/CaseStatusHistory";
import VisaCaseDetails from "../Visas/VisaCaseDetails";
import AssignAgents from "../Visas/AssignAgents";
import ClientActivityLog from "./ClientActivityLog";
import AddLead from "../Leads/AddLead";
import AddAppointment from "../Appointments/AddAppointment";
import AddVisaCases from "../Visas/AddVisas";
import SendComposedMail from "../Mailbox/SendComposedMail";
import StartCounselling from "../Counsel/StartCounselling";
import { MdPreview } from "react-icons/md";
import { VscDebugStart } from "react-icons/vsc";
import QuotationDetails from "../Quotation/QuotationDetails";
import AddQuotation from "../Quotation/AddQuotation";
import TaskTable from "../../Components/TaskTableComponent/TaskTable";
import AddTask from "../Tasks/AddTask";
import EmailLogDetail from "../../Components/EmailLog/EmailLogDetail";

const { Meta } = Card;
const { confirm } = Modal;

// import { useDispatch, useSelector } from "react-redux";
const LeadColumns = [
  {
    title: "Lead ID",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Full Name",
    dataIndex: "fullname",
    align: "center",
  },
  {
    title: "Contact Details",
    dataIndex: "contactDetails",
    align: "center",
  },
  {
    title: "Purpose/Country",
    dataIndex: "purposeNameAndCountry",
    align: "center",
  },
  {
    title: "Source",
    dataIndex: "leadSourceName",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "statusName",
    align: "center",
  },
  {
    title: "Campaign",
    dataIndex: "campaign",
    align: "center",
  },

  {
    title: "Added on",
    dataIndex: "addedDateAndTime",
    width: 150,
    align: "center",
  },

  {
    title: "Lead By",
    dataIndex: "leadBy",
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
    fixed: "right",
  },
];

const AppointmentColumns = [
  {
    title: "ID",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Full Name",
    dataIndex: "firstName",
    align: "center",
  },
  {
    title: "Mobile Number",
    dataIndex: "mobileNumber",
    align: "center",
  },
  {
    title: "Appointment Date",
    dataIndex: "bookingDate",
    align: "center",
  },
  {
    title: "Appointment Status",
    dataIndex: "appointmentStatus",
    align: "center",
  },
  {
    title: "Lead Status",
    dataIndex: "leadStatus",
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
  },
];

const CasesColumns = [
  {
    title: "Case No.",
    dataIndex: "caseNo",
  },
  {
    title: "Client",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "Visa Type",
    dataIndex: "visaCategory",
    align: "center",
  },
  {
    title: "Sales Rep",
    dataIndex: "salesRepName",
    align: "center",
  },
  {
    title: "Grand Total",
    dataIndex: "totalAmount",
    align: "center",
  },

  {
    title: "Added Date",
    dataIndex: "addedOn",
    align: "center",
  },

  {
    title: "Lead Type",
    dataIndex: "leadType",
    align: "center",
  },
  {
    title: "Visa Outcome",
    dataIndex: "visaOutcome",
    align: "center",
  },
  {
    title: "Case Status",
    dataIndex: "status",
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
    fixed: "right",
  },
];
const quotationColumns = [
  {
    title: "Quote Id",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "Customer",
    dataIndex: "customer",
    align: "center",
  },
  {
    title: "Nature of Quote",
    dataIndex: "natureOfQuote",
    align: "center",
  },
  {
    title: "Total",
    dataIndex: "total",
    align: "center",
  },
  {
    title: "Quote Date",
    dataIndex: "quoteDate",
    align: "center",
  },
  {
    title: "Expiry Date",
    dataIndex: "expiryDate",
    align: "center",
  },
  {
    title: "Quote Status",
    dataIndex: "quoteStatus",
    align: "center",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
    fixed: "right",
  },
];

const ClientDetails = ({
  Record,
  isClientDetailsModelVisible,
  setIsClientDetailsModelVisible,
}) => {
  const store = useSelector((state) => {
    return {
      clientState: state.ClientsReducer,
      profileState: state.ProfileReducer,
    };
  });
  const record = store?.clientState.clientById;

  const dispatch = useDispatch();
  const [current, setCurrent] = useState("profile");
  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false);
  const [caseAppointmentLeadData, setCaseAppointmentLeadData] = useState({
    leadPrefix: "",
    leadReminder: "",
    appointmentPerfix: "",
    appointmentReminder: "",
    casePerfix: "",
  });
  const successFunction = (response) => {
    setCaseAppointmentLeadData((previousData) => {
      return {
        ...previousData,
        leadPrefix: response?.data?.prefix?.leadPrefix,
        leadReminder: response?.data?.expiryDates?.leadReminderDateAfter,
        appointmentPerfix: response?.data?.prefix?.appointmentPrefix,
        appointmentReminder:
          response?.data?.expiryDates?.appointmentReminderDateAfter,
        casePerfix: response?.data?.prefix?.casePrefix,
        quoteExpiryDateAfter: response?.data?.expiryDates?.quoteExpiryDateAfter,
      };
    });
  };

  const fetchClientById = () => {
    dispatch({
      type: "FETCH_CLIENT_BY_ID_REQUEST",
      payload: Record.id,
    });
  };

  const fetchEmailLogDetails = () => {
    dispatch({
      type: "FETCH_EMAIL_LOG_DETAILS",
      payload: {
        id: record?.id,
        forWhat: "CLIENT",
      },
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  useEffect(() => {
    fetchEmailLogDetails();
  }, []);

  useEffect(() => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
    if (isClientDetailsModelVisible === true) {
      fetchClientById();

      dispatch({
        type: "FETCH_CLIENT_ACTIVITY_LOG_BY_ID_REQUEST",
        payload: Record.id,
      });
      // dispatch({
      //   type: "FETCH_CLIENT_BY_ID_REQUEST",
      //   payload: Record.id,
      // });
      dispatch({
        type: "FETCH_LEAD_CUSTOMER_BY_ID_REQUEST",
        payload: Record.id,
      });
      dispatch({
        type: "FETCH_APPOINTMENT_CUSTOMER_BY_ID_REQUEST",
        payload: Record.id,
      });
      dispatch({
        type: "FETCH_CASE_CUSTOMER_BY_ID_REQUEST",
        payload: Record.id,
      });
      fetchQuotationByCustomerId();
    }
  }, [isClientDetailsModelVisible]);

  const fetchQuotationByCustomerId = () => {
    dispatch({
      type: "FETCH_QUOTATION_CUSTOMER_BY_ID_REQUEST",
      payload: Record.id,
    });
  };

  const [
    isUpdateCustomerInfoModalVisible,
    setIsUpdateCustomerInfoModalVisible,
  ] = useState(false);
  const [
    isAppointmentDetailsModalVisible,
    setIsAppointmentDetailsModalVisible,
  ] = useState();
  const [isUpdateAppointmentModelVisible, setIsUpdateAppointmentModelVisible] =
    useState(false);
  const [appointmentRecord, setAppointmentRecord] = useState();
  const [caseRecord, setCaseRecord] = useState();
  const [isUpdateCourseModalVisible, setIsUpdateCourseModalVisible] =
    useState(false);
  const [
    isUpdateQualificationModalVisible,
    setIsUpdateQualificationModalVisible,
  ] = useState(false);
  const [isUpdateExperienceModalVisible, setIsUpdateExperienceModalVisible] =
    useState(false);
  const [isUpdateDocumentModalVisible, setIsUpdateDocumentModalVisible] =
    useState(false);
  const [isLeadsDetailsModelVisible, setIsLeadsDetailsModelVisible] =
    useState(false);
  const [isEditQualificationModalVisible, setIsEditQualificationModalVisible] =
    useState();
  const [isEditExperienceModalVisible, setIsEditExperienceModalVisible] =
    useState();
  const [leadRecord, setLeadRecord] = useState();
  const [qualificationRecord, setQualificationRecord] = useState();
  const [experienceRecord, setExperienceRecord] = useState();
  const [isStatusHistoryModelVisible, setIsStatusHistoryModelVisible] =
    useState(false);
  const [isAddContactVisible, setIsAddContactVisible] = useState(false);
  const [isAddPurposeModalVisible, setIsAddPurposeModalVisible] =
    useState(false);
  const [isAddAboutUsModalVisible, setIsAddAboutUsModalVisible] =
    useState(false);
  const [isAddLeadSourceModalVisible, setIsAddLeadSourceModalVisible] =
    useState(false);
  const [isAddLeadVisible, setIsAddLeadVisible] = useState(false);
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isAddLeadStatusModalVisible, setIsAddLeadStatusModalVisible] =
    useState(false);
  const [isAddConsultantModalVisible, setIsAddConsultantModalVisible] =
    useState(false);
  const [isAddReferralModalVisible, setIsAddReferralModalVisible] =
    useState(false);
  const [isUpdateLeadModelVisible, setIsUpdateLeadModelVisible] =
    useState(false);
  //casesw
  const [isCaseStatusHistoryModelVisible, setIsCaseStatusHistoryModelVisible] =
    useState(false);
  const [isVisaCaseDetailsModalVisible, setIsVisaCaseDetailsModalVisible] =
    useState(false);
  const [isAssignAgentsModallVisible, setIsAssignAgentsModallVisible] =
    useState(false);

  const [isAddAppointmentModelVisible, setIsAddAppointmentModelVisible] =
    useState(false);
  const [isStartCounsellingModelVisible, setIsStartCounsellingModelVisible] =
    useState(false);
  const [isAddVisasModalVisible, setIsAddVisasModalVisible] = useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);

  const [isQuotationDetailsModelVisible, setIsQuotationDetailsModelVisible] =
    useState(false);
  const [isAddQuotationModalVisible, setIsAddQuotationModalVisible] =
    useState(false);
  const [isUpdateQuotationModalVisible, setIsUpdateQuotationModalVisible] =
    useState(false);
  const [quotationRecord, setQuotationRecord] = useState();

  console.log("testing", store?.clientState.customerLead?.data);
  const leadData = store?.clientState?.customerLead?.data?.map((dataObj) => {
    return {
      key: dataObj.id,
      id: `${caseAppointmentLeadData?.leadPrefix ?? ""}${dataObj.id}`,
      contactDetails: (
        <div className="flexColumnWithoutStyle">
          {dataObj.email !== null && dataObj.email !== "" && (
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "2px", color: "000", color: "000" }}
            >
              <AiOutlineMail />
              <a href={`mailto:${dataObj.email}`}>{dataObj.email}</a>
            </div>
          )}
          {dataObj.mobile !== null && dataObj.mobile !== "" && (
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "2px", color: "000" }}
            >
              <CiMobile3 />
              {dataObj.mobile}
            </div>
          )}
        </div>
      ),
      purposeNameAndCountry: (
        <div className="flexColumnWithoutStyle">
          <div
            className="flexRowWithoutStyle"
            style={{ gap: "2px", color: "000", color: "000" }}
          >
            <AiOutlineCopy />
            {dataObj.purposeName ?? "N/A"}
          </div>
          {dataObj.countryName !== null && dataObj.countryName !== "" && (
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "2px", color: "000" }}
            >
              <FiFlag />
              {dataObj.countryName ?? "N/A"}
            </div>
          )}
        </div>
      ),
      fullname: (
        <Tooltip title="Leads Details">
          <a>
            <div
              className="bordered"
              onClick={() => {
                setIsLeadsDetailsModelVisible(true);
                setLeadRecord(dataObj);
              }}
            >
              {dataObj.firstName + " " + dataObj.lastName}
            </div>
          </a>
        </Tooltip>
      ),
      email: dataObj.email,
      mobile: dataObj.mobile,
      leadSourceName: dataObj.leadSourceName ?? "N/A",
      campaign: dataObj?.campaign ?? "N/A",
      statusName: (
        <Tag
          color={
            dataObj.leadStatus?.statusColorCode
              ? dataObj.leadStatus?.statusColorCode
              : null
          }
        >
          {dataObj.leadStatus?.statusName
            ? dataObj.leadStatus?.statusName
            : "N/A"}
        </Tag>
      ),
      leadBy: dataObj.leadBy,
      addedDateAndTime: formattedDateTime(dataObj.addedDate),
      actions: (
        <Space size="middle">
          {store.profileState?.profile?.department?.rolesAndPermissionList?.LEADS?.includes(
            "UPDATE"
          ) && (
            <Tooltip title="Update Lead">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateLeadModelVisible(true);
                    setLeadRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>
          )}
          {/* <Tooltip title="Delete Lead">
            <Popconfirm
              title="Are you sure to delete this lead?"
              onConfirm={() => {
                dispatch({
                  type: "DELETE_LEAD_REQUEST",
                  payload: { id: dataObj.id },
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <div className="bordered">
                  <DeleteOutlined />
                </div>
              </a>{" "}
            </Popconfirm>
          </Tooltip> */}
          {/* <Tooltip title="Book an appointment">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setCaseRecord(dataObj);
                    setIsBookAppointmentModalVisible(true);
                  }}
                >
                  <AiOutlineClockCircle />
                </div>
              </a>
            </Tooltip> */}
          <Tooltip title="Leads Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsLeadsDetailsModelVisible(true);
                  setLeadRecord(dataObj);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),
    };
  });
  const AppointmentData = store?.clientState.customerAppointments?.data?.map(
    (dataObj) => {
      return {
        key: dataObj.id,
        id: `${caseAppointmentLeadData?.appointmentPerfix ?? ""}${dataObj.id}`,
        firstName: (
          <Tooltip title="Appointment Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsAppointmentDetailsModalVisible(true);
                  setAppointmentRecord(dataObj);
                }}
              >
                {dataObj.firstName + " " + dataObj.lastName}
              </div>
            </a>
          </Tooltip>
        ),
        appointmentStatus: dataObj?.appointmentStatus
          ? fourTagResponses(
              dataObj?.appointmentStatus,
              "PENDING",
              "COMPLETED",
              "CONFIRMED",
              "CANCELLED"
            )
          : "N/A",
        mobileNumber: (
          <div className="flexColumnWithoutStyle">
            {dataObj.email !== "" && dataObj.email !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000", color: "000" }}
              >
                <AiOutlineMail />
                <a href={`mailto:${dataObj.email}`}>{dataObj.email ?? "N/A"}</a>
              </div>
            ) : null}
            {dataObj.mobile !== "" && dataObj.mobile !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000" }}
              >
                <CiMobile3 />
                <a href={`tel:${dataObj.mobile}`}>{dataObj.mobile ?? "N/A"}</a>
              </div>
            ) : null}
          </div>
        ),
        bookingDate: dataObj.appointmentDate
          ? formattedDate(dataObj.appointmentDate)
          : dataObj.counsellingBooking !== null &&
            dataObj.counsellingBooking?.bookingDate !== null
          ? `${dataObj.counsellingBooking?.bookingDate} ${dataObj.counsellingBooking?.startTime}`
          : "N/A",
        bookingDay: dataObj.counsellingBooking?.bookingDay,
        sessionDuration: dataObj.counsellingBooking?.sessionTime,
        sessionTime:
          dataObj.counsellingBooking?.startTime +
          "-" +
          dataObj.counsellingBooking?.endTime,
        email: dataObj.email,
        campaign: dataObj.campaign?.name ?? "N/A",

        consultant: dataObj.counsellingBooking?.consultant?.emailId,
        leadStatus: dataObj.leadStatus ? (
          <>
            {dataObj.leadStatus === "BOOK_AN_APPOINTMENT" ? (
              <Tag color={dataObj.leadStatusColorCode ?? "white"}>
                IN PROGRESS
              </Tag>
            ) : (
              <Tag color={dataObj.leadStatusColorCode}>
                {dataObj.leadStatus}
              </Tag>
            )}
          </>
        ) : (
          "N/A"
        ),
        actions: (
          <Space size="middle">
            {dataObj.appointmentStatus === "COMPLETED" ||
            (dataObj.appointmentStatus === "CONFIRMED" &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
                "CHANGE_STATUS"
              )) ? (
              <Tooltip
                title={`${
                  dataObj?.isSessionStarted
                    ? "View Counselling"
                    : "Start Counselling"
                }`}
              >
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setAppointmentRecord(dataObj);
                      setIsStartCounsellingModelVisible(true);
                      dispatch({
                        type: "START_COUNSELLING_REQUEST",
                        payload: {
                          id: dataObj.id,
                          formData: {
                            confirmationText: "",
                          },
                        },
                      });
                    }}
                  >
                    {dataObj.isSessionStarted ? (
                      <MdPreview />
                    ) : (
                      <VscDebugStart />
                    )}
                  </div>
                </a>
              </Tooltip>
            ) : null}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.APPOINTMENTS?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update Appointment">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateAppointmentModelVisible(true);
                      setAppointmentRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            <Tooltip title="Appointment Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsAppointmentDetailsModalVisible(true);
                    setAppointmentRecord(dataObj);
                  }}
                >
                  <EyeOutlined />
                </div>
              </a>
            </Tooltip>
            {store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
              "DELETE"
            ) && (
              <Tooltip title="Delete Appointment">
                <Popconfirm
                  title="Are you sure to delete this appointment?"
                  onConfirm={() => {
                    dispatch({
                      type: "DELETE_APPOINTMENT_REQUEST",
                      payload: { id: dataObj.id },
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <div className="bordered">
                      <DeleteOutlined />
                    </div>
                  </a>{" "}
                </Popconfirm>
              </Tooltip>
            )}
            {/* <Tooltip title="View status History">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsStatusHistoryModelVisible(true);
                    setLeadRecord(dataObj);
                  }}
                >
                  <AiOutlineHistory />
                </div>
              </a>
            </Tooltip> */}
            {/* <Tooltip title="Change appointment status">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  const actionStatus = dataObj.isActive ? "inactive" : "active";
                  dispatch({
                    type: "CHANGE_APPOINTMENT_STATUS_REQUEST",
                    payload: { id: dataObj.id, actionStatus },
                  });
                }}
              >
                <MdOutlineDisabledVisible />
              </div>
            </a>
          </Tooltip> */}
          </Space>
        ),
      };
    }
  );
  const CaseData = store?.clientState.customerCases?.data?.map((dataObj) => {
    return {
      key: dataObj.id,
      caseNo: `${caseAppointmentLeadData?.casePerfix ?? ""}${dataObj.id}`,
      caseStatus: dataObj.caseStatus?.caseStatus ?? "N/A",
      visaOutcome: dataObj.visaOutcome ? (
        <Tag
          color={
            dataObj.visaOutcomeColorCode
              ? dataObj.visaOutcomeColorCode
              : "White"
          }
        >
          {dataObj.visaOutcome}
        </Tag>
      ) : (
        "N/A"
      ),
      name: (
        <Tooltip title="Case Details">
          <a>
            <div
              className="bordered"
              onClick={() => {
                setCaseRecord(dataObj);
                setIsVisaCaseDetailsModalVisible(true);
              }}
            >
              {dataObj.customer.firstName + " " + dataObj.customer.lastName}
            </div>
          </a>
        </Tooltip>
      ),
      visaCategory: dataObj.visaCategory ?? "N/A",
      salesRepName: dataObj.salesRepresentativeResponse?.salesRepName ?? "N/A",
      totalAmount: dataObj.totalAmount ?? 0,
      percentage: dataObj.institute?.percentage ?? "N/A",
      commission: dataObj.institute?.commission ?? "N/A",
      addedOn: formattedDate(dataObj.addedOn),
      leadType: dataObj.leadType,
      status: dataObj.workFlowStatusDto?.statusName ? (
        <Tag
          color={
            dataObj.workFlowStatusDto?.colorCode
              ? dataObj.workFlowStatusDto?.colorCode
              : "green"
          }
        >
          {dataObj.workFlowStatusDto?.statusName}
        </Tag>
      ) : null,

      statusUpdate: (
        <Space>
          <Tooltip title="Case Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setCaseRecord(dataObj);
                  setIsVisaCaseDetailsModalVisible(true);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
          <Tooltip title="View status History">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setCaseRecord(dataObj);
                  setIsCaseStatusHistoryModelVisible(true);
                }}
              >
                <AiOutlineHistory />
              </div>
            </a>
          </Tooltip>
        </Space>
      ),

      actions: (
        <Space size="middle">
          {/* <Tooltip title="Update Case Status">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setCaseRecord(dataObj);
                  setIsUpdateCaseStatusModalVisible(true);
                }}
              >
                <EditOutlined />
              </div>
            </a>
          </Tooltip> */}
          {/* <Tooltip title="Delete Visas">
            <Popconfirm
              title="Are you sure to delete this Visas?"
              onConfirm={() => {
                dispatch({
                  type: "DELETE_VISAS_REQUEST",
                  payload: { id: dataObj.id },
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <div className="bordered">
                  <DeleteOutlined />
                </div>
              </a>{" "}
            </Popconfirm>
          </Tooltip> */}

          <Tooltip title="Case Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsVisaCaseDetailsModalVisible(true);
                  setCaseRecord(dataObj);
                }}
              >
                <AiOutlineEye />
              </div>
            </a>
          </Tooltip>
          {/* <Tooltip title="View status History">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setCaseRecord(dataObj);
                  setIsCaseStatusHistoryModelVisible(true);
                }}
              >
                <AiOutlineHistory />
              </div>
            </a>
          </Tooltip> */}
          {store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
            "ASSIGN_AGENTS"
          ) && (
            <Tooltip title="Assign Agents">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setCaseRecord(dataObj);
                    setIsAssignAgentsModallVisible(true);
                  }}
                >
                  <CgAssign />
                </div>
              </a>
            </Tooltip>
          )}
        </Space>
      ),
    };
  });
  const quotationData = store?.clientState.quotationById?.data?.map(
    (dataObj) => {
      return {
        key: dataObj.id,
        id: dataObj.id,
        customer: dataObj?.customerId?.fullName ? (
          <Tooltip title="Institute Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setQuotationRecord(dataObj);
                  setIsQuotationDetailsModelVisible(true);
                }}
              >
                {dataObj?.customerId?.fullName}
              </div>
            </a>
          </Tooltip>
        ) : (
          "N/A"
        ),
        natureOfQuote: dataObj.natureOfProject,
        total: dataObj.totalAmount ?? "N/A",
        quoteDate: dataObj.addedDateTime
          ? formattedDate(dataObj.addedDateTime)
          : null,

        expiryDate: dataObj.quoteExpiryDate
          ? formattedDate(dataObj.quoteExpiryDate)
          : "N/A",
        quoteStatus:
          dataObj?.quoteStatus === "QUOTE_DRAFT" ? (
            <p className="orangeTag">QUOTE_DRAFT</p>
          ) : dataObj?.quoteStatus === "QUOTE_SENT" ? (
            <p className="blueTag">QUOTE_SENT</p>
          ) : dataObj?.quoteStatus === "WIN" ? (
            <p className="greenTag">WIN</p>
          ) : dataObj?.quoteStatus === "LOSE" ? (
            <p className="redTag">LOSE</p>
          ) : (
            "N/A"
          ),
        actions: (
          <Space size="middle">
            {dataObj.quoteStatus === "QUOTE_SENT" &&
              store.profileState?.profile?.department?.rolesAndPermissionList?.QUOTATION?.includes(
                "CHANGE_STATUS"
              ) && (
                <Tooltip title="Approve quotation">
                  <a>
                    <div
                      className="bordered"
                      onClick={() => {
                        confirm({
                          title: "Do you Want to apporove this quotation?",
                          icon: <ExclamationCircleFilled />,
                          // content: 'Some descriptions',
                          onOk() {
                            dispatch({
                              type: "APPROVE_QUOTATION_REQUEST",
                              payload: {
                                quoteId: dataObj.id,
                              },
                            });
                          },
                          onCancel() {},
                        });
                        // setIsUpdateQuotationModalVisible(true);
                        // setRecord(dataObj);
                      }}
                    >
                      <AiOutlineCheckCircle />
                    </div>
                  </a>
                </Tooltip>
              )}
            {store.profileState?.profile?.department?.rolesAndPermissionList?.QUOTATION?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update quotation">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setQuotationRecord(dataObj);
                      setIsUpdateQuotationModalVisible(true);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            <Tooltip title="Quotation Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setQuotationRecord(dataObj);
                    setIsQuotationDetailsModelVisible(true);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Download PDF">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    confirm({
                      title: "Do you want to download now?",
                      icon: <ExclamationCircleFilled />,
                      okText: "Download",
                      cancelText: "Cancel",
                      onOk() {
                        // dispatch({
                        //   type: "DOWNLOAD_QUOTATION_REQUEST",
                        //   payload: {
                        //     quotationId: dataObj.id,
                        //   },
                        // });
                      },
                      onCancel() {},
                    });
                  }}
                >
                  <AiOutlineFilePdf />
                </div>
              </a>
            </Tooltip>
            {/* <Tooltip title="Delete quotation">
            <Popconfirm
              title="Are you sure to delete this quotation?"
              onConfirm={() => {
                dispatch({
                  type: "DELETE_QUOTATION_REQUEST",
                  payload: { id: dataObj.id },
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <div className="bordered">
                  <DeleteOutlined />
                </div>
              </a>{" "}
            </Popconfirm>
          </Tooltip> */}
            {/* <Tooltip title="Toggle quotation status">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  const actionStatus =
                    dataObj.status === "INACTIVE" ? "active" : "inactive";
                  dispatch({
                    type: "TOGGLE_QUOTATION_STATUS_REQUEST",
                    payload: { id: dataObj.id, actionStatus },
                  });
                }}
              >
                <MdOutlineDisabledVisible />
              </div>
            </a>
          </Tooltip> */}
          </Space>
        ),
      };
    }
  );
  const clientDetailsTabList = [
    {
      key: "profile",
      tab: "Profile",
    },
    store.profileState?.profile?.department?.rolesAndPermissionList?.LEADS
      ?.length > 0 && {
      key: "leads",
      tab: "Leads",
    },
    store.profileState?.profile?.department?.rolesAndPermissionList
      ?.APPOINTMENTS?.length > 0 && {
      key: "appointment",
      tab: "Appointment",
    },
    store.profileState?.profile?.department?.rolesAndPermissionList?.QUOTATION
      ?.length > 0 && {
      key: "quote",
      tab: "Quote",
    },
    store.profileState?.profile?.department?.rolesAndPermissionList?.CASES
      ?.length > 0 && {
      key: "case",
      tab: "Case",
    },
    {
      key: "invoice",
      tab: "Invoice",
    },
    {
      key: "activityLog",
      tab: "Activity Log",
    },
    {
      key: "task",
      tab: "Task",
    },
    {
      tab: "Email Log",
      key: "emailLog",
    },
  ];

  // const caseTabList = [
  //   {
  //     key: "educationMigration",
  //     tab: "Education/Migration",
  //   },
  //   {
  //     key: "inhousePackage",
  //     tab: "Inhouse package",
  //   },
  //   {
  //     key: "thirdPartyPackage",
  //     tab: "Third Party Package",
  //   },
  // ];

  return (
    <Drawer
      title="Client Details"
      open={isClientDetailsModelVisible}
      onClose={() => {
        setIsClientDetailsModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card
        style={{ borderRadius: "0.5rem" }}
        className="drawerStyle"
        loading={store?.clientState?.isLoading}
      >
        <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
          {record?.photo ? (
            <>
              <input
                type={"file"}
                name="file1"
                style={{ display: "none" }}
                id="file1"
                onChange={(e) => {
                  const profileFormData = new FormData();
                  profileFormData.append("image", e.target.files[0]);
                  dispatch({
                    type: "CHANGE_CLIENT_IMAGE_REQUEST",
                    payload: profileFormData,
                  });
                }}
              />
              <label for="file1">
                <div className="container">
                  <Image
                    src={record?.photo}
                    className={"clientImage"}
                    preview={false}
                  />
                </div>
              </label>
            </>
          ) : (
            <Image src={client} className={"clientImage"} preview={false} />
          )}
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {record?.firstName + " " + record?.lastName}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.4rem", color: "#aaa" }}
            >
              {record?.mobile && (
                <span className="flexRowWithGap">
                  <AiOutlinePhone
                    style={{
                      fontSize: "20",
                    }}
                  />{" "}
                  {record?.mobile}
                </span>
              )}
              {record?.address && (
                <span className="flexRowWithGap">
                  <GoLocation
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {record?.address}
                </span>
              )}
              {record?.email && (
                <span className="flexRowWithGap">
                  <AiOutlineMail
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {record?.email}
                </span>
              )}
            </div>
            <div className="flexColumnwithoutStyle">
              <div
                className="flexRowWithoutStyle"
                style={{ width: "65%", gap: "1rem", padding: "1rem" }}
              >
                <div
                  className="flexColumnwithoutStyle"
                  style={{
                    border: "1px dashed #ccc",
                    borderRadius: "0.5rem",
                    padding: "1rem",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "#888",
                  }}
                >
                  <span
                    className="flexRowWithoutStyle"
                    style={{ fontSize: "20px" }}
                  >
                    <ArrowUpOutlined
                      style={{ color: "green", fontSize: "20px" }}
                    />
                    <CountUp
                      end={store?.clientState.customerCases?.totalData}
                    />
                  </span>
                  <span>Cases</span>
                </div>

                <div
                  className="flexColumnwithoutStyle"
                  style={{
                    border: "1px dashed #ccc",
                    borderRadius: "0.5rem",
                    padding: "1rem",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "#888",
                  }}
                >
                  <span
                    className="flexRowWithoutStyle"
                    style={{ fontSize: "20px" }}
                  >
                    <ArrowUpOutlined
                      style={{ color: "green", fontSize: "20px" }}
                    />
                    <CountUp
                      end={store?.clientState.customerAppointments?.totalData}
                    />
                  </span>
                  <span>Appointments</span>
                </div>
                <div
                  className="flexColumnwithoutStyle"
                  style={{
                    border: "1px dashed #ccc",
                    borderRadius: "0.5rem",
                    padding: "1rem",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "#888",
                  }}
                >
                  <span
                    className="flexRowWithoutStyle"
                    style={{ fontSize: "20px" }}
                  >
                    <ArrowUpOutlined
                      style={{ color: "green", fontSize: "20px" }}
                    />
                    <CountUp end={store?.clientState.customerLead?.totalData} />
                  </span>
                  <span>Leads</span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "0.5rem",
                }}
              >
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
                  "SEND_MESSAGE"
                ) && (
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "#ddddddbb",
                      borderRadius: "0.2rem",
                      padding: "0.3rem 0.6rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "0.2rem",
                      cursor: "pointer",
                    }}
                  >
                    <BsChatLeftText style={{ fontSize: "10px" }} />
                    <span>Message</span>
                  </button>
                )}
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
                  "SEND_EMAIL"
                ) && (
                  <button
                    className="emailAndMessage"
                    onClick={() => {
                      setIsSendComposedEmailModalVisible(true);
                    }}
                  >
                    <AiOutlineMail />
                    <span>Email</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "profile" && (
            <div>
              <h2 className="cardHeader flexRowWitoutStyle">
                Customer Info{" "}
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
                  "UPDATE"
                ) && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsUpdateCustomerInfoModalVisible(true);
                    }}
                    style={{ float: "right" }}
                  >
                    Edit
                  </Button>
                )}
              </h2>
              <Card className="clientCard" style={{ width: "100%" }}>
                <div
                  className="flexColumnWithoutStyle"
                  style={{ width: "100%" }}
                >
                  <div className="flexRowSpaceBetween">
                    Campaign:
                    <span>
                      {record?.campaign?.name ? record?.campaign?.name : "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div className="flexRowSpaceBetween">
                    Date of Birth:
                    <span>
                      {record?.dob ? formattedDate(record?.dob) : "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                  <div className="flexRowSpaceBetween">
                    Current Address:
                    <span>{record?.address ? record?.address : "N/A"}</span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div className="flexRowSpaceBetween">
                    Phone Number:
                    <span>{record?.phone ? record?.phone : "N/A"}</span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div className="flexRowSpaceBetween">
                    Passport Number:
                    <span>
                      {record?.passportNumber ? record?.passportNumber : "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                  <div className="flexRowSpaceBetween">
                    Passport Expiry Date:
                    <span>
                      {record?.passportExpiryDate
                        ? formattedDate(record?.passportExpiryDate)
                        : "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                  <div className="flexRowSpaceBetween">
                    Country of Residence:
                    <span>
                      {record?.countryOfResidence
                        ? record?.countryOfResidence
                        : "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div className="flexRowSpaceBetween">
                    Marital Status:
                    <span>
                      {record?.maritalStatus ? record?.maritalStatus : "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div className="flexRowSpaceBetween">
                    Sex Status:
                    <span>{record?.sexStatus ? record?.sexStatus : "N/A"}</span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div className="flexRowSpaceBetween">
                    Referral:
                    <span>
                      {record?.referral?.emailId
                        ? record?.referral?.emailId
                        : "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div className="flexRowSpaceBetween">
                    About Us:
                    <span>
                      {record?.aboutUs?.aboutUs
                        ? record?.aboutUs?.aboutUs
                        : "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                  <div className="flexRowSpaceBetween">
                    Visa Type:
                    <span>{record?.visaType ? record?.visaType : "N/A"}</span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                  <div className="flexRowSpaceBetween">
                    Visa Expiry Date:
                    <span>
                      {record?.visaExpiryDate
                        ? formattedDate(record?.visaExpiryDate)
                        : "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div className="flexRowSpaceBetween">
                    Visa Status:
                    <span>
                      {record?.visaStatus ? record?.visaStatus : "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
                </div>
              </Card>

              <h2 className="cardHeader">
                English Test
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
                  "UPDATE"
                ) && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsUpdateCourseModalVisible(true);
                    }}
                    style={{ float: "right" }}
                  >
                    Edit
                  </Button>
                )}
              </h2>
              <div className="flexColumnWithoutStyle" style={{ width: "100%" }}>
                <Card style={{ width: "100%" }} className="clientCard">
                  <div className="flexRowSpaceBetween">
                    <span> IELTS score: </span>
                    {record?.completedIelts && (
                      <>
                        <div>
                          <span> Listening: </span>
                          {record?.listening ?? (
                            <span>{record?.listening}</span>
                          )}
                        </div>
                        <div>
                          <span> Reading: </span>
                          {record?.reading ?? <span>{record?.reading}</span>}
                        </div>
                        <div>
                          <span> Writing: </span>
                          {record?.writing ?? <span>{record?.writing}</span>}
                        </div>
                        <div>
                          <span> Speaking: </span>
                          {record?.speaking ?? <span>{record?.speaking}</span>}
                        </div>
                      </>
                    )}
                    <span className="scoreStyle">
                      {record?.totalIeltsScore ?? "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div className="flexRowSpaceBetween">
                    SAT score:{" "}
                    <span className="scoreStyle">
                      {record?.satIbtScore ?? "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div className="flexRowSpaceBetween">
                    <span>PTE score: </span>
                    {record?.completedPte && (
                      <>
                        <div>
                          <span> Listening: </span>
                          {record?.pteListening ?? (
                            <span>{record?.pteListening}</span>
                          )}
                        </div>
                        <div>
                          <span> Reading: </span>
                          {record?.pteReading ?? (
                            <span>{record?.pteReading}</span>
                          )}
                        </div>
                        <div>
                          <span> Writing: </span>
                          {record?.pteWriting ?? (
                            <span>{record?.pteWriting}</span>
                          )}
                        </div>
                        <div>
                          <span> Speaking: </span>
                          {record?.pteSpeaking ?? (
                            <span>{record?.pteSpeaking}</span>
                          )}
                        </div>
                      </>
                    )}
                    <span className="scoreStyle">
                      {record?.pteScore ?? "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div className="flexRowSpaceBetween">
                    GRE score:{" "}
                    <span className="scoreStyle">
                      {record?.greIbtScore ?? "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div className="flexRowSpaceBetween">
                    Tofel score:{" "}
                    <span className="scoreStyle">
                      {record?.tofelIbtScore ?? "N/A"}
                    </span>
                  </div>
                  <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />

                  <div className="flexRowSpaceBetween">
                    GMAT Score:{" "}
                    <span className="scoreStyle">
                      {record?.gmatIbtScore ?? "N/A"}
                    </span>
                  </div>
                </Card>
              </div>
              <h2 className="cardHeader">
                Qualification
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
                  "UPDATE"
                ) && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsUpdateQualificationModalVisible(true);
                    }}
                    style={{ float: "right" }}
                  >
                    Add
                  </Button>
                )}
              </h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1px",
                  flexWrap: "wrap",
                }}
              >
                {record?.qualifications?.length === 0 ? (
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    No Data Available
                  </div>
                ) : null}
                {record?.qualifications?.map((qualification) => {
                  return (
                    <>
                      <Card
                        actions={[
                          <Tooltip title={"Update Qualification"}>
                            <EditOutlined
                              key="education"
                              size={20}
                              onClick={() => {
                                setQualificationRecord(qualification);
                                setIsEditQualificationModalVisible(true);
                              }}
                            />
                          </Tooltip>,
                          <Tooltip title={"Delete Qualification"}>
                            <Popconfirm
                              title="Are you sure to delete this qualification?"
                              onConfirm={() => {
                                dispatch({
                                  type: "DELETE_QUALIFICATION_REQUEST",
                                  payload: {
                                    id: qualification.id,
                                    clientId: record.id,
                                  },
                                });
                              }}
                              onCancel={() => {}}
                              okText="Yes"
                              cancelText="No"
                            >
                              <a>
                                <div className="bordered">
                                  <DeleteOutlined />
                                </div>
                              </a>{" "}
                            </Popconfirm>
                          </Tooltip>,
                        ]}
                        className="clientCard"
                        title={qualification?.qualification}
                        key={qualification.id}
                        style={{ width: 300 }}
                      >
                        <div className="flexRowSpaceBetween">
                          Institution: <span>{qualification.institution}</span>
                        </div>
                        <div className="flexRowSpaceBetween">
                          Country: <span>{qualification.country}</span>
                        </div>
                        <div className="flexRowSpaceBetween">
                          Year of Commencement:{" "}
                          <span>{qualification.yearsOfCommencement}</span>
                        </div>
                        <div className="flexRowSpaceBetween">
                          Year of Completion:{" "}
                          <span>{qualification.yearsOfCompletion}</span>
                        </div>
                        <div className="flexRowSpaceBetween">
                          Obtained(%): <span>{qualification.percentage}</span>
                        </div>
                      </Card>
                    </>
                  );
                })}
              </div>
              <h2 className="cardHeader">
                Experience{" "}
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
                  "UPDATE"
                ) && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsUpdateExperienceModalVisible(true);
                    }}
                    style={{ float: "right" }}
                  >
                    Add
                  </Button>
                )}
              </h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1px",
                  flexWrap: "wrap",
                }}
              >
                {record?.experiences?.length === 0 ? (
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    No Data Available
                  </div>
                ) : null}
                {record?.experiences?.map((qualification) => {
                  return (
                    <>
                      <Card
                        className="clientCard"
                        title={qualification?.experience}
                        key={qualification.id}
                        style={{ width: 300 }}
                        actions={[
                          <Tooltip title={"Update Experience"}>
                            <EditOutlined
                              size={20}
                              onClick={() => {
                                setExperienceRecord(qualification);
                                setIsEditExperienceModalVisible(true);
                              }}
                            />
                          </Tooltip>,
                          <Tooltip title={"Delete Experience"}>
                            <Popconfirm
                              title="Are you sure to delete this experience?"
                              onConfirm={() => {
                                dispatch({
                                  type: "DELETE_EXPERIENCE_REQUEST",
                                  payload: {
                                    id: qualification.id,
                                    clientId: record.id,
                                  },
                                });
                              }}
                              onCancel={() => {}}
                              okText="Yes"
                              cancelText="No"
                            >
                              <a>
                                <div className="bordered">
                                  <DeleteOutlined />
                                </div>
                              </a>{" "}
                            </Popconfirm>
                          </Tooltip>,
                        ]}
                      >
                        <div className="flexRowSpaceBetween">
                          Position: <span>{qualification?.position}</span>
                        </div>
                        <div className="flexRowSpaceBetween">
                          Company: <span>{qualification.institution}</span>
                        </div>
                        <div className="flexRowSpaceBetween">
                          Location: <span>{qualification.country}</span>
                        </div>
                        <div className="flexRowSpaceBetween">
                          Year of Commencement:{" "}
                          <span>{qualification.yearsOfCommencement}</span>
                        </div>
                        <div className="flexRowSpaceBetween">
                          Year of Completion:{" "}
                          <span>{qualification.yearsOfCompletion}</span>
                        </div>
                      </Card>
                    </>
                  );
                })}
              </div>
              <h2 className="cardHeader">
                Documents{" "}
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CLIENTS?.includes(
                  "UPDATE"
                ) && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsUpdateDocumentModalVisible(true);
                    }}
                    style={{ float: "right" }}
                  >
                    Add
                  </Button>
                )}
              </h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1px",
                  flexWrap: "wrap",
                }}
              >
                {record?.documents?.length === 0 ? (
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    No Data Available
                  </div>
                ) : null}
                {record?.documents?.map((document) => {
                  return (
                    <Card
                      className="clientCard"
                      key={document.id}
                      bordered={false}
                      style={{ width: 300 }}
                      title={
                        document?.documentType?.name
                          ? document?.documentType?.name
                          : ""
                      }
                      actions={[
                        <Tooltip title={"Download"}>
                          <a href={document?.documentUrl}>
                            <AiOutlineDownload size={20} />
                          </a>
                        </Tooltip>,
                        <Tooltip title={"Delete Document"}>
                          <Popconfirm
                            title="Are you sure to delete this document?"
                            onConfirm={() => {
                              dispatch({
                                type: "DELETE_CUSTOMER_DOCUMENT_REQUEST",
                                payload: {
                                  id: document.id,
                                  clientId: record.id,
                                },
                              });
                            }}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                          >
                            <a>
                              <div className="bordered">
                                <DeleteOutlined />
                              </div>
                            </a>{" "}
                          </Popconfirm>
                        </Tooltip>,
                      ]}
                    >
                      {document.documentUrl ? (
                        <>
                          <Image
                            src={document.documentUrl}
                            className={"clientImage"}
                          />
                        </>
                      ) : (
                        <Image src={client} className={"clientImage"} />
                      )}
                      <Meta
                        title={
                          document.documentName !== "null" &&
                          document.documentName !== "undefined"
                            ? document.documentName
                            : ""
                        }
                        description={
                          document?.description == "null" &&
                          document?.description == "undefined"
                            ? document?.description
                            : ""
                        }
                      />
                    </Card>
                  );
                })}
              </div>
            </div>
          )}

          {current === "leads" && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0.8rem",
                }}
              >
                {store.profileState?.profile?.department?.rolesAndPermissionList?.LEADS?.includes(
                  "ADD"
                ) && (
                  <Button
                    type="primary"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setIsAddLeadVisible(true);
                    }}
                  >
                    <AiOutlinePlus color="white" fontSize={16} /> ADD
                  </Button>
                )}
              </div>
              <Table bordered columns={LeadColumns} dataSource={leadData} />
            </div>
          )}
          {current === "appointment" && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0.8rem",
                }}
              >
                {store.profileState?.profile?.department?.rolesAndPermissionList?.APPOINTMENTS?.includes(
                  "ADD"
                ) && (
                  <Button
                    type="primary"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setIsAddAppointmentModelVisible(true);
                    }}
                  >
                    <AiOutlinePlus color="white" fontSize={16} /> ADD
                  </Button>
                )}
              </div>
              <Table
                bordered
                columns={AppointmentColumns}
                dataSource={AppointmentData}
              />
            </div>
          )}
          {current === "case" && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0.8rem",
                }}
              >
                {store.profileState?.profile?.department?.rolesAndPermissionList?.CASES?.includes(
                  "ADD"
                ) && (
                  <Button
                    type="primary"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setIsAddVisasModalVisible(true);
                    }}
                  >
                    <AiOutlinePlus color="white" fontSize={16} /> ADD
                  </Button>
                )}
              </div>
              <Table bordered columns={CasesColumns} dataSource={CaseData} />
            </div>
          )}
          {current === "quote" && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0.8rem",
                }}
              >
                {store.profileState?.profile?.department?.rolesAndPermissionList?.QUOTATION?.includes(
                  "ADD"
                ) && (
                  <Button
                    type="primary"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setIsAddQuotationModalVisible(true);
                    }}
                  >
                    <AiOutlinePlus color="white" fontSize={16} /> ADD
                  </Button>
                )}
              </div>
              <Table
                bordered
                columns={quotationColumns}
                dataSource={quotationData}
              />
            </div>
          )}
          {current === "activityLog" && (
            <>
              {store?.clientState?.clientActivityLog?.data?.map(
                (dataObj, index) => {
                  return (
                    <Timeline.Item key={dataObj.key}>
                      <div className="flexColumnwithoutStyle">
                        <div style={{ fontWeight: "200" }}>
                          {formattedDateTime(dataObj?.createdOn)}
                        </div>
                        <span>
                          <div style={{ fontWeight: "bold" }}>
                            {dataObj.activityLog}
                          </div>
                        </span>
                        {dataObj.appointments !== null && (
                          <>
                            <div>
                              {dataObj.appointments?.id
                                ? `Appointment Id: ${dataObj?.appointments?.id}`
                                : ""}
                            </div>
                            <div>
                              {dataObj.appointments?.firstName
                                ? `${dataObj?.appointments?.firstName} ${dataObj.appointments?.lastName}`
                                : ""}
                            </div>
                            <div>
                              {dataObj?.appointments?.email
                                ? dataObj?.appointments?.email
                                : ""}
                            </div>
                            <div>
                              {dataObj?.appointments?.mobile
                                ? dataObj?.appointments?.mobile
                                : ""}
                            </div>
                          </>
                        )}
                        {dataObj.leads !== null && (
                          <>
                            <div>
                              {dataObj.leads?.id
                                ? `Lead Id: ${dataObj?.leads?.id}`
                                : ""}
                            </div>
                            <div>
                              {dataObj.leads?.firstName
                                ? `${dataObj?.leads?.firstName} ${dataObj.leads?.lastName}`
                                : ""}
                            </div>
                            <div>
                              {dataObj?.leads?.email
                                ? dataObj?.leads?.email
                                : ""}
                            </div>
                            <div>
                              {dataObj?.leads?.mobile
                                ? dataObj?.leads?.mobile
                                : ""}
                            </div>
                          </>
                        )}
                        {dataObj.cases !== null && (
                          <>
                            <div>
                              {dataObj.cases?.id
                                ? `Case Id: ${dataObj?.cases?.id}`
                                : ""}
                            </div>
                            <div>
                              {dataObj.cases?.firstName
                                ? `${dataObj?.cases?.customer?.firstName} ${dataObj.cases?.customer?.lastName}`
                                : ""}
                            </div>
                            <div>
                              {dataObj?.cases?.customer?.email
                                ? dataObj?.cases?.customer?.email
                                : ""}
                            </div>
                            <div>
                              {dataObj?.cases?.customer?.mobile
                                ? dataObj?.cases?.customer?.mobile
                                : ""}
                            </div>
                          </>
                        )}
                        {dataObj?.updateRemarks !== null &&
                          dataObj?.updateRemarks !== "" &&
                          dataObj?.updateRemarks !== undefined && (
                            <div>Update Remarks: {dataObj?.updateRemarks}</div>
                          )}
                        {dataObj.nextAction !== null &&
                          dataObj.nextAction !== "" &&
                          dataObj.nextAction !== undefined && (
                            <div>Next Action: {dataObj.nextAction}</div>
                          )}
                        {dataObj.dayUpdate !== null &&
                          dataObj.dayUpdate !== "" &&
                          dataObj.dayUpdate !== undefined && (
                            <div>Description: {dataObj.dayUpdate}</div>
                          )}
                        {dataObj?.updatedByEmployee !== null &&
                          dataObj?.updatedByEmployee !== "" &&
                          dataObj?.updatedByEmployee !== undefined && (
                            <div>Updated By: {dataObj?.updatedByEmployee}</div>
                          )}
                      </div>
                    </Timeline.Item>
                  );
                }
              )}
            </>
          )}

          {current === "emailLog" && <EmailLogDetail />}

          {current === "task" && (
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={() => {
                      setIsAddTaskModalVisible(true);
                    }}
                    type="primary"
                  >
                    Add Task
                  </Button>
                </div>
                <TaskTable
                  record={record?.taskRelatedToCustomer}
                  updateType="client"
                  fetchClientById={fetchClientById}
                />
              </div>
            </div>
          )}
        </Card>
      </Card>
      {isAddTaskModalVisible && (
        <AddTask
          updateType="Client"
          fetchDetailRecordById={fetchClientById}
          taskId={record?.id}
          isAddTaskModalVisible={isAddTaskModalVisible}
          setIsAddTaskModalVisible={setIsAddTaskModalVisible}
        />
      )}

      {isUpdateCustomerInfoModalVisible && (
        <UpdateCustomerInfo
          record={record}
          isUpdateCustomerInfoModalVisible={isUpdateCustomerInfoModalVisible}
          setIsUpdateCustomerInfoModalVisible={
            setIsUpdateCustomerInfoModalVisible
          }
          setIsClientDetailsModelVisible={setIsClientDetailsModelVisible}
        />
      )}
      {isUpdateCourseModalVisible && (
        <UpdateCustomerCourse
          record={record}
          isUpdateCustomerCourseModalVisible={isUpdateCourseModalVisible}
          setIsUpdateCustomerCourseModalVisible={setIsUpdateCourseModalVisible}
          setIsClientDetailsModelVisible={setIsClientDetailsModelVisible}
        />
      )}
      {isUpdateQualificationModalVisible && (
        <UpdateCustomerQualification
          record={record}
          isUpdateCustomerQualificationModalVisible={
            isUpdateQualificationModalVisible
          }
          setIsUpdateCustomerQualificationModalVisible={
            setIsUpdateQualificationModalVisible
          }
          setIsClientDetailsModelVisible={setIsClientDetailsModelVisible}
        />
      )}
      {isUpdateExperienceModalVisible && (
        <UpdateCustomerExperience
          record={record}
          isUpdateCustomerExperienceModalVisible={
            isUpdateExperienceModalVisible
          }
          setIsUpdateCustomerExperienceModalVisible={
            setIsUpdateExperienceModalVisible
          }
          setIsClientDetailsModelVisible={setIsClientDetailsModelVisible}
        />
      )}
      {isUpdateDocumentModalVisible && (
        <UpdateCustomerDocuments
          record={record}
          isAddCustomerDocumentModalVisible={isUpdateDocumentModalVisible}
          setIsAddCustomerDocumentModalVisible={setIsUpdateDocumentModalVisible}
          setIsClientDetailsModelVisible={setIsClientDetailsModelVisible}
        />
      )}
      {isLeadsDetailsModelVisible && (
        <LeadDetails
          leadData={caseAppointmentLeadData}
          Record={leadRecord}
          isLeadsDetailsModelVisible={isLeadsDetailsModelVisible}
          setIsLeadsDetailsModelVisible={setIsLeadsDetailsModelVisible}
        />
      )}
      <UpdateLead
        isUpdateLeadModelVisible={isUpdateLeadModelVisible}
        setIsUpdateLeadModelVisible={setIsUpdateLeadModelVisible}
        record={leadRecord}
        isAddContactVisible={isAddContactVisible}
        setIsAddContactVisible={setIsAddContactVisible}
        isAddAboutUsModalVisible={isAddAboutUsModalVisible}
        setIsAddAboutUsModalVisible={setIsAddAboutUsModalVisible}
        isAddLeadSourceModalVisible={isAddLeadSourceModalVisible}
        setIsAddCountryModalVisible={setIsAddCountryModalVisible}
        setIsAddLeadSourceModalVisible={setIsAddLeadSourceModalVisible}
        isAddLeadStatusModalVisible={isAddLeadStatusModalVisible}
        setIsAddLeadStatusModalVisible={setIsAddLeadStatusModalVisible}
        isAddPurposeModalVisible={isAddPurposeModalVisible}
        setIsAddPurposeModalVisible={setIsAddPurposeModalVisible}
        isAddConsultantModalVisible={isAddConsultantModalVisible}
        setIsAddConsultantModalVisible={setIsAddConsultantModalVisible}
        isAddReferralModalVisible={isAddReferralModalVisible}
        setIsAddReferralModalVisible={setIsAddReferralModalVisible}
      />
      {isStatusHistoryModelVisible && (
        <StatusHistory
          type={"LEAD"}
          record={leadRecord}
          isStatusHistoryModalVisible={isStatusHistoryModelVisible}
          setIsStatusHistoryModalVisible={setIsStatusHistoryModelVisible}
        />
      )}
      {isEditQualificationModalVisible && (
        <EditCustomerQualification
          clientRecord={record}
          record={qualificationRecord}
          isUpdateCustomerQualificationModalVisible={
            isEditQualificationModalVisible
          }
          setIsUpdateCustomerQualificationModalVisible={
            setIsEditQualificationModalVisible
          }
          setIsClientDetailsModelVisible={setIsClientDetailsModelVisible}
        />
      )}
      {isEditExperienceModalVisible && (
        <EditCustomerExperience
          clientRecord={record}
          record={experienceRecord}
          isUpdateCustomerExperienceModalVisible={isEditExperienceModalVisible}
          setIsUpdateCustomerExperienceModalVisible={
            setIsEditExperienceModalVisible
          }
          setIsClientDetailsModelVisible={setIsClientDetailsModelVisible}
        />
      )}
      {isUpdateAppointmentModelVisible && (
        <UpdateAppointment
          record={appointmentRecord}
          isUpdateAppointmentModelVisible={isUpdateAppointmentModelVisible}
          setIsUpdateAppointmentModelVisible={
            setIsUpdateAppointmentModelVisible
          }
        />
      )}
      {isAppointmentDetailsModalVisible && (
        <AppointmentDetails
          appointmentData={caseAppointmentLeadData}
          appointmentObj={appointmentRecord}
          isAppointmentDetailsModalVisible={isAppointmentDetailsModalVisible}
          setIsAppointmentDetailsModalVisible={
            setIsAppointmentDetailsModalVisible
          }
        />
      )}
      {isCaseStatusHistoryModelVisible && (
        <CaseStatusHistory
          record={caseRecord}
          isUpdateCaseStatusModalVisible={isCaseStatusHistoryModelVisible}
          setIsUpdateCaseStatusModalVisible={setIsCaseStatusHistoryModelVisible}
        />
      )}
      {isVisaCaseDetailsModalVisible && (
        <VisaCaseDetails
          caseData={caseAppointmentLeadData}
          visaRecord={caseRecord}
          record={caseRecord}
          isVisaCaseDetailsModalVisible={isVisaCaseDetailsModalVisible}
          setIsVisaCaseDetailsModalVisible={setIsVisaCaseDetailsModalVisible}
        />
      )}
      {isAssignAgentsModallVisible && (
        <AssignAgents
          record={caseRecord}
          isAssignAgentsModalVisible={isAssignAgentsModallVisible}
          setIsAssignAgentsModalVisible={setIsAssignAgentsModallVisible}
        />
      )}
      {isAddLeadVisible && (
        <AddLead
          appointmentData={caseAppointmentLeadData}
          fromClientsDetails={true}
          contactRecord={Record}
          isAddLeadVisible={isAddLeadVisible}
          setIsAddLeadVisible={setIsAddLeadVisible}
        />
      )}
      {isAddAppointmentModelVisible && (
        <AddAppointment
          leadData={caseAppointmentLeadData}
          fromClientsDetails={true}
          leadRecord={{
            customerResponse: {
              mobile: Record?.mobile,
              email: Record?.email,
              lastName: Record?.lastName,
              firstName: Record?.firstName,
              id: Record?.id,
              fullName: Record?.fullName,
              countryOfResidence: Record?.countryOfResidence,
            },
          }}
          isAddAppointmentModalVisible={isAddAppointmentModelVisible}
          setIsAddAppointmentModelVisible={setIsAddAppointmentModelVisible}
        />
      )}
      {isAddVisasModalVisible && (
        <AddVisaCases
          fromCounselling={false}
          fromClientsDetails={true}
          recordData={{
            leadResponse: {
              customerResponse: {
                ...Record,
                customerId: Record?.id,
              },
            },
          }}
          isAddVisaCasesModalVisible={isAddVisasModalVisible}
          setIsAddVisaCasesModalVisible={setIsAddVisasModalVisible}
        />
      )}
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          mailTemplateType="CLIENT"
          composeMailRecord={{
            toUser: [record?.email],
          }}
          forWhatId={record?.id}
          fetchEmailLogDetails={fetchEmailLogDetails}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
      {isStartCounsellingModelVisible && (
        <StartCounselling
          record={appointmentRecord}
          isStartCounsellingModalVisible={isStartCounsellingModelVisible}
          setIsStartCounsellingModalVisible={setIsStartCounsellingModelVisible}
        />
      )}
      {isQuotationDetailsModelVisible && (
        <QuotationDetails
          record={quotationRecord}
          isQuotationDetailsModelVisible={isQuotationDetailsModelVisible}
          setIsQuotationDetailsModelVisible={setIsQuotationDetailsModelVisible}
        />
      )}
      {isAddQuotationModalVisible && (
        <AddQuotation
          quotationRecord={Record}
          quotationSettingsData={caseAppointmentLeadData}
          isAddQuotationModalVisible={isAddQuotationModalVisible}
          setIsAddQuotationModalVisible={setIsAddQuotationModalVisible}
          addType={true}
          fetchQuotationByCustomerId={fetchQuotationByCustomerId}
        />
      )}
    </Drawer>
  );
};

export default ClientDetails;
