import { Button, Checkbox, Form, Input, InputNumber, Select } from "antd";
import React, { useState } from "react";

const BillingAddress = ({ current, setCurrent }) => {
  const [isNext, setIsNext] = useState(false);
  const { Option } = Select;

  const handleSubmitBillingData = (values) => {
    isNext ? setCurrent((prev) => prev + 1) : setCurrent((prev) => prev - 1);
  };
  return (
    <Form
      layout="vertical"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "1rem",
        maxWidth: "1024px",
        width: "100%",
      }}
      onFinish={handleSubmitBillingData}
    >
      <Form.Item name="address1" label="Address1">
        <Input placeholder="Address1" />
      </Form.Item>
      <Form.Item name="address2" label="Address2">
        <Input placeholder="Address2" />
      </Form.Item>
      <Form.Item name="suburb" label="Suburb">
        <Input placeholder="Suburb" />
      </Form.Item>
      <Form.Item name="postCode" label="Postcode">
        <InputNumber
          type="number"
          style={{ width: "100%" }}
          placeholder="Postcode"
        />
      </Form.Item>

      <Form.Item name="country" label="Country">
        <Select placeholder="Select Country">
          <Option>ABC</Option>
        </Select>{" "}
      </Form.Item>
      <Form.Item name="state" label="State">
        <Select placeholder="Select State">
          <Option>ABC</Option>
        </Select>{" "}
      </Form.Item>

      <div
        style={{
          gridColumn: "span 2",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          style={{ width: "fit-content" }}
          type="primary"
          htmlType="submit"
          onClick={() => setIsNext(false)}
        >
          Back
        </Button>
        <Button
          style={{ width: "fit-content" }}
          type="primary"
          htmlType="submit"
          onClick={() => setIsNext(true)}
        >
          Next
        </Button>
      </div>
    </Form>
  );
};

export default BillingAddress;
