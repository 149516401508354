import { Button, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import AddChecklist from "./AddChecklist";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export default function ProjectChecklist({ record }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const { projectChecklist } = useSelector((store) => store?.ProjectReducer);

  useEffect(() => {
    fetchChecklists();
  }, []);

  const fetchChecklists = () => {
    dispatch({
      type: "FETCH_CHECKLISTS_REQUEST",
      payload: record?.task_id,
      successFunction: () => {},
      failureFunction: () => {},
    });
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "title",
      align: "center",
    },
    {
      title: "Checklist",
      dataIndex: "checklists",
      align: "center",
      render: (data) => (
        <div>
          {data?.map((data) => (
            <Tag>{data?.name}</Tag>
          ))}
        </div>
      ),
    },
    {
      title: "Detail",
      dataIndex: "addd_on",
      align: "center",
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      {" "}
      <Button
        style={{ width: "fit-content" }}
        type="primary"
        onClick={() => setIsOpenModal(true)}
      >
        Add Checklist
      </Button>
      <Table
        columns={columns}
        bordered
        dataSource={projectChecklist?.project_checklists}
        pagination={false}
      />
      {isOpenModal && (
        <AddChecklist
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          record={record}
          fetchChecklists={fetchChecklists}
        />
      )}
    </div>
  );
}
