import {
  Button,
  Card,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  Tag,
  Timeline,
} from "antd";
import React, { useEffect } from "react";
import { formattedDateTime } from "../../HelperFunction";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import client from "./../../Assets/client.png";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { BsChatLeftText } from "react-icons/bs";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";

const { TextArea } = Input;

const EventDetails = ({ record, isDrawerOpen, setIsDrawerOpen }) => {
  const { eventById } = useSelector((store) => store?.EventsReducer);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState("eventDetails");

  console.log("testing", record);

  useEffect(() => {
    fetchLeadDetailData();
  }, []);

  const fetchLeadDetailData = () => {
    dispatch({
      type: "FETCH_EVENT_DETAILS_REQUEST",
      payload: { id: record?.event_id },
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  const clientDetailsTabList = [
    {
      key: "leadDetails",
      tab: <span style={{ fontSize: "1rem" }}>Details</span>,
    },
  ];

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween" style={{ gap: "1rem" }}>
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };
  const statusUpdateReloadFn = (response) => {
    dispatch({ type: "FETCH_LEAD_BY_ID_REQUEST", payload: record?.leadId });
    setTimeout(() => {}, 1000);
  };
  const navigate = useNavigate();

  return (
    <Drawer
      title="Event Details"
      open={isDrawerOpen}
      onClose={() => {
        setIsDrawerOpen(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.3rem" }} className="drawerStyle">
        <div
          className="flexRowWithoutStyle"
          style={{
            gap: "1rem",
            alignItems: "stretch",
          }}
        >
          {eventById?.image ? (
            <Image src={eventById?.image} className={"clientImage"} />
          ) : (
            <Image src={client} className={"clientImage"} />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: "10rem",
              height: "fit-content",
              width: "100%",
            }}
          >
            <div
              className="flexColumnWithoutStyle"
              style={{
                justifyContent: "flex-start",
                position: "relative",
                width: "100%",
                gap: "0.5rem",
              }}
            >
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                {`${eventById?.event_name ?? ""} (Lead ID: ${
                  eventById?.event_id
                })`}
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.4rem", color: "#aaa" }}
              >
                <span className="flexRowWithGap">
                  <AiOutlineMail
                    style={{
                      fontSize: "16",
                    }}
                  />{" "}
                  {eventById?.invited_users?.length > 0
                    ? eventById?.invited_users?.map((email) => `${email}, `)
                    : "N/A"}
                </span>
              </div>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.5rem",
                marginTop: "0.5rem",
                alignSelf: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <button className="emailAndMessage" onClick={() => {}}>
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>
              <button className="emailAndMessage" onClick={() => {}}>
                <AiOutlineMail />
                <span>Email</span>
              </button>
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "eventDetails" && (
            <>
              <div
                style={{
                  padding: "0.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    flexDirection: "row",
                  }}
                >
                  <Card
                    className="clientCard"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {detailFun(
                      "Event Name",
                      `${eventById?.event_name ?? "N/A"}`
                    )}

                    {detailFun("Send email automatically", `N/A`)}
                    {detailFun("Action", `${eventById?.action ?? "N/A"}`)}
                    {detailFun("Status", `${eventById?.status ?? "N/A"}`)}
                    {detailFun(
                      "Event Date",
                      `${eventById?.event_date ?? " N/A"}`
                    )}
                    {detailFun(
                      "Event Time",
                      `${eventById?.event_time ?? " N/A"}`
                    )}
                    {detailFun(
                      "Event End Date",
                      `${eventById?.end_date ?? " N/A"}`
                    )}
                    {detailFun(
                      "Event End Time",
                      `${eventById?.end_time ?? " N/A"}`
                    )}
                    {detailFun(
                      "Reminder Time",
                      `${eventById?.is_reminded ?? " N/A"}`
                    )}
                    {detailFun(
                      "Description",
                      `${eventById?.event_details ?? " N/A"}`
                    )}
                  </Card>
                </div>
              </div>
            </>
          )}
        </Card>
      </Card>
    </Drawer>
  );
};

export default EventDetails;
