import {
  Button,
  Card,
  Divider,
  Drawer,
  Empty,
  Form,
  Image,
  Input,
  Popconfirm,
  Skeleton,
  Tag,
  Timeline,
  message,
} from "antd";
import React, { useEffect } from "react";
import { formattedDateTime } from "../../HelperFunction";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import client from "./../../Assets/client.png";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { BsChatLeftText } from "react-icons/bs";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import UpdateTaskStatus from "./UpdateTaskStatus";
import PickupJob from "../../Components/pickupJob/PickupJob";

const { TextArea } = Input;

const ViewTask = ({ record, isDrawerOpen, setIsDrawerOpen, fetchTaskList }) => {
  const { taskById, isLoading } = useSelector((store) => store?.TaskReducer);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState("eventDetails");
  const [isStatusUpdateOpen, setIsStatusUpdateOpen] = useState(false);
  const [isPickupJobVisible, setIsPickupJobVisible] = useState(false);
  const [taskStatusById, setTaskStatusById] = useState(null);
  const [isAssignedUser, setIsAssignedUser] = useState(true);

  useEffect(() => {
    setIsAssignedUser(taskById?.task_users?.length > 0 ? true : false);
    setTaskStatusById(
      taskById?.status?.find(
        (data) => data?.status_id === taskById?.task_detail?.task_status
      )?.status_name ?? null
    );
  }, [taskById]);

  useEffect(() => {
    fetchTaskDetailData();
  }, []);

  const fetchTaskDetailData = () => {
    dispatch({
      type: "FETCH_TASK_DETAILS_REQUEST",
      payload: { id: record?.task_id },
      isSuccessFn: true,
      successFunction: () => {},
    });
  };

  const clientDetailsTabList = [
    {
      key: "leadDetails",
      tab: <span style={{ fontSize: "1rem" }}>Details</span>,
    },
  ];

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween" style={{ gap: "1rem" }}>
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };

  return (
    <Drawer
      title="Task Details"
      open={isDrawerOpen}
      onClose={() => {
        setIsDrawerOpen(false);
        fetchTaskList();
      }}
      footer={null}
      width={1163}
    >
      {isLoading ? (
        <Skeleton active={true} />
      ) : (
        <Card style={{ borderRadius: "0.3rem" }} className="drawerStyle">
          <div
            className="flexRowWithoutStyle"
            style={{
              gap: "1rem",
              alignItems: "stretch",
            }}
          >
            {taskById?.task_detail?.image ? (
              <Image
                src={taskById?.task_detail?.image}
                className={"clientImage"}
              />
            ) : (
              <Image src={client} className={"clientImage"} />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight: "10rem",
                height: "fit-content",
                width: "100%",
              }}
            >
              <div
                className="flexColumnWithoutStyle"
                style={{
                  justifyContent: "flex-start",
                  position: "relative",
                  width: "100%",
                  gap: "0.5rem",
                }}
              >
                <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {`${taskById?.task_detail?.task_name ?? ""} (Task ID: ${
                    taskById?.task_detail?.task_id
                  })`}
                </div>
                <div
                  className="flexRowWithoutStyle"
                  style={{ gap: "0.4rem", color: "#aaa" }}
                >
                  <span className="flexRowWithGap">
                    <AiOutlineMail
                      style={{
                        fontSize: "16",
                      }}
                    />{" "}
                    {taskById?.task_detail?.invited_users?.length > 0
                      ? taskById?.task_detail?.invited_users?.map(
                          (email) => `${email}, `
                        )
                      : "N/A"}
                  </span>
                </div>
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{
                  gap: "0.5rem",
                  marginTop: "0.5rem",
                  alignSelf: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <button className="emailAndMessage" onClick={() => {}}>
                  <BsChatLeftText style={{ fontSize: "10px" }} />
                  <span>Message</span>
                </button>
                <button className="emailAndMessage" onClick={() => {}}>
                  <AiOutlineMail />
                  <span>Email</span>
                </button>
                <button
                  className="emailAndMessage"
                  onClick={() => setIsStatusUpdateOpen(true)}
                >
                  {/* <AiOutlineMail /> */}
                  <span>Update Status</span>
                </button>

                {!isAssignedUser && taskStatusById?.trim() === "Pending" && (
                  <button
                    className="emailAndMessage"
                    onClick={() => {
                      setIsPickupJobVisible(true);
                    }}
                  >
                    <span>Pickup job</span>
                  </button>
                )}
                {taskStatusById?.trim() === "Assigned" && (
                  <Popconfirm
                    title="Do you want to complete task?"
                    onConfirm={() => {
                      dispatch({
                        type: "UPDATE_TASK_STATUS_REQUEST",
                        payload: {
                          id: taskById?.task_detail?.task_id,
                          formData: {
                            detail: "Task completed",
                            branch_id: localStorage.getItem("branchId"),
                            is_completed: true,
                            file_name: "",
                            employee_id: localStorage.getItem("thokyoUserId"),
                            date: null,
                            time: null,
                            estimated_hours: null,
                          },
                        },
                        isSuccessFn: true,
                        successFunction: (res) => {
                          message.success("Task status change to completed");
                          fetchTaskDetailData();
                        },
                        failureFunction: () => {},
                      });
                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button className="emailAndMessage">
                      <span>Complete Task</span>
                    </button>
                  </Popconfirm>
                )}
              </div>
            </div>
          </div>
          <Card
            bordered={false}
            tabList={clientDetailsTabList}
            activeTabKey={current}
            onTabChange={(key) => {
              setCurrent(key);
            }}
          >
            {current === "eventDetails" && (
              <>
                <div
                  style={{
                    padding: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      flexDirection: "row",
                    }}
                  >
                    <Card
                      className="clientCard"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      {detailFun(
                        "Task Name",
                        `${taskById?.task_detail?.task_name ?? "N/A"}`
                      )}

                      {detailFun("Project", `${record?.project_name ?? "N/A"}`)}
                      {detailFun(
                        "Priority",
                        <Tag>{taskById?.task_detail?.priority}</Tag>
                      )}

                      {detailFun(
                        "Start Date",
                        `${taskById?.task_detail?.start_date ?? " N/A"}`
                      )}

                      {detailFun(
                        "End Date",
                        `${taskById?.task_detail?.end_date ?? " N/A"}`
                      )}
                      {detailFun(
                        "Task Status",
                        // `${?.task_detail?.task_status ?? " N/A"}`
                        <Tag
                          style={{
                            background: taskById?.status?.find(
                              (data) =>
                                data?.status_id ===
                                taskById?.task_detail?.task_status
                            )?.code,
                            color: "white",
                          }}
                        >
                          {
                            taskById?.status?.find(
                              (data) =>
                                data?.status_id ===
                                taskById?.task_detail?.task_status
                            )?.status_name
                          }
                        </Tag>
                      )}

                      {detailFun(
                        "Assign To",
                        record?.assigned_employees?.map((data) => (
                          <Tag>
                            {data?.first_name} {data?.last_name}
                          </Tag>
                        ))
                      )}

                      {detailFun(
                        "Description",
                        `${taskById?.task_detail?.task_detail ?? " N/A"}`
                      )}
                    </Card>
                    <Card
                      className="clientCard"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <h3>Activity Log</h3>
                      <Divider style={{ margin: "0.5rem 0" }} />

                      <div
                        style={{
                          height: "100%",
                          maxHeight: "18rem",
                          overflowY: "scroll",
                          padding: "0.5rem 0 0 0",
                        }}
                      >
                        {taskById?.updates?.length > 0 ? (
                          <Timeline>
                            {taskById?.updates?.map((data) => (
                              <Timeline.Item>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span>
                                    {data?.first_name} {data?.last_name} (
                                    {data?.added_at})
                                  </span>
                                  <p>{data?.content}</p>
                                </div>
                              </Timeline.Item>
                            ))}
                          </Timeline>
                        ) : (
                          <Empty />
                        )}
                      </div>
                    </Card>
                  </div>
                </div>
              </>
            )}
          </Card>
        </Card>
      )}
      {isStatusUpdateOpen && (
        <UpdateTaskStatus
          record={taskById}
          isStatusUpdateOpen={isStatusUpdateOpen}
          setIsStatusUpdateOpen={setIsStatusUpdateOpen}
          fetchTaskDetailData={fetchTaskDetailData}
        />
      )}

      {isPickupJobVisible && (
        <PickupJob
          isPickupJobVisible={isPickupJobVisible}
          setIsPickupJobVisible={setIsPickupJobVisible}
          record={record}
          fetchTaskDetailData={fetchTaskDetailData}
        />
      )}
    </Drawer>
  );
};

export default ViewTask;
