import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

function JobBasket({
  isAddJobBasketVisible,
  setIsAddJobBasketVisible,
  fetchTaskList,
}) {
  const dispatch = useDispatch();
  const handleAddJobBasket = (values) => {
    dispatch({
      type: "ADD_JOB_BASKET_REQUEST",
      payload: { ...values, branch_id: localStorage.getItem("branchId") },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsAddJobBasketVisible(false);
        fetchTaskList();
      },
    });
  };
  return (
    <Modal
      open={isAddJobBasketVisible}
      onCancel={() => {
        setIsAddJobBasketVisible(false);
      }}
      title="Add Job Basket"
      footer={false}
    >
      <Form
        layout="vertical"
        initialValues={{ name: "Day2Day Task" }}
        onFinish={handleAddJobBasket}
      >
        <Form.Item
          name="name"
          label="Task title"
          rules={[
            {
              required: true,
              message: "Task title is required",
            },
          ]}
        >
          <Input placeholder="Task title" />
        </Form.Item>
        <Form.Item
          name="details"
          label="Task detail"
          rules={[
            {
              required: true,
              message: "Task detail is required",
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="Task detail" />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Add Job Basket
        </Button>
      </Form>
    </Modal>
  );
}

export default JobBasket;
