import {
  Button,
  Card,
  Checkbox,
  Empty,
  Form,
  Input,
  Spin,
  Timeline,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";

export default function OrderCustomerNote({ orderById, fetchOrderById }) {
  const dispatch = useDispatch();
  const [uploadFile, setUploadFile] = useState(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [customerNoteForm, setCustomerNoteForm] = useState(null);
  const [form] = Form.useForm();

  console.log("data data", orderById);

  useEffect(() => {
    fetchOrderCustomerNote();
  }, []);

  const fetchOrderCustomerNote = () => {
    dispatch({
      type: "FETCH_ORDER_CUSTOMER_NOTE",
      payload: orderById?.orders?.order_id,
      isSuccessFn: true,
      successFunction: (res) => {
        setCustomerNoteForm(res?.data);
        form.setFieldsValue({
          useremails: res?.data?.customer_arr?.email,
          other_email: res?.data?.update_emails[0] ?? null,
        });
      },
      failureFunction: () => {},
    });
  };

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    const formData = new FormData();
    formData.append("files", newFile);
    formData.append("branch_id", localStorage.getItem("branchId"));
    formData.append("path", "lms_project");

    setIsUploadLoading(true);
    dispatch({
      type: "UPLOAD_FILE_REQUEST",
      payload: formData,
      isSuccessFn: true,
      successFunction: (res) => {
        setUploadFile(res?.data?.source?.src);
        message.success("Upload file successful");
        setIsUploadLoading(false);
      },
      failureFunction: () => {
        setIsUploadLoading(false);
        message.error("Failed to upload file");
      },
    });
  };
  const handleSubmitRecord = (values) => {
    dispatch({
      type: "ADD_ORDER_CUSTOMER_NOTE",
      payload: {
        id: orderById?.orders?.order_id,
        formData: {
          comment: values?.comment,
          file_name: uploadFile ?? null,
          useremails: [values?.useremails],
          other_email: values?.other_email,
          copy_email: values?.copy_email ? 1 : 0,
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success("Customer note added");
        form.resetFields();
        fetchOrderCustomerNote();
        fetchOrderById();
      },
      failureFunction: () => {
        message.error("Fail to add customer note");
      },
    });
  };

  return (
    <div
      style={{ display: "grid", gridTemplateColumns: "2fr 2fr", gap: "1rem" }}
    >
      <Card>
        <Form
          form={form}
          initialValues={{
            copy_email: true,
            reminder_date: orderById?.orders?.reminder_date ?? null,
          }}
          layout="vertical"
          onFinish={handleSubmitRecord}
        >
          <Form.Item label="Reminder date" name="reminder_date">
            <Input readOnly placeholder="Reminder date" />
          </Form.Item>
          <Form.Item
            label="Customer Note"
            name="comment"
            rules={[{ required: true, message: "Note is required" }]}
          >
            <Input.TextArea rows={3} placeholder="Write comment" />
          </Form.Item>{" "}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              alignItems: "flex-start",
            }}
          >
            <Form.Item name={"file_name"} label="Add New Attachment">
              <Input
                onChange={handleFileChange}
                placeholder="Upload New Attachment"
                type="file"
              />
            </Form.Item>
            {isUploadLoading && (
              <span style={{ marginTop: "2rem" }}>
                <Spin />
              </span>
            )}
          </div>{" "}
          <Form.Item name="useremails" label="Contact Emails">
            <Input placeholder="Contact Emails" />
          </Form.Item>
          <Form.Item name="other_email" label="Other Emails">
            <Input.TextArea
              rows={3}
              placeholder="Add the valid email address seperated by comma."
            />
          </Form.Item>
          <Form.Item name="copy_email" valuePropName="checked">
            <Checkbox> Copy email to me</Checkbox>
          </Form.Item>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form>
      </Card>
      <Card style={{ width: "100%" }}>
        <Timeline mode={"left"}>
          {orderById?.orders?.comments?.length > 0 ? (
            orderById?.orders?.comments?.map((data) => (
              <Timeline.Item>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: "0" }}
                >
                  <p
                    style={{
                      margin: "0",
                      padding: "0",
                      color: "grey",
                      fontStyle: "italic",
                      fontSize: "15px",
                    }}
                  >
                    {data?.customer_note}
                  </p>
                  <p style={{ fontSize: "12px", margin: "0", padding: "0" }}>
                    {data?.from_name},<span> {data?.added_date}</span>{" "}
                  </p>
                </div>
              </Timeline.Item>
            ))
          ) : (
            <Empty description="No customer notes" />
          )}
        </Timeline>
      </Card>
    </div>
  );
}
