import {
  Button,
  Card,
  Divider,
  Drawer,
  Empty,
  Form,
  Image,
  Input,
  Tag,
  Timeline,
} from "antd";
import React, { useEffect } from "react";
import { formattedDateTime } from "../../HelperFunction";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import client from "./../../Assets/client.png";
import { GoLocation } from "react-icons/go";
import { useState } from "react";
import { BsChatLeftText } from "react-icons/bs";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import UpdateLeadStatus from "./UpdateLeadStatus";
import SendComposedMail from "../Mailbox/SendComposedMail";

const { TextArea } = Input;

const LeadDetails = ({
  leadData,
  Record,
  isLeadsDetailsModelVisible,
  setIsLeadsDetailsModelVisible,
}) => {
  const { leadById } = useSelector((store) => store?.LeadReducer);
  const [record, setRecord] = useState(Record);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState("leadDetails");
  const [isStatusUpdateOpen, setIsStatusUpdateOpen] = useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);

  useEffect(() => {
    fetchLeadDetailData();
  }, []);

  const fetchLeadDetailData = () => {
    dispatch({
      type: "FETCH_LEADS_DETAILS_REQUEST",
      payload: Record?.lead_id,
    });
  };

  const clientDetailsTabList = [
    {
      key: "leadDetails",
      tab: <span style={{ fontSize: "1rem" }}>Details</span>,
    },
  ];

  const detailFun = (title, value) => {
    return (
      <div style={{ width: "100%" }}>
        <div className="flexRowSpaceBetween" style={{ gap: "1rem" }}>
          {title}:<span>{value}</span>
        </div>
        <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
      </div>
    );
  };

  return (
    <Drawer
      title="Lead Details"
      open={isLeadsDetailsModelVisible}
      onClose={() => {
        setIsLeadsDetailsModelVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Card style={{ borderRadius: "0.3rem" }} className="drawerStyle">
        <div
          className="flexRowWithoutStyle"
          style={{
            gap: "1rem",
            alignItems: "stretch",
          }}
        >
          {leadById?.lead?.image ? (
            <Image src={leadById?.lead?.image} className={"clientImage"} />
          ) : (
            <Image src={client} className={"clientImage"} />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: "10rem",
              height: "fit-content",
              width: "100%",
            }}
          >
            <div
              className="flexColumnWithoutStyle"
              style={{
                justifyContent: "flex-start",
                position: "relative",
                width: "100%",
                gap: "0.5rem",
              }}
            >
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                {`${leadById?.lead?.lead_name ?? ""} (Lead ID: ${
                  leadById?.lead?.lead_id
                })`}
              </div>
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "0.4rem", color: "#aaa" }}
              >
                {leadById?.lead?.email && (
                  <span className="flexRowWithGap">
                    <AiOutlineMail
                      style={{
                        fontSize: "16",
                      }}
                    />{" "}
                    {leadById?.lead?.email ? leadById?.lead?.email : "N/A"}
                  </span>
                )}
              </div>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "0.5rem",
                marginTop: "0.5rem",
                alignSelf: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <button className="emailAndMessage" onClick={() => {}}>
                <BsChatLeftText style={{ fontSize: "10px" }} />
                <span>Message</span>
              </button>
              <button
                className="emailAndMessage"
                onClick={() => {
                  setIsSendComposedEmailModalVisible(true);
                }}
              >
                <AiOutlineMail />
                <span>Email</span>
              </button>

              <button
                className="emailAndMessage"
                onClick={() => setIsStatusUpdateOpen(true)}
              >
                {/* <AiOutlineMail /> */}
                <span>Update Status</span>
              </button>
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        >
          {current === "leadDetails" && (
            <>
              <div
                style={{
                  padding: "0.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    flexDirection: "row",
                  }}
                >
                  <Card
                    className="clientCard"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {detailFun(
                      "Lead Title",
                      `${leadById?.lead?.lead_name ?? "N/A"}`
                    )}

                    {detailFun(
                      "Company",
                      `${leadById?.lead?.company_name ?? "N/A"}`
                    )}
                    {detailFun("Email", `${leadById?.lead?.email ?? "N/A"}`)}
                    {detailFun(
                      "Phone",
                      `${leadById?.lead?.phone_number ?? "N/A"}`
                    )}
                    {detailFun(
                      "Priority",
                      leadById?.lead?.priority
                        ? // <Tag color={leadById?.lead?.color_code}>
                          //   {leadById?.lead?.priority}
                          // </Tag>
                          leadById?.lead?.priority
                        : "N/A"
                    )}
                    {detailFun(
                      "Status",
                      leadById?.lead?.priority ? (
                        <Tag color={leadById?.lead?.color_code}>
                          {leadById?.lead?.status}
                        </Tag>
                      ) : (
                        "N/A"
                      )
                    )}
                    {detailFun(
                      "Description",
                      `${leadById?.lead?.requirements ?? " N/A"}`
                    )}
                  </Card>

                  <Card
                    className="clientCard"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <h3>Activity Log</h3>
                    <Divider style={{ margin: "0.5rem 0" }} />

                    <div
                      style={{
                        height: "100%",
                        maxHeight: "15rem",
                        overflowY: "scroll",
                        padding: "0.5rem 0 0 0",
                      }}
                    >
                      {leadById?.comments?.length > 0 ? (
                        <Timeline>
                          {leadById?.comments?.map((data) => (
                            <Timeline.Item>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>
                                  {data?.first_name} {data?.last_name} (
                                  {data?.added_date})
                                </span>
                                <p>{data?.content}</p>
                              </div>
                            </Timeline.Item>
                          ))}
                        </Timeline>
                      ) : (
                        <Empty />
                      )}
                    </div>
                  </Card>
                </div>
              </div>
            </>
          )}
        </Card>
      </Card>
      {isStatusUpdateOpen && (
        <UpdateLeadStatus
          record={leadById}
          fetchLeadDetailData={fetchLeadDetailData}
          isStatusUpdateOpen={isStatusUpdateOpen}
          setIsStatusUpdateOpen={setIsStatusUpdateOpen}
        />
      )}
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          docsList={leadById?.docs?.map((data) => data?.doc_id) ?? []}
          emailList={[leadById?.lead?.email]}
          forWhatId={leadById?.lead?.lead_id}
          urlPath={"/send_lead_email"}
          fetchRecordDetail={fetchLeadDetailData}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
          emailType="lead"
        />
      )}
    </Drawer>
  );
};

export default LeadDetails;
