import React, { useState, useRef, useEffect } from "react";

const OtpModal = ({ otp, setOtp }) => {
  const { otp1, otp2, otp3, otp4 } = otp;

  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();

  const handleOtpChange = (e, inputRef) => {
    const inputOtp = e.target.value.split("");
    if (e.target.value.length === 1 && inputRef?.current) {
      inputRef.current.focus();
    }
    if (inputOtp.length > 1 && inputOtp.length <= 6) {
      inputOtp.map((num, index) => {
        setOtp((prev) => {
          return { ...prev, [`otp${index + 1}`]: num };
        });
      });
    } else {
      setOtp((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  };

  //   useEffect(() => {
  //     setOtpData(otp);
  //   }, [otp]);

  return (
    <div className="otp--container">
      <input
        maxLength={1}
        className="otp--input--field"
        onChange={(e) => handleOtpChange(e, input2)}
        name="otp1"
        value={otp1}
        type="number"
        required
        ref={input1}
      />
      <input
        maxLength={1}
        name="otp2"
        className="otp--input--field"
        value={otp2}
        onChange={(e) => handleOtpChange(e, input3)}
        type="number"
        required
        ref={input2}
      />
      <input
        maxLength={1}
        name="otp3"
        className="otp--input--field"
        value={otp3}
        onChange={(e) => handleOtpChange(e, input4)}
        type="number"
        required
        ref={input3}
      />
      <input
        maxLength={1}
        name="otp4"
        className="otp--input--field"
        value={otp4}
        onChange={(e) => handleOtpChange(e)}
        type="number"
        required
        ref={input4}
      />
    </div>
  );
};

export default OtpModal;
