import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Skeleton,
  Space,
  Tag,
  TimePicker,
} from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

const AddInhouseOrder = ({
  openAddInhouseDrawer,
  setOpenAddInhouseDrawer,
  existingQuoteRecord,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;
  const [isCheckedRecursive, setIsCheckedRecursive] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const [orderForm, setOrderForm] = useState(existingQuoteRecord);

  const [customerList, setCustomerList] = useState(
    existingQuoteRecord?.customers ?? []
  );
  const [customerDetail, setCustomerDetail] = useState(null);

  const handleSubmitRecord = (values) => {
    dispatch({
      type: "ADD_INHOUSE_ORDER_ATTEMPT",
      payload: {
        ...values,
        send_invoice:
          values?.send_invoice === "Customer"
            ? values?.custom_send_invoice
            : values?.send_invoice,
        branch_id: localStorage.getItem("branchId"),
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setOpenAddInhouseDrawer(false);
      },
      failureFunction: () => {},
    });
  };

  const handleSelectCustomer = (value, option) => {
    const tempCustomer = JSON.parse(option.key);
    setCustomerDetail(tempCustomer);
  };

  return (
    <Drawer
      title="Add Inhouse Order"
      placement="right"
      open={openAddInhouseDrawer}
      onClose={() => {
        setOpenAddInhouseDrawer(false);
      }}
      width={1163}
      footer={null}
    >
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Form
          layout="vertical"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
            maxWidth: "1024px",
            width: "100%",
          }}
          form={form}
          onFinish={handleSubmitRecord}
        >
          <div
            style={{
              gridColumn: "span 2",
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Form.Item
                style={{ width: "100%" }}
                name="customer"
                label="Customer"
              >
                <Select
                  onSearch={(value) => {
                    const searchKey = value.trim();
                    const test = existingQuoteRecord?.customers?.filter(
                      (employee) =>
                        employee.customer_name
                          .toLowerCase()
                          .includes(searchKey.toLowerCase())
                    );
                    setCustomerList(
                      searchKey === "" ? existingQuoteRecord?.customers : test
                    );
                  }}
                  showSearch
                  filterOption={false}
                  placeholder="Select Customer"
                  onChange={handleSelectCustomer}
                >
                  {customerList?.map((data) => (
                    <Option
                      key={JSON.stringify(data)}
                      value={data?.customer_id}
                    >
                      {data?.customer_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                style={{
                  width: "100%",
                }}
                label="Nature of Project"
                name="txt_name"
              >
                <Input placeholder="Nature of project" />
              </Form.Item>
            </div>
            <Card style={{ width: "100%" }}>
              <p>Customer No: {customerDetail?.customer_no ?? "N/A"}</p>{" "}
              <p>Name: {customerDetail?.customer_name ?? "N/A"}</p>
              <p>Company: {customerDetail?.company_name ?? "N/A"}</p>
              <p>Contact No: {customerDetail?.contact_number ?? "N/A"}</p>
              <p>Email: {customerDetail?.email ?? "N/A"}</p>
            </Card>
          </div>
          <Form.Item
            style={{
              width: "100%",
              gridColumn: "span 2",
            }}
            label="Project Features            "
            name="description"
          >
            <Input.TextArea rows={6} placeholder="description" />
          </Form.Item>
          <Form.Item
            style={{
              width: "100%",
            }}
            label="Date"
            name="date"
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            style={{
              width: "100%",
            }}
            label=" No of peoples"
            name="no_of_people"
          >
            <Input type="number" placeholder="No of people" />
          </Form.Item>
          <Form.Item
            style={{
              width: "100%",
            }}
            label="Order status"
            name="order_status"
          >
            <Select placeholder="Select status">
              {orderForm?.status?.map((data) => (
                <Select.Option value={data?.threatre_id}>
                  {data?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            style={{
              width: "100%",
            }}
            label="Deadline"
            name="deadline"
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Divider style={{ gridColumn: "span 2", margin: 0 }} />
          <Form.Item
            style={{
              width: "100%",
            }}
            name="recursive_invoice"
            valuePropName="checked"
          >
            <Checkbox
              onChange={(e) => {
                const isChecked = e.target.checked;
                setIsCheckedRecursive(isChecked);
                !isChecked &&
                  form.setFieldsValue({
                    txt_invoice_date: null,
                    send_invoice: null,
                    custom_send_invoice: null,
                    istart_date: null,
                    iend_date: null,
                  });

                !isChecked && setSelectedInvoice(null);
              }}
            >
              Recursive Invoice
            </Checkbox>
          </Form.Item>
          {isCheckedRecursive && (
            <div
              style={{
                gridColumn: "span 2",
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Form.Item
                  style={{
                    width: "30%",
                  }}
                  label="Send invoice before"
                  name="txt_invoice_date"
                >
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                    }}
                  >
                    <Input type="number" />
                  </span>
                </Form.Item>{" "}
                <span> Days</span>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <Form.Item
                  style={{
                    width: "100%",
                  }}
                  label="Send Invoice"
                  name="send_invoice"
                >
                  <Radio.Group
                    onChange={(e) => {
                      setSelectedInvoice(e.target.value);
                    }}
                  >
                    <Radio value={"Monthly"}>Monthly</Radio>
                    <Radio value={"Yearly"}>Yearly</Radio>
                    <Radio value={"Custom"}>Custom</Radio>
                  </Radio.Group>
                </Form.Item>
                {selectedInvoice === "Custom" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Form.Item
                      style={{ width: "40%" }}
                      name="custom_send_invoice"
                    >
                      <Input />
                    </Form.Item>
                    <span style={{ marginBottom: "2rem" }}>every day</span>
                  </div>
                )}
              </div>
            </div>
          )}
          {isCheckedRecursive && (
            <div
              style={{
                gridColumn: "span 2",
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
              }}
            >
              <Form.Item
                style={{
                  width: "100%",
                }}
                label="Invoice start date"
                name="istart_date"
              >
                <DatePicker format={"DD-MM-YYYY"} />
              </Form.Item>
              <Form.Item
                style={{
                  width: "100%",
                }}
                label="Invoice end date"
                name="iend_date"
              >
                <DatePicker format={"DD-MM-YYYY"} />
              </Form.Item>
            </div>
          )}

          <Divider style={{ gridColumn: "span 2", margin: 0 }} />
          <Form.Item
            style={{
              width: "100%",
            }}
            name="send_email"
            valuePropName="checked"
          >
            <Checkbox>Send email automatically </Checkbox>
          </Form.Item>
          <Form.Item
            style={{
              width: "100%",
            }}
            name="send_sms"
            valuePropName="checked"
          >
            <Checkbox>Send sms automatically</Checkbox>
          </Form.Item>

          <Button
            style={{ width: "fit-content", gridColumn: "span 2" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form>
      </div>
    </Drawer>
  );
};

export default AddInhouseOrder;
