import { Button, Form, Input, InputNumber, Modal, Select } from "antd";
import React from "react";
const { Option } = Select;

export default function AddUser({ isAddUser, setIsAddUser }) {
  return (
    <Modal
      title="Add User"
      footer={false}
      open={isAddUser}
      onCancel={() => {
        setIsAddUser(false);
      }}
    >
      <Form
        layout="vertical"
        style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}
      >
        <Form.Item
          rules={[{ required: true, message: "User role is required" }]}
          style={{ gridColumn: "span 2" }}
          label="User Role"
        >
          <Select placeholder="Select Role">
            <Option>Role1</Option>
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Firstname is required" }]}
          label="First Name"
        >
          <Input placeholder="Firstname" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Lastname is required" }]}
          label="Last Name"
        >
          <Input placeholder="Lastname" />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Please enter valid email" },
          ]}
          style={{ gridColumn: "span 2" }}
          label="Email"
        >
          <Input type="email" placeholder="Email" />
        </Form.Item>
        <Form.Item
          rules={[
            { min: 9, max: 11, message: "Phone number must be in 9 to 11" },
          ]}
          style={{ gridColumn: "span 2" }}
          label="Phone Number"
        >
          <InputNumber
            style={{ width: "100%" }}
            type="number"
            placeholder="Phone"
          />
        </Form.Item>{" "}
        <Form.Item
          rules={[{ required: true, message: "Password is required" }]}
          label="Password"
        >
          <Input.Password placeholder="Password	" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Confirm Password is required" }]}
          label="Confirm Password"
        >
          <Input.Password placeholder="Confirm Password	" />
        </Form.Item>
        <Button htmlType="submit" type="primary">
          Submit
        </Button>
        <Button onClick={() => setIsAddUser(false)} type="danger">
          Cancel
        </Button>
      </Form>
    </Modal>
  );
}
