import { Button, DatePicker, Form, Input, Modal, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export default function OrderStatusUpdate({
  isStatusUpdate,
  setIsStatusUpdate,
  fetchOrderById,
  record,
  orderById,
}) {
  const dispatch = useDispatch();
  const [statusForm, setStatusForm] = useState(null);
  const [form] = Form.useForm();

  console.log("testing", orderById);

  useEffect(() => {
    fetchOrderStatus();
  }, []);

  const fetchOrderStatus = () => {
    dispatch({
      type: "FETCH_ORDER_STATUS_FORM_ATTEMPT",
      payload: record?.order_id,

      isSuccessFn: true,
      successFunction: (res) => {
        setStatusForm(res?.data);
        form.setFieldsValue({
          userEmails: res?.data?.customer_arr?.email,
          other_emails: res?.data?.update_emails[0] ?? [],
        });
      },
      failureFunction: (err) => {},
    });
  };

  const handleSubmitRecord = (values) => {
    dispatch({
      type: "UPDATE_ORDER_STATUS_ATTEMPT",
      payload: {
        id: record?.order_id,
        formData: {
          ...values,
          userEmails: [values?.userEmails] ?? [],
          branch_id: localStorage.getItem("branchId"),
        },
      },

      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data);
        setIsStatusUpdate(false);
        fetchOrderById();
      },
      failureFunction: (err) => {
        message.error("Fail to update order status");
      },
    });
  };

  return (
    <Modal
      title="Order update status"
      open={isStatusUpdate}
      onCancel={() => setIsStatusUpdate(false)}
      width={500}
      footer={false}
    >
      <Form
        initialValues={{ order_status: orderById?.orders?.status_id }}
        layout="vertical"
        onFinish={handleSubmitRecord}
        form={form}
      >
        <div>
          <Form.Item name="userEmails" label="Contact Emails">
            <Input value={record?.customer_name} readOnly />
          </Form.Item>
        </div>
        <Form.Item
          rules={[{ required: true, message: "select order status" }]}
          label="Order status"
          name={"order_status"}
        >
          <Select placeholder="Select Status">
            {statusForm?.order_status?.map((data) => (
              <Select.Option value={data?.threatre_id}>
                {data?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Other Emails" name="other_emails">
          <Input.TextArea
            rows={3}
            placeholder="Add the valid email address seperated by comma."
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
}
