import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Tag,
  TimePicker,
} from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { flatMap } from "lodash";

const AddProject = ({
  isAddModalVisible,
  setIsAddModalVisible,
  fetchProjectList = () => {},
}) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { projectFormData } = useSelector((store) => store?.ProjectReducer);
  const [isExisting, setIsExisting] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    fetchProjectForm();

    // dispatch({
    //   type: "FETCH_PROJECT_EMPLOYEE_REQUEST",
    //   payload: {},
    //   isSuccessFn: false,
    //   successFunction: (res) => {},
    // });
  }, []);

  const handleCompanyList = (e) => {
    !e.target.checked &&
      form.setFieldsValue({
        [e.target.value]: [],
      });

    e.target.checked
      ? setCompanyList((prev) => [...prev, e.target.value])
      : setCompanyList((prev) => [
          ...prev?.filter((data) => data !== e.target.value),
        ]);
  };

  const fetchProjectForm = () => {
    dispatch({
      type: "FETCH_PROJECT_FORM_REQUEST",
      payload: {},
      isSuccessFn: false,
      successFunction: (res) => {
        console.log("res project", res);
        setOrderList(res?.data?.orders);
      },
    });
  };

  const handleAddProject = (values) => {
    dispatch({
      type: "ADD_PROJECT_REQUEST",
      payload: {
        branch_id: localStorage.getItem("branchId"),
        is_existing_order: isExisting ? "Yes" : "No",
        title: values?.title,
        status: values?.status,
        start_date: values?.start_date,
        end_date: values?.end_date,
        order: isExisting ? values?.order : null,
        detail: values?.detail,
        companies: companyList,
        assign_to:
          companyList?.length > 0
            ? flatMap(companyList?.map((cname) => values?.[cname]))
            : [],
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsAddModalVisible(false);
        fetchProjectList();
      },
    });
  };

  return (
    <Drawer
      title="Add Project"
      placement="right"
      open={isAddModalVisible}
      onClose={() => {
        setIsAddModalVisible(false);
      }}
      width={1163}
      footer={null}
    >
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Form
          layout="vertical"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
            maxWidth: "1024px",
            width: "100%",
          }}
          onFinish={handleAddProject}
          form={form}
        >
          <Form.Item
            style={{ gridColumn: "span 2" }}
            name="is_existing_order"
            label="Is Existing Order?"
          >
            <Radio.Group onChange={(e) => setIsExisting(e.target.value)}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {isExisting && (
            <Form.Item
              name="order"
              label="Order"
              style={{ gridColumn: "span 2" }}
            >
              <Select
                onSearch={(value) => {
                  const searchKey = value.trim();
                  const test = projectFormData?.orders?.filter((data) =>
                    data.product.toLowerCase().includes(searchKey.toLowerCase())
                  );
                  setOrderList(
                    searchKey === "" ? projectFormData?.orders : test
                  );
                }}
                showSearch
                filterOption={false}
                placeholder="Select Order"
              >
                {orderList?.map((ord) => (
                  <Select.Option key={ord?.product} value={ord?.order_id}>
                    {ord?.product}: {ord?.company_name} {ord?.customer_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <span style={{ gridColumn: "span 2" }}>Assign To</span>
          {projectFormData?.users?.map((cmpy) => (
            <div style={{ gridColumn: "span 2" }}>
              <Checkbox
                onChange={handleCompanyList}
                key={cmpy?.company}
                value={cmpy?.company_id}
              >
                {cmpy?.company}
              </Checkbox>
              {companyList?.includes(cmpy?.company_id) && (
                <Form.Item name={`${cmpy?.company_id}`}>
                  <Checkbox.Group
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gap: "0.5rem",
                      marginLeft: "1.5rem",
                    }}
                  >
                    {cmpy?.data?.map((usr) => (
                      <Checkbox value={usr?.userid}>
                        {usr?.first_name} {usr?.last_name}{" "}
                        <Tag>{usr?.employee_type}</Tag>
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              )}
            </div>
          ))}
          <Form.Item
            name="title"
            label="Project Title"
            rules={[{ required: true, message: "Project title is requried" }]}
          >
            <Input placeholder="Project Title" />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: "Project status is requried" }]}
          >
            <Select placeholder="Select Status">
              {projectFormData?.status?.map((stat) => (
                <Select.Option value={stat?.status_id}>
                  {stat?.status_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="start_date" label="Start Date">
            <DatePicker
              style={{ width: "100%" }}
              format={"DD-MM-YYYY"}
              placeholder="Select Start Date"
            />
          </Form.Item>
          <Form.Item name="end_date" label="End Date">
            <DatePicker
              style={{ width: "100%" }}
              format={"DD-MM-YYYY"}
              placeholder="Start End Date"
            />
          </Form.Item>

          <Form.Item
            name="detail"
            label="Project Description"
            rules={[{ required: true, message: "Project detail is requried" }]}
            style={{ gridColumn: "span 2" }}
          >
            <TextArea rows={8} placeholder="Write here..." />
          </Form.Item>

          <Button
            style={{ width: "fit-content" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form>
      </div>
    </Drawer>
  );
};

export default AddProject;
