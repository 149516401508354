import { Card, Empty, Skeleton, Timeline } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export default function AdminOrderNote({ record }) {
  const dispatch = useDispatch();

  const [adminNoteList, setAdminNoteList] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch({
      type: "FETCH_ORDER_ADMIN_NOTE_ATTEMPT",
      payload: record?.order_id,
      isSuccessFn: true,
      successFunction: (res) => {
        setAdminNoteList(res?.data);
        console.log("res data", res);
        setLoading(false);
      },
      failureFunction: (err) => {
        setLoading(false);
      },
    });
  }, []);
  return (
    <Card>
      {loading ? (
        <Skeleton active={true} />
      ) : adminNoteList?.admin_notes?.length > 0 ? (
        <Timeline mode={"left"}>
          {adminNoteList?.admin_notes?.map((data) => (
            <Timeline.Item>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "0" }}
              >
                <p
                  style={{
                    margin: "0",
                    padding: "0",
                    color: "grey",
                    fontStyle: "italic",
                    fontSize: "15px",
                  }}
                >
                  {data?.admin_note}
                </p>
                <p style={{ fontSize: "12px", margin: "0", padding: "0" }}>
                  {data?.first_name} {data?.last_name},
                  <span> {data?.added_date}</span>{" "}
                </p>
              </div>
            </Timeline.Item>
          ))}
        </Timeline>
      ) : (
        <Empty description="No admin notes" />
      )}
    </Card>
  );
}
