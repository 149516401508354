import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Skeleton,
  Space,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import OrderCustomer from "../../Components/OrderCustomer/OrderCustmer";
import moment from "moment";

const UpdatePrice = ({
  isEditPriceModalVisible,
  setIsEditPriceModalVisible,
  record,
  fetchOrderList,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [isAddCustomerModalVisible, setIsAddCustomerModalVisible] =
    useState(false);

  const [customerList, setCustomerList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [isGstApp, setIsGstApp] = useState();
  const [isloading, setIsloading] = useState(false);
  const [orderForm, setOrderForm] = useState(null);
  const [orderRecord, setOrderRecord] = useState(null);
  const [customerDetail, setCustomerDetail] = useState(null);

  useEffect(() => {
    fetchPresetOrderForm();
  }, []);

  const fetchPresetOrderForm = () => {
    setIsloading(true);
    dispatch({
      type: "FETCH_EDIT_ORDER_ATTEMPT",
      payload: record?.order_id,
      isSuccessFn: true,
      successFunction: (res) => {
        setOrderRecord(res?.data?.order);
        setOrderForm(res?.data);
        setCustomerDetail(res?.data?.customer);

        form.setFieldsValue({
          packages: res?.data?.order?.products?.map((data) => ({
            package_desc: data?.descriptions,
            short_desc: data?.short_desc,
            package_qty: Number(data?.quantity) ?? null,
            package_price: Number(data?.price) ?? null,
            package_amount: Number(data?.amount) ?? null,
          })),

          is_flat: res?.data?.order?.is_flat === "1" ? false : true,
          gst_applicable: Number(res?.data?.order?.gst_applicable) ?? null,
          gst: res?.data?.order?.gst,
          discount: res?.data?.order?.discount,
          price: res?.data?.order?.qprice,
          radio_gst: Number(res?.data?.order?.is_included) ?? null,
          minimum_deposit: res?.data?.order?.minimum_deposit,
          advance_payment: res?.data?.order?.advance_payment,
          payment_method: res?.data?.order?.payment_method,
          due: Number(record?.due_amount) ?? 0,
          total_price: res?.data?.order?.price,
          sub_total:
            res?.data?.order?.is_included === "0"
              ? Number(res?.data?.order?.price)
              : (
                  Number(res?.data?.order?.price) -
                  Number(res?.data?.order?.gst)
                ).toFixed(2),
        });

        setIsGstApp(Number(res?.data?.order?.gst_applicable) ?? null);

        setCustomerList(res?.data?.customers);
        setPackageList(res?.data?.package_types);
        setIsloading(false);
      },
      failureFunction: (err) => {
        setIsloading(false);
      },
    });
  };

  const handleSelectPackage = (value, option, index) => {
    const tempPackage = JSON.parse(option?.key);
    const tempPackageList = form.getFieldValue("packages");
    tempPackageList[index] = {
      package_desc: value,
      package_qty: 1,
      package_price: tempPackage?.price,
      package_amount: parseFloat(tempPackage?.price) * 1,
    };

    const tempPrice = tempPackageList?.reduce(
      (total, data) => total + parseFloat(data?.package_amount),
      0
    );

    const tempDiscount = form.getFieldValue("discount") ?? 0;
    const tempIsPercentage = form.getFieldValue("is_flat");

    const tempSubTotal = tempIsPercentage
      ? parseFloat(tempPrice) -
        (parseFloat(tempPrice) * parseFloat(tempDiscount)) / 100
      : parseFloat(tempPrice) - parseFloat(tempDiscount);

    const tempGstApplicable = form.getFieldValue("gst_applicable");
    const tempRadioGst = form.getFieldValue("radio_gst");

    const tempGst =
      tempGstApplicable === 1 && tempRadioGst === 1
        ? parseFloat(tempSubTotal) * 0.1
        : tempGstApplicable === 1 && tempRadioGst === 0
        ? parseFloat(tempSubTotal) / 11
        : 0;
    const tempTotalPrice =
      tempRadioGst === 1
        ? parseFloat(tempSubTotal) + parseFloat(tempGst)
        : parseFloat(tempSubTotal);

    form.setFieldsValue({
      packages: tempPackageList,
      price: Number(tempPrice).toFixed(2),
      sub_total: Number(tempSubTotal).toFixed(2),
      gst: Number(tempGst).toFixed(2),
      total_price: Number(tempTotalPrice).toFixed(2),
    });
  };

  const handlePerformCalculation = (index) => {
    const tempPackage = form.getFieldValue("packages");
    const changePackage = tempPackage[index];
    tempPackage[index] = {
      ...changePackage,
      package_amount:
        parseFloat(changePackage?.package_qty) *
        parseFloat(changePackage?.package_price),
    };

    const tempPrice = tempPackage?.reduce(
      (total, data) => total + parseFloat(data?.package_amount),
      0
    );

    const tempDiscount = form.getFieldValue("discount") ?? 0;
    const tempIsPercentage = form.getFieldValue("is_flat");

    const tempSubTotal = tempIsPercentage
      ? parseFloat(tempPrice) -
        (parseFloat(tempPrice) * parseFloat(tempDiscount)) / 100
      : parseFloat(tempPrice) - parseFloat(tempDiscount);

    const tempGstApplicable = form.getFieldValue("gst_applicable");
    const tempRadioGst = form.getFieldValue("radio_gst");
    const tempGst =
      tempGstApplicable === 1 && tempRadioGst === 1
        ? parseFloat(tempSubTotal) * 0.1
        : tempGstApplicable === 1 && tempRadioGst === 0
        ? parseFloat(tempSubTotal) / 11
        : 0;

    const tempTotalPrice =
      tempRadioGst === 1
        ? parseFloat(tempSubTotal) + parseFloat(tempGst)
        : parseFloat(tempSubTotal);

    form.setFieldsValue({
      packages: tempPackage,
      price: Number(tempPrice).toFixed(2),
      sub_total: Number(tempSubTotal).toFixed(2),
      gst: Number(tempGst).toFixed(2),
      total_price: Number(tempTotalPrice).toFixed(2),
    });
  };

  const handleSubmitRecord = (values) => {
    dispatch({
      type: "EDIT_ORDER_PRICE_ATTEMPT",
      payload: {
        id: record?.order_id,
        formData: {
          ...values,
          is_flat: values?.is_flat ? 0 : 1,
          note: "",
          packages: values?.packages?.map((data) => ({
            ...data,
            package_qty: Number(data?.package_qty) ?? null,
            package_price: Number(data?.package_price) ?? null,
            package_amount: Number(data?.package_amount) ?? null,
          })),

          branch_id: localStorage.getItem("branchId"),
        },
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setIsEditPriceModalVisible(false);
        message.success(res?.data);
        fetchOrderList();
      },
      failureFunction: () => {
        message.failure("Unable to update price");
      },
    });
  };

  const handleSelectCustomer = (value, option) => {
    const tempCustomer = JSON.parse(option.key);
    setCustomerDetail(tempCustomer);
  };

  return (
    <Drawer
      title="Update  Price"
      z
      placement="right"
      open={isEditPriceModalVisible}
      onClose={() => {
        setIsEditPriceModalVisible(false);
      }}
      width={1163}
      footer={null}
    >
      {isloading ? (
        <Card>
          <Skeleton active={true} />
        </Card>
      ) : (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Form
            layout="vertical"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
              maxWidth: "1024px",
              width: "100%",
            }}
            form={form}
            onFinish={handleSubmitRecord}
          >
            <div
              style={{
                gridColumn: "span 2",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "0.3rem",
              }}
            >
              <Form.List name="packages">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <Space
                          key={key}
                          align="baseline"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            padding: "1rem",
                            background: "#cfcfcf20",
                          }}
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "package_desc"]}
                            fieldKey={[fieldKey, "package_desc"]}
                            style={{ width: "100%" }}
                          >
                            <Select
                              onSearch={(value) => {
                                const searchKey = value.trim();
                                const test = orderForm?.package_types?.filter(
                                  (data) =>
                                    data?.name
                                      ?.toLowerCase()
                                      .includes(searchKey.toLowerCase())
                                );
                                setPackageList(
                                  searchKey === ""
                                    ? orderForm?.package_types
                                    : test
                                );
                              }}
                              showSearch
                              placeholder="Select package"
                              onChange={(value, option) =>
                                handleSelectPackage(value, option, index)
                              }
                            >
                              {packageList?.map((data) => (
                                <Option
                                  key={JSON.stringify(data)}
                                  // value={data?.threatre_id}
                                  value={data?.name}
                                >
                                  {data?.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "short_desc"]}
                            fieldKey={[fieldKey, "short_desc"]}
                          >
                            <Input placeholder="Short Description" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "package_qty"]}
                            fieldKey={[fieldKey, "package_qty"]}
                            rules={[
                              {
                                required: true,
                                message: "Quantity is required",
                              },
                            ]}
                          >
                            <Input
                              onChange={(e) => handlePerformCalculation(index)}
                              type="number"
                              placeholder="Quantity"
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "package_price"]}
                            fieldKey={[fieldKey, "package_price"]}
                            rules={[
                              { required: true, message: "price is required" },
                            ]}
                          >
                            <Input
                              onChange={(e) => handlePerformCalculation(index)}
                              type="number"
                              placeholder="Price"
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "package_amount"]}
                            fieldKey={[fieldKey, "package_amount"]}
                            rules={[
                              {
                                required: true,
                                message: "Total is required",
                              },
                            ]}
                          >
                            <Input readOnly type="number" placeholder="Total" />
                          </Form.Item>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              gap: "0.5rem",
                            }}
                          >
                            <MinusCircleOutlined
                              style={{
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                color: "red",
                              }}
                              onClick={() => {
                                const tempPackageList =
                                  form.getFieldValue("packages");
                                const tempPrice = form.getFieldValue("price");
                                const tempPackage = tempPackageList[index];

                                const newTempPrice =
                                  parseFloat(tempPrice) -
                                  parseFloat(tempPackage?.package_amount);

                                const tempDiscount =
                                  form.getFieldValue("discount") ?? 0;
                                const tempIsPercentage =
                                  form.getFieldValue("is_flat");

                                const tempSubTotal = tempIsPercentage
                                  ? parseFloat(newTempPrice) -
                                    (parseFloat(newTempPrice) *
                                      parseFloat(tempDiscount)) /
                                      100
                                  : parseFloat(newTempPrice) -
                                    parseFloat(tempDiscount);

                                const tempGstApplicable =
                                  form.getFieldValue("gst_applicable");
                                const tempRadioGst =
                                  form.getFieldValue("radio_gst");
                                const tempGst =
                                  tempGstApplicable === 1 && tempRadioGst === 1
                                    ? parseFloat(tempSubTotal) * 0.1
                                    : tempGstApplicable === 1 &&
                                      tempRadioGst === 0
                                    ? parseFloat(tempSubTotal) / 11
                                    : 0;

                                const tempTotalPrice =
                                  tempRadioGst === 1
                                    ? parseFloat(tempSubTotal) +
                                      parseFloat(tempGst)
                                    : parseFloat(tempSubTotal);

                                form.setFieldsValue({
                                  price: Number(newTempPrice).toFixed(2),
                                  sub_total: Number(tempSubTotal).toFixed(2),
                                  gst: Number(tempGst).toFixed(2),
                                  total_price:
                                    Number(tempTotalPrice).toFixed(2),
                                });

                                remove(name);
                              }}
                            />
                          </div>
                        </Space>
                      )
                    )}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Package
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
            <Form.Item
              name={"price"}
              label="Regular Price"
              rules={[{ required: true, message: "Regular price is required" }]}
            >
              <Input readOnly type="number" placeholder="Regular price" />
            </Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Form.Item name={"discount"} label="Discount">
                <InputNumber
                  onChange={(value) => {
                    const tempIsPercentage = form.getFieldValue("is_flat");
                    const tempPrice = form.getFieldValue("price");
                    const tempSubTotal = tempIsPercentage
                      ? parseFloat(tempPrice) -
                        (parseFloat(tempPrice) * value) / 100
                      : parseFloat(tempPrice) - value;

                    const tempGstApplicable =
                      form.getFieldValue("gst_applicable");
                    const tempRadioGst = form.getFieldValue("radio_gst");
                    const tempGst =
                      tempGstApplicable === 1 && tempRadioGst === 1
                        ? parseFloat(tempSubTotal) * 0.1
                        : tempGstApplicable === 1 && tempRadioGst === 0
                        ? parseFloat(tempSubTotal) / 11
                        : 0;

                    const tempTotalPrice =
                      tempRadioGst === 1
                        ? parseFloat(tempSubTotal) + parseFloat(tempGst)
                        : parseFloat(tempSubTotal);

                    form.setFieldsValue({
                      sub_total: Number(tempSubTotal).toFixed(2),
                      gst: Number(tempGst).toFixed(2),
                      total_price: Number(tempTotalPrice).toFixed(2),
                    });
                  }}
                  type="number"
                  placeholder="Discount"
                />
              </Form.Item>
              <Form.Item
                style={{ marginTop: "1.5rem" }}
                valuePropName="checked"
                name={"is_flat"}
              >
                <Checkbox
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    const tempPrice = form.getFieldValue("price");
                    const tempDiscount = form.getFieldValue("discount") ?? 0;

                    const tempSubTotal = isChecked
                      ? parseFloat(tempPrice) -
                        (parseFloat(tempPrice) * parseFloat(tempDiscount)) / 100
                      : parseFloat(tempPrice) - parseFloat(tempDiscount);

                    const tempGstApplicable =
                      form.getFieldValue("gst_applicable");
                    const tempRadioGst = form.getFieldValue("radio_gst");
                    const tempGst =
                      tempGstApplicable === 1 && tempRadioGst === 1
                        ? parseFloat(tempSubTotal) * 0.1
                        : tempGstApplicable === 1 && tempRadioGst === 0
                        ? parseFloat(tempSubTotal) / 11
                        : 0;

                    const tempTotalPrice =
                      tempRadioGst === 1
                        ? parseFloat(tempSubTotal) + parseFloat(tempGst)
                        : parseFloat(tempSubTotal);

                    form.setFieldsValue({
                      sub_total: Number(tempSubTotal).toFixed(2),
                      gst: tempGst.toFixed(2),
                      total_price: Number(tempTotalPrice).toFixed(2),
                    });
                  }}
                >
                  Is Percentage?
                </Checkbox>
              </Form.Item>
            </div>
            <Form.Item name={"sub_total"} label="Sub Total">
              <Input readOnly type="number" placeholder="Sub total" />
            </Form.Item>
            <Form.Item name={"gst_applicable"} label="GST Applicable">
              <Radio.Group
                onChange={(e) => {
                  const tempGstApplicable = e.target.value;
                  setIsGstApp(tempGstApplicable);

                  const tempSubTotal = form.getFieldValue("sub_total");
                  const tempRadioGst = form.getFieldValue("radio_gst");

                  const tempGst =
                    tempGstApplicable === 1 && tempRadioGst === 1
                      ? parseFloat(tempSubTotal) * 0.1
                      : tempGstApplicable === 1 && tempRadioGst === 0
                      ? parseFloat(tempSubTotal) / 11
                      : 0;

                  const tempTotalPrice =
                    tempRadioGst === 1
                      ? parseFloat(tempSubTotal) + parseFloat(tempGst)
                      : parseFloat(tempSubTotal);

                  form.setFieldsValue({
                    gst:
                      tempGstApplicable === 1 ? Number(tempGst).toFixed(2) : 0,
                    total_price: Number(tempTotalPrice).toFixed(2),
                  });
                }}
              >
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </Form.Item>
            {isGstApp === 1 && (
              <div
                style={{
                  gridColumn: "span 2",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                }}
              >
                <Form.Item name={"radio_gst"}>
                  <Radio.Group
                    onChange={(e) => {
                      const tempRadioGst = e.target.value;
                      const tempGstApplicable =
                        form.getFieldValue("gst_applicable");
                      const tempSubTotal = form.getFieldValue("sub_total");

                      const tempGst =
                        tempGstApplicable === 1 && tempRadioGst === 1
                          ? parseFloat(tempSubTotal) * 0.1
                          : tempGstApplicable === 1 && tempRadioGst === 0
                          ? parseFloat(tempSubTotal) / 11
                          : 0;

                      form.setFieldsValue({
                        gst: Number(tempGst).toFixed(2),
                        total_price:
                          tempRadioGst === 1
                            ? (
                                parseFloat(tempSubTotal) + parseFloat(tempGst)
                              ).toFixed(2)
                            : parseFloat(tempSubTotal).toFixed(2),
                      });
                    }}
                  >
                    <Radio value={0}> Price including GST</Radio>
                    <Radio value={1}>Price excluding GST </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item name={"gst"} label="GST">
                  <Input type="number" placeholder="GST" />
                </Form.Item>
              </div>
            )}
            <Form.Item name={"total_price"} label="Total Price">
              <Input readOnly type="number" placeholder="Total Price" />
            </Form.Item>
            <Form.Item name={"minimum_deposit"} label="Minimum deposit (%)">
              <Input type="number" placeholder="Minimunm deposit" />
            </Form.Item>
            <Form.Item name={"due"} label="Due">
              <Input readOnly type="number" placeholder="Minimunm deposit" />
            </Form.Item>
            <Form.Item name={"advance_payment"} label="Advance payment">
              <Input type="number" placeholder="Advance payment" />
            </Form.Item>
            <Form.Item name={"payment_method"} label="Payment method">
              <Select placeholder="Payment method">
                <Option value="Bank Transfer">Bank Transfer</Option>
                <Option value="Credit Card">Credit Card</Option>
                <Option value="COD">COD</Option>
                <Option value="Paypal">Paypal</Option>
              </Select>
            </Form.Item>

            <Button
              style={{ width: "fit-content", gridColumn: "span 2" }}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form>
        </div>
      )}
    </Drawer>
  );
};

export default UpdatePrice;
